import { useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import { Redo, Undo } from "@mui/icons-material";
import { IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import { useOpenState } from "src/utils/useOpenState";
import { AppPopper } from "../AppPopper/AppPopper";
import { QueryExamplesModalContent } from "../QueryExamplesModalContent/QueryExamplesModalContent";
import { AppPopperArrowSize } from "../AppPopper/components/AppPopperArrow/AppPopperArrow.model";

const MAXACTIONNUMBER = 20;

export function CodeEditorControls({
  value,
  onChange,
  hideTitle = false,
}: {
  value?: string;
  onChange: (value?: string) => void;
  hideTitle?: boolean;
}) {
  const popperState = useOpenState();
  const containerRef = useRef<HTMLDivElement>(null);

  const [actionList, setActionList] = useState<Array<string>>([""]);
  const [currentActionListIndex, setCurrentActionListIndex] = useState(0);

  const [debouncedValue] = useDebounce(value, 1000);

  useEffect(() => {
    if (!debouncedValue) return;
    if (debouncedValue === actionList[currentActionListIndex]) return;
    if (debouncedValue === actionList[currentActionListIndex - 1]) return;
    if (currentActionListIndex < actionList.length - 1) return;

    setCurrentActionListIndex((prevValue) => {
      if (prevValue === MAXACTIONNUMBER - 1) return prevValue;
      return prevValue + 1;
    });

    if (actionList.length !== MAXACTIONNUMBER) {
      return setActionList((prevValue) => {
        const updatedArray = [...prevValue, debouncedValue];
        return updatedArray;
      });
    }

    setActionList((prevValue) => [...prevValue.slice(1), debouncedValue]);
  }, [debouncedValue, actionList, currentActionListIndex]);

  const handleUndoChange = () => {
    setCurrentActionListIndex((prevValue) => {
      if (prevValue === 0) return prevValue;
      onChange(actionList[prevValue - 1]);
      return prevValue - 1;
    });
  };
  const handleRedoChange = () => {
    setCurrentActionListIndex((prevValue) => {
      if (prevValue === MAXACTIONNUMBER - 1) return prevValue;
      onChange(actionList[prevValue + 1]);
      return prevValue + 1;
    });
  };

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {!hideTitle && (
        <Typography
          variant="subtitle1"
          display="flex"
          alignItems="center"
          ref={containerRef}
        >
          Custom Query
          <SvgIcon
            sx={{ ml: 1, cursor: "pointer", width: "14px", height: "14px" }}
            onClick={popperState.show}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="8" cy="8" r="8" fill="#B3BEC3" />
              <path
                d="M9.34096 4.29223C9.42837 4.85542 9.04311 5.30439 8.49571 5.38935C7.9483 5.4743 7.44719 5.1629 7.35979 4.5997C7.27238 4.03651 7.65553 3.58787 8.20294 3.50291C8.74929 3.41812 9.25356 3.72904 9.34096 4.29223Z"
                fill="white"
              />
              <path
                d="M10 11C10.0479 11.1629 9.63036 11.8539 9.5 12C8.3306 13.3109 8.5555 13.1377 7.43964 13.3109C6.6634 13.3106 6.27004 12.8391 6.35297 12.2829L6.98711 7.93796C6.97951 7.83483 6.99153 7.64965 6.94888 7.64142C6.83805 7.62196 6.65004 7.7859 6.5 8L5.75085 8.77136C5.70293 8.60847 5.68085 8.32729 5.66354 8.2157C6.16814 7.18116 7.12137 6.27966 7.84668 6.16709C8.54455 6.12778 8.95063 6.60486 8.92242 7.20109L8.2847 11.3851C8.28254 11.4365 8.29882 11.4871 8.3306 11.5276C8.36239 11.5681 8.40759 11.596 8.45808 11.6061C8.56874 11.6245 8.85029 11.7162 9 11.5L10 11Z"
                fill="white"
              />
              <path
                d="M8.49571 5.38935C9.04311 5.30439 9.42837 4.85542 9.34096 4.29223C9.25356 3.72904 8.74929 3.41812 8.20294 3.50291C7.65553 3.58787 7.27238 4.03651 7.35979 4.5997C7.44719 5.1629 7.9483 5.4743 8.49571 5.38935ZM9.5 12C9.63036 11.8539 10.0479 11.1629 10 11L9 11.5C8.85029 11.7162 8.56874 11.6245 8.45808 11.6061C8.40759 11.596 8.36239 11.5681 8.3306 11.5276C8.29882 11.4871 8.28254 11.4365 8.2847 11.3851L8.92242 7.20109C8.95063 6.60486 8.54455 6.12778 7.84668 6.16709C7.12137 6.27966 6.16814 7.18116 5.66354 8.2157C5.68085 8.32729 5.70293 8.60847 5.75085 8.77136L6.5 8C6.65004 7.7859 6.83805 7.62196 6.94888 7.64142C7.00363 7.65198 6.96829 7.95413 7 8C7.03171 8.04587 7.00946 7.79465 7 7.84961L6.35297 12.2829C6.27004 12.8391 6.6634 13.3106 7.43964 13.3109C8.5555 13.1377 8.3306 13.3109 9.5 12Z"
                stroke="white"
                stroke-width="0.2"
              />
            </svg>
          </SvgIcon>
        </Typography>
      )}
      <Stack
        flexDirection="row"
        justifyContent="end"
        alignItems="center"
        gap={2.5}
      >
        <IconButton
          disabled={currentActionListIndex === 0}
          onClick={handleUndoChange}
          edge="end"
          sx={{ height: 30 }}
        >
          <Undo />
        </IconButton>
        <IconButton
          disabled={currentActionListIndex === actionList.length - 1}
          onClick={handleRedoChange}
          edge="end"
          sx={{ height: 30 }}
        >
          <Redo />
        </IconButton>
      </Stack>
      <AppPopper
        onClose={popperState.hide}
        open={popperState.isOpen}
        anchorEl={containerRef.current}
        placement="right"
        arrow={AppPopperArrowSize.medium}
        children={<QueryExamplesModalContent onClose={popperState.hide} />}
      />
    </Stack>
  );
}
