import axios from "axios";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { watchQueryKey } from "./useWatchQuery";
import { watchTermResultQueryKey } from "./useWatchTermResults";
import { OriginType } from "./useWatchQueryAlertCreate";
import { watchQueryListKey } from "./useWatchQueryList";
import { watchQueryListLazyKey } from "./useWatchQueryListLazy";
import { watchTermResultQueryInfiniteKey } from "./useWatchTermResultsInfinite";

const apiPath: keyof paths = "/api/user/query/{queryId}";
const method: keyof paths[typeof apiPath] = "put";

type Endpoint = paths[typeof apiPath][typeof method];

type RequestBody =
  Required<Endpoint>["requestBody"]["content"]["application/json"];

type Response = Endpoint["responses"][200];
type ResponseBody = Response["content"]["application/json"];

type UseWatchQueryUpdate = Omit<
  UseMutationOptions<ResponseBody, unknown, RequestBody>,
  "mutationFn"
> & {
  origin?: OriginType;
};

const getQueryKeyName = (origin: OriginType) => {
  switch (origin) {
    case "edit":
      return [watchQueryKey];
    case "create":
      return [watchQueryListKey];
    case "watchlist":
      return [watchQueryListKey, watchQueryListLazyKey];
  }
};

function getSuccessMessage(data: ResponseBody, origin?: OriginType) {
  if (origin) {
    const isAlertsEnabled = data.alerts.every((alert) => alert.enabled);
    return `The Scheduled alert for the "${
      data.title
    }" Watch term was successfully ${
      isAlertsEnabled ? "activated" : "deactivated"
    }.`;
  }

  return `The "${data.title}" Watch term was successfully modified.`;
}

export function useWatchQueryUpdate({
  options,
}: {
  options?: UseWatchQueryUpdate;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation<ResponseBody, unknown, RequestBody>({
    ...options,
    mutationKey: ["watch-query-modify"],
    mutationFn: async (requestBody) => {
      const apiUrl = makeApiUrl(apiPath, {
        path: {
          queryId: requestBody.id,
        },
      });

      const token = await getAccessTokenSilently();

      const response = await axios<ResponseBody>(apiUrl, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: requestBody,
      });

      return response.data;
    },
    onSuccess: (data, variables, context) => {
      const successMessage = getSuccessMessage(data, options?.origin);
      enqueueSnackbar(successMessage, {
        variant: options?.origin ? undefined : "success",
      });

      if (!options?.origin) {
        queryClient.refetchQueries({
          queryKey: [watchQueryKey, data.id],
        });

        queryClient.refetchQueries({
          queryKey: watchTermResultQueryKey,
        });

        queryClient.refetchQueries({
          queryKey: watchTermResultQueryInfiniteKey,
        });
      }

      if (options?.origin) {
        const queryKeyName = getQueryKeyName(options?.origin);
        queryKeyName.forEach((keyName) => {
          queryClient.refetchQueries({
            queryKey: keyName,
          });
        });
      }

      options?.onSuccess && options.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });

      options?.onError && options.onError(error, variables, context);
    },
    onSettled(data, error, variables, context) {
      options?.onSettled && options.onSettled(data, error, variables, context);
    },
  });
}
