import { EventSourceOption } from "src/api/useEventSources";
import { TagPickerValue } from "src/components/TagPicker/TagPicker.model";

const groupOrder = [
  "EventTypeComposite",
  "CountryCode",
  "Country:MarketState",
  "MarketID",
  "StationGUID",
];
//Sort EventSourceOptions by group with groupOrder index order, and also sort by name to match BE order to find the right preset.
export const sortIncludeExcludeValues = (
  values: TagPickerValue<EventSourceOption>
): TagPickerValue<EventSourceOption> => {
  const sortedValues = values?.list?.slice().sort((a, b) => {
    const orderA =
      groupOrder.indexOf(a.group) !== -1
        ? groupOrder.indexOf(a.group)
        : groupOrder.length;
    const orderB =
      groupOrder.indexOf(b.group) !== -1
        ? groupOrder.indexOf(b.group)
        : groupOrder.length;

    if (orderA !== orderB) {
      return orderA - orderB;
    }

    return a.name.localeCompare(b.name);
  });
  const newSortedValues = { ...values, list: sortedValues };
  return newSortedValues;
};
