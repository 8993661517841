import { useMemo } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

export const DownloadCSV = ({
  disabled,
  color,
  width = "24px",
  height = "24px",
  fillColor,
  labelColor,
  sx,
  ...props
}: Omit<SvgIconProps, "color"> & {
  disabled?: boolean;
  color?: string;
  fillColor?: string;
  labelColor?: string;
}) => {
  const { palette } = useTheme();

  const iconColor = useMemo(() => {
    if (disabled) {
      return palette.action.disabled;
    }
    return color ? color : palette.primary.main;
  }, [disabled, palette.action.disabled, color, palette.primary.main]);

  const CSVLabelColor = labelColor
    ? labelColor
    : color
    ? palette.primary.main
    : "white";

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        style={{
          width,
          height,
        }}
      >
        <path
          d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2ZM14 9H13V4L18 9H14Z"
          fill={fillColor || iconColor}
        />
        <path
          d="M9.09031 18.0599C8.82031 18.0599 8.56865 18.0166 8.33531 17.9299C8.10531 17.8399 7.90531 17.7133 7.73531 17.5499C7.56531 17.3866 7.43198 17.1949 7.33531 16.9749C7.24198 16.7549 7.19531 16.5133 7.19531 16.2499C7.19531 15.9866 7.24198 15.7449 7.33531 15.5249C7.43198 15.3049 7.56531 15.1133 7.73531 14.9499C7.90865 14.7866 8.11031 14.6616 8.34031 14.5749C8.57031 14.4849 8.82198 14.4399 9.09531 14.4399C9.39865 14.4399 9.67198 14.4933 9.91531 14.5999C10.162 14.7033 10.3686 14.8566 10.5353 15.0599L10.0153 15.5399C9.89531 15.4033 9.76198 15.3016 9.61531 15.2349C9.46865 15.1649 9.30865 15.1299 9.13531 15.1299C8.97198 15.1299 8.82198 15.1566 8.68531 15.2099C8.54865 15.2633 8.43031 15.3399 8.33031 15.4399C8.23031 15.5399 8.15198 15.6583 8.09531 15.7949C8.04198 15.9316 8.01531 16.0833 8.01531 16.2499C8.01531 16.4166 8.04198 16.5683 8.09531 16.7049C8.15198 16.8416 8.23031 16.9599 8.33031 17.0599C8.43031 17.1599 8.54865 17.2366 8.68531 17.2899C8.82198 17.3433 8.97198 17.3699 9.13531 17.3699C9.30865 17.3699 9.46865 17.3366 9.61531 17.2699C9.76198 17.1999 9.89531 17.0949 10.0153 16.9549L10.5353 17.4349C10.3686 17.6383 10.162 17.7933 9.91531 17.8999C9.67198 18.0066 9.39698 18.0599 9.09031 18.0599Z"
          fill={CSVLabelColor}
        />
        <path
          d="M12.3828 18.0599C12.1028 18.0599 11.8344 18.0233 11.5778 17.9499C11.3211 17.8733 11.1144 17.7749 10.9578 17.6549L11.2328 17.0449C11.3828 17.1516 11.5594 17.2399 11.7628 17.3099C11.9694 17.3766 12.1778 17.4099 12.3878 17.4099C12.5478 17.4099 12.6761 17.3949 12.7728 17.3649C12.8728 17.3316 12.9461 17.2866 12.9928 17.2299C13.0394 17.1733 13.0628 17.1083 13.0628 17.0349C13.0628 16.9416 13.0261 16.8683 12.9528 16.8149C12.8794 16.7583 12.7828 16.7133 12.6628 16.6799C12.5428 16.6433 12.4094 16.6099 12.2628 16.5799C12.1194 16.5466 11.9744 16.5066 11.8278 16.4599C11.6844 16.4133 11.5528 16.3533 11.4328 16.2799C11.3128 16.2066 11.2144 16.1099 11.1378 15.9899C11.0644 15.8699 11.0278 15.7166 11.0278 15.5299C11.0278 15.3299 11.0811 15.1483 11.1878 14.9849C11.2978 14.8183 11.4611 14.6866 11.6778 14.5899C11.8978 14.4899 12.1728 14.4399 12.5028 14.4399C12.7228 14.4399 12.9394 14.4666 13.1528 14.5199C13.3661 14.5699 13.5544 14.6466 13.7178 14.7499L13.4678 15.3649C13.3044 15.2716 13.1411 15.2033 12.9778 15.1599C12.8144 15.1133 12.6544 15.0899 12.4978 15.0899C12.3411 15.0899 12.2128 15.1083 12.1128 15.1449C12.0128 15.1816 11.9411 15.2299 11.8978 15.2899C11.8544 15.3466 11.8328 15.4133 11.8328 15.4899C11.8328 15.5799 11.8694 15.6533 11.9428 15.7099C12.0161 15.7633 12.1128 15.8066 12.2328 15.8399C12.3528 15.8733 12.4844 15.9066 12.6278 15.9399C12.7744 15.9733 12.9194 16.0116 13.0628 16.0549C13.2094 16.0983 13.3428 16.1566 13.4628 16.2299C13.5828 16.3033 13.6794 16.3999 13.7528 16.5199C13.8294 16.6399 13.8678 16.7916 13.8678 16.9749C13.8678 17.1716 13.8128 17.3516 13.7028 17.5149C13.5928 17.6783 13.4278 17.8099 13.2078 17.9099C12.9911 18.0099 12.7161 18.0599 12.3828 18.0599Z"
          fill={CSVLabelColor}
        />
        <path
          d="M15.6413 17.9999L14.1313 14.4999H15.0063L16.3263 17.5999H15.8113L17.1512 14.4999H17.9563L16.4412 17.9999H15.6413Z"
          fill={CSVLabelColor}
        />
      </svg>
    </SvgIcon>
  );
};
