import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { enqueueSnackbar } from "notistack";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { paths } from "@tveyes/twosionwebapischema";
import { useTranslation } from "react-i18next";
import { useSessionContext } from "./useSessionContext";
import { watchQueryListLazyKey } from "./useWatchQueryListLazy";
import { watchQueryListKey } from "./useWatchQueryList";

const template: keyof paths = "/api/user/{userId}/query";
const method: keyof paths[typeof template] = "post";

type Endpoint = paths[typeof template][typeof method];

type RequestBody =
  Required<Endpoint>["requestBody"]["content"]["application/json"];
// FIXME: remove Required<Endpoint> endpoint if requestBody is no longer optional
// prettier-ignore
type RequestParams = Omit<RequestBody, "id" | "userId" | "sort" | "alerts"> & {
  alerts?: RequestBody["alerts"];
};

type Response = Endpoint["responses"][200];
type ResponseBody = Response["content"]["application/json"];

type UseWatchQueryCreate = Omit<
  UseMutationOptions<ResponseBody, unknown, RequestParams>,
  "mutationFn"
>;

export function useWatchQueryCreate({
  options,
}: {
  options?: UseWatchQueryCreate;
}) {
  const { t } = useTranslation();

  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const sessionData = useSessionContext();
  const apiPath = template.replace("{userId}", `${sessionData.userId}`);

  return useMutation<ResponseBody, unknown, RequestParams>({
    ...options,
    mutationKey: [apiPath],
    mutationFn: async (requestParams) => {
      const token = await getAccessTokenSilently();
      const requestBody: RequestBody = {
        userId: sessionData.userId,
        // FIXME: for some reason this is required by backend
        id: "00000000-0000-0000-0000-000000000000",
        sort: 0,
        ...requestParams,
        alerts: requestParams.alerts ?? [],
      };

      const response = await axios<ResponseBody>(apiPath, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: requestBody,
      });

      return response.data;
    },
    onSuccess: (data, variables, context) => {
      enqueueSnackbar(
        t("watchTermCreateMessage", { termName: `"${data.title}"` }),
        {
          variant: "success",
        }
      );

      options?.onSuccess && options.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });

      options?.onError && options.onError(error, variables, context);
    },
    onSettled(data, error, variables, context) {
      queryClient.refetchQueries({
        queryKey: watchQueryListLazyKey,
      });

      queryClient.refetchQueries({
        queryKey: watchQueryListKey,
      });

      options?.onSettled && options.onSettled(data, error, variables, context);
    },
  });
}
