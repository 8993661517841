import { alpha, Palette } from "@mui/material";
import { EventType } from "src/models/EventType";

const reportColordDarkTheme = "rgb(144, 202, 249)";

export function getThemeColor(palette: Palette, eventType?: string) {
  if (palette.mode === "dark") {
    return eventType !== EventType.Report
      ? palette.primary.dark
      : reportColordDarkTheme;
  }
  return palette.primary.light;
}

export function getBgColor(palette: Palette, color: string) {
  if (palette.mode === "light") return alpha(color, 0.04);
  return alpha(color, 0.08);
}

export function getIconColor(palette: Palette, color: string) {
  if (palette.mode === "light") return alpha(color, 0.08);
  return alpha(color, 0.3);
}
