import { Chip, Stack, Tooltip } from "@mui/material";
import { format } from "date-fns";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { AccountsManagementItemMenuButton } from "src/pages/AccountsManagementEditAccount/components/AccountsManagementItemMenuButton/AccountsManagementItemMenuButton";
import { AppLink } from "src/components/AppLink/AppLink";
import { accountsManagementEditAccountRoute } from "src/pages/AccountsManagementEditAccount/AccountsManagementEditAccount.route";
import { accountsManagementActivityLogsRoute } from "src/pages/AccountsManagementActivityLogs/AccountsManagementActivityLogs.route";
import { AccountsManagementTableClasses } from "./AccountsManagementTable.const";
import { AccountsManagementTableColDef } from "./AccountsManagementTable.model";

const commonCellParams: Pick<
  AccountsManagementTableColDef,
  "sortable" | "align" | "headerAlign" | "disableColumnMenu"
> = {
  sortable: false,
  disableColumnMenu: true,
};

const getChipColor = (activity: number) => {
  if (activity < 35) {
    return "error";
  } else if (activity < 65) {
    return "warning";
  } else {
    return "success";
  }
};

export const getBorderColor = (status?: string | null) => {
  switch (status) {
    case "expired":
      return "warning.main";
    case "trial":
      return "info.main";
    case "disabled":
      return "text.disabled";
    case "expired trial":
      return "warning.main";
    case "paying":
      return "success.main";
    case "active":
      return "success.light";
    default:
      return "text.disabled";
  }
};

export const getStatusIcon = (status?: string | null) => {
  return (
    <>
      {status === "expired" && <WatchLaterIcon color="warning" />}
      {status === "trial" && <AccountCircleIcon color="primary" />}
      {status === "disabled" && <ErrorIcon color="disabled" />}
      {status === "deleted" && <ErrorIcon color="disabled" />}
      {status === "expired trial" && <AccountCircleIcon color="warning" />}
      {status === "paying" && (
        <CheckCircleIcon sx={{ color: "success.light" }} />
      )}
      {status === "active" && (
        <CheckCircleIcon sx={{ color: "success.light" }} />
      )}
    </>
  );
};

export function makeAccountsManagementColumns(
  enableActivityLogs: boolean
): AccountsManagementTableColDef[] {
  return [
    {
      ...commonCellParams,
      headerName: "Account name",
      field: "name",
      minWidth: 150,
      headerClassName: AccountsManagementTableClasses.accountNameheader,
      cellClassName: AccountsManagementTableClasses.cellAccountName,
      renderCell(params) {
        const name = params?.row?.account?.name;

        const accountUrl = accountsManagementEditAccountRoute.makeUrl({
          accountId: params?.row?.account?.id || "",
        });
        return (
          <Tooltip title={name}>
            <TextLineClamp lineClamp={2} color="primary">
              <AppLink to={accountUrl}>{name}</AppLink>
            </TextLineClamp>
          </Tooltip>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Status",
      field: "status",
      align: "left",
      width: 135,
      cellClassName: AccountsManagementTableClasses.cellStatus,
      renderCell(params) {
        const status = params?.row?.account?.status;
        return (
          <Stack direction="row" alignItems={"center"} gap={1}>
            {getStatusIcon(status)}
            <Tooltip
              title={status}
              componentsProps={{
                tooltip: {
                  sx: {
                    textTransform: "capitalize",
                  },
                },
              }}
            >
              <TextLineClamp lineClamp={2}>{status}</TextLineClamp>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Expiration Date",
      field: "date",
      type: "dateTime",
      width: 140,
      renderCell(params) {
        const date = params?.row?.account?.expirationDate;
        const expirationDate = date ? format(new Date(date), "PP pp") : " - ";
        return (
          <Tooltip title={expirationDate}>
            <TextLineClamp lineClamp={1} textOverflow={"ellipsis"}>
              {expirationDate}
            </TextLineClamp>
          </Tooltip>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Organization Type",
      field: "type",
      width: 165,
      renderCell(params) {
        const type = params?.row?.account?.organizationType;
        return (
          <Stack>
            <Tooltip title={type}>
              <TextLineClamp lineClamp={1}>{type}</TextLineClamp>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "State",
      field: "state",
      width: 80,
      renderCell(params: any) {
        const state = params.row.account.stateOrProvince || "";
        return (
          <Stack>
            <Tooltip title={state}>
              <TextLineClamp lineClamp={1}>{state}</TextLineClamp>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Active users",
      field: "users",
      width: 110,
      cellClassName: AccountsManagementTableClasses.cellActiveUsers,
      renderCell(params) {
        const users = params.row.activeUserCount;
        return (
          <Stack>
            <Tooltip title={users}>
              <TextLineClamp lineClamp={1}>{users}</TextLineClamp>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Account representative",
      field: "email",
      width: 200,
      renderCell(params) {
        const accountRepresentative = params?.row?.accountManagerEmail;
        return (
          <Stack>
            <Tooltip title={accountRepresentative}>
              <TextLineClamp lineClamp={1}>
                {accountRepresentative}
              </TextLineClamp>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Activity",
      field: "activity",
      width: 80,
      renderCell(params) {
        const activity = params?.row?.healthScore;
        return enableActivityLogs ? (
          <Stack>
            <Tooltip title={activity}>
              <AppLink
                to={accountsManagementActivityLogsRoute.makeUrl({
                  accountId: params?.row?.account?.id ?? "",
                })}
              >
                <Chip
                  variant={"filled"}
                  color={getChipColor(activity)}
                  size={"medium"}
                  label={activity}
                  sx={{
                    minWidth: "50px",
                  }}
                />
              </AppLink>
            </Tooltip>
          </Stack>
        ) : (
          <Stack>
            <Tooltip title={activity}>
              <Chip
                variant={"filled"}
                color={getChipColor(activity)}
                size={"medium"}
                label={activity}
                sx={{
                  minWidth: "50px",
                }}
              />
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Action",
      headerAlign: "center",
      field: "actions",
      align: "center",
      width: 80,
      type: "actions",
      renderCell(params) {
        const config = {
          isActive: params.row.account?.isActive,
          isTrial: params.row.account?.isTrial,
          isPaying: params.row.account?.isPaying,
        };
        return (
          <AccountsManagementItemMenuButton
            id={params?.row?.account?.id}
            accountName={params?.row?.account?.name}
            expiration={params.row.account?.expirationDate}
            seatCount={params.row.account?.seatCount}
            status={params.row.account?.status}
            config={config}
            accountManager={params?.row?.account?.accountManager}
            accountManagerEmail={params?.row.accountManagerEmail}
            annualizedRecurringRevenue={
              params?.row?.account?.annualRecurringRevenue
            }
          />
        );
      },
    },
  ];
}
