import { useState, cloneElement, useMemo, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Badge,
  Box,
  Button,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Typography,
  alpha,
  badgeClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridPaginationModel } from "@mui/x-data-grid-premium";
import {
  ArrayParam,
  BooleanParam,
  useQueryParam,
  withDefault,
} from "use-query-params";
import { DateTimeRangePickerMobile } from "src/components/DateTimeRangePickerMobile/DateTimeRangePickerMobile";
import {
  DateTimeSelector,
  DateTimeSelectorMode,
} from "src/components/DateSelector/DateTimeSelector";
import { SearchInput } from "src/components/SearchInput";
import { ViewModeSwitch } from "src/components/ViewModeSwitch/ViewModeSwitch";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { useReport } from "src/api/useReport";
import { useReportEvents } from "src/api/useReportEvents";
import { useReportEventsInfinite } from "src/api/useReportEventsInfinite";
import {
  DownloadReportFormat,
  getDownloadTooltipTitle,
} from "src/api/useReportExport";
import { enqueueSnackbar } from "notistack";
import { useQueryDateRangeWithEmptyDefault } from "src/utils/useQueryDateRangeWithEmptyDefault";
import { useListFilter } from "src/utils/useListFilter";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { useQueryPagination } from "src/utils/useQueryPagination";
import { combineInfiniteTilesData } from "src/utils/combineInfiniteTilesData";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";
import { useEventsDownloadLimits } from "src/utils/useEventsDownloadLimits";
import { SortingMenu } from "src/components/SortingMenu/SortingMenu";
import {
  SortBy,
  SortOption,
  SortByParameters,
  OrderByParameters,
} from "src/components/SortingMenu/SortingMenu.model";
import { useReportEventsDataView } from "src/components/ReportEvents/ReportEvents.hook";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { AppTab } from "src/components/AppTabLayout/AppTab";
import { AppTabLayout } from "src/components/AppTabLayout/AppTabLayout";
import { TooltipBaseMobile } from "src/components/TooltipBaseMobile/TooltipBaseMobile";
import { PageLoader } from "src/components/PageLoader";
import { DownloadPDF } from "src/components/icons/DownloadPDF";
import { IntercomPageAnchor } from "src/components/IntercomPageAnchor/IntercomPageAnchor";
import { DownloadCSV } from "src/components/icons/DownloadCSV";
import { DownloadFilesSnackbar } from "src/components/DownloadFilesSnackbar/DownloadFilesSnackbar";
import { useDownloadFilesSnackbar } from "src/api/useDownloadFilesSnackbar";
import { PageLayoutPublicDesktop } from "src/components/PageLayoutPublicDesktop";
import { DateRange } from "src/components/DateSelector/DateSelector";
import { pageNotFoundRoute } from "src/pages/PageNotFound/PageNotFound.route";
import { CustomBannerHeader } from "src/components/CustomBannerHeader/CustomBannerHeader";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";
import { reportDownloadRequest } from "src/api/useReportDownloadRequest";
import { useReportLoad } from "src/api/useReportLoad";
import { ReportItem } from "src/models/Report";
import { useSessionContext } from "src/api/useSessionContext";
import { ReportPreviewAccordion } from "../ReportCreate/components/ReportPreviewTab/ReportPreviewAccordion";
import { publicReportEventRoute } from "../PublicReportEvent/PublicReportEvent.route";

const viewModeOptions: {
  desktop: ViewMode[];
  mobile: ViewMode[];
} = {
  desktop: [ViewMode.table, ViewMode.list, ViewMode.tile],
  mobile: [ViewMode.tile, ViewMode.table],
};

export enum PublicReportPageTabMode {
  info = "info",
  events = "events",
  analytics = "analytics",
}

const pageTabKey = "pageTab";

export function PublicReportPage() {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const { isAuthenticated } = useAuth0();
  const { t } = useTranslation();
  const { effectiveEntitlements } = useSessionContext();
  const [filterText, setFilterText] = useState("");
  const [isExpandedTextMode, setExpandedTextMode] = useQueryParam(
    "expanded",
    BooleanParam
  );
  const [sortBy, setSortBy] = useState<SortByParameters>("default");
  const [sortOption, setSortOption] = useState(SortBy.defaultDesc);
  const [orderBy, setOrderBy] = useState<OrderByParameters>("desc");
  const [dateRange, setDateRange] = useQueryDateRangeWithEmptyDefault();
  const [pagination, setPagination] = useQueryPagination();

  /** is used for switching between pages while groupDuplicates is set to true */
  const [pagesHistory, setPagesHistory] = useQueryParam(
    "groupedPages",
    withDefault(ArrayParam, [""])
  );
  /** "after" is overriding "from" on the BE side, is used with groupDuplicates set to true */
  const after = pagesHistory[pagesHistory.length - 1];

  const navigate = useNavigate();
  const downloadFilesSnackBar = useDownloadFilesSnackbar();

  const { reportId = "" } = useParams();
  const enableReportDownloads =
    effectiveEntitlements.enableReportDownloads?.value;
  const initialViewMode: ViewMode = isMobile ? ViewMode.tile : ViewMode.list;
  const [viewMode, setViewModeWrapper] = useViewModeQueryParam<ViewMode>({
    paramKey: "tab",
    defaultValue: initialViewMode,
  });

  const [pageTabMode, setPageTabMode] =
    useViewModeQueryParam<PublicReportPageTabMode>({
      paramKey: pageTabKey,
      defaultValue: PublicReportPageTabMode.events,
    });

  const analytics = null;

  const [refetchInterval, setRefetchInterval] = useState<number | false>(2000);
  const [reportWasLoaded, setReportWasLoaded] = useState(false);

  const reportLoadMutation = useReportLoad({
    options: {
      onSuccess: () => {
        setReportWasLoaded(true);
      },
    },
  });

  const reportData = useReport(
    {
      path: {
        id: reportId,
      },
    },
    refetchInterval,
    {
      onSuccess: (report: ReportItem) => {
        if (!report.warm && !reportWasLoaded)
          reportLoadMutation.mutate({
            params: {
              path: { id: report.id },
            },
          });
      },
    }
  );

  useEffect(() => {
    if (reportData.data?.warm) {
      setRefetchInterval(false);
    }
  }, [reportData.data?.warm]);

  const groupDuplicates = reportData.data?.groupDuplicates;
  const reportEventsCount = reportData.data?.count ?? 0;

  const getEventsDownloadLimit = useEventsDownloadLimits();
  const pdfLimit = getEventsDownloadLimit(DownloadReportFormat.pdf) || 0;
  const pdfLimitExceeded = reportEventsCount > pdfLimit;
  const csvLimit = getEventsDownloadLimit(DownloadReportFormat.csv) || 0;
  const csvLimitExceeded = reportEventsCount > csvLimit;

  useEffect(() => {
    if (reportData.isFetched && !reportData.data) {
      navigate(pageNotFoundRoute.path);
    }
  }, [reportData.isFetched, reportData.data, navigate]);

  const reportEvents = useReportEvents({
    options: {
      enabled: !!reportData.data?.warm && viewMode !== ViewMode.tile,
    },
    params: {
      path: {
        id: reportId,
      },
      query: {
        from: pagination.page * pagination.pageSize,
        size: pagination.pageSize,
        sortby: sortBy,
        orderby: orderBy,
        startDate: dateRange[0]
          ? formatDateTimeWithoutTimeZone(dateRange[0])
          : undefined,
        endDate: dateRange[1]
          ? formatDateTimeWithoutTimeZone(dateRange[1])
          : undefined,
        after: groupDuplicates && after ? after : undefined,
      },
    },
  });

  const reportEventsTiles = useReportEventsInfinite({
    options: {
      enabled: !!reportData.data?.warm && viewMode === ViewMode.tile,
    },
    params: {
      path: {
        id: reportId,
      },
      query: {
        sortby: sortBy,
        orderby: orderBy,
        startDate: dateRange[0]
          ? formatDateTimeWithoutTimeZone(dateRange[0])
          : undefined,
        endDate: dateRange[1]
          ? formatDateTimeWithoutTimeZone(dateRange[1])
          : undefined,
        after: groupDuplicates && after ? after : undefined,
      },
    },
  });

  const reportEventsData = useMemo(() => {
    if (viewMode === ViewMode.tile) {
      if (reportEventsTiles.isError) return [];
      return combineInfiniteTilesData(reportEventsTiles.data?.pages);
    }
    if (reportEvents.isError) return [];
    return reportEvents.data?.results || [];
  }, [
    viewMode,
    reportEvents.data,
    reportEventsTiles.data,
    reportEvents.isError,
    reportEventsTiles.isError,
  ]);

  const reportsEventsTotal = useMemo(() => {
    if (viewMode === ViewMode.tile) {
      if (reportEventsTiles.isError) return 0;
      const tilesPagesData = reportEventsTiles.data?.pages;
      return tilesPagesData?.[tilesPagesData.length - 1]?.total || 0;
    }
    if (reportEvents.isError) return 0;
    return reportEvents.data?.total || 0;
  }, [
    viewMode,
    reportEventsTiles.data?.pages,
    reportEvents.data?.total,
    reportEvents.isError,
    reportEventsTiles.isError,
  ]);

  const setPaginationWrapper = useCallback(
    (nextPagination: GridPaginationModel) => {
      if (groupDuplicates) {
        if (nextPagination.pageSize !== pagination.pageSize) {
          setPagesHistory([""], "replaceIn");
        }
        const nextPage = reportEvents.data?.configuration?.next;
        if (nextPagination.page > pagination.page && nextPage) {
          // go to the next page:
          setPagesHistory([...pagesHistory, nextPage], "replaceIn");
        }
        if (nextPagination.page < pagination.page) {
          // go to the previous page:
          setPagesHistory(pagesHistory.slice(0, -1), "replaceIn");
        }
      }
      setPagination(nextPagination);
    },
    [
      groupDuplicates,
      pagination,
      setPagination,
      setPagesHistory,
      reportEvents.data?.configuration?.next,
      pagesHistory,
    ]
  );

  const navToTheFirstPage = useCallback(() => {
    setPagesHistory([""], "replaceIn");
    setPagination({
      ...pagination,
      page: 0,
    });
  }, [setPagesHistory, setPagination, pagination]);

  const exportReportItem = async (format: DownloadReportFormat) => {
    if (reportData.data) {
      const { status } = await reportDownloadRequest({
        format,
        reportId: reportData.data.id,
      });
      if (status === 200) {
        enqueueSnackbar(
          <div>
            <Typography variant="body1" fontWeight={500}>
              {`${format?.toUpperCase()} is generating: '${
                reportData.data.title
              }'`}
            </Typography>
            <Typography variant="body2">
              You will receive email and system notification once it's ready
            </Typography>
          </div>,
          {
            variant: "info",
            autoHideDuration: 5000,
          }
        );
      }
    }
  };

  const filterData = useListFilter(reportEventsData || [], filterText, [
    "title",
    "highlights",
    "country",
    "source",
    "market",
  ]);

  const handleChangePageTabMode = (newValue: string) => {
    setPageTabMode(newValue as PublicReportPageTabMode);
  };

  const viewModeSwitch = useMemo(
    () => (
      <ViewModeSwitch
        value={viewMode}
        onChange={setViewModeWrapper}
        size={isMobile ? "small" : "medium"}
        options={isMobile ? viewModeOptions.mobile : viewModeOptions.desktop}
      />
    ),
    [viewMode, setViewModeWrapper, isMobile]
  );

  const getEventUrl = useCallback(
    (eventId: string) => {
      return publicReportEventRoute.makeUrl({
        reportId,
        eventId,
      });
    },
    [reportId]
  );

  const onSortChange = useCallback(
    (sortBy: SortOption) => {
      setSortOption(sortBy.value);
      setSortBy(sortBy.field);
      setOrderBy(sortBy.order);
    },
    [setSortOption, setSortBy, setOrderBy]
  );

  const sortingMenu = useMemo(
    () => (
      <SortingMenu
        value={sortOption}
        onChange={onSortChange}
        size={isMobile ? "small" : "medium"}
        options={[
          SortBy.titleAsc,
          SortBy.titleDesc,
          SortBy.defaultAsc,
          SortBy.defaultDesc,
        ]}
      />
    ),
    [sortOption, onSortChange, isMobile]
  );

  const searchInput = useMemo(
    () => (
      <SearchInput
        placeholder={isMobile ? "Search..." : "Search by text..."}
        onTextChangeThrottled={setFilterText}
        fullWidth
        sx={isMobile ? {} : { width: 180 }}
      />
    ),
    [isMobile, setFilterText]
  );

  const expandTextButton = viewMode === ViewMode.tile &&
    pageTabMode === PublicReportPageTabMode.events && (
      <FormControlLabel
        checked={!!isExpandedTextMode}
        control={
          <Switch
            onChange={(_e, checked) =>
              setExpandedTextMode(checked, "replaceIn")
            }
          />
        }
        label={<Typography variant="caption">Expanded Text</Typography>}
        sx={{ mr: 0, whiteSpace: "nowrap" }}
      />
    );

  const informationContent = useMemo(
    () => (
      <Stack p={2} gap={2}>
        <Stack>
          <Typography variant="subtitle2">Description</Typography>
          <Typography>{reportData.data?.description || "-"}</Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle2">Audience information</Typography>
          <Typography>
            {reportData.data?.audienceInformation?.toLocaleString() || "-"}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle2">Local Market Viewership</Typography>
          <Typography>
            {reportData.data?.localViewership?.toLocaleString() || "-"}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle2">National Viewership</Typography>
          <Typography>
            {reportData.data?.nationalViewership?.toLocaleString() || "-"}
          </Typography>
        </Stack>
      </Stack>
    ),
    [reportData.data]
  );

  const setDateRangeWrapper = useCallback(
    (dateRange: DateRange) => {
      setDateRange(dateRange);
      navToTheFirstPage();
    },
    [setDateRange, navToTheFirstPage]
  );

  const toolbarDesktop = (
    <Stack
      p={2}
      gap={2}
      direction={{ sm: "column", md: "row" }}
      justifyContent="space-between"
    >
      <Stack direction="row" flex={1}>
        <Stack sx={{ justifyContent: "flex-end", mr: 2 }}>{searchInput}</Stack>
        <DateTimeSelector
          mode={DateTimeSelectorMode.variable}
          views={["year", "month", "day", "hours", "minutes"]}
          value={dateRange}
          onChange={setDateRangeWrapper}
          sx={{ flex: 1 }}
        />
      </Stack>

      <Stack direction="row" spacing={1} justifyContent="flex-end">
        {sortingMenu}
        {viewModeSwitch}
      </Stack>
    </Stack>
  );

  const toolbarMobile = useMemo(
    () => (
      <Stack
        direction="row"
        spacing={1}
        alignItems="flex-end"
        sx={{
          height: 40,
          m: 2,
        }}
      >
        {searchInput}
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          {sortingMenu}
          <DateTimeRangePickerMobile
            value={dateRange}
            onChange={setDateRangeWrapper}
            iconButtonSize="small"
          />
          {viewModeSwitch}
        </Stack>
      </Stack>
    ),
    [searchInput, sortingMenu, dateRange, setDateRangeWrapper, viewModeSwitch]
  );

  const renderMobileHeader = useCallback(() => {
    return toolbarMobile;
  }, [toolbarMobile]);

  const nextPageButtonIsDisabled = useMemo(() => {
    if (groupDuplicates) {
      const loading = reportEvents.isLoading || reportEvents.isFetching;
      const nextPage = reportEvents.data?.configuration?.next;
      if (loading || !nextPage) return true;
    }
    return false;
  }, [
    groupDuplicates,
    reportEvents.isLoading,
    reportEvents.isFetching,
    reportEvents.data?.configuration?.next,
  ]);

  const dataViewElement = useReportEventsDataView({
    data: filterData,
    viewMode,
    rowCount: reportsEventsTotal,
    isExpandedTextMode,
    getEventUrl,
    isLoading: reportEvents.isLoading || reportEvents.isFetching,
    paginationModel: pagination,
    onPaginationModelChange: setPaginationWrapper,
    reportEventsTiles,
    renderHeader: renderMobileHeader,
    isCompact: true,
    nextPageButtonIsDisabled,
  });

  const mobileEventsLayout = useMemo(
    () => (
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          height: "100%",
        }}
      >
        {viewMode === ViewMode.tile ? null : toolbarMobile}
        <Stack flex={1} overflow="hidden">
          {dataViewElement}
        </Stack>
      </Paper>
    ),
    [viewMode, toolbarMobile, dataViewElement]
  );

  const desktopContent = (
    <Stack maxHeight="80vh">
      <ReportPreviewAccordion
        title={<Typography variant="subtitle1">Information</Typography>}
        children={informationContent}
      />

      {!!analytics && (
        <ReportPreviewAccordion
          title={
            <Stack
              flexDirection="row"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle1">Analytics</Typography>
              <Typography
                variant="body2"
                color={alpha(palette.text.secondary, 0.65)}
                children={"Sep 14th 2022 06:54 AM - Sep 14th 2022 02:36 PM"}
              />
              <Stack />
            </Stack>
          }
        >
          <Typography>Content</Typography>
        </ReportPreviewAccordion>
      )}
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          border: `1px solid ${palette.divider}`,
          mt: 2,
          overflow: "hidden",
          height: "100%",
          minHeight: "500px",
        }}
      >
        {toolbarDesktop}
        <Stack flex={1} overflow="hidden">
          {dataViewElement}
        </Stack>
      </Paper>
      <DownloadFilesSnackbar
        open={downloadFilesSnackBar.isOpen}
        onClose={downloadFilesSnackBar.hide}
      />
    </Stack>
  );

  const downloadPdfButton = (
    <Button
      variant="contained"
      startIcon={
        <DownloadPDF
          color={
            palette.mode === "light"
              ? palette.common.white
              : palette.primary.contrastText
          }
        />
      }
      sx={{ width: "100%", height: "42px" }}
      disabled={!reportData.data || !enableReportDownloads || pdfLimitExceeded}
      onClick={() => exportReportItem(DownloadReportFormat.pdf)}
      children="PDF"
    />
  );

  const downloadCsvButton = (
    <Button
      variant="contained"
      startIcon={
        <DownloadCSV
          color={
            palette.mode === "light"
              ? palette.common.white
              : palette.primary.contrastText
          }
        />
      }
      sx={{ width: "100%", height: "42px" }}
      disabled={!reportData.data || !enableReportDownloads || csvLimitExceeded}
      onClick={() => exportReportItem(DownloadReportFormat.csv)}
      children="CSV"
    />
  );

  const mobileTabContent = useMemo(() => {
    return (
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          height: "100%",
        }}
      >
        {pageTabMode === PublicReportPageTabMode.info && informationContent}
        {pageTabMode === PublicReportPageTabMode.events && mobileEventsLayout}
        {pageTabMode === PublicReportPageTabMode.analytics && (
          <Typography variant="body2" p={2}>
            Analytics content
          </Typography>
        )}
        <DownloadFilesSnackbar
          open={downloadFilesSnackBar.isOpen}
          onClose={downloadFilesSnackBar.hide}
        />
      </Paper>
    );
  }, [
    pageTabMode,
    informationContent,
    mobileEventsLayout,
    downloadFilesSnackBar.isOpen,
    downloadFilesSnackBar.hide,
  ]);

  const mobileTabs = () => {
    let tabsElements = [
      <AppTab label="Info" value="info" children={mobileTabContent} />,
      <AppTab
        label={
          <Badge
            badgeContent={reportEventsCount}
            color="primary"
            sx={{
              [`.${badgeClasses.badge}`]: {
                top: 10,
                right: 4,
              },
            }}
          >
            <Typography sx={{ pr: 3.5, fontSize: 14, fontWeight: 500 }}>
              Events
            </Typography>
          </Badge>
        }
        value="events"
        children={mobileTabContent}
      />,
    ];

    if (analytics) {
      tabsElements = [
        ...tabsElements,
        <AppTab
          label="Analytics"
          value="analytics"
          children={mobileTabContent}
        />,
      ];
    }
    return tabsElements;
  };

  const mobileContent = (
    <>
      <Stack height="100%" sx={{ overflowY: "auto", overflowX: "hidden" }}>
        <AppTabLayout
          variant="fullWidth"
          height="100%"
          currentTab={pageTabMode}
          onCurrentTabChange={handleChangePageTabMode}
        >
          {mobileTabs().map((tab) => {
            return cloneElement(tab, {
              ...tab.props,
              key: tab.props.value,
            });
          })}
        </AppTabLayout>
      </Stack>
      {isAuthenticated && (
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            px: 2,
            py: 3,
          }}
          spacing={2}
        >
          <TooltipBaseMobile
            width={249}
            title={
              enableReportDownloads
                ? getDownloadTooltipTitle(DownloadReportFormat.pdf, pdfLimit)
                : t("reportDownloadsDisabled")
            }
            placement="top"
            disableHoverListener={enableReportDownloads && !pdfLimitExceeded}
          >
            {downloadPdfButton}
          </TooltipBaseMobile>
          <TooltipBaseMobile
            width={249}
            title={
              enableReportDownloads
                ? getDownloadTooltipTitle(DownloadReportFormat.csv, csvLimit)
                : t("reportDownloadsDisabled")
            }
            placement="top"
            disableHoverListener={enableReportDownloads && !csvLimitExceeded}
          >
            {downloadCsvButton}
          </TooltipBaseMobile>
        </Stack>
      )}
    </>
  );

  if (
    reportData.isLoading ||
    reportEvents.isLoading ||
    reportEventsTiles.isLoading ||
    !reportData.data?.warm
  ) {
    return <PageLoader />;
  }

  if (isMobile) {
    return (
      <>
        <CustomBannerHeader
          isPublicPage
          publicUserId={reportData?.data?.userId}
        />
        <Stack
          flex={1}
          width="100%"
          overflow="hidden"
          bgcolor={palette.background.paper}
        >
          <Stack
            my={1}
            mx={2}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
              width="100%"
              minHeight={50}
            >
              <TooltipBaseMobile
                width={250}
                placement="bottom"
                title={reportData.data?.title}
              >
                <TextLineClamp variant="subtitle1" lineClamp={2}>
                  {reportData.data?.title || ""}
                </TextLineClamp>
              </TooltipBaseMobile>
              {expandTextButton}
            </Stack>
          </Stack>
          {mobileContent}
        </Stack>
      </>
    );
  }

  return (
    <PageLayoutPublicDesktop
      overflow="scroll"
      header={
        <>
          <CustomBannerHeader
            isPublicPage
            publicUserId={reportData?.data?.userId}
            maxWidth="882px"
          />
          <Stack
            px={{ sm: 2, md: 0 }}
            my={3}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            flexWrap={{ sm: "wrap", md: "nowrap" }}
            gap={{ sm: 1, md: 4 }}
            width="100%"
          >
            <Stack>
              <Badge
                badgeContent={reportEventsCount}
                color="info"
                sx={{
                  [`.${badgeClasses.badge}`]: {
                    top: 16,
                    right: 18,
                  },
                }}
              >
                <Typography variant="h5" sx={{ pr: 5 }}>
                  {reportData.data?.title || ""}
                </Typography>
                <IntercomPageAnchor />
              </Badge>
            </Stack>

            {isAuthenticated && (
              <Stack flexDirection="row" height={42} width={222} gap={2}>
                <TooltipBase
                  width={249}
                  textAlign="left"
                  title={
                    enableReportDownloads
                      ? getDownloadTooltipTitle(
                          DownloadReportFormat.pdf,
                          pdfLimit
                        )
                      : t("reportDownloadsDisabled")
                  }
                  placement="bottom"
                  disableHoverListener={
                    enableReportDownloads && !pdfLimitExceeded
                  }
                >
                  <Box width="102px">{downloadPdfButton}</Box>
                </TooltipBase>
                <TooltipBase
                  width={249}
                  textAlign="left"
                  title={
                    enableReportDownloads
                      ? getDownloadTooltipTitle(
                          DownloadReportFormat.csv,
                          csvLimit
                        )
                      : t("reportDownloadsDisabled")
                  }
                  placement="bottom"
                  disableHoverListener={
                    enableReportDownloads && !csvLimitExceeded
                  }
                >
                  <Box width="102px">{downloadCsvButton}</Box>
                </TooltipBase>
              </Stack>
            )}
          </Stack>
        </>
      }
      content={desktopContent}
    />
  );
}
