import { Chip } from "@mui/material";
import FilterIcon from "@mui/icons-material/Filter";
import { DuplicatesChipTooltip } from "./DuplicatesChipTooltip";

export function DuplicatesChip({
  onClick,
  count,
  selectedCount,
}: {
  onClick: () => void;
  count: number;
  selectedCount: number;
}) {
  return (
    <DuplicatesChipTooltip>
      <Chip
        color={selectedCount ? "primary" : "default"}
        icon={<FilterIcon fontSize="small" />}
        label={selectedCount ? `${selectedCount}/${count}` : count}
        onClick={onClick}
        sx={{ px: "3px", zIndex: 3 }}
      />
    </DuplicatesChipTooltip>
  );
}
