import { SupervisorAccount } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useManagementView } from "src/api/useManagementView";
import { useSessionContext } from "src/api/useSessionContext";

export function AccountSeatsInfoBar() {
  const { account } = useSessionContext();
  const managementView = useManagementView();
  const userSeatCount = managementView.data?.accountActiveUserCount ?? 0;

  return (
    <Typography display="inline-flex">
      <SupervisorAccount
        sx={{
          color: "text.secondary",
          mr: 1,
        }}
      />

      {managementView.isFetching
        ? "Loading..."
        : `${userSeatCount} out of ${account.seatCount} seats used`}
    </Typography>
  );
}
