import { KeyboardReturn } from "@mui/icons-material";
import { Stack, Typography, useTheme } from "@mui/material";

export function RecipientsCreatePrompt({
  userInput,
  parsedCount,
}: {
  userInput: string;
  parsedCount?: number;
}) {
  const { palette } = useTheme();
  const color = palette.text.disabled;

  const renderHint = () => {
    if (userInput) {
      return (
        <Stack direction="row" alignItems="center" columnGap={1} flex={1}>
          <Typography
            flex={1}
            fontStyle="italic"
            color={color}
            children={
              parsedCount
                ? parsedCount.toString().slice(-1) === "1"
                  ? 'Press "Enter" to add following recipient'
                  : `Press "Enter" to add following recipients`
                : "No recipient email addresses were parsed"
            }
            noWrap
          />
          <Typography
            children="Enter"
            fontSize={13}
            fontFamily="monospace"
            fontWeight={600}
            sx={{
              px: 0.5,
              border: `1px solid ${color}`,
              color,
            }}
          />
          <KeyboardReturn fontSize="small" sx={{ color }} />
        </Stack>
      );
    }

    return (
      <Stack>
        <Typography
          flex={1}
          fontStyle="italic"
          color={color}
          children={"Type a recipient email addresses"}
          noWrap
        />

        <Typography
          flex={1}
          fontStyle="italic"
          color={color}
          children={"You can separate them with comma or space"}
          noWrap
        />
      </Stack>
    );
  };

  return (
    <Stack
      px={2}
      py={2}
      direction="row"
      columnGap={1}
      rowGap={2}
      alignItems="center"
      borderBottom={`1px solid ${palette.divider}`}
      overflow="hidden"
      flexWrap="wrap"
      children={renderHint()}
    />
  );
}
