import { max, min } from "date-fns";
import { LineChart } from "@mui/x-charts/LineChart";
import { components } from "@tveyes/twosionwebapischema";
import { useAppChartColorMapper } from "src/components/charts/utils/useAppChartColorMapper";
import { AppChartContainer } from "./AppChartContainer";
import { axisNumberTickAutoFormat } from "./utils/axisNumberTickAutoFormat";
import { axisDateValueFormatter } from "./utils/axisDateValueFormatter";

type AppLineChartData = {
  series?: components["schemas"]["MentionCompositeAnalysisSeriesDTO"][] | null;
  dates?: string[] | null;
};

type AppLineChartProps = {
  title: string;
  data?: AppLineChartData;
  isLoading: boolean;
};

export function AppLineChart({ title, data, isLoading }: AppLineChartProps) {
  const { getColorForId } = useAppChartColorMapper();

  return (
    <AppChartContainer title={title} legend={data?.series}>
      <LineChart
        loading={isLoading}
        sx={{
          "& .MuiChartsAxis-directionY .MuiChartsAxis-label": {
            transform: "translateX(-32px) !important",
          },
        }}
        margin={{
          left: 80,
          right: 24,
          top: 16,
        }}
        slotProps={{
          legend: { hidden: true },
        }}
        // this produces a ton of duplicate key warnings
        grid={{
          horizontal: true,
          vertical: true,
        }}
        series={
          data?.series?.map(({ values, queryId, queryTitle }) => {
            const data = values?.map(({ value }) => value ?? null);
            return {
              id: queryId,
              data,
              label: queryTitle ?? undefined,
              area: true,
              color: getColorForId(queryId).bg,
              stack: "default",
            };
          }) || []
        }
        xAxis={data?.series?.map(({ queryId, values }) => {
          const xValues = values
            ? values.map(({ timeStamp }) => new Date(`${timeStamp}`))
            : [];

          const t0 = min(xValues);
          const t1 = max(xValues);

          return {
            id: queryId,
            label: "Date",
            scaleType: "time",
            data: xValues,
            min: t0,
            max: t1,
            valueFormatter: (value, context) =>
              axisDateValueFormatter(value, context, t0, t1),
          };
        })}
        yAxis={[
          {
            label: "Mentions",
            valueFormatter: axisNumberTickAutoFormat,
          },
        ]}
      />
    </AppChartContainer>
  );
}
