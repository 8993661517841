import HeroImage from "./hero-swirl.png";
import { Box, Stack, StackProps, useTheme } from "@mui/material";

export function HeroSwirlContainer({ children, ...props }: StackProps) {
  const { palette } = useTheme();

  return (
    <Stack
      className="hero-swirl"
      position="relative"
      bgcolor={palette.background.brand}
      {...props}
    >
      <Box
        component="img"
        alt="Wave"
        src={HeroImage}
        sx={{
          width: "100%",
          position: "absolute",
          bottom: 0,
          transform: "translateY(35%)",
          pointerEvents: "none",
        }}
      />
      {children}
    </Stack>
  );
}
