import { useMemo, useState } from "react";
import {
  Box,
  Divider,
  ListItemSecondaryAction,
  Stack,
  Tooltip,
  Typography,
  useTheme,
  buttonBaseClasses,
  alpha,
} from "@mui/material";
import { DateRange } from "@mui/icons-material";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { ListBaseItem } from "src/components/ListBase/ListBaseItem";
import { TranscriptText } from "src/components/TranscriptText/TranscriptText";
import {
  getPowerSearchResultCountry,
  getPowerSearchResultDateTime,
  getPowerSearchResultMarket,
  getPowerSearchResultEvent,
  getPowerSearchResultEventType,
  getPowerSearchResultSource,
  getPowerSearchResultViews,
  getPowerSearchResultMediaType,
  getPowerSearchResultTag,
  getPowerSearchResultIndividualTranscript,
  getPowerSearchResultDuplicates,
  getPowerSearchResultId,
} from "src/api/usePowerSearchResult.getters";
import { KeyValue } from "src/components/KeyValue/KeyValue";
import { AppLink } from "src/components/AppLink/AppLink";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { getEventIcon, getEventTooltipTitle } from "src/utils/getEventIcon";
import { watchListTermResultClipRoute } from "src/pages/WatchListTermResultClip/WatchListTermResultClip.route";
import { TranscriptBlock } from "src/components/TranscriptBlock/TranscriptBlock";
import { ClipViewer } from "src/components/ClipViewer/ClipViewer";
import { TileCheckBox } from "src/components/TileCheckBox/TileCheckBox";
import { useEventSelectionWatchTermResults } from "src/api/useEventSelectionWatchTermResults";
import { WatchTermResultItem } from "src/api/useWatchTermResults";
import { AdvChip } from "src/components/AdvChip/AdvChip";
import { DuplicatesChipListView } from "src/components/DuplicatesChip/DuplicatesChipListView";
import { TranscriptParser } from "src/components/TranscriptParser/TranscriptParser";
import { stripHTMLTags } from "src/utils/stripHTMLTags";
import { getEventTypeLabel } from "src/utils/getEventTypeLabel";
import { useEventSelectionDuplicatesDrawer } from "src/api/useEventSelectionDuplicatesDrawer";
import { WatchTermResultsActions } from "../WatchTermResultsActions/WatchTermResultsActions";

export function WatchTermResultsListItem({
  queryId,
  value,
  isChecked,
  addItemToReport,
  isExpandedTextMode,
  hideMarket,
  showDuplicatesDrawer,
  drawerParentEventId,
}: {
  queryId: string;
  value: PowerSearchResultItem;
  isChecked: boolean;
  addItemToReport: (value: WatchTermResultItem) => void;
  isExpandedTextMode: boolean;
  hideMarket?: boolean;
  showDuplicatesDrawer: (eventId: string, duplicatesIds: string[]) => void;
  drawerParentEventId?: string;
}) {
  const { palette, spacing } = useTheme();

  const [activeMention, setActiveMention] = useState(0);

  const eventType = getPowerSearchResultEventType(value);
  const mediaType = getPowerSearchResultMediaType(value);
  const Icon = getEventIcon(eventType, mediaType);

  const eventId = getPowerSearchResultId(value);
  const eventTitle = getPowerSearchResultEvent(value);
  const detailsUrl = watchListTermResultClipRoute.makeUrl({
    queryId,
    eventId,
  });

  const eventTypeLabel = getEventTypeLabel(eventType);

  const transcript = getPowerSearchResultIndividualTranscript(
    value,
    activeMention,
    isExpandedTextMode
  );
  const transcriptTooltip = stripHTMLTags({ transcript });

  const duplicatesIds = getPowerSearchResultDuplicates(value);
  const duplicatesDrawerEventsState = useEventSelectionDuplicatesDrawer();
  const selectedDuplicatesIds = duplicatesDrawerEventsState.list.map(
    (ev) => ev.id
  );
  const itemSelectedDuplicates = duplicatesIds.filter((id) =>
    selectedDuplicatesIds.includes(id)
  );

  const selectedEventsState = useEventSelectionWatchTermResults();

  const handleChange = (checked: boolean) => {
    if (checked) {
      selectedEventsState.select(value);
    } else {
      selectedEventsState.unselect(value);
    }
  };

  const viewershipBlock = useMemo(() => {
    const viewership = getPowerSearchResultViews(value);

    return viewership && viewership !== "0" ? (
      <KeyValue
        name="Viewership"
        value={getPowerSearchResultViews(value)}
        flexGrow={2}
        flexShrink={0}
      />
    ) : null;
  }, [value]);

  return (
    <ListBaseItem
      key={eventId}
      sx={{
        minHeight: 208,
        alignItems: "stretch",
        columnGap: 2,
        backgroundColor:
          eventId === drawerParentEventId
            ? alpha(palette.secondary.main, 0.08)
            : "inherit",
      }}
    >
      <Box
        position="relative"
        borderRadius={2}
        overflow="hidden"
        border={`1px solid ${palette.action.disabledBackground}`}
        width={215}
        maxHeight={176}
      >
        <ClipViewer
          id={eventId}
          onMentionChange={setActiveMention}
          event={value}
          viewMode="compact"
          sx={{
            [`.player-container .${buttonBaseClasses.root}`]: {
              minWidth: "59px",
              width: "59px",
              height: "59px",
              opacity: "0.4",
            },
          }}
        />
        <TileCheckBox value={isChecked} onChange={handleChange} zIndex={2} />
        {getPowerSearchResultTag(value).length ? (
          <AdvChip
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          />
        ) : null}
      </Box>

      <Stack flex={1} justifyContent="space-between" overflow="hidden" gap={1}>
        {/* header card */}
        <Stack direction="row" spacing={2} mr={8}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Tooltip title={eventTypeLabel}>
              <Box display="flex">
                <Icon />
              </Box>
            </Tooltip>
            <Tooltip title={eventTitle}>
              <AppLink to={detailsUrl}>
                <TextLineClamp
                  variant="subtitle1"
                  color="primary"
                  lineClamp={1}
                  height={20}
                >
                  {getPowerSearchResultEvent(value)}
                </TextLineClamp>
              </AppLink>
            </Tooltip>
          </Stack>

          <Divider orientation="vertical" variant="middle" flexItem />

          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            minWidth="fit-content"
          >
            <DateRange sx={{ color: palette.action.active }} />
            <TextLineClamp variant="body2" lineClamp={1}>
              {getPowerSearchResultDateTime(value).toLocaleString()}
            </TextLineClamp>
          </Stack>
        </Stack>

        {/* attributes */}
        <Stack
          direction="row"
          columnGap={2}
          justifyContent="space-between"
          maxWidth="100%"
          overflow="hidden"
        >
          <KeyValue
            name="Type"
            value={getEventTooltipTitle(eventType, mediaType)}
          />
          <KeyValue name="Source" value={getPowerSearchResultSource(value)} />
          <KeyValue name="Country" value={getPowerSearchResultCountry(value)} />
          {!hideMarket && (
            <KeyValue name="Market" value={getPowerSearchResultMarket(value)} />
          )}
          {viewershipBlock}
        </Stack>

        <Tooltip title={transcriptTooltip}>
          <TranscriptBlock px={2} py={1} borderRadius={1}>
            {isExpandedTextMode ? (
              <Typography variant="body1">
                <TranscriptParser
                  highlightColor={palette.primary.main}
                  transcript={transcript}
                />
              </Typography>
            ) : (
              <TranscriptText
                variant="body1"
                transcript={transcript}
                lineClamp={4}
              />
            )}
          </TranscriptBlock>
        </Tooltip>
      </Stack>
      <ListItemSecondaryAction
        sx={{
          top: spacing(1),
          right: 0,
          transform: "none",
          display: "flex",
          alignItems: "center",
        }}
      >
        {!!duplicatesIds.length && (
          <DuplicatesChipListView
            count={duplicatesIds.length}
            selectedCount={itemSelectedDuplicates.length}
            onClick={() => showDuplicatesDrawer(eventId, duplicatesIds)}
          />
        )}
        <WatchTermResultsActions
          value={value}
          addItemToReport={addItemToReport}
          queryId={queryId}
        />
      </ListItemSecondaryAction>
    </ListBaseItem>
  );
}
