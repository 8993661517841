import { alpha, toggleButtonClasses, useTheme } from "@mui/material";
import {
  DataGridPremium,
  GridValidRowModel,
  gridClasses,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useMemo } from "react";
import { TableBaseProps } from "./TableBase.model";
import { TableBaseFooter } from "./TableBaseFooter";
import { TableBaseLoadingOverlay } from "./TableBaseLoadingOverlay";

const defaultHeaderHeight = 56;
const defaultRowHeight = 72;
const defaultRowHeightMobile = 56;

/*
 * A pre-configured data table.
 */
export function TableBase<RowData extends GridValidRowModel>({
  id = "table-base",
  showDensitySwitch,
  rowCount = 0,
  isMobile,
  columnHeaderHeight = defaultHeaderHeight,
  rowHeight = isMobile ? defaultRowHeightMobile : defaultRowHeight,
  compactHorizontalPadding,
  renderCustomFooterEl,
  nextPageButtonIsDisabled,
  renderLabelDisplayedRows,
  ...props
}: TableBaseProps<RowData>) {
  const { palette } = useTheme();
  const defaultApiRef = useGridApiRef();
  // use externally provided apiRef, if any
  const apiRef = props.apiRef || defaultApiRef;

  const CustomFooterConstructor = useMemo(() => {
    return () => {
      return (
        <TableBaseFooter
          showDensitySwitch={showDensitySwitch}
          renderCustomFooterEl={renderCustomFooterEl}
          nextPageButtonIsDisabled={nextPageButtonIsDisabled}
          renderLabelDisplayedRows={renderLabelDisplayedRows}
        />
      );
    };
  }, [
    showDensitySwitch,
    renderCustomFooterEl,
    nextPageButtonIsDisabled,
    renderLabelDisplayedRows,
  ]);

  return (
    <DataGridPremium<RowData>
      apiRef={apiRef}
      rowHeight={rowHeight}
      rowCount={rowCount}
      {...props}
      slots={{
        footer: CustomFooterConstructor,
        loadingOverlay: TableBaseLoadingOverlay,
        ...props.slots,
      }}
      sx={{
        borderStyle: isMobile ? "solid" : "none",
        overflowY: isMobile ? undefined : "auto",
        overflow: "hidden",
        minHeight: isMobile ? 265 : undefined,
        margin: isMobile
          ? `0 ${compactHorizontalPadding ? "8px" : "16px"} 16px !important`
          : undefined,

        [`.${gridClasses.pinnedColumnHeaders}`]: {
          boxShadow: "none",
        },
        [`.${gridClasses.pinnedColumns}`]: {
          backgroundColor:
            palette.mode === "light" ? palette.background.paper : undefined,
          boxShadow: "none",
        },
        [`.${gridClasses.columnHeaders}`]: {
          background:
            palette.mode === "light"
              ? alpha(palette.action.hover, 0.04)
              : alpha(palette.action.hover, 0.04),

          // this is to prevent header from changing its height with density
          minHeight: `${columnHeaderHeight}px!important`,
          maxHeight: `${columnHeaderHeight}px!important`,
          lineHeight: `${columnHeaderHeight}px!important`,
        },
        [`.${gridClasses.columnHeader}`]: {
          // this is to prevent header from changing its height with density
          minHeight: `${columnHeaderHeight}px!important`,
          maxHeight: `${columnHeaderHeight}px!important`,
          lineHeight: `${columnHeaderHeight}px!important`,
        },
        [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
          // make more space between header pin button and title
          [`.${toggleButtonClasses.root}`]: {
            marginLeft: 2,
          },
        },
        [`.${gridClasses.columnSeparator}`]: {
          color: "transparent",
        },
        [`.${gridClasses.row}`]: {
          "&:hover": {
            background:
              palette.mode === "light"
                ? alpha(palette.action.hover, 0.04)
                : alpha(palette.action.hover, 0.08),
          },
          "&.row-highlighted": {
            backgroundColor: alpha(palette.secondary.main, 0.08),
          },
          "&.Mui-selected": {
            background:
              palette.mode === "light"
                ? alpha(palette.primary.main, 0.08)
                : alpha(palette.primary.main, 0.16),
          },
        },

        [`.${gridClasses.cell}`]: {
          fontSize: isMobile ? 14 : 16,
        },

        [`.${gridClasses.cellContent}`]: {
          // this default style is used when cell does not have cell renderer
          overflow: "hidden",
          display: "-webkit-box",
          lineClamp: "2",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
          whiteSpace: "break-spaces",
          wordBreak: "break-all",
          textOverflow: "ellipsis",
          color: palette.text.primary,
        },

        ...props.sx,
      }}
    />
  );
}
