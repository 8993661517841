import { Stack } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { TextInputBase } from "src/components/TextInputBase";
import { UserInviteAcceptFormValues } from "./UserInviteAcceptForm.model";

export function UserInviteAcceptForm({ isLoading }: { isLoading?: boolean }) {
  const { control } = useFormContext<UserInviteAcceptFormValues>();
  return (
    <Stack gap={2}>
      <Controller
        name="firstName"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextInputBase
              label="First Name"
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={!!fieldState.error}
              helperText={fieldState.error?.message || " "}
              disabled={isLoading}
            />
          );
        }}
      />

      <Controller
        name="lastName"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextInputBase
              label="Last Name"
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={!!fieldState.error}
              helperText={fieldState.error?.message || " "}
              disabled={isLoading}
            />
          );
        }}
      />
    </Stack>
  );
}
