import { useEffect, useRef } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import isEqual from "lodash/isEqual";
import {
  CONTENT_FILTER_PRESETS,
  INCLUDE_EXCLUDE_PRESETS,
} from "src/utils/contentFilterPresets";
import { parseIncludeExcludeGroup } from "src/utils/makeWatchQueryFormValues";
import { useSelectedPresetStore } from "src/utils/usePresets";
import { SourcesPicker } from "src/components/SourcesPicker/SourcesPicker";
import { makeContentFilterQuery } from "src/utils/makeContentFilterQuery";
import { sortIncludeExcludeValues } from "src/utils/sortIncludeExcludeContentFilterValues";
import { NewAccountFormValues } from "../../AccountsManagementCreateAccount.model";

export function AccountsManagementContentFilterForm({
  disabled,
  height,
}: {
  disabled?: boolean;
  height?: number;
}) {
  const { breakpoints } = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(breakpoints.down("lg"));
  const { control, watch, setValue } = useFormContext<NewAccountFormValues>();

  const { setSelectedPreset, selectedPreset } = useSelectedPresetStore();

  const contentFilterFormData = watch();

  const excludeValues = watch("sourcesExclude");
  const includeValues = watch("sourcesInclude");
  const formPreset = watch("preset");

  useEffect(() => {
    const filters = makeContentFilterQuery(
      contentFilterFormData.sourcesInclude,
      contentFilterFormData.sourcesExclude
    );
    const matchingPreset = INCLUDE_EXCLUDE_PRESETS.find((item) =>
      isEqual(item.contentAccessFilter, filters)
    );
    if (!matchingPreset) {
      setValue("preset", "custom");
    } else {
      setValue("preset", matchingPreset.title);
      setSelectedPreset({
        include: contentFilterFormData.sourcesInclude,
        exclude: contentFilterFormData.sourcesExclude,
      });
    }
  }, [
    contentFilterFormData.sourcesExclude,
    contentFilterFormData.sourcesInclude,
    setValue,
    setSelectedPreset,
  ]);

  useEffect(() => {
    if (selectedPreset) {
      const isIncludeValuesEqual = isEqual(
        includeValues,
        selectedPreset.include
      );
      const isExcludeValuesEqual = isEqual(
        excludeValues,
        selectedPreset.exclude
      );

      if (!isIncludeValuesEqual || !isExcludeValuesEqual) {
        setValue("preset", "custom");
        setSelectedPreset(null);
      }
    }

    if (formPreset) {
      if (
        includeValues?.list?.length === 0 &&
        excludeValues?.list?.length === 0
      ) {
        setValue("preset", "Apex");
      }
    }
  }, [
    excludeValues,
    includeValues,
    selectedPreset,
    formPreset,
    setValue,
    setSelectedPreset,
  ]);

  const handlePresetChange = (event: SelectChangeEvent) => {
    const presetTitle = event.target.value;
    const selectedContentFilterPreset = CONTENT_FILTER_PRESETS.find(
      (preset) => preset.title === presetTitle
    );
    if (selectedContentFilterPreset) {
      const sources = parseIncludeExcludeGroup(
        selectedContentFilterPreset.queryDefinition.sourceFilter
      );
      setValue("sourcesInclude", sources.include);
      setValue("sourcesExclude", sources.exclude);
      setValue("preset", presetTitle, { shouldDirty: true });
      setSelectedPreset({
        include: sources.include,
        exclude: sources.exclude,
      });
    }
  };
  const allSources =
    !excludeValues?.list?.length && !includeValues?.list?.length;

  return (
    <>
      <div ref={containerRef} style={{ width: "100%" }} />
      <Stack py={3} height={height}>
        <Typography variant="subtitle1">Content Filter</Typography>
        <Grid container spacing={isMobile ? 1.5 : 1} mb={2} mt={1}>
          <Grid item xs={12} mb={3}>
            <Controller
              name="preset"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth>
                  <InputLabel>
                    {allSources ? "All sources" : "Presets"}
                  </InputLabel>
                  <Select
                    {...field}
                    disabled={disabled}
                    id="preset"
                    label={allSources ? "All sources" : "Presets"}
                    value={field.value}
                    onChange={handlePresetChange}
                    onBlur={field.onBlur}
                    fullWidth
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        width: 100,
                      },
                    }}
                  >
                    <MenuItem value={"custom"}>Custom </MenuItem>
                    {CONTENT_FILTER_PRESETS.map((preset) => (
                      <MenuItem key={preset.title} value={preset.title}>
                        {preset.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="sourcesInclude"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <SourcesPicker
                    id="source-include"
                    label="Include"
                    value={field.value}
                    excludedOptions={
                      contentFilterFormData?.sourcesExclude?.list
                    }
                    onChange={(e) => {
                      //Sort selected options to match BE order
                      const sortedValues = sortIncludeExcludeValues(e);
                      field.onChange(e?.list?.length ? sortedValues : e);
                    }}
                    onBlur={field.onBlur}
                    disabled={disabled}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message || " "}
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="sourcesExclude"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <SourcesPicker
                    id="source-exclude"
                    label="Exclude"
                    value={field.value}
                    excludedOptions={
                      contentFilterFormData?.sourcesInclude?.list
                    }
                    disabled={disabled}
                    onChange={(e) => {
                      //Sort selected options to match BE order
                      const sortedValues = sortIncludeExcludeValues(e);
                      field.onChange(e?.list?.length ? sortedValues : e);
                    }}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message || " "}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
