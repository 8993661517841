import { List, ListItem, Stack, Typography } from "@mui/material";

export function UserInviteFooter() {
  return (
    <Stack alignItems={"center"}>
      {/* accessibilitySwitch */}
      <Typography textAlign={"center"}>
        <span>Copyright ©1999 - 2022 TVEyes, Inc. </span>
        <span>All rights reserved.</span>
      </Typography>
      <List
        sx={{
          display: "flex",
          flexDirection: "row",
          whiteSpace: "nowrap",
        }}
      >
        <ListItem disablePadding>
          <Typography variant="caption" m={1} lineHeight={1.5}>
            <a
              href="https://www.tveyes.com/privacy-policy/"
              style={{ color: "black" }}
            >
              Privacy Policy
            </a>
          </Typography>
        </ListItem>
        <ListItem disablePadding>
          <Typography variant="caption" m={1} lineHeight={1.5}>
            <a
              href="mailto: customersuccess@tveyes.com"
              style={{ color: "black" }}
            >
              Send Feedback
            </a>
          </Typography>
        </ListItem>
        <ListItem disablePadding>
          <Typography variant="caption" m={1} lineHeight={1.5}>
            <a href="https://tveyes.com" style={{ color: "black" }}>
              TVEyes.com
            </a>
          </Typography>
        </ListItem>
      </List>
    </Stack>
  );
}
