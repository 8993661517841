import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { enqueueSnackbar } from "notistack";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { watchQueryListLazyKey } from "./useWatchQueryListLazy";
import { watchQueryListKey } from "./useWatchQueryList";

const apiPath: keyof paths = "/api/user/query/{queryId}/clone";
const method: keyof paths[typeof apiPath] = "post";

type Endpoint = paths[typeof apiPath][typeof method];
type Parameters = Endpoint["parameters"];
type Response = Endpoint["responses"][200];
type ResponseBody = Response["content"]["application/json"];

type UseWatchQueryCreate = Omit<
  UseMutationOptions<ResponseBody, unknown, Parameters>,
  "mutationFn"
>;

export function useWatchQueryClone({
  options,
}: {
  options?: UseWatchQueryCreate;
}) {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation<ResponseBody, unknown, Parameters>({
    ...options,
    mutationKey: [apiPath],
    mutationFn: async (requestParams) => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl(apiPath, requestParams);
      const response = await axios<ResponseBody>(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    },
    onSuccess: (data, variables, context) => {
      enqueueSnackbar(
        t("watchTermCreateMessage", { termName: `"${data.title}"` }),
        {
          variant: "success",
        }
      );

      options?.onSuccess && options.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });

      options?.onError?.(error, variables, context);
    },
    onSettled(data, error, variables, context) {
      queryClient.refetchQueries({
        queryKey: watchQueryListLazyKey,
      });

      queryClient.refetchQueries({
        queryKey: watchQueryListKey,
      });

      options?.onSettled?.(data, error, variables, context);
    },
  });
}
