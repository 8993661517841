import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { useSessionContext } from "./useSessionContext";

export const userInviteListKey = "userInviteListKey";

const apiPath: keyof paths = "/api/Invites";
const method: keyof paths[typeof apiPath] = "get";
type Endpoint = paths[typeof apiPath][typeof method];

type Parameters = Endpoint["parameters"];
type Response = Endpoint["responses"][200]["content"]["application/json"];

export function useUserInviteList() {
  const { getAccessTokenSilently } = useAuth0();
  const { accountId } = useSessionContext();

  return useQuery<Response, unknown>({
    keepPreviousData: true,
    queryKey: [userInviteListKey],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Parameters>(apiPath, {
        query: {
          accountId,
        },
      });
      const { data } = await axios<Response>(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
