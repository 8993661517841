import { Stack, useTheme } from "@mui/material";
import { ReactNode, Ref, useMemo, useRef } from "react";
import {
  FixedSizeGrid,
  FixedSizeGridProps,
  ListOnItemsRenderedProps,
} from "react-window";
import { useCustomScrollBarStyles } from "src/utils/useCustomScrollBarStyles";
import { useOverflowEffectDecoration } from "../ScrollBox/useOverflowEffectDecorator";

type TileLayoutRendererInsets = {
  left?: number;
  top?: number;
  right?: number;
  bottom?: number;
};

type ItemData<T> = {
  colCount: number;
  colWidth: number;
  colGapPx: number;
  //
  rowCount: number;
  rowHeight: number;
  rowGapPx: number;
  // padding inside scrolling container
  insets?: TileLayoutRendererInsets;
  // total number of items, including not yet loaded
  total: number;
  // all loaded items
  items: T[];
  isItemLoaded: (index: number) => boolean;
  renderItem: (params: {
    data: T | null;
    index: number;
    columnIndex: number;
    rowIndex: number;
    cellWidth: number;
    cellHeight: number;
  }) => ReactNode;
};

const TileLayoutCellRenderer: FixedSizeGridProps<ItemData<any>>["children"] = ({
  style,
  columnIndex,
  rowIndex,
  data,
}) => {
  const { spacing } = useTheme();
  const { renderItem } = data;
  const { colCount, colWidth, colGapPx } = data;
  const { rowCount, rowHeight, rowGapPx } = data;
  const { insets, items, total } = data;

  const insetLeftPx = parseFloat(spacing(insets?.left ?? 0));
  const insetTopPx = parseFloat(spacing(insets?.top ?? 0));
  const insetBottomPx = parseFloat(spacing(insets?.bottom ?? 0));

  const tileWidth = colWidth - colGapPx / 2;
  const tileHeight = rowHeight - rowGapPx / 2;

  const tileStyles = {
    ...style,
    left: insetLeftPx + (tileWidth + colGapPx) * columnIndex,
    top: insetTopPx + (tileHeight + rowGapPx) * rowIndex,
    width: tileWidth,
    height: tileHeight,
  };

  // calculate index in the source list
  const index = rowIndex * colCount + columnIndex;

  // do not render cells that are out of range
  if (total !== undefined && index >= total) return null;

  // get value for a cell and render data or loading indicator
  const content = renderItem({
    data: items[index] ?? null,
    index,
    columnIndex,
    rowIndex,
    cellWidth: tileWidth,
    cellHeight: tileHeight,
  });

  return (
    <Stack
      style={tileStyles}
      children={content}
      // this is required to heighten the container, so it includes bottom inset
      pb={rowIndex === rowCount - 1 ? `${insetBottomPx}px` : undefined}
    />
  );
};

const tileLayoutGridClasses = {
  root: "TileLayoutGrid-root",
  scroll: "TileLAyoutGrid-scroll",
};

export type TileLayoutGridProps<T = unknown> = {
  innerRef: Ref<FixedSizeGrid<ItemData<T>>>;
  items: T[];
  width: number;
  height: number;
  total: number;
  colCount: number;
  rowCount: number;

  insets?: TileLayoutRendererInsets;

  onItemsRendered: (params: ListOnItemsRenderedProps) => ReactNode;
  onScroll?: FixedSizeGridProps["onScroll"];
  isItemLoaded: (index: number) => boolean;

  /** Column gap */
  getColGap: (containerWidth: number) => number | void;

  /** Row gap */
  getRowGap: (containerWidth: number) => number | void;

  /** Get row height */
  getRowHeight?: (containerWidth: number) => number;

  /** Get column width */
  getColWidth?: (containerWidth: number) => number;

  /** Get item key, usually an id */
  getItemKey: (item?: T) => string | undefined;

  /** Render item, when item is null you can render loading indicator */
  renderItem: (params: {
    data: T | null;
    index: number;
    columnIndex: number;
    rowIndex: number;
    cellWidth: number;
    cellHeight: number;
  }) => ReactNode;

  /** Render empty container */
  renderEmpty?: () => JSX.Element;
};

export const TileLayoutGrid = <T,>({
  innerRef,
  items,
  width,
  height,
  colCount,
  rowCount,
  total,
  insets,
  onItemsRendered,
  isItemLoaded,
  onScroll,
  getColGap,
  getColWidth,
  getRowGap,
  getRowHeight,
  getItemKey,
  renderItem,
}: TileLayoutGridProps<T>) => {
  const { spacing } = useTheme();
  const outerRef = useRef(null);
  const scrollRef = useRef(null);
  const insetLeftPx = parseFloat(spacing(insets?.left ?? 0));
  const insetRightPx = parseFloat(spacing(insets?.right ?? 0));
  const availableWidth = width - insetLeftPx - insetRightPx;

  const colWidth = getColWidth
    ? getColWidth(availableWidth)
    : // default
      availableWidth / colCount;

  const rowHeight = getRowHeight
    ? getRowHeight(colWidth)
    : // square by default, same as column width
      colWidth;

  // return number of pixels for column gap
  const colGapSize = getColGap?.(availableWidth) ?? 0;
  const colGapPx = parseFloat(spacing(colGapSize));

  // return number of pixels for row gap
  const rowGapSize = getRowGap?.(availableWidth) ?? colGapSize;
  const rowGapPx = parseFloat(spacing(rowGapSize));

  const itemData: ItemData<T> = useMemo(() => {
    return {
      //
      colCount,
      colWidth,
      colGapPx,
      //
      rowCount,
      rowHeight,
      rowGapPx,
      //
      width,
      insets,
      items,
      total,
      renderItem,
      isItemLoaded,
    };
  }, [
    colGapPx,
    rowGapPx,
    colCount,
    colWidth,
    rowCount,
    rowHeight,
    insets,
    items,
    total,
    renderItem,
    isItemLoaded,
    width,
  ]);

  useOverflowEffectDecoration({
    outerRef,
    scrollRef,
  });

  const scrollStyleSx = useCustomScrollBarStyles({
    overflowX: "scroll",
    overflowY: "scroll",
    size: "normal",
  });

  return (
    <Stack
      position="relative"
      overflow="hidden"
      width={width}
      height={height}
      ref={outerRef}
      className={tileLayoutGridClasses.root}
      sx={{
        [`.${tileLayoutGridClasses.scroll}`]: {
          ...scrollStyleSx,
        },
      }}
    >
      <FixedSizeGrid<ItemData<T>>
        ref={innerRef}
        outerRef={scrollRef}
        columnCount={colCount}
        columnWidth={colWidth}
        rowCount={rowCount}
        rowHeight={rowHeight}
        width={width}
        height={height}
        onScroll={onScroll}
        className={tileLayoutGridClasses.scroll}
        itemData={itemData}
        itemKey={({ rowIndex, columnIndex, data }) => {
          const index = rowIndex * colCount + columnIndex;
          const item = data.items[index];

          return getItemKey(item) ?? index;
        }}
        onItemsRendered={({
          visibleRowStartIndex,
          visibleRowStopIndex,
          overscanRowStartIndex,
          overscanRowStopIndex,
        }) => {
          onItemsRendered({
            overscanStartIndex: overscanRowStartIndex * colCount,
            overscanStopIndex: overscanRowStopIndex * colCount,
            visibleStartIndex: visibleRowStartIndex * colCount,
            visibleStopIndex: visibleRowStopIndex * colCount,
          });
        }}
        children={TileLayoutCellRenderer}
      />
    </Stack>
  );
};
