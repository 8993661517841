import { ArrowBack } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppTopNavBar } from "src/components/AppTopNavBar/AppTopNavBar";
import { PageDesktopHeaderProps } from "./PageHeaderDesktop.model";
import { IntercomPageAnchor } from "../IntercomPageAnchor/IntercomPageAnchor";

export function PageHeaderDesktop({
  title,
  breadcrumbLabels,
  hidePenultimateRoute,
  hideBreadcrumbs,
  toolbar,
  onBack,
  hideBackButton = false,
  ...props
}: PageDesktopHeaderProps) {
  const navigate = useNavigate();
  const goBackWrapper = () => {
    if (onBack) {
      onBack();
      return;
    }
    navigate(-1);
  };

  const pageTitleBar = (
    <Stack alignSelf="center">
      <Stack
        id="page-header-desktop-title-container"
        direction="row"
        height="100%"
        width="fit-content"
      >
        {!hideBackButton && (
          <IconButton
            onClick={goBackWrapper}
            edge="start"
            children={<ArrowBack />}
          />
        )}

        {typeof title === "string" ? (
          <Tooltip title={title}>
            <Typography
              flex={1}
              variant="h6"
              children={title}
              display="inline-flex"
              alignItems="center"
            />
          </Tooltip>
        ) : (
          title
        )}
        <IntercomPageAnchor />
      </Stack>
      {!hideBreadcrumbs && (
        <AppTopNavBar
          maxItems={2}
          hidePenultimateRoute={hidePenultimateRoute}
          routeLabels={breadcrumbLabels}
        />
      )}
    </Stack>
  );

  return (
    <Stack mb={3} id="page-header-layout-desktop">
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={{ xs: "wrap", lg: "nowrap" }}
        gap={2}
      >
        {pageTitleBar}
        {toolbar && <Stack flex={1} alignItems="flex-end" children={toolbar} />}
      </Stack>
    </Stack>
  );
}
