import { alpha, Stack, svgIconClasses, useTheme } from "@mui/material";
import { getEventIcon } from "src/utils/getEventIcon";

export function ClipViewerPlaceholder({
  type,
  mediaType,
}: {
  type?: string;
  mediaType?: string;
}) {
  const Icon = getEventIcon(type, mediaType);
  const { palette } = useTheme();

  return (
    <Stack
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      className="ClipViewerPlaceholder-root"
      sx={{
        backgroundColor: alpha(palette.primary.light, 0.08),
        [`.${svgIconClasses.root}`]: {
          height: "50%",
          width: "50%",
          color: alpha(palette.primary.main, 0.3),
        },
      }}
      children={<Icon width="100%" height="100%" />}
    />
  );
}
