import {
  Box,
  Chip,
  FilterOptionsState,
  Stack,
  Tooltip,
  autocompleteClasses,
  outlinedInputClasses,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import { GridRenderEditCellParams } from "@mui/x-data-grid-premium";
import { TranscriptText } from "src/components/TranscriptText/TranscriptText";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { AutocompleteInline } from "src/components/AutocompleteInline/AutocompleteInline";
import { TextInputBase } from "src/components/TextInputBase";
import { AppLink } from "src/components/AppLink/AppLink";
import {
  getMediaCenterResultCountry,
  getMediaCenterResultDateTime,
  getMediaCenterResultEvent,
  getMediaCenterResultEventType,
  getMediaCenterResultSource,
  getMediaCenterResultTranscript,
  getMediaCenterResultLanguage,
  getMediaCenterResultMarket,
  getMediaCenterResultCategory,
  getMediaCenterResultFormat,
  getMediaCenterResultDuration,
  getMediaCenterResultMediaType,
} from "src/api/useMediaCenter.getters";
import { TagListDataItem } from "src/api/useTagListData";
import { getEventIcon, getEventTooltipTitle } from "src/utils/getEventIcon";
import { makeTranscriptHTML } from "src/utils/makeTranscriptHTML";
import { stripHTMLTags } from "src/utils/stripHTMLTags";
import { mediaCenterClipRoute } from "src/pages/MediaCenterClip/MediaCenterClip.route";
import { hashStringToColor } from "src/pages/MediaCenter/MediaCenter.utils";
import { ResizedColumns } from "./MediaCenterTable";
import { MediaCenterTableColDef } from "./MediaCenterTable.model";
import { MediaCenterTableClasses } from "./MediaCenterTable.const";
import { MediaCenterActions } from "../MediaCenterActions/MediaCenterActions";
import { MediaCenterItem } from "src/api/useMediaCenter";

type TagsDataItem = {
  id: string;
  tags: string[] | null;
};

const commonCellParams: Pick<
  MediaCenterTableColDef,
  "sortable" | "align" | "headerAlign" | "disableColumnMenu"
> = {
  sortable: false,
  disableColumnMenu: true,
};

export function makeMediaCenterTableColumns({
  options,
  resizedColumns,
  tagsData,
  inputValue,
  setInputValue,
  filter,
  handleAppendTags,
  handleTagDelete,
  downloadMediaItem,
  addItemToReport,
}: {
  options: TagListDataItem[];
  resizedColumns: ResizedColumns;
  tagsData: TagsDataItem[];
  inputValue: string;
  setInputValue: (value: string) => void;
  filter: (options: string[], state: FilterOptionsState<string>) => string[];
  handleAppendTags: (id: string, tagName: string[]) => void;
  handleTagDelete: (id: string, tagName: string[]) => void;
  downloadMediaItem: (value: MediaCenterItem) => void;
  addItemToReport: (value: MediaCenterItem) => void;
}): MediaCenterTableColDef[] {
  return [
    {
      ...commonCellParams,
      headerName: "Event",
      field: "title",
      minWidth: 150,
      flex: 1,
      cellClassName: MediaCenterTableClasses.cellEvent,
      renderCell(params) {
        const detailsUrl = mediaCenterClipRoute.makeUrl({
          eventId: params.row.id,
        });

        const eventTitle = getMediaCenterResultEvent(params.row);
        return (
          <Tooltip title={eventTitle}>
            <AppLink to={detailsUrl}>
              <TextLineClamp lineClamp={2} color="primary">
                {eventTitle}
              </TextLineClamp>
            </AppLink>
          </Tooltip>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Type",
      field: "type",
      width: 56,
      renderCell(params) {
        const eventType = getMediaCenterResultEventType(params.row);
        const mediaType = getMediaCenterResultMediaType(params.row);
        const Icon = getEventIcon(eventType, mediaType);
        const tooltipTitle = getEventTooltipTitle(eventType, mediaType);

        return (
          <Tooltip title={tooltipTitle}>
            <Stack m="auto">
              <Icon />
            </Stack>
          </Tooltip>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Date",
      field: "date",
      type: "dateTime",
      width: 140,
      renderCell(params) {
        return params.row.status === "Complete" ? (
          <Tooltip title={getMediaCenterResultDateTime(params.row)}>
            <TextLineClamp lineClamp={2}>
              {getMediaCenterResultDateTime(params.row)}
            </TextLineClamp>
          </Tooltip>
        ) : (
          <Chip
            icon={<UploadIcon sx={{ width: "18px", height: "18px" }} />}
            label="In progress"
            variant="outlined"
            sx={{
              height: "18px",
              py: "12px",
            }}
          />
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Transcription",
      field: "transcription",
      minWidth: 100,
      flex: 1,
      valueGetter(params) {
        return getMediaCenterResultTranscript(params.row);
      },
      renderCell(params) {
        const transcript = params.value
          ? makeTranscriptHTML({ transcript: params.value })
          : "";

        const tooltipText = params.value ? stripHTMLTags({ transcript }) : "";
        return (
          <Tooltip title={tooltipText}>
            <TranscriptText
              transcript={transcript}
              variant="inherit"
              lineClamp={2}
            />
          </Tooltip>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Tag",
      field: "tags",
      editable: true,
      width: resizedColumns["tags"] || 142,
      renderCell(params) {
        //FIXME: problem with params.row.tags (won't update if I successfully add or remove tags from the table)
        const filteredTagsData =
          tagsData.find((item) => item.id === params.row.id)?.tags || [];

        return (
          <Stack flexDirection="row" gap={1}>
            {filteredTagsData.map((label, idx) => {
              const tagData = options.find((op) => op.tagName === label);

              return (
                <Tooltip key={`${label}-${idx}`} title={label}>
                  <Chip
                    sx={{
                      color: "white",
                      backgroundColor: tagData?.color,
                      "&:hover": {
                        backgroundColor: tagData?.color,
                      },
                    }}
                    size="small"
                    label={label}
                  />
                </Tooltip>
              );
            })}
          </Stack>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        //FIXME: problem with params.row.tags (won't update if I successfully add or remove tags from the table)
        const filteredTagsData =
          tagsData.find((item) => item.id === params.row.id)?.tags || [];

        const autocompleteOptions = options.map((opt) => opt.tagName);

        return (
          <AutocompleteInline<string, true, true, true>
            multiple
            freeSolo
            autoSelect
            inputValue={inputValue}
            onInputChange={(_event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            sx={{
              width: "100%",
              [`.${autocompleteClasses.input}`]: {
                minWidth: "20px !important",
              },
            }}
            disableClearable
            value={filteredTagsData}
            onChange={(_e, newValue) => {
              if (typeof newValue === "string") {
                const nextFilteredTags = [...filteredTagsData, newValue];
                handleAppendTags(params.row.id, nextFilteredTags);
              } else {
                handleAppendTags(params.row.id, newValue);
              }
            }}
            renderInput={(param) => (
              <TextInputBase
                {...param}
                sx={{
                  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
                    {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                      borderWidth: 1,
                    },
                }}
                label="Tag Name"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.stopPropagation();
                    handleAppendTags(params.row.id, [inputValue]);
                    setInputValue("");
                  }
                }}
              />
            )}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;

              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push(inputValue);
              }

              return filtered;
            }}
            options={autocompleteOptions || []}
            renderOption={(props, option) => {
              const tagData = options.find((op) => op.tagName === option);
              return (
                <Box component="li" {...props}>
                  <Chip
                    label={option}
                    size="small"
                    sx={{
                      color: "white",
                      backgroundColor:
                        tagData?.color || hashStringToColor(option),
                      "&:hover": {
                        backgroundColor:
                          tagData?.color || hashStringToColor(option),
                      },
                    }}
                  />
                </Box>
              );
            }}
            renderStartAdornment={() => null}
            renderTags={(value: string[], getTagProps) => {
              const tagElements = value.map((option, index) => {
                const tagData = options.find((op) => op.tagName === option);

                return (
                  <Chip
                    label={option}
                    {...getTagProps({ index })}
                    onDelete={() => {
                      const tagsForDelete = value.filter(
                        (item) => item === option
                      );
                      handleTagDelete(params.row.id, tagsForDelete);
                    }}
                    sx={{
                      color: "white",
                      backgroundColor: `${
                        tagData?.color || hashStringToColor(option)
                      } !important`,
                      "&:hover": {
                        backgroundColor: tagData?.color,
                      },
                    }}
                    size="small"
                  />
                );
              });

              return (
                <Stack flexDirection="row" overflow="auto" maxHeight={114}>
                  {tagElements}
                </Stack>
              );
            }}
          />
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Country",
      field: "country",
      width: 56,
      valueGetter(params) {
        return getMediaCenterResultCountry(params.row);
      },
    },
    {
      ...commonCellParams,
      headerName: "Market",
      field: "market",
      width: 80,
      valueGetter(params) {
        return getMediaCenterResultMarket(params.row);
      },
    },
    {
      ...commonCellParams,
      headerName: "Source",
      field: "source",
      width: 72,
      valueGetter(params) {
        return getMediaCenterResultSource(params.row);
      },
    },
    {
      ...commonCellParams,
      headerName: "Language",
      field: "language",
      width: 64,
      valueGetter(params) {
        return getMediaCenterResultLanguage(params.row);
      },
    },
    {
      ...commonCellParams,
      headerName: "Category",
      field: "category",
      width: 76,
      valueGetter(params) {
        return getMediaCenterResultCategory(params.row);
      },
    },
    {
      ...commonCellParams,
      headerName: "Duration",
      headerAlign: "center",
      field: "duration",
      align: "center",
      type: "number",
      width: 88,
      valueGetter(params) {
        return getMediaCenterResultDuration(params.row);
      },
    },
    {
      ...commonCellParams,
      headerName: "Format",
      headerAlign: "center",
      field: "format",
      align: "center",
      width: 56,
      valueGetter(params) {
        return getMediaCenterResultFormat(params.row);
      },
    },
    {
      ...commonCellParams,
      headerName: "",
      headerAlign: "center",
      field: "actions",
      align: "center",
      type: "actions",
      width: 48,
      renderCell(params) {
        return (
          <MediaCenterActions
            value={params.row}
            downloadMediaItem={downloadMediaItem}
            addItemToReport={addItemToReport}
          />
        );
      },
    },
  ];
}
