import { useState, useRef, useMemo, Fragment } from "react";
import { useQueryParam } from "use-query-params";
import { Grid, Paper, Stack, useMediaQuery, useTheme } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { PageLayoutMobile } from "src/components/PageLayoutMobile";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { ViewModeSwitch } from "src/components/ViewModeSwitch/ViewModeSwitch";
import { PageHeaderDesktop } from "src/components/PageHeaderDesktop/PageHeaderDesktop";
import { GlobalSearchDialog } from "src/components/GlobalSearchDialog/GlobalSearchDialog";
import { GlobalSearchDesktopButton } from "src/components/GlobalSearchDesktopButton/GlobalSearchDesktopButton";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { SortingMenu } from "src/components/SortingMenu/SortingMenu";
import {
  SortBy,
  SortOption,
  SortByParameters,
  OrderByParameters,
} from "src/components/SortingMenu/SortingMenu.model";
import { SourcesPicker } from "src/components/SourcesPicker/SourcesPicker";
import { useGlobalSearchOpen } from "src/api/useGlobalSearchOpen";
import { SnapshotTiles } from "./components/SnapshotTiles/SnapshotTiles";
import { useSnapshotQueryFilter } from "./hooks/useSnapshotQueryFilter";
import { MOST_COMMON_PRESETS } from "./__mock__/Snapshots.mock";

const viewModeOptions: {
  desktop: ViewMode[];
  mobile: ViewMode[];
} = {
  desktop: [ViewMode.smallTiles, ViewMode.largeTiles],
  mobile: [ViewMode.smallTiles, ViewMode.largeTiles],
};

export function SnapshotsPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const containerRef = useRef<HTMLDivElement>(null);
  const [sortBy, setSortBy] = useState<SortByParameters>("market");
  const [sortOption, setSortOption] = useState(SortBy.marketSizeAsc);
  const [orderBy, setOrderBy] = useState<OrderByParameters>("asc");
  const [sources, setSources] = useSnapshotQueryFilter();
  const watchQuerySearchDialog = useGlobalSearchOpen();

  const tabParam = useQueryParam<ViewMode>("tab");
  const [viewMode = ViewMode.smallTiles, setViewMode] = tabParam;
  const defaultLabel = sources.list.length ? "Include selected" : "All Sources";

  const onSortChange = (sortBy: SortOption) => {
    setSortOption(sortBy.value);
    setSortBy(sortBy.field);
    setOrderBy(sortBy.order);
  };

  const sourceSelector = (
    <Stack position="relative" width={isMobile ? "85%" : "100%"}>
      <SourcesPicker
        presets={MOST_COMMON_PRESETS}
        label={defaultLabel}
        value={sources}
        onChange={setSources}
        dropDownBoundsEl={containerRef}
        hideOperator
        size="small"
        isBroadcast
      />
      {isMobile && (
        <ArrowDropDownIcon
          sx={{
            position: "absolute",
            right: 12,
            top: 12,
            color: theme.palette.action.active,
          }}
        />
      )}
    </Stack>
  );

  const sortingMenu = (
    <SortingMenu
      value={sortOption}
      onChange={onSortChange}
      size={"medium"}
      options={[
        SortBy.marketTitleAsc,
        SortBy.marketTitleDesc,
        SortBy.marketSizeDesc,
        SortBy.marketSizeAsc,
        SortBy.disctanceAsc,
        SortBy.distanceDesc,
      ]}
      minWidth={"45px"}
      sx={{
        [`.${SortBy.marketSizeDesc}`]: {
          display: "none",
        },
        justifyContent: "end",
      }}
    />
  );

  const viewModeSwitch = (
    <ViewModeSwitch
      value={viewMode}
      onChange={setViewMode}
      options={isMobile ? viewModeOptions.mobile : viewModeOptions.desktop}
      size={isMobile ? "small" : "medium"}
    />
  );

  const dataViewElement = useMemo(() => {
    return (
      <SnapshotTiles
        viewMode={viewMode}
        sources={sources}
        sortBy={sortBy}
        orderBy={orderBy}
      />
    );
  }, [viewMode, sources, sortBy, orderBy]);

  const commonContent = (
    <GlobalSearchDialog
      open={watchQuerySearchDialog.isOpen}
      onClose={watchQuerySearchDialog.hide}
    />
  );

  const headerDesktop = (
    <Fragment>
      <div
        ref={containerRef}
        style={{
          width: "100%",
        }}
      />
      <PageHeaderDesktop
        title="Snapshot"
        hideBreadcrumbs
        hideBackButton
        toolbar={
          <Stack ml="auto">
            <GlobalSearchDesktopButton />
          </Stack>
        }
      />
    </Fragment>
  );

  const desktopContent = (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        height: "100%",
      }}
    >
      <Grid container spacing={2} px={2} py={2} position="relative">
        <Grid item xs={12} pt="0!important"></Grid>

        <Grid
          item
          xs={12}
          md={8}
          lg={6}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          width={1152}
        >
          {sourceSelector}
        </Grid>
        <Grid
          item
          xs={12}
          md="auto"
          ml="auto"
          display="flex"
          flexDirection="row"
          gap={2}
          height={64}
        >
          {sortingMenu}
          {viewModeSwitch}
        </Grid>
      </Grid>

      {dataViewElement}
    </Paper>
  );

  const mobileContent = (
    <Stack direction="column" width="100%" height="100%">
      <Stack pl={2} pt={1}>
        <PageHeaderDesktop title="Snapshot" hideBreadcrumbs hideBackButton />
      </Stack>

      <Stack px={2} pb={2} direction={"row"} gap={2}>
        {sourceSelector}
        {sortingMenu}
      </Stack>

      {dataViewElement}
    </Stack>
  );

  if (isMobile) {
    return (
      <Fragment>
        <PageLayoutMobile content={mobileContent} />
        {commonContent}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <PageLayoutDesktop
        header={headerDesktop}
        content={desktopContent}
        overflow="hidden"
      />
      {commonContent}
    </Fragment>
  );
}
