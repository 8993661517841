import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { AppTab } from "src/components/AppTabLayout/AppTab";
import { AppTabLayout } from "src/components/AppTabLayout/AppTabLayout";
import { UserInviteDTO } from "src/models/UserInvite";
import { useListFilter } from "src/utils/useListFilter";
import { SearchInput } from "src/components/SearchInput";
import { UserInviteTable } from "../UserInviteTable/UserInviteTable";
import { UserInviteForm } from "../UserInviteForm/UserInviteForm";

export function UserInviteTabs({
  invites,
  isLoading,
  isSubmitting,
}: {
  invites: UserInviteDTO[];
  isLoading?: boolean;
  isSubmitting?: boolean;
}) {
  const [filterText, setFilterText] = useState("");
  const inviteList = useListFilter(invites, filterText, [
    "email",
    "comment",
    "status",
  ]);

  const newInviteContent = (
    <Stack height="100%" gap={3} mx={2} pt={1.5}>
      <Typography>
        Send an invitation to your colleague to create an account. The invite
        will expire in 7 days, so act fast!
      </Typography>

      <UserInviteForm isDisabled={isSubmitting} />
    </Stack>
  );

  const allInvitesContent = (
    <Stack height="100%" gap={3} mx={2} py={2}>
      <SearchInput
        label="Filter Invites"
        onTextChangeThrottled={setFilterText}
      />
      <UserInviteTable showBorder rows={inviteList} isLoading={isLoading} />
    </Stack>
  );

  return (
    <AppTabLayout flex={1}>
      <AppTab
        label="New Invite"
        value="new-invite"
        children={newInviteContent}
      />
      <AppTab
        label="All Invites"
        value="all-invites"
        children={allInvitesContent}
      />
    </AppTabLayout>
  );
}
