import { AppRouteBuilder } from "src/models/AppRoute";
import { PermissionGuard } from "src/components/PermissionGuard";
import { ACTIVITYLOGSROLES } from "src/utils/useUserRoleList";
import { UserManagementActivityLogPage } from "./UserManagementActivityLogs.page";

type UserManagementActivityLogsParams = {
  userId: string;
};

export const userManagementActivityLogsRoute =
  new AppRouteBuilder<UserManagementActivityLogsParams>({
    path: "/user-management/logs/:userId",
    element: (
      <PermissionGuard
        component={UserManagementActivityLogPage}
        allowedRoles={ACTIVITYLOGSROLES}
        disableMobile
        redirectTo="/"
        // silent
      />
    ),
  });
