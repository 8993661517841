import Hotjar from "@hotjar/browser";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isDebugEnv } from "src/utils/isDebugEnv";
import { useSessionContext } from "./useSessionContext";

const siteId = 5038336;
const hotJarVersion = 6;

export function useHotJar() {
  const location = useLocation();
  const allowHotJar = !isDebugEnv();
  const { user } = useAuth0();
  const { userId, account, accountId, managementContact } = useSessionContext();

  useEffect(() => {
    if (!allowHotJar || Hotjar.isReady()) {
      return;
    }

    Hotjar.init(siteId, hotJarVersion, {
      // no additional options
    });
  }, [allowHotJar]);

  useEffect(() => {
    if (!allowHotJar || !userId || !user) {
      return;
    }

    const hotJarPayload = {
      firstName: `${user.given_name}`,
      lastName: `${user.family_name}`,
      accountName: account?.name ?? "",
      accountId: accountId,
      salesRep: `${managementContact?.firstName} ${managementContact?.lastName}`,
    };

    console.log(`@@ DEBUG:HotJar:identify`, hotJarPayload);

    Hotjar.identify(userId, hotJarPayload);
  }, [allowHotJar, user, userId, managementContact, account?.name, accountId]);

  useEffect(() => {
    if (!allowHotJar) {
      return;
    }

    Hotjar.stateChange(location.pathname);
  }, [allowHotJar, location]);

  return Hotjar;
}
