import axios from "axios";
import { UseQueryOptions, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiPath: keyof paths = "/api/activity/account/{accountId}/aggregations";
const method: keyof paths[typeof apiPath] = "get";

type Endpoint = paths[typeof apiPath][typeof method];
type Parameters = Endpoint["parameters"];

type ResponseBody = Endpoint["responses"][200]["content"]["application/json"];

export const accountActivityLogsAggregationsKey =
  "accountActivityLogsAggregationsKey";

export function useAccountActivityLogAggregations({
  request,
  options,
}: {
  request?: Parameters;
  options?: UseQueryOptions<ResponseBody, unknown>;
}) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<ResponseBody, unknown>({
    queryKey: [accountActivityLogsAggregationsKey, request],
    ...options,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Parameters>(apiPath, request);
      const { data } = await axios<ResponseBody>(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
