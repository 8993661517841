import {
  Stack,
  SwipeableDrawer,
  SwipeableDrawerProps,
  alpha,
  drawerClasses,
  styled,
  useTheme,
} from "@mui/material";

type BottomSideDrawerProps = Omit<
  SwipeableDrawerProps,
  "anchor" | "onClose"
> & {
  onClose: () => void;
  onOpen: () => void;
  height?: number | string;
  footer?: JSX.Element;
  zIndexDrawer?: number;
};

const Puller = styled("div")(({ theme }) => ({
  width: 32,
  height: 4,
  backgroundColor: alpha(theme.palette.action.active, 0.54),
  borderRadius: 3,
  position: "absolute",
  top: 14,
  left: "calc(50% - 16px)",
}));

export function BottomSideDrawer({
  onClose,
  onOpen,
  children,
  footer,
  //FIXME: we need a mechanism to determine the height depending on the screen height
  height = "50%",
  zIndexDrawer,
  ...props
}: BottomSideDrawerProps) {
  const { zIndex } = useTheme();

  return (
    <SwipeableDrawer
      {...props}
      anchor="bottom"
      onClose={onClose}
      onOpen={onOpen}
      PaperProps={{
        ...props.PaperProps,
        elevation: 2,
      }}
      ModalProps={{
        keepMounted: true,
        slotProps: {
          root: {
            style: {
              zIndex: zIndexDrawer || zIndex.drawer,
            },
          },
        },
      }}
      sx={{
        [`.${drawerClasses.paper}`]: {
          width: "100%",
          height: "100%",
          minHeight: height,
          maxHeight: height,
          borderTopLeftRadius: "28px",
          borderTopRightRadius: "28px",
          overflow: "visible",
        },
      }}
    >
      <Stack
        justifyContent="space-between"
        height="100%"
        pt={4.5}
        position="relative"
      >
        <Stack>
          <Puller />
        </Stack>
        {children}
        {footer}
      </Stack>
    </SwipeableDrawer>
  );
}
