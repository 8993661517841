import { CarouselItem } from "./UserInviteMarketingContent.model";

export const userInviteMarketingContent: CarouselItem[] = [
  {
    id: 1,
    title: "Find it here",
    description:
      "Global audio and video intelligence for data-driven decision makers.",
    link: "https://info.tveyes.com/free-trial-offer-tveyes-broadcast-tv-radio-media-monitoring",
    linkText: "Start free trial",
  },
  {
    id: 2,
    title: "Powerful Data & APIs",
    description:
      "TVEyes data products and APIs are designed to make audio and video content - and the data associated with it - easily accessible through partner platforms.",
    link: "https://www.tveyes.com/",
    linkText: "Explore our data",
  },
];
