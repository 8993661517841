import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import { queryAdvertisementValues } from "src/models/Advertisement";
import { MediaCenterFilterFormValues } from "./MediaCenterFilterForm.model";
import { MediaCenterFilterFormSchema } from "./MediaCenterFilterForm.schema";

export const makeEmptyMediaCenterFilterFormValues = (
  merge?: Partial<MediaCenterFilterFormValues>
): MediaCenterFilterFormValues => ({
  tags: merge?.tags || [],

  // sources
  sourcesInclude: {
    list: merge?.sourcesInclude?.list || [],
    logic: merge?.sourcesInclude?.logic || "or",
  },
  sourcesExclude: {
    list: merge?.sourcesExclude?.list || [],
    logic: merge?.sourcesExclude?.logic || "or",
  },
  // sources

  advertisement: merge?.advertisement || queryAdvertisementValues.all,
  programInclude: {
    list: merge?.programInclude?.list || [],
    logic: merge?.programInclude?.logic || "or",
  },
  programExclude: {
    list: merge?.programExclude?.list || [],
    logic: merge?.programExclude?.logic || "or",
  },
});

type UseMediaCenterFilterFormArgs = {
  initial?: Partial<MediaCenterFilterFormValues>;
};

export function useMediaCenterFilterForm(
  params?: UseMediaCenterFilterFormArgs
) {
  const { initial } = params || {};
  const emptyFormValues = makeEmptyMediaCenterFilterFormValues();
  const formHook = useForm<MediaCenterFilterFormValues>({
    mode: "all",
    resolver: joiResolver(MediaCenterFilterFormSchema),
    defaultValues: { ...emptyFormValues, ...initial },
  });

  return formHook;
}
