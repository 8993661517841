import { Fragment, useCallback, useMemo, useState } from "react";
import {
  Alert,
  alertClasses,
  alpha,
  Badge,
  badgeClasses,
  Box,
  Button,
  FormControlLabel,
  Paper,
  Snackbar,
  Stack,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { BooleanParam, useQueryParam } from "use-query-params";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

import { PageLayoutMobile } from "src/components/PageLayoutMobile";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { PageHeaderDesktop } from "src/components/PageHeaderDesktop/PageHeaderDesktop";
import { AppTabLayout } from "src/components/AppTabLayout/AppTabLayout";
import { AppTab } from "src/components/AppTabLayout/AppTab";
import { PageLoader } from "src/components/PageLoader";
import { ShareReportDialog } from "src/components/ShareReportDialog/ShareReportDialog";
import { SnackbarBase } from "src/components/SnackbarBase/SnackbarBase";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { useQueryPagination } from "src/utils/useQueryPagination";
import { useOpenState } from "src/utils/useOpenState";
import { downloadWarningEventsLength } from "src/utils/reportLimits";
import { useQueryDateRangeWithEmptyDefault } from "src/utils/useQueryDateRangeWithEmptyDefault";
import { useQueryDateRangeReportsWithEmptyDefault } from "src/utils/useQueryDateRangeReportsWithEmptyDefault";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";
import { useEventsDownloadLimits } from "src/utils/useEventsDownloadLimits";
import { combineInfiniteTilesData } from "src/utils/combineInfiniteTilesData";
import { AppTopNavBarMobile } from "src/components/AppTopNavBarMobile/AppTopNavBarMobile";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { ShareButtonDesktop } from "src/components/ShareButtonDesktop/ShareButtonDesktop";
import { GlobalSearchDialog } from "src/components/GlobalSearchDialog/GlobalSearchDialog";
import { GlobalSearchDesktopButton } from "src/components/GlobalSearchDesktopButton/GlobalSearchDesktopButton";
import { useEventsPreselected } from "src/api/useEventsPreselected";
import { useEventsSelectedForReports } from "src/api/useEventsSelectedForReport";
import { useReportsSelection } from "src/api/useReportsSelection";
import { useReportScratchEvents } from "src/api/useReportScratchEvents";
import { useReportScratchEventsInfinite } from "src/api/useReportScratchEventsInfinite";
import { useReportsList } from "src/api/useReportList";
import { useReportsListInfinite } from "src/api/useReportListInfinite";
import {
  useShareReportFormSnackbar,
  useShareReportLinkSnackbar,
} from "src/api/useShareReportSnackbar";
import { useGlobalSearchOpen } from "src/api/useGlobalSearchOpen";
import { DownloadReportFormat } from "src/api/useReportExport";
import { ReportItem } from "src/models/Report";
import {
  SortBy,
  SortOption,
  SortByParameters,
  OrderByParameters,
} from "src/components/SortingMenu/SortingMenu.model";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";
import { useSessionContext } from "src/api/useSessionContext";
import { reportDownloadRequest } from "src/api/useReportDownloadRequest";
import { ReportsExportButton } from "./components/ReportsExportButton/ReportsExportButton";
import { reportCreateRoute } from "../ReportCreate/ReportCreate.route";
import { PreselectedEventsTab } from "./components/PreselectedEventsTab/PreselectedEventsTab";
import { ReportsTabContent } from "./components/ReportsTab/ReportsTabContent";
import { ReportsPageTabMode, reportsPageTabKey } from "./Reports.const";
import { ReportsExportDialogMobile } from "./components/ReportsExportButton/ReportsExportDialogMobile";
import { DownloadMessageDialog } from "./components/ReportsTab/DownloadMessageDialog";
import { DownloadLimitsDialog } from "./components/ReportsTab/DownloadLimitsDialog";

export function ReportsPage() {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const navigate = useNavigate();
  const exportDialog = useOpenState();
  const shareDialog = useOpenState();
  const shareLinkSnackBar = useShareReportLinkSnackbar();
  const shareFormSnackBar = useShareReportFormSnackbar();
  const downloadMessageDialog = useOpenState();
  const downloadLimitsDialog = useOpenState();
  const watchQuerySearchDialog = useGlobalSearchOpen();
  const [isExpandedTextMode = false, setExpandedTextMode] = useQueryParam(
    "expanded",
    BooleanParam
  );
  const [dateRangeEvents, setDateRangeEvents] =
    useQueryDateRangeWithEmptyDefault();
  const [dateRangeReports, setDateRangeReports] =
    useQueryDateRangeReportsWithEmptyDefault();
  const [sortBy, setSortBy] = useState<SortByParameters>("createTime");
  const [sortOption, setSortOption] = useState(SortBy.defaultDesc);
  const [orderBy, setOrderBy] = useState<OrderByParameters>("desc");
  const [pagination, setPagination] = useQueryPagination();
  const [paginationReports, setPaginationReports] = useQueryPagination({
    pageKey: "pageReports",
    pageSizeKey: "pageSizeReports",
  });

  const { effectiveEntitlements } = useSessionContext();
  const limitEventsInReport =
    effectiveEntitlements?.limitEventsInReport?.value || 0;

  const { t } = useTranslation();

  const { highlightedEventsIds, removeHighlighting, allAddedEventsLenght } =
    useEventsPreselected();
  const eventsSelectedForReports = useEventsSelectedForReports();
  const reportsSelection = useReportsSelection();

  const [downloadReportFormat, setDownloadReportFormat] =
    useState<DownloadReportFormat>(DownloadReportFormat.csv);
  const getEventsDownloadLimit = useEventsDownloadLimits();

  const initialViewMode: ViewMode = isMobile ? ViewMode.tile : ViewMode.list;
  const [viewMode] = useViewModeQueryParam<ViewMode>({
    paramKey: "tab",
    defaultValue: initialViewMode,
  });

  const [pageTabMode, setPageTabMode] =
    useViewModeQueryParam<ReportsPageTabMode>({
      paramKey: reportsPageTabKey,
      defaultValue: ReportsPageTabMode.events,
    });

  const reportScratchEventsWithoutFilters = useReportScratchEvents({
    request: {
      query: {
        from: 0,
        size: 1,
      },
    },
  });

  const reportScratchEventsTotalWithoutFilters =
    reportScratchEventsWithoutFilters.data?.total || 0;
  const showReportsActionButtons =
    reportScratchEventsTotalWithoutFilters > 0 ||
    reportsSelection.list.length > 0;

  const reportScratchEvents = useReportScratchEvents({
    options: {
      enabled: viewMode !== ViewMode.tile,
    },
    request: {
      query: {
        from: pagination.page * pagination.pageSize,
        size: pagination.pageSize,
        sortby: sortBy,
        orderby: orderBy,
        startDate: dateRangeEvents[0]
          ? formatDateTimeWithoutTimeZone(dateRangeEvents[0])
          : undefined,
        endDate: dateRangeEvents[1]
          ? formatDateTimeWithoutTimeZone(dateRangeEvents[1])
          : undefined,
      },
    },
  });

  const reportScratchEventsTiles = useReportScratchEventsInfinite({
    options: {
      enabled: viewMode === ViewMode.tile,
    },
    request: {
      query: {
        sortby: sortBy,
        orderby: orderBy,
        startDate: dateRangeEvents[0]
          ? formatDateTimeWithoutTimeZone(dateRangeEvents[0])
          : undefined,
        endDate: dateRangeEvents[1]
          ? formatDateTimeWithoutTimeZone(dateRangeEvents[1])
          : undefined,
      },
    },
  });

  const reportScratchEventsData = useMemo(() => {
    if (viewMode === ViewMode.tile) {
      if (reportScratchEventsTiles.isError) return [];
      return combineInfiniteTilesData(reportScratchEventsTiles.data?.pages);
    }
    if (reportScratchEvents.isError) return [];
    return reportScratchEvents.data?.results || [];
  }, [
    viewMode,
    reportScratchEvents.data,
    reportScratchEventsTiles.data,
    reportScratchEvents.isError,
    reportScratchEventsTiles.isError,
  ]);

  const preselectedEventsTotal = useMemo(() => {
    if (viewMode === ViewMode.tile) {
      if (reportScratchEventsTiles.isError) return 0;
      const tilesPagesData = reportScratchEventsTiles.data?.pages;
      return tilesPagesData?.[tilesPagesData.length - 1]?.total || 0;
    }
    if (reportScratchEvents.isError) return 0;
    return reportScratchEvents.data?.total || 0;
  }, [
    viewMode,
    reportScratchEventsTiles.data?.pages,
    reportScratchEvents.data?.total,
    reportScratchEvents.isError,
    reportScratchEventsTiles.isError,
  ]);

  const reportsListWithoutFilters = useReportsList({
    request: {
      query: {
        from: 0,
        size: 1,
        /** Pass "archived" to hide shares in the report list */
        filter: "archived",
      },
    },
  });

  const reportsList = useReportsList({
    options: {
      enabled: viewMode !== ViewMode.tile,
    },
    request: {
      query: {
        from: paginationReports.page * paginationReports.pageSize,
        size: paginationReports.pageSize,
        /** Pass "archived" to hide shares in the report list */
        filter: "archived",
        sortby: sortBy,
        orderby: orderBy,
        startTime: dateRangeReports[0]
          ? formatDateTimeWithoutTimeZone(dateRangeReports[0])
          : undefined,
        endTime: dateRangeReports[1]
          ? formatDateTimeWithoutTimeZone(dateRangeReports[1])
          : undefined,
      },
    },
  });

  const reportsListTiles = useReportsListInfinite({
    options: {
      enabled: viewMode === ViewMode.tile,
    },
    request: {
      query: {
        /** Pass "archived" to hide shares in the report list */
        filter: "archived",
        sortby: sortBy,
        orderby: orderBy,
        startTime: dateRangeReports[0]
          ? formatDateTimeWithoutTimeZone(dateRangeReports[0])
          : undefined,
        endTime: dateRangeReports[1]
          ? formatDateTimeWithoutTimeZone(dateRangeReports[1])
          : undefined,
      },
    },
  });

  const reportsListData = useMemo(() => {
    if (viewMode === ViewMode.tile) {
      if (reportsListTiles.isError) return [];
      return combineInfiniteTilesData(reportsListTiles.data?.pages);
    }
    if (reportsList.isError) return [];
    return reportsList.data?.results || [];
  }, [
    viewMode,
    reportsList.data,
    reportsListTiles.data,
    reportsList.isError,
    reportsListTiles.isError,
  ]);

  const reportsListDataTotal = useMemo(() => {
    if (viewMode === ViewMode.tile) {
      if (reportsListTiles.isError) return 0;
      const tilesPagesData = reportsListTiles.data?.pages;
      return tilesPagesData?.[tilesPagesData.length - 1]?.total || 0;
    }
    if (reportsList.isError) return 0;
    return reportsList.data?.total || 0;
  }, [
    viewMode,
    reportsListTiles.data?.pages,
    reportsList.data?.total,
    reportsList.isError,
    reportsListTiles.isError,
  ]);

  const exportReportItem = useCallback(
    async (
      report: ReportItem,
      format: DownloadReportFormat = downloadReportFormat
    ) => {
      const downloadLimit = getEventsDownloadLimit(format);
      const downloadLimitExceeded =
        downloadLimit && report.count > downloadLimit;
      if (!downloadLimitExceeded) {
        const { status } = await reportDownloadRequest({
          format,
          reportId: report.id,
        });
        if (status === 200) {
          enqueueSnackbar(
            <div>
              <Typography variant="body1" fontWeight={500}>
                {`${format?.toUpperCase()} is generating: '${report.title}'`}
              </Typography>
              <Typography variant="body2">
                You will receive email and system notification once it's ready
              </Typography>
            </div>,
            {
              variant: "info",
              autoHideDuration: 5000,
            }
          );
        }
      }
    },
    [downloadReportFormat, getEventsDownloadLimit]
  );

  const handleReportExport = useCallback(
    (format?: DownloadReportFormat) => {
      reportsSelection.list.forEach((report) =>
        exportReportItem(report, format)
      );
      reportsSelection.unselectAll();
    },
    [reportsSelection, exportReportItem]
  );

  const exportReports = useCallback(
    (format: DownloadReportFormat) => {
      const downloadLimit = getEventsDownloadLimit(format);
      const downloadLimitExceeded = reportsSelection.list.some(
        (report) => downloadLimit && report.count > downloadLimit
      );
      if (downloadLimitExceeded) {
        setDownloadReportFormat(format);
        downloadLimitsDialog.show();
        return;
      }

      const selectedReportsEventsCount = reportsSelection.list.reduce(
        (sum, report) => {
          return sum + report.count;
        },
        0
      );
      if (selectedReportsEventsCount > downloadWarningEventsLength) {
        setDownloadReportFormat(format);
        downloadMessageDialog.show();
        return;
      }

      handleReportExport(format);
    },
    [
      reportsSelection,
      downloadMessageDialog,
      handleReportExport,
      downloadLimitsDialog,
      getEventsDownloadLimit,
    ]
  );

  const isDownloadDisabled = reportsSelection.list.length > 10;

  const copyShareLinkCallback = () => {
    reportsSelection.unselectAll();
    shareLinkSnackBar.show();
  };

  const handleShareDialogSubmit = () => {
    reportsSelection.unselectAll();
    shareFormSnackBar.show();
  };

  const handleChangePageTabMode = useCallback(
    (newValue: string) => {
      if (highlightedEventsIds.length || allAddedEventsLenght) {
        removeHighlighting();
      }
      setPageTabMode(newValue as ReportsPageTabMode);
    },
    [
      highlightedEventsIds,
      allAddedEventsLenght,
      removeHighlighting,
      setPageTabMode,
    ]
  );

  const createReport = useCallback(
    (isAll: boolean) => {
      if (highlightedEventsIds.length || allAddedEventsLenght) {
        removeHighlighting();
      }
      const url = reportCreateRoute.makeUrl(undefined, {
        all: isAll ? 1 : undefined,
      });
      navigate(url);
    },
    [navigate, highlightedEventsIds, removeHighlighting, allAddedEventsLenght]
  );

  const onSortChange = (sortBy: SortOption) => {
    setSortOption(sortBy.value);
    //Special case for reports, backend can only sort date by column name.
    if (
      sortBy.value === SortBy.defaultAsc ||
      sortBy.value === SortBy.defaultDesc
    ) {
      setSortBy("createTime");
    } else {
      setSortBy(sortBy.field);
    }
    setOrderBy(sortBy.order);
  };

  const excludeEventsQuantity = eventsSelectedForReports.excludeEvents.length;

  const selectedEventsLength = eventsSelectedForReports.areAllPagesSelected
    ? preselectedEventsTotal - excludeEventsQuantity
    : eventsSelectedForReports.events.length ?? 0;
  const disableCreateReport = selectedEventsLength > limitEventsInReport;
  const disableCreateReportAll = preselectedEventsTotal > limitEventsInReport;

  const createReportButton = (
    <TooltipBase
      title={t("limitEventsInReport", {
        limit: `${limitEventsInReport}`,
      })}
      disableHoverListener={!disableCreateReport}
    >
      <Box>
        <Badge
          badgeContent={`${selectedEventsLength ?? 0}`}
          color="primary"
          sx={{
            width: isMobile ? "100%" : "unset",
            [`.${badgeClasses.badge}`]: {
              fontSize: 12,
              top: 21,
              right: 24,
              backgroundColor:
                !!selectedEventsLength && !disableCreateReport
                  ? palette.primary.main
                  : alpha(palette.text.secondary, 0.18),
            },
          }}
        >
          <Button
            variant="contained"
            color="info"
            sx={{
              width: isMobile ? "100%" : 180,
              height: 42,
              pr: 4.5,
            }}
            disabled={!selectedEventsLength || disableCreateReport}
            onClick={() => createReport(false)}
          >
            Create Report
          </Button>
        </Badge>
      </Box>
    </TooltipBase>
  );

  const createReportAllButton = (
    <TooltipBase
      title={t("limitEventsInReport", {
        limit: `${limitEventsInReport}`,
      })}
      disableHoverListener={!disableCreateReportAll}
    >
      <Box>
        <Badge
          badgeContent="All"
          color={
            pageTabMode === ReportsPageTabMode.reports ? "primary" : "info"
          }
          sx={{
            width: isMobile ? "100%" : "unset",
            [`.${badgeClasses.badge}`]: {
              fontSize: 12,
              top: 21,
              right: 24,
              color:
                palette.mode === "light"
                  ? palette.primary.contrastText
                  : palette.text.primary,
              backgroundColor:
                !reportScratchEventsTotalWithoutFilters ||
                disableCreateReportAll
                  ? alpha(palette.text.secondary, 0.18)
                  : pageTabMode === ReportsPageTabMode.reports
                  ? palette.primary.main
                  : palette.info.main,
            },
          }}
        >
          <Button
            variant="contained"
            color={
              pageTabMode === ReportsPageTabMode.reports ? "info" : "primary"
            }
            sx={{
              width: isMobile ? "100%" : 180,
              height: 42,
              pr: 4.5,
            }}
            disabled={
              !reportScratchEventsTotalWithoutFilters || disableCreateReportAll
            }
            onClick={() => createReport(true)}
          >
            Create Report
          </Button>
        </Badge>
      </Box>
    </TooltipBase>
  );

  const exportShareButtons = (
    <>
      <ReportsExportButton
        disabled={!reportsSelection.list.length}
        sx={{
          minWidth: { xs: 100, md: 149 },
          height: 42,
        }}
        exportReports={exportReports}
        isDownloadDisabled={isDownloadDisabled}
      />
      <ShareButtonDesktop
        disabled={!reportsSelection.list.length}
        showShareDialog={shareDialog.show}
        badgeContent={reportsSelection.list.length ?? 0}
        sx={{ minWidth: { xs: 100, md: 149, height: 42 } }}
      />
    </>
  );

  const emptyReportsList =
    pageTabMode === ReportsPageTabMode.reports && !reportsListDataTotal;

  const emptyPreselectedEvents =
    pageTabMode === ReportsPageTabMode.events && !preselectedEventsTotal;

  const reportsExpandTextButton = viewMode === ViewMode.tile &&
    !emptyReportsList &&
    !emptyPreselectedEvents && (
      <FormControlLabel
        checked={!!isExpandedTextMode}
        control={
          <Switch
            onChange={(_e, checked) =>
              setExpandedTextMode(checked, "replaceIn")
            }
          />
        }
        label={<Typography variant="caption">Expanded Text</Typography>}
        sx={{ mx: 0 }}
      />
    );

  const tabContent = useMemo(() => {
    return (
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          height: "100%",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          ...(isMobile && {
            borderRadius: 0,
          }),
        }}
      >
        {pageTabMode === ReportsPageTabMode.events ? (
          reportScratchEvents.isLoading ||
          reportScratchEventsTiles.isLoading ? (
            <PageLoader />
          ) : (
            <PreselectedEventsTab
              isLoading={reportScratchEvents.isFetching}
              events={reportScratchEventsData}
              rowCount={preselectedEventsTotal}
              paginationModel={pagination}
              onPaginationModelChange={setPagination}
              sortOption={sortOption}
              onSortChange={onSortChange}
              dateRange={dateRangeEvents}
              setDateRange={setDateRangeEvents}
              reportScratchEventsTiles={reportScratchEventsTiles}
            />
          )
        ) : reportsList.isLoading || reportsListTiles.isLoading ? (
          <PageLoader />
        ) : (
          <ReportsTabContent
            isLoading={reportsList.isFetching}
            reports={reportsListData}
            rowCount={reportsListDataTotal}
            paginationModel={paginationReports}
            onPaginationModelChange={setPaginationReports}
            sortOption={sortOption}
            onSortChange={onSortChange}
            dateRange={dateRangeReports}
            setDateRange={setDateRangeReports}
            reportsListTiles={reportsListTiles}
          />
        )}
      </Paper>
    );
  }, [
    pageTabMode,
    reportScratchEvents,
    reportScratchEventsData,
    reportsList,
    reportsListData,
    reportsListDataTotal,
    pagination,
    setPagination,
    paginationReports,
    setPaginationReports,
    preselectedEventsTotal,
    isMobile,
    sortOption,
    dateRangeEvents,
    setDateRangeEvents,
    dateRangeReports,
    setDateRangeReports,
    reportScratchEventsTiles,
    reportsListTiles,
  ]);

  const reportsAppTabLabel = useMemo(
    () => (
      <Badge
        badgeContent={`${reportsListWithoutFilters.data?.total ?? 0}`}
        invisible={reportsListWithoutFilters.isLoading}
        color="primary"
        sx={{
          [`.${badgeClasses.badge}`]: {
            top: 10,
            right: 4,
            backgroundColor: !reportsListWithoutFilters.data?.total
              ? alpha(palette.text.secondary, 0.18)
              : pageTabMode === ReportsPageTabMode.reports
              ? palette.primary.main
              : alpha(palette.text.secondary, 0.65),
          },
        }}
      >
        <Typography sx={{ pr: 3.5, fontSize: 14, fontWeight: 500 }}>
          Reports
        </Typography>
      </Badge>
    ),
    [
      reportsListWithoutFilters.data?.total,
      reportsListWithoutFilters.isLoading,
      pageTabMode,
      palette,
    ]
  );

  const reportsTabs = useMemo(
    () => (
      <AppTabLayout
        variant={isMobile ? "fullWidth" : "scrollable"}
        height="100%"
        currentTab={pageTabMode}
        onCurrentTabChange={handleChangePageTabMode}
      >
        <AppTab
          label={
            <Badge
              badgeContent={`${reportScratchEventsTotalWithoutFilters}`}
              invisible={
                viewMode === ViewMode.tile
                  ? reportScratchEventsTiles.isFetching ||
                    reportScratchEventsTiles.isLoading
                  : reportScratchEvents.isLoading
              }
              color="primary"
              sx={{
                [`.${badgeClasses.badge}`]: {
                  top: 10,
                  right: 4,
                  backgroundColor:
                    pageTabMode === ReportsPageTabMode.events
                      ? palette.primary.main
                      : alpha(palette.text.secondary, 0.65),
                },
              }}
            >
              <Typography sx={{ pr: 3.5, fontSize: 14, fontWeight: 500 }}>
                {isMobile ? "Events" : "Preselected events"}
              </Typography>
            </Badge>
          }
          value={ReportsPageTabMode.events}
          children={tabContent}
          sx={{ px: 1, width: "230px" }}
        />
        <AppTab
          label={
            !!reportsListWithoutFilters.data?.total ? (
              reportsAppTabLabel
            ) : (
              <Tooltip title={"No reports available yet."}>
                {reportsAppTabLabel}
              </Tooltip>
            )
          }
          style={{ pointerEvents: "auto" }}
          disabled={!reportsListWithoutFilters.data?.total}
          value={ReportsPageTabMode.reports}
          children={tabContent}
          sx={{ px: 1, width: "230px" }}
        />
      </AppTabLayout>
    ),
    [
      isMobile,
      pageTabMode,
      handleChangePageTabMode,
      reportScratchEventsTotalWithoutFilters,
      reportScratchEvents.isLoading,
      palette,
      reportsAppTabLabel,
      reportsListWithoutFilters.data?.total,
      tabContent,
      viewMode,
      reportScratchEventsTiles.isFetching,
      reportScratchEventsTiles.isLoading,
    ]
  );

  const headerDesktop = (
    <PageHeaderDesktop
      title="Reports"
      hideBackButton
      hideBreadcrumbs
      toolbar={
        <Stack direction="row" spacing={2}>
          <GlobalSearchDesktopButton />
          {pageTabMode === ReportsPageTabMode.events && createReportButton}
          {createReportAllButton}
          {pageTabMode === ReportsPageTabMode.reports && exportShareButtons}
        </Stack>
      }
    />
  );

  const mobileContent = (
    <>
      <Stack height="100%" sx={{ overflowY: "auto" }}>
        <Stack direction="row" justifyContent="space-between" pr={2}>
          <AppTopNavBarMobile title="Reports" />
          {reportsExpandTextButton}
        </Stack>
        {reportsTabs}
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          px: 2,
          py: 3,
        }}
        spacing={2}
      >
        {!!reportScratchEventsTotalWithoutFilters &&
          pageTabMode === ReportsPageTabMode.events && (
            <>
              <Box sx={{ flex: 1 }}>{createReportButton}</Box>
              <Box sx={{ flex: 1 }}>{createReportAllButton}</Box>
            </>
          )}

        {pageTabMode === ReportsPageTabMode.reports &&
          showReportsActionButtons && (
            <>
              <Box sx={{ flex: 1 }}>{createReportAllButton}</Box>
              <Button
                disabled={!reportsSelection.list.length}
                variant="contained"
                color="primary"
                sx={{
                  minWidth: 74,
                  width: 74,
                  display: "flex",
                  justifyContent: reportsSelection.list.length
                    ? "space-between"
                    : "center",
                }}
                onClick={exportDialog.show}
              >
                <FileDownloadOutlinedIcon />
                {reportsSelection.list.length ? (
                  <Badge
                    color="info"
                    badgeContent={reportsSelection.list.length ?? 0}
                  />
                ) : null}
              </Button>
              <Button
                disabled={!reportsSelection.list.length}
                variant="contained"
                color="secondary"
                sx={{
                  minWidth: 74,
                  width: 74,
                  height: 42,
                  display: "flex",
                  justifyContent: reportsSelection.list.length
                    ? "space-between"
                    : "center",
                }}
                onClick={shareDialog.show}
              >
                <ShareOutlinedIcon />
                {reportsSelection.list.length ? (
                  <Badge
                    color="success"
                    badgeContent={reportsSelection.list.length ?? 0}
                  />
                ) : null}
              </Button>
            </>
          )}
        <ReportsExportDialogMobile
          open={exportDialog.isOpen}
          onClose={exportDialog.hide}
          exportReports={exportReports}
          isDownloadDisabled={isDownloadDisabled}
        />
      </Stack>
    </>
  );

  const commonContent = (
    <Fragment>
      <GlobalSearchDialog
        open={watchQuerySearchDialog.isOpen}
        onClose={watchQuerySearchDialog.hide}
      />
      {!!reportsSelection.list.length && (
        <ShareReportDialog
          reports={reportsSelection.list}
          title={
            reportsSelection.list.length > 1 ? "Share Reports" : "Share Report"
          }
          open={shareDialog.isOpen}
          onClose={shareDialog.hide}
          onSubmit={handleShareDialogSubmit}
          copyShareLinkCallback={copyShareLinkCallback}
        />
      )}
      <Snackbar
        open={shareLinkSnackBar.isOpen}
        autoHideDuration={5000}
        onClose={shareLinkSnackBar.hide}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{
          bottom: 122,
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          onClose={shareLinkSnackBar.hide}
          sx={{
            borderRadius: "4px",
            alignItems: "center",
            [`.${alertClasses.action}`]: {
              pt: 0,
            },
          }}
        >
          The link to the Reports was successfully copied to the clipboard
        </Alert>
      </Snackbar>
      <SnackbarBase
        open={shareFormSnackBar.isOpen}
        onClose={shareFormSnackBar.hide}
        title="Your report is being sent."
        subtitle={
          <>
            It may take some time depending on the amount of items in the
            report.
            <br />
            Finished reports appears in your email and email of your recipient
          </>
        }
        sxProps={{
          bottom: 122,
        }}
      />

      <DownloadMessageDialog
        open={downloadMessageDialog.isOpen}
        onClose={() => {
          downloadMessageDialog.hide();
          reportsSelection.unselectAll();
        }}
        handleReportExport={handleReportExport}
        format={downloadReportFormat}
      />

      <DownloadLimitsDialog
        open={downloadLimitsDialog.isOpen}
        onClose={() => {
          downloadLimitsDialog.hide();
          reportsSelection.unselectAll();
        }}
        handleReportExport={handleReportExport}
        format={downloadReportFormat}
        reports={reportsSelection.list}
      />
    </Fragment>
  );

  if (isMobile) {
    return (
      <Fragment>
        <PageLayoutMobile content={mobileContent} />
        {commonContent}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <PageLayoutDesktop
        header={headerDesktop}
        content={reportsTabs}
        overflow="hidden"
      />
      {commonContent}
    </Fragment>
  );
}
