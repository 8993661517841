import Joi from "joi";
import { ShareFormValues } from "./ShareForm.model";

export const ShareFormEmailSchema = Joi.string()
  .email({ tlds: { allow: false } })
  .required();

export const ShareFormSchema = Joi.object<ShareFormValues>({
  recipients: Joi.array().items(ShareFormEmailSchema).min(1).max(50).required(),
  subject: Joi.string().required(),
  note: Joi.string().allow("").max(200),
});
