import { ReactNode } from "react";
import { ChevronLeftOutlined, Close } from "@mui/icons-material";
import { Stack, IconButton, StackProps, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TextLineClamp } from "../TextLineClamp/TextLineClamp";
import { IntercomPageAnchor } from "../IntercomPageAnchor/IntercomPageAnchor";

export function AppTopNavBarMobile({
  title,
  toolbar,
  hideBackButton,
  edited,
  onBack,
  ...props
}: {
  title: string;
  hideBackButton?: boolean;
  toolbar?: ReactNode;
  /** Override onBack action. Return true if you still want to bo back */
  onBack?: () => void | boolean;
  edited?: boolean;
} & Omit<StackProps, "direction" | "alignItems">) {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const handleGoBack = () => navigate(-1);

  const goBackWrapper = () => {
    if (onBack) {
      const canGoBack = onBack();
      if (canGoBack) {
        handleGoBack();
      }
    } else {
      handleGoBack();
    }
  };

  const BackIcon = edited ? Close : ChevronLeftOutlined;

  return (
    <Stack direction="row" alignItems="center" height={48} {...props}>
      {!hideBackButton ? (
        <IconButton onClick={goBackWrapper}>
          <BackIcon fontSize="large" sx={{ color: palette.text.primary }} />
        </IconButton>
      ) : null}
      <TextLineClamp
        variant="subtitle1"
        lineClamp={1}
        flex={1}
        sx={{
          lineBreak: "anywhere",
        }}
      >
        {edited ? "Edit clip" : title}
        <IntercomPageAnchor />
      </TextLineClamp>
      {toolbar}
    </Stack>
  );
}
