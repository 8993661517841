import { TableEmptyLayout } from "src/components/TableEmptyLayout/TableEmptyLayout";
import { useTheme } from "@mui/material";
import { ReactComponent as NoInvitesSvg } from "./no-invites.svg";
import { ReactComponent as NoInvitesDarkSvg } from "./no-invites-dark.svg";

export function UserInvitesEmpty() {
  const { palette } = useTheme();
  return (
    <TableEmptyLayout
      icon={palette.mode === "light" ? <NoInvitesSvg /> : <NoInvitesDarkSvg />}
      header="No sent invites yet"
    />
  );
}
