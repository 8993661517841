import clsx from "clsx";
import { useCallback, useState } from "react";
import { LinearProgress, Stack } from "@mui/material";
import { scrollHeaderLayoutCustomClasses } from "./ScrollHeaderLayoutCustom.const";
import { ScrollHeaderLayoutCustomProps } from "./ScrollHeaderLayoutCustom.model";

export function ScrollHeaderLayoutCustom({
  renderHeader,
  renderScrollContainer,
  headerMinHeight = 0,
  headerJustifyContent = "flex-end",
  scrollThreshold = 40,
  isLoading,
  onScroll,
  ...props
}: ScrollHeaderLayoutCustomProps) {
  const [scrollTop, setScrollTop] = useState(0);
  const [headerRef, setHeaderEl] = useState<HTMLDivElement | null>(null);

  const contentHeight = headerRef?.scrollHeight;
  const scroll = Math.max(0, scrollTop - scrollThreshold);

  const height =
    typeof contentHeight === "number"
      ? Math.max(headerMinHeight, contentHeight - scroll / 2)
      : undefined;

  const transition =
    typeof height === "number"
      ? Math.max(0, height - scroll / 2) / height
      : 1.0;

  const onScrollWrapper = useCallback<
    NonNullable<ScrollHeaderLayoutCustomProps["onScroll"]>
  >(
    (scroll) => {
      if (renderHeader) {
        setScrollTop(scroll.scrollTop);
      }
      onScroll?.(scroll);
    },
    [onScroll, renderHeader]
  );

  return (
    <Stack
      flex={1}
      overflow="hidden"
      position="relative"
      className={clsx(scrollHeaderLayoutCustomClasses.root)}
      {...props}
    >
      {renderHeader ? (
        <Stack
          height={height && `${height}px`}
          justifyContent={headerJustifyContent}
          children={
            <Stack ref={setHeaderEl}>
              {renderHeader({ transition, scrollTop })}
            </Stack>
          }
        />
      ) : null}

      {isLoading ? (
        <LinearProgress
          sx={{
            position: "absolute",
            top: typeof height === "number" ? `${height}px` : undefined,
            left: 0,
            right: 0,
            zIndex: 3,
          }}
        />
      ) : null}

      {renderScrollContainer({
        onScroll: onScrollWrapper,
      })}
    </Stack>
  );
}
