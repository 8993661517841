import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Stack,
  Switch,
  Typography,
  formHelperTextClasses,
  inputClasses,
} from "@mui/material";
import { isEmpty } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { TextInputBase } from "src/components/TextInputBase";
import { PremiumIcon } from "src/components/PremiumIcon/PremiumIcon";
import { useGlobalEntitlements } from "src/api/useGlobalEntitlements";
import { NewAccountFormValues } from "../../AccountsManagementCreateAccount.model";

export function AccountsManagementFeaturesTab({
  disabled,
  onSubmit,
  onReset,
}: {
  disabled?: boolean;
  onSubmit?: () => void;
  onReset?: () => void;
}) {
  const { control, formState } = useFormContext<NewAccountFormValues>();
  const transformValue = (value: string) => {
    if (value === "0") return "";
    return value;
  };

  const { data } = useGlobalEntitlements();
  const defaultData = data?.globalEntitlements;

  return (
    <>
      <Grid component={Paper} container direction={"row"} mb={2}>
        <Grid item sm={12} md={5}>
          <Stack px={4} pb={2} minHeight={300}>
            <Typography pt={3} variant="subtitle1">
              Enable Features
            </Typography>
            <Stack py={2} pb={4}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Controller
                      name="enableMediaDownloads"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          checked={value}
                          onChange={onChange}
                          disabled={disabled}
                          color="primary"
                        />
                      )}
                    />
                  }
                  label="Enable save clips"
                />
                <FormControlLabel
                  control={
                    <Controller
                      name="enableEditWatchList"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          checked={value}
                          onChange={onChange}
                          disabled={disabled}
                          color="primary"
                        />
                      )}
                    />
                  }
                  label="Edit Watchlist"
                />
                <FormControlLabel
                  control={
                    <Controller
                      name="enableAdFiltering"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          checked={value}
                          onChange={onChange}
                          disabled={disabled}
                          color="primary"
                        />
                      )}
                    />
                  }
                  label="Enable ad filtering"
                />
                <FormControlLabel
                  control={
                    <Controller
                      name="enableCustomReportBanner"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          checked={value}
                          onChange={onChange}
                          disabled={disabled}
                          color="primary"
                        />
                      )}
                    />
                  }
                  label="Enable report customization"
                />
                <FormControlLabel
                  control={
                    <Controller
                      name="enableReportDownloads"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          checked={value}
                          onChange={onChange}
                          disabled={disabled}
                          color="primary"
                        />
                      )}
                    />
                  }
                  label="Enable report downloads"
                />
                <FormControlLabel
                  control={
                    <Controller
                      name="enableWatchlistRSS"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          checked={value}
                          onChange={onChange}
                          disabled={disabled}
                          color="primary"
                        />
                      )}
                    />
                  }
                  label="Enable RSS Feed for Watchlist"
                />
                <Stack direction={"row"} alignItems={"center"}>
                  <FormControlLabel
                    control={
                      <Controller
                        name="enableArchivedScheduledReports"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Switch
                            checked={value}
                            onChange={onChange}
                            disabled={disabled}
                            color="primary"
                          />
                        )}
                      />
                    }
                    label="Archive Alert"
                  />
                  <Stack data-intercom-account-pro-feature="intercom-archive-alert-premium">
                    <PremiumIcon />
                  </Stack>
                </Stack>
              </FormGroup>
            </Stack>
          </Stack>
        </Grid>
        <Grid item sm={12} md={7}>
          <Stack px={4} pb={2} minHeight={300}>
            <Typography pt={3} variant="subtitle1">
              Configure Limits
            </Typography>
            <Stack py={2}>
              {/*1 Limit Watchlist Size */}
              <Stack direction={"row"} gap={4} mt={1}>
                <Stack width="150px">
                  <Controller
                    name="limitWatchlistSize"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <TextInputBase
                          autoComplete="off"
                          type="number"
                          size="small"
                          placeholder={defaultData?.[
                            field.name
                          ]?.value.toString()}
                          label={field.value ? "Number" : "Default limit"}
                          InputLabelProps={{ shrink: true }}
                          value={field.value || ""}
                          onChange={(e) =>
                            field.onChange(transformValue(e.target.value))
                          }
                          onBlur={field.onBlur}
                          disabled={disabled}
                          InputProps={{ inputProps: { min: 0 } }}
                          sx={{
                            [`.${inputClasses.root}`]: {
                              width: "150px",
                            },
                            [`& .${formHelperTextClasses.root}`]: {
                              marginX: "0px",
                              width: "max-content",
                            },
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || " "}
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    variant="body1"
                    pt={1}
                  >
                    Limit Watchlist Size
                  </Typography>
                </Stack>
              </Stack>
              {/*1 Limit Watchlist Size */}

              {/*2 Limit items per email */}
              <Stack direction={"row"} gap={4} mt={1}>
                <Stack width="150px">
                  <Controller
                    name="limitItemsPerEmail"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <TextInputBase
                          InputProps={{ inputProps: { min: 0 } }}
                          type="number"
                          autoComplete="off"
                          placeholder={defaultData?.[
                            field.name
                          ]?.value.toString()}
                          label={field.value ? "Number" : "Default limit"}
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          value={field.value || ""}
                          onChange={(e) =>
                            field.onChange(transformValue(e.target.value))
                          }
                          onBlur={field.onBlur}
                          disabled={disabled}
                          sx={{
                            [`.${inputClasses.root}`]: {
                              width: "150px",
                            },
                            [`& .${formHelperTextClasses.root}`]: {
                              marginX: "0px",
                              width: "max-content",
                            },
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || " "}
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    variant="body1"
                    pt={1}
                  >
                    Limit items per email
                  </Typography>
                </Stack>
              </Stack>
              {/*2 Limit items per email */}

              {/*3 Limit limitEventsInReport */}
              <Stack direction={"row"} gap={4} mt={1}>
                <Stack width="150px">
                  <Controller
                    name="limitEventsInReport"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <TextInputBase
                          InputProps={{ inputProps: { min: 0 } }}
                          type="number"
                          autoComplete="off"
                          placeholder={defaultData?.[
                            field.name
                          ]?.value.toString()}
                          label={field.value ? "Number" : "Default limit"}
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          value={field.value || ""}
                          onChange={(e) =>
                            field.onChange(transformValue(e.target.value))
                          }
                          onBlur={field.onBlur}
                          disabled={disabled}
                          sx={{
                            [`.${inputClasses.root}`]: {
                              width: "150px",
                            },
                            [`& .${formHelperTextClasses.root}`]: {
                              marginX: "0px",
                              width: "max-content",
                            },
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || " "}
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    variant="body1"
                    pt={1}
                  >
                    Limit Events in Reports/Share
                  </Typography>
                </Stack>
              </Stack>
              {/*3 Limit limitEventsInReport */}

              {/*4 Limit limitEventsInCSV */}
              <Stack direction={"row"} gap={4} mt={1}>
                <Stack width="150px">
                  <Controller
                    name="limitEventsInCSV"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <TextInputBase
                          InputProps={{ inputProps: { min: 0 } }}
                          type="number"
                          size="small"
                          autoComplete="off"
                          placeholder={defaultData?.[
                            field.name
                          ]?.value.toString()}
                          label={field.value ? "Number" : "Default limit"}
                          InputLabelProps={{ shrink: true }}
                          value={field.value || ""}
                          onChange={(e) =>
                            field.onChange(transformValue(e.target.value))
                          }
                          onBlur={field.onBlur}
                          disabled={disabled}
                          sx={{
                            [`.${inputClasses.root}`]: {
                              width: "150px",
                            },
                            [`& .${formHelperTextClasses.root}`]: {
                              marginX: "0px",
                              width: "max-content",
                            },
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || " "}
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    variant="body1"
                    pt={1}
                  >
                    Limit Events in CSV
                  </Typography>
                </Stack>
              </Stack>

              {/*4 Limit limitEventsInCSV */}

              {/*5 Limit limitEventsInPDF */}
              <Stack direction={"row"} gap={4} mt={1}>
                <Stack width="150px">
                  <Controller
                    name="limitEventsInPDF"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <TextInputBase
                          InputProps={{ inputProps: { min: 0 } }}
                          type="number"
                          size="small"
                          autoComplete="off"
                          placeholder={defaultData?.[
                            field.name
                          ]?.value.toString()}
                          label={field.value ? "Number" : "Default limit"}
                          InputLabelProps={{ shrink: true }}
                          value={field.value || ""}
                          onChange={(e) =>
                            field.onChange(transformValue(e.target.value))
                          }
                          onBlur={field.onBlur}
                          disabled={disabled}
                          sx={{
                            [`.${inputClasses.root}`]: {
                              width: "150px",
                            },
                            [`& .${formHelperTextClasses.root}`]: {
                              marginX: "0px",
                              width: "max-content",
                            },
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || " "}
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    variant="body1"
                    pt={1}
                  >
                    Limit Events in PDF
                  </Typography>
                </Stack>
              </Stack>
              {/*5 Limit limitEventsInPDF */}

              {/*6 Limit limitScratchReportItems */}
              <Stack direction={"row"} gap={4} mt={1}>
                <Stack width="150px">
                  <Controller
                    name="limitScratchReportItems"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <TextInputBase
                          InputProps={{ inputProps: { min: 0 } }}
                          type="number"
                          autoComplete="off"
                          placeholder={defaultData?.[
                            field.name
                          ]?.value.toString()}
                          label={field.value ? "Number" : "Default limit"}
                          InputLabelProps={{ shrink: true }}
                          value={field.value || ""}
                          size="small"
                          onChange={(e) =>
                            field.onChange(transformValue(e.target.value))
                          }
                          onBlur={field.onBlur}
                          disabled={disabled}
                          sx={{
                            [`.${inputClasses.root}`]: {
                              width: "150px",
                            },
                            [`& .${formHelperTextClasses.root}`]: {
                              marginX: "0px",
                              width: "max-content",
                            },
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || " "}
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    variant="body1"
                    pt={1}
                  >
                    Limit scratch report items
                  </Typography>
                </Stack>
              </Stack>
              {/*6 Limit limitScratchReportItems */}
            </Stack>
            {onReset && onSubmit ? (
              <Stack direction="row" justifyContent="flex-end" gap={2} mb={3}>
                <Button
                  variant="outlined"
                  sx={{ height: "42px", width: "124px", fontSize: "15px" }}
                  onClick={onReset}
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ height: "42px", width: "124px", fontSize: "15px" }}
                  onClick={onSubmit}
                  disabled={!isEmpty(formState.errors) || !formState.isDirty}
                >
                  Save
                </Button>
              </Stack>
            ) : null}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
