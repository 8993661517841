import { useState, useMemo, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { GridPaginationModel } from "@mui/x-data-grid-premium";
import {
  ArrayParam,
  BooleanParam,
  useQueryParam,
  withDefault,
} from "use-query-params";
import { DateTimeRangePickerMobile } from "src/components/DateTimeRangePickerMobile/DateTimeRangePickerMobile";
import {
  DateTimeSelector,
  DateTimeSelectorMode,
} from "src/components/DateSelector/DateTimeSelector";
import { SearchInput } from "src/components/SearchInput";
import { ViewModeSwitch } from "src/components/ViewModeSwitch/ViewModeSwitch";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { useReport } from "src/api/useReport";
import { useReportEvents } from "src/api/useReportEvents";
import { useReportEventsInfinite } from "src/api/useReportEventsInfinite";
import { useQueryDateRangeWithEmptyDefault } from "src/utils/useQueryDateRangeWithEmptyDefault";
import { useListFilter } from "src/utils/useListFilter";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { useQueryPagination } from "src/utils/useQueryPagination";
import { combineInfiniteTilesData } from "src/utils/combineInfiniteTilesData";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";
import { SortingMenu } from "src/components/SortingMenu/SortingMenu";
import {
  SortBy,
  SortOption,
  SortByParameters,
  OrderByParameters,
} from "src/components/SortingMenu/SortingMenu.model";
import { DateRange } from "src/components/DateSelector/DateSelector";
import { useReportEventsDataView } from "src/components/ReportEvents/ReportEvents.hook";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { TooltipBaseMobile } from "src/components/TooltipBaseMobile/TooltipBaseMobile";
import { PageLoader } from "src/components/PageLoader";
import { publicSharedEventRoute } from "src/pages/PublicSharedEvent/PublicSharedEvent.route";
import { pageNotFoundRoute } from "src/pages/PageNotFound/PageNotFound.route";
import { PageLayoutPublicDesktop } from "src/components/PageLayoutPublicDesktop";
import { CustomBannerHeader } from "src/components/CustomBannerHeader/CustomBannerHeader";
import { IntercomPageAnchor } from "src/components/IntercomPageAnchor/IntercomPageAnchor";

const viewModeOptions: {
  desktop: ViewMode[];
  mobile: ViewMode[];
} = {
  desktop: [ViewMode.table, ViewMode.list, ViewMode.tile],
  mobile: [ViewMode.tile, ViewMode.table],
};

export function PublicShareEventsPage() {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const [filterText, setFilterText] = useState("");
  const [isExpandedTextMode, setExpandedTextMode] = useQueryParam(
    "expanded",
    BooleanParam
  );
  const [sortBy, setSortBy] = useState<SortByParameters>("default");
  const [sortOption, setSortOption] = useState(SortBy.defaultDesc);
  const [orderBy, setOrderBy] = useState<OrderByParameters>("desc");
  const [dateRange, setDateRange] = useQueryDateRangeWithEmptyDefault();
  const [pagination, setPagination] = useQueryPagination();

  /** is used for switching between pages while groupDuplicates is set to true */
  const [pagesHistory, setPagesHistory] = useQueryParam(
    "groupedPages",
    withDefault(ArrayParam, [""])
  );
  /** "after" is overriding "from" on the BE side, is used with groupDuplicates set to true */
  const after = pagesHistory[pagesHistory.length - 1];

  const navigate = useNavigate();

  const { reportId = "" } = useParams();

  const initialViewMode: ViewMode = isMobile ? ViewMode.tile : ViewMode.list;
  const [viewMode, setViewModeWrapper] = useViewModeQueryParam<ViewMode>({
    paramKey: "tab",
    defaultValue: initialViewMode,
  });

  const reportData = useReport({
    path: {
      id: reportId,
    },
  });

  const groupDuplicates = reportData.data?.groupDuplicates;

  useEffect(() => {
    if (reportData.isFetched && !reportData.data) {
      navigate(pageNotFoundRoute.path);
    }
  }, [reportData.isFetched, reportData.data, navigate]);

  const reportEvents = useReportEvents({
    options: {
      enabled: viewMode !== ViewMode.tile,
    },
    params: {
      path: {
        id: reportId,
      },
      query: {
        from: pagination.page * pagination.pageSize,
        size: pagination.pageSize,
        sortby: sortBy,
        orderby: orderBy,
        startDate: dateRange[0]
          ? formatDateTimeWithoutTimeZone(dateRange[0])
          : undefined,
        endDate: dateRange[1]
          ? formatDateTimeWithoutTimeZone(dateRange[1])
          : undefined,
        after: groupDuplicates && after ? after : undefined,
      },
    },
  });

  const reportEventsTiles = useReportEventsInfinite({
    options: {
      enabled: viewMode === ViewMode.tile,
    },
    params: {
      path: {
        id: reportId,
      },
      query: {
        sortby: sortBy,
        orderby: orderBy,
        startDate: dateRange[0]
          ? formatDateTimeWithoutTimeZone(dateRange[0])
          : undefined,
        endDate: dateRange[1]
          ? formatDateTimeWithoutTimeZone(dateRange[1])
          : undefined,
        after: groupDuplicates && after ? after : undefined,
      },
    },
  });

  const reportEventsData = useMemo(() => {
    if (viewMode === ViewMode.tile) {
      if (reportEventsTiles.isError) return [];
      return combineInfiniteTilesData(reportEventsTiles.data?.pages);
    }
    if (reportEvents.isError) return [];
    return reportEvents.data?.results || [];
  }, [
    viewMode,
    reportEvents.data,
    reportEventsTiles.data,
    reportEvents.isError,
    reportEventsTiles.isError,
  ]);

  const reportsEventsTotal = useMemo(() => {
    if (viewMode === ViewMode.tile) {
      if (reportEventsTiles.isError) return 0;
      const tilesPagesData = reportEventsTiles.data?.pages;
      return tilesPagesData?.[tilesPagesData.length - 1]?.total || 0;
    }
    if (reportEvents.isError) return 0;
    return reportEvents.data?.total || 0;
  }, [
    viewMode,
    reportEventsTiles.data?.pages,
    reportEvents.data?.total,
    reportEvents.isError,
    reportEventsTiles.isError,
  ]);

  const filterData = useListFilter(reportEventsData || [], filterText, [
    "title",
    "highlights",
  ]);

  const setPaginationWrapper = useCallback(
    (nextPagination: GridPaginationModel) => {
      if (groupDuplicates) {
        if (nextPagination.pageSize !== pagination.pageSize) {
          setPagesHistory([""], "replaceIn");
        }
        const nextPage = reportEvents.data?.configuration?.next;
        if (nextPagination.page > pagination.page && nextPage) {
          // go to the next page:
          setPagesHistory([...pagesHistory, nextPage], "replaceIn");
        }
        if (nextPagination.page < pagination.page) {
          // go to the previous page:
          setPagesHistory(pagesHistory.slice(0, -1), "replaceIn");
        }
      }
      setPagination(nextPagination);
    },
    [
      groupDuplicates,
      pagination,
      setPagination,
      setPagesHistory,
      reportEvents.data?.configuration?.next,
      pagesHistory,
    ]
  );

  const navToTheFirstPage = useCallback(() => {
    setPagesHistory([""], "replaceIn");
    setPagination({
      ...pagination,
      page: 0,
    });
  }, [setPagesHistory, setPagination, pagination]);

  const viewModeSwitch = useMemo(
    () => (
      <ViewModeSwitch
        value={viewMode}
        onChange={setViewModeWrapper}
        size={isMobile ? "small" : "medium"}
        options={isMobile ? viewModeOptions.mobile : viewModeOptions.desktop}
      />
    ),
    [viewMode, setViewModeWrapper, isMobile]
  );

  const getEventUrl = useCallback(
    (eventId: string) => {
      return publicSharedEventRoute.makeUrl({
        reportId,
        eventId,
      });
    },
    [reportId]
  );

  const onSortChange = useCallback(
    (sortBy: SortOption) => {
      setSortOption(sortBy.value);
      setSortBy(sortBy.field);
      setOrderBy(sortBy.order);
    },
    [setSortOption, setSortBy, setOrderBy]
  );

  const sortingMenu = useMemo(
    () => (
      <SortingMenu
        value={sortOption}
        onChange={onSortChange}
        size={isMobile ? "small" : "medium"}
        options={[
          SortBy.titleAsc,
          SortBy.titleDesc,
          SortBy.defaultAsc,
          SortBy.defaultDesc,
        ]}
      />
    ),
    [sortOption, onSortChange, isMobile]
  );

  const searchInput = useMemo(
    () => (
      <SearchInput
        placeholder={isMobile ? "Search..." : "Search by text..."}
        onTextChangeThrottled={setFilterText}
        fullWidth
        sx={isMobile ? {} : { width: 180 }}
      />
    ),
    [isMobile, setFilterText]
  );

  const expandTextButton = viewMode === ViewMode.tile && (
    <FormControlLabel
      checked={!!isExpandedTextMode}
      control={
        <Switch
          onChange={(_e, checked) => setExpandedTextMode(checked, "replaceIn")}
        />
      }
      label={<Typography variant="caption">Expanded Text</Typography>}
      sx={{ mr: 0, whiteSpace: "nowrap" }}
    />
  );

  const setDateRangeWrapper = useCallback(
    (dateRange: DateRange) => {
      setDateRange(dateRange);
      navToTheFirstPage();
    },
    [setDateRange, navToTheFirstPage]
  );

  const toolbarDesktop = (
    <Stack
      p={2}
      gap={2}
      direction={{ sm: "column", md: "row" }}
      justifyContent="space-between"
    >
      <Stack direction="row" flex={1}>
        <Stack sx={{ justifyContent: "flex-end", mr: 2 }}>{searchInput}</Stack>
        <DateTimeSelector
          mode={DateTimeSelectorMode.variable}
          views={["year", "month", "day", "hours", "minutes"]}
          value={dateRange}
          onChange={setDateRangeWrapper}
          sx={{ flex: 1 }}
        />
      </Stack>
      <Stack direction="row" spacing={1} justifyContent="flex-end">
        {sortingMenu}
        {viewModeSwitch}
      </Stack>
    </Stack>
  );

  const toolbarMobile = useMemo(
    () => (
      <Stack
        direction="row"
        spacing={1}
        alignItems="flex-end"
        sx={{
          height: 40,
          m: 2,
        }}
      >
        {searchInput}
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          {sortingMenu}
          <DateTimeRangePickerMobile
            value={dateRange}
            onChange={setDateRangeWrapper}
            iconButtonSize="small"
          />
          {viewModeSwitch}
        </Stack>
      </Stack>
    ),
    [searchInput, sortingMenu, dateRange, setDateRangeWrapper, viewModeSwitch]
  );

  const renderMobileHeader = useCallback(() => {
    return toolbarMobile;
  }, [toolbarMobile]);

  const nextPageButtonIsDisabled = useMemo(() => {
    if (groupDuplicates) {
      const loading = reportEvents.isLoading || reportEvents.isFetching;
      const nextPage = reportEvents.data?.configuration?.next;
      if (loading || !nextPage) return true;
    }
    return false;
  }, [
    groupDuplicates,
    reportEvents.isLoading,
    reportEvents.isFetching,
    reportEvents.data?.configuration?.next,
  ]);

  const dataViewElement = useReportEventsDataView({
    data: filterData,
    viewMode,
    rowCount: reportsEventsTotal,
    isExpandedTextMode,
    getEventUrl,
    isLoading: reportEvents.isLoading || reportEvents.isFetching,
    paginationModel: pagination,
    onPaginationModelChange: setPaginationWrapper,
    reportEventsTiles,
    renderHeader: renderMobileHeader,
    isCompact: true,
    nextPageButtonIsDisabled,
  });

  const desktopContent = (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${palette.divider}`,
        mt: 2,
        overflow: "hidden",
        height: "100%",
      }}
    >
      {toolbarDesktop}
      <Stack flex={1} overflow="hidden">
        {dataViewElement}
      </Stack>
    </Paper>
  );

  if (reportData.isFetching) {
    return <PageLoader />;
  }

  if (isMobile) {
    return (
      <>
        <CustomBannerHeader
          isPublicPage
          publicUserId={reportData?.data?.userId}
        />
        <Stack
          sx={{
            backgroundColor: palette.background.paper,
            height: "calc(100% - 100px)",
            overflow: "hidden",
            flex: 1,
          }}
        >
          <Stack
            mt={1}
            mx={{ xs: 2, md: 0 }}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ flexWrap: { sm: "wrap", md: "nowrap" } }}
            gap={{ sm: 1, md: 4 }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              gap={1}
              height={38}
            >
              <TooltipBaseMobile
                width={250}
                placement="bottom"
                title={reportData.data?.title}
              >
                <TextLineClamp variant="subtitle1" lineClamp={1}>
                  {reportData.data?.title || ""}
                </TextLineClamp>
              </TooltipBaseMobile>
              {expandTextButton}
            </Stack>
          </Stack>
          {viewMode === ViewMode.tile ? null : toolbarMobile}
          <Stack flex={1} overflow="hidden">
            {dataViewElement}
          </Stack>
        </Stack>
      </>
    );
  }

  return (
    <PageLayoutPublicDesktop
      overflow="hidden"
      header={
        <>
          <CustomBannerHeader
            isPublicPage
            publicUserId={reportData?.data?.userId}
            maxWidth="882px"
          />
          <Stack
            mt={2}
            mx={{ xs: 2, md: 0 }}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ flexWrap: { sm: "wrap", md: "nowrap" } }}
            gap={{ sm: 1, md: 4 }}
          >
            <Typography variant="h5">
              {reportData.data?.title || ""}
              <IntercomPageAnchor />
            </Typography>
          </Stack>
        </>
      }
      content={desktopContent}
    />
  );
}
