import { Link, useLocation } from "react-router-dom";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Badge,
  Button,
  ListItemProps,
} from "@mui/material";

export const ProfileButtonMenuItem = ({
  divider,
  target = "_self",
  ...props
}: {
  icon: JSX.Element;
  label: string;
  tooltip?: string;
  path?: string;
  hasBadge?: boolean;
  onClick?: () => void;
  divider?: ListItemProps["divider"];
  target?: "_blank" | "_self";
}) => {
  const { pathname } = useLocation();
  const selected = props.path
    ? props.path === "/"
      ? pathname === props.path
      : pathname.startsWith(props.path)
    : false;

  const onclickWrapper = () => {
    props.onClick && props.onClick();
  };

  return (
    <Tooltip placement="right" title={props.tooltip} arrow>
      <ListItem
        disablePadding
        disableGutters
        sx={{ height: 48 }}
        divider={divider}
      >
        <ListItemButton
          disableGutters
          selected={selected}
          sx={{ padding: 1 }}
          component={props.path ? Link : Button}
          to={props.path}
          onClick={onclickWrapper}
          target={target}
        >
          <ListItemIcon
            sx={{
              minWidth: 32,
              marginRight: 2,
              justifyContent: "center",
              color: selected ? "primary.main" : "text.secondary",
            }}
          >
            {props.hasBadge ? (
              <Badge color="error" overlap="circular" variant="dot">
                {props.icon}
              </Badge>
            ) : (
              props.icon
            )}
          </ListItemIcon>
          <ListItemText
            primary={props.label}
            sx={{
              "& span": {
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              },
            }}
          />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
};
