import { SyntheticEvent } from "react";
import { Stack, useTheme, Chip, Button } from "@mui/material";
import CopyAll from "@mui/icons-material/CopyAll";
import FileDownloadDone from "@mui/icons-material/FileDownloadDone";
import Cached from "@mui/icons-material/Cached";
import { getFullReportDetailsLink } from "src/utils/getFullPublicPageLink";
import { useCopyButtonState } from "src/utils/useCopyButtonState";

export const ReportLink = ({
  reportId,
  reportStatus,
}: {
  reportId: string;
  reportStatus: string | null;
}) => {
  const { palette } = useTheme();
  const { wasCopied, copy } = useCopyButtonState();

  const fullReportDetailsLink = getFullReportDetailsLink(reportId);

  const copyLinkToClipboard = async (event: SyntheticEvent) => {
    event.stopPropagation();
    if (!navigator.clipboard) {
      console.error("browser does not support navigation.clipboard");
    }
    await navigator.clipboard.writeText(fullReportDetailsLink);
    copy();
  };

  if (reportStatus !== "ready") {
    return (
      <Chip
        label={reportStatus === "loading" ? "Loading..." : "Generating..."}
        icon={<Cached color="disabled" />}
        variant="outlined"
        size="small"
        sx={{
          color: palette.text.disabled,
        }}
      />
    );
  }

  return (
    <Stack direction="row" columnGap={1} alignItems="center">
      {wasCopied ? (
        <Button startIcon={<FileDownloadDone />}>Copied</Button>
      ) : (
        <Button startIcon={<CopyAll />} onClick={copyLinkToClipboard}>
          Copy link
        </Button>
      )}
    </Stack>
  );
};
