import Joi from "joi";
import { EditAccountFeaturesFormValues } from "./AccountManagementEditFeatures.model";
import { GlobalEntitlementsDTO } from "src/pages/AccountsManagementCreateAccount/AccountsManagementCreateAccount.schema";

export const AccountManagementEditFeaturesSchema = (
  limits: GlobalEntitlementsDTO
) =>
  Joi.object<EditAccountFeaturesFormValues>({
    //Features
    enableMediaDownloads: Joi.boolean().optional().label("Media Download"),
    enableEditWatchList: Joi.boolean().optional().label("Watch List"),
    enableAdFiltering: Joi.boolean().optional().label("Ad Filtering"),
    enableCustomReportBanner: Joi.boolean()
      .optional()
      .label("Custom Report Banner"),
    enableReportDownloads: Joi.boolean().optional().label("Report Downloads"),
    enableWatchlistRSS: Joi.boolean().optional().label("Watchlist RSS"),
    enableArchivedScheduledReports: Joi.boolean()
      .optional()
      .label("Archive Alert"),
    limitWatchlistSize: Joi.number()
      .integer()
      .max(limits?.limitWatchlistSize?.value || 0)
      .messages({
        "number.base": "Invalid number",
        "number.integer": "Invalid number",
        "number.max": `Max limit: ${limits?.limitWatchlistSize?.value || 0}`,
        "number.unsafe": "Invalid number",
      })
      .optional()
      .label("Watchlist size limit")
      .allow(""),
    limitItemsPerEmail: Joi.number()
      .integer()
      .max(limits?.limitItemsPerEmail?.value || 0)
      .messages({
        "number.base": "Invalid number",
        "number.integer": "Invalid number",
        "number.max": `Max limit: ${limits?.limitItemsPerEmail?.value || 0}`,
        "number.unsafe": "Invalid number",
      })
      .optional()
      .label("Items per email limit")
      .allow(""),
    limitEventsInReport: Joi.number()
      .integer()
      .max(limits?.limitEventsInReport?.value || 0)
      .messages({
        "number.base": "Invalid number",
        "number.integer": "Invalid number",
        "number.max": `Max limit: ${limits?.limitEventsInReport?.value || 0}`,
        "number.unsafe": "Invalid number",
      })
      .optional()
      .label("Events in report limit")
      .allow(""),
    limitEventsInCSV: Joi.number()
      .integer()
      .max(limits?.limitEventsInCSV?.value || 0)
      .messages({
        "number.base": "Invalid number",
        "number.integer": "Invalid number",
        "number.max": `Max limit: ${limits?.limitEventsInCSV?.value || 0}`,
        "number.unsafe": "Invalid number",
      })
      .optional()
      .label("Events in CSV limit")
      .allow(""),
    limitEventsInPDF: Joi.number()
      .integer()
      .max(limits?.limitEventsInPDF?.value || 0)
      .messages({
        "number.base": "Invalid number",
        "number.integer": "Invalid number",
        "number.max": `Max limit: ${limits?.limitEventsInPDF?.value || 0}`,
        "number.unsafe": "Invalid number",
      })
      .optional()
      .label("Events in PDF limit")
      .allow(""),
    limitScratchReportItems: Joi.number()
      .integer()
      .max(limits?.limitScratchReportItems?.value || 0)
      .messages({
        "number.base": "Invalid number",
        "number.integer": "Invalid number",
        "number.max": `Max limit: ${
          limits?.limitScratchReportItems?.value || 0
        }`,
        "number.unsafe": "Invalid number",
      })
      .optional()
      .label("Scratch report items limit")
      .allow(""),
  });
