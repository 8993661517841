import { Box, BoxProps, useTheme } from "@mui/material";
import { getEventIcon } from "src/utils/getEventIcon";
import {
  getBgColor,
  getIconColor,
  getThemeColor,
} from "./ImagePlaceholder.utils";

type NoImagePlaceholderProps = {
  iconScale?: number;
  eventType?: string;
  mediaType?: string;
} & Omit<BoxProps, "children">;

export function ImagePlaceholder({
  iconScale = 4.5,
  eventType = "",
  mediaType = "",
  ...props
}: NoImagePlaceholderProps) {
  const { palette } = useTheme();
  const mainColor = getThemeColor(palette, eventType);

  const Icon = getEventIcon(eventType, mediaType);

  return (
    <Box
      {...props}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: getBgColor(palette, mainColor),
        ...props.sx,
      }}
    >
      <Icon
        sx={{
          transform: `scale(${iconScale})`,
          color: getIconColor(palette, mainColor),
        }}
      />
    </Box>
  );
}
