import { addDays } from "date-fns";
import { ContentAccessFilter } from "src/models/ContentAccessFilter";

export const createAccountDefaultValues = {
  //General
  isActive: true,
  name: "",
  organizationType: "Operating Company",
  country: "United States",
  city: "",
  stateOrProvince: "",
  postalCode: "",
  creationDate: new Date().toISOString(),
  expirationDate: addDays(new Date(), 45).toISOString(),
  accountRepresentative: "",
  timezone: "",
  isTrial: true,
  //Features
  enableMediaDownloads: true,
  enableEditWatchList: true,
  enableAdFiltering: true,
  enableCustomReportBanner: true,
  enableReportDownloads: true,
  enableWatchlistRSS: true,
  enableArchivedScheduledReports: true,
  limitWatchlistSize: undefined,
  limitItemsPerEmail: undefined,
  limitEventsInReport: undefined,
  limitEventsInCSV: undefined,
  limitEventsInPDF: undefined,
  limitScratchReportItems: undefined,
  status: "trial",
  //Content Filter
  preset: "",
  sourcesInclude: {
    list: [],
    logic: "or",
  },
  sourcesExclude: {
    list: [],
    logic: "or",
  },
  //Bulk users
  users: [],
};

export const contentFilter: ContentAccessFilter = {
  contentTypes: {
    included: [],
    excluded: [],
  },
  countries: {
    included: [],
    excluded: [],
  },
  states: {
    included: [],
    excluded: [],
  },
  markets: {
    included: [],
    excluded: [],
  },
  stations: {
    included: [],
    excluded: [],
  },
  podcastSources: {
    included: [],
    excluded: [],
  },
};
