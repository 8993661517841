import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  addDays,
  addSeconds,
  endOfDay,
  format,
  getDate,
  startOfDay,
} from "date-fns";
import { ExpandMore } from "@mui/icons-material";
import { DateRangeIcon, DateRangePicker } from "@mui/x-date-pickers-pro";
import {
  Accordion,
  accordionClasses,
  AccordionSummary,
  accordionSummaryClasses,
  alpha,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { AxisConfig, ChartsXAxisProps } from "@mui/x-charts";
import { useAccount } from "src/api/useAccount";
import { useUserData } from "src/api/useUserData";
import { useUserActivityLogAggregations } from "src/api/useUserActivityLogAggregations";
import { ActivityLogItem } from "src/api/useAccountActivityLogs";
import { useUserActivityLogs } from "src/api/useUserActivityLogs";
import { PageHeaderDesktop } from "src/components/PageHeaderDesktop/PageHeaderDesktop";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { SearchInput } from "src/components/SearchInput";
import { DateRange } from "src/components/DateSelector/DateSelector";
import { useQueryDateTimeRangeWatchlist } from "src/utils/useQueryDateTimeRangeWatchlist";
import { useQueryPagination } from "src/utils/useQueryPagination";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";
import { ActivityLogTable } from "../AccountsManagementActivityLogs/ActivityLogTable/ActivityLogTable";
import { getTimeWithoutTimezone } from "../AccountsManagementActivityLogs/ActivityLogTable/ActivityLogTable.utils";
import { AnalyticsLiteDateTimeRangePickerActions } from "../ReportAnalysisLite/components/AnalyticsLiteDateTimeRangePickerActions";
import { isEqualDates } from "../AccountsManagementActivityLogs/AccountsManagementActivityLogs.utils";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 350,
    },
  },
};

const emptyList: ActivityLogItem[] = [];

export function UserManagementActivityLogPage() {
  const { userId = "", accountId = "" } = useParams();
  const [pagination, setPagination] = useQueryPagination();
  const { palette } = useTheme();

  const [filterText, setFilterText] = useState("");
  const [filterType, setFilterType] = useState("All");

  const [dateRange, setDateRange] = useQueryDateTimeRangeWatchlist({
    queryDays: 30,
  });

  const { data: userData, isLoading: isUserDataLoading } = useUserData({
    request: {
      params: {
        path: {
          id: userId,
        },
      },
    },
  });

  const { data: accountData, isLoading: isAccountDataLoading } = useAccount({
    options: {
      enabled: !!accountId,
    },
    request: {
      path: {
        id: accountId,
      },
    },
  });

  const {
    data,
    isLoading: isDataLoading,
    isFetching,
  } = useUserActivityLogs({
    options: {
      enabled: Boolean(userId),
    },
    request: {
      path: {
        userId,
      },
      query: {
        from: pagination.page * pagination.pageSize,
        size: pagination.pageSize,
        startTime: dateRange[0]
          ? formatDateTimeWithoutTimeZone(dateRange[0])
          : "",
        endTime: dateRange[1]
          ? formatDateTimeWithoutTimeZone(dateRange[1])
          : "",
        search: filterText,
        filter: filterType === "All" ? "" : filterType,
      },
    },
  });

  const { data: aggregationsData, isLoading: isDataAggregationsLoading } =
    useUserActivityLogAggregations({
      options: {
        enabled: Boolean(userId),
      },
      request: {
        path: {
          userId,
        },
        query: {
          startTime: dateRange[0]
            ? formatDateTimeWithoutTimeZone(dateRange[0])
            : "",
          endTime: dateRange[1]
            ? formatDateTimeWithoutTimeZone(dateRange[1])
            : "",
          filter: filterType === "All" ? "" : filterType,
        },
      },
    });

  const graphLabels = Object.keys(aggregationsData?.dailyActivity ?? []).map(
    (key) => {
      const formatedStartDate = getDate(dateRange[0]);
      const formatedEndDate = getDate(dateRange[1]);
      if (formatedStartDate !== formatedEndDate)
        return format(new Date(key), "d MMM");

      return getTimeWithoutTimezone(key, "p");
    }
  );
  const graphValues = Object.values(aggregationsData?.dailyActivity ?? []);

  const navToTheFirstPage = useCallback(() => {
    setPagination({
      ...pagination,
      page: 0,
    });
  }, [setPagination, pagination]);

  const setDateRangeWrapper = useCallback(
    (date: DateRange) => {
      setDateRange([
        // ensure proper day bounds
        date[0] ? startOfDay(date[0]) : null,
        date[1] ? endOfDay(date[1]) : null,
      ]);
      navToTheFirstPage();
    },
    [setDateRange, navToTheFirstPage]
  );

  const headerDesktop = (
    <PageHeaderDesktop
      hideBackButton
      hidePenultimateRoute={!accountId}
      title="Activity Log"
      breadcrumbLabels={
        accountId
          ? {
              "3": "Account Management",
              "2": null,
              "1": isAccountDataLoading
                ? "Loading..."
                : accountData?.name || " - ",
              "0": isUserDataLoading
                ? "Loading..."
                : userData?.fullName || " - ",
            }
          : {
              "2": "Users Management",
              "0": isUserDataLoading
                ? "Loading..."
                : userData?.fullName || " - ",
            }
      }
    />
  );

  const dataViewElement = useMemo(() => {
    return (
      <ActivityLogTable
        id="activity-log-table"
        featureType="users"
        rows={data?.results || emptyList}
        rowCount={data?.total ?? 0}
        loading={isDataLoading || isFetching}
        paginationMode="server"
        paginationModel={pagination}
        onPaginationModelChange={setPagination}
        accountId={accountId}
      />
    );
  }, [
    data?.results,
    data?.total,
    accountId,
    isDataLoading,
    isFetching,
    pagination,
    setPagination,
  ]);

  const filterInput = useMemo(
    () => (
      <SearchInput
        placeholder="Search by text..."
        onTextChangeThrottled={setFilterText}
        sx={{ minWidth: 168, maxWidth: 200 }}
        fullWidth
      />
    ),
    [setFilterText]
  );

  const dateRangePicker = (
    <DateRangePicker
      calendars={2}
      value={dateRange}
      onChange={setDateRangeWrapper}
      localeText={{ start: "Start date", end: "End date" }}
      format="PP"
      disableFuture
      slots={{
        actionBar: (actionBarProps) => (
          <AnalyticsLiteDateTimeRangePickerActions
            setDateTimeRange={setDateRange}
            {...actionBarProps}
          />
        ),
      }}
      sx={{ maxWidth: 450 }}
      slotProps={{
        desktopPaper: {
          elevation: palette.mode === "dark" ? 2 : undefined,
        },
        textField: {
          variant: "standard",
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <DateRangeIcon />
              </InputAdornment>
            ),
          },
        },
      }}
    />
  );

  const tableToolbar = (
    <Stack direction="row" justifyContent="space-between" m={2}>
      <Stack
        sx={{
          justifyContent: "flex-end",
          mr: 2,
        }}
      >
        {filterInput}
      </Stack>
      {dateRangePicker}
      <Stack width={220}>
        <FormControl fullWidth>
          <InputLabel variant="standard">Activity</InputLabel>
          <Select
            value={filterType}
            label="Activity"
            variant="standard"
            MenuProps={MenuProps}
            onChange={(e: SelectChangeEvent) => setFilterType(e.target.value)}
          >
            <MenuItem sx={{ height: 48 }} value={"All"}>
              All
            </MenuItem>

            <MenuItem sx={{ height: 48 }} value={"Sessions"}>
              Sessions
            </MenuItem>

            <MenuItem sx={{ height: 48 }} value={"Watchlist"}>
              Watchlist
            </MenuItem>

            <MenuItem sx={{ height: 48 }} value={"Alerts"}>
              Alerts
            </MenuItem>

            <MenuItem sx={{ height: 48 }} value={"Searches"}>
              Searches
            </MenuItem>

            <MenuItem sx={{ height: 48 }} value={"Clip Views"}>
              Clip Views
            </MenuItem>

            <MenuItem sx={{ height: 48 }} value={"Save Clips"}>
              Save Clips
            </MenuItem>

            <MenuItem sx={{ height: 48 }} value={"Reports"}>
              Reports
            </MenuItem>

            <MenuItem sx={{ height: 48 }} value={"Shares"}>
              Shares
            </MenuItem>

            <MenuItem sx={{ height: 48 }} value={"Other"}>
              Other
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </Stack>
  );

  const activityByType = Object.keys(aggregationsData?.activityType ?? []).map(
    (activityType) => (
      <Stack
        key={activityType}
        height={80}
        maxWidth={133}
        width="100%"
        alignItems="center"
        justifyContent="center"
        gap={1}
        component={Paper}
      >
        <Typography variant="subtitle2">{activityType}</Typography>
        <Typography
          variant="body1"
          color="primary.main"
          sx={{
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          onClick={() => setFilterType(activityType)}
        >
          {aggregationsData?.activityType
            ? aggregationsData?.activityType[activityType]
            : 0}
        </Typography>
      </Stack>
    )
  );

  const desktopContent = (
    <Stack overflow="hidden" height="100%" component={Paper}>
      <Accordion
        disableGutters
        defaultExpanded
        sx={{
          [`.${accordionSummaryClasses.root}.${accordionClasses.expanded}`]: {
            bgcolor: `${alpha(palette.primary.main, 0.04)} !important`,
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="activityLog-graph-section"
          id="activityLog-graph-section"
          sx={{ minHeight: "68px", background: "transparent !important" }}
        >
          <Typography variant="subtitle1" pl={2} pt={1}>
            {isUserDataLoading
              ? "Loading..."
              : `${userData?.fullName} Activity`}
          </Typography>
        </AccordionSummary>
        <Stack
          sx={{ bgcolor: alpha(palette.primary.main, 0.04) }}
          pb={2}
          gap={2}
        >
          <Stack height={90} width="100%">
            <BarChart
              loading={isDataAggregationsLoading}
              slotProps={{
                legend: { hidden: true },
              }}
              margin={{
                left: 20,
                right: 20,
                top: -10,
                bottom: 24,
              }}
              leftAxis={null}
              xAxis={[
                {
                  data: graphLabels,
                  scaleType: "band",
                  disableTicks: true,
                  disableLine: true,
                  categoryGapRatio: 0.05,
                } as AxisConfig<"band", unknown, ChartsXAxisProps>,
              ]}
              series={[
                {
                  data: graphValues,
                  label: "Product activity",
                  type: "bar",
                  color: palette.secondary.main,
                },
              ]}
              onItemClick={(_event, barItemIdentifier) => {
                const dates = Object.keys(
                  aggregationsData?.dailyActivity ?? []
                );
                const areDatesTheSame = isEqualDates(dates);
                if (areDatesTheSame) return;

                const { dataIndex } = barItemIdentifier;
                const startDate = addDays(dateRange[0], dataIndex);
                const endDate = addSeconds(
                  addDays(dateRange[0], dataIndex + 1),
                  -1
                );
                const newDateRange: [Date, Date] = [startDate, endDate];
                setDateRange(newDateRange);
              }}
            />
          </Stack>
          <Stack
            width="100%"
            gap={1}
            flexDirection="row"
            justifyContent="center"
          >
            {activityByType}
          </Stack>
        </Stack>
      </Accordion>

      <Divider />
      {tableToolbar}

      <Stack flex={1} overflow="hidden">
        {dataViewElement}
      </Stack>
    </Stack>
  );

  return (
    <PageLayoutDesktop
      header={headerDesktop}
      content={desktopContent}
      overflow="hidden"
    />
  );
}
