import { Dispatch, SetStateAction } from "react";
import { Add, Remove } from "@mui/icons-material";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  ToggleButton,
  useTheme,
} from "@mui/material";
import { addHours } from "date-fns";
import {
  AlertConfig,
  AlertConfigDaily,
} from "src/components/ScheduledAlertPopover/ScheduledAlertPopover.model";
import {
  AlertTypesKey,
  getDefaultAlertStateData,
  isAlertConfigType,
} from "src/components/ScheduledAlertPopover/ScheduledAlertPopover.utils";
import { AppTimePicker } from "src/components/AppTimePicker/AppTimePicker";

type AlertConfigFormDailyProps = {
  value: AlertConfigDaily;
  onChange: Dispatch<SetStateAction<AlertConfig>>;
  updateSelectOpen?: (v: boolean) => void;
};

export const AlertConfigFormDaily = ({
  value,
  onChange,
  updateSelectOpen,
}: AlertConfigFormDailyProps) => {
  const { palette } = useTheme();

  const { time, type, preset } = value;

  const onTypeChange = (event: SelectChangeEvent) => {
    if (isAlertConfigType(event?.target.value)) {
      onChange(getDefaultAlertStateData(event.target.value));
      updateSelectOpen && updateSelectOpen(false);
    } else {
      throw new Error(
        `Invalid value: ${event.target.value}. Should be one of ${Object.values(
          AlertTypesKey
        )}`
      );
    }
  };

  const onTimeChange = (val: Date | null, idx: number) => {
    if (!val) return;

    onChange((prev) => {
      if (prev.type !== AlertTypesKey.daily) return prev;

      const updatedTime = time.map((v, index) => {
        return index === idx ? val : v;
      });

      return {
        ...prev,
        time: updatedTime,
      };
    });
  };

  const onFieldChange = (idx?: number) => {
    onChange((prev) => {
      if (prev.type !== AlertTypesKey.daily) return prev;

      if (!idx && idx !== 0) {
        const newDate = addHours(prev.time[time.length - 1], 1);

        return {
          ...prev,
          time: [...prev.time, newDate],
        };
      }

      return {
        ...prev,
        time: prev.time.filter((_: Date, index: number) => index !== idx),
      };
    });
  };

  return (
    <>
      <FormControl
        fullWidth
        data-intercom-scheduled-alert-type-select-id="scheduled-alert-type-select-id"
      >
        <InputLabel>Type of alert</InputLabel>
        <Select
          onOpen={() => {
            updateSelectOpen && updateSelectOpen(true);
          }}
          MenuProps={{
            TransitionProps: {
              onExited: () => {
                updateSelectOpen && updateSelectOpen(false);
              },
            },
          }}
          value={preset || type}
          label="Type of alert"
          onChange={onTypeChange}
        >
          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.hourly}>
            Hourly
          </MenuItem>

          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.weekdayMorning}>
            Every Weekday Morning
          </MenuItem>
          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.threeTimes}>
            Three Times Daily
          </MenuItem>
          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.mondayMorning}>
            Monday Morning
          </MenuItem>
          <MenuItem sx={{ height: 48 }} divider value={AlertTypesKey.firstDay}>
            First Day of the Month
          </MenuItem>

          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.daily}>
            Custom Every Day
          </MenuItem>
          <MenuItem
            data-intercom-scheduled-alert-weekly-item-id="scheduled-alert-weekly-item-id"
            sx={{ height: 48 }}
            value={AlertTypesKey.weekly}
          >
            Custom Day of Week
          </MenuItem>
          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.monthly}>
            Custom Monthly
          </MenuItem>
        </Select>
      </FormControl>

      {time.map((t, idx) => (
        <Stack
          key={`time-${idx}-${t.getTime()}`}
          flexDirection="row"
          justifyContent="space-between"
        >
          <AppTimePicker
            label="Time"
            layoutWidth="260px"
            value={t}
            onAccept={(val) => onTimeChange(val, idx)}
          />
          <ToggleButton
            value={true}
            onClick={() => {
              onFieldChange(idx !== time.length - 1 ? idx : undefined);
            }}
            sx={{ width: 56 }}
          >
            {idx !== time.length - 1 ? (
              <Remove />
            ) : (
              <Add
                sx={{
                  color:
                    palette.mode === "dark" ? "primary.main" : "text.primary",
                }}
              />
            )}
          </ToggleButton>
        </Stack>
      ))}
    </>
  );
};
