import { Stack, StackProps, useTheme, alpha } from "@mui/material";
import { EventDetails } from "src/models/EventDetails";
import { EventDetailsDataBlock } from "../EventDetailsDataBlock/EventDetailsDataBlock";

export function BCastViewership({
  event,
  ...props
}: Omit<StackProps, "children"> & { event: EventDetails }) {
  const { palette } = useTheme();
  const { localAdValue, nationalAudience, nationalAdValue, localAudience } =
    event.audience || {};
  const AdValueUnits = "$";
  if (
    [localAdValue, nationalAudience, nationalAdValue, localAudience].every(
      (value) => !value
    )
  ) {
    return (
      <Stack rowGap={2} columnGap={2} px={2} {...props} direction={"column"}>
        <Stack
          height="43px"
          width="100%"
          justifyContent="center"
          alignItems="center"
          sx={{
            color: palette.text.secondary,
            backgroundColor: alpha(palette.primary.main, 0.04),
            borderRadius: "8px",
          }}
        >
          Audience data not available
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack rowGap={2} columnGap={2} px={2} {...props}>
      <Stack flex={1} rowGap={2}>
        {localAudience ? (
          <EventDetailsDataBlock
            title="Local Market Viewership"
            value={localAudience ? localAudience?.toLocaleString() : "-"}
          />
        ) : null}
        {localAdValue ? (
          <EventDetailsDataBlock
            title="Local Market AdValue"
            value={
              localAdValue
                ? `${AdValueUnits} ${localAdValue?.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  })}`
                : "-"
            }
          />
        ) : null}
      </Stack>

      <Stack flex={1} rowGap={2}>
        {nationalAudience ? (
          <EventDetailsDataBlock
            title="National Viewership"
            value={nationalAudience ? nationalAudience.toLocaleString() : "-"}
          />
        ) : null}
        {nationalAdValue ? (
          <EventDetailsDataBlock
            title="National AdValue"
            value={
              nationalAdValue
                ? `${AdValueUnits} ${nationalAdValue?.toLocaleString(
                    undefined,
                    {
                      maximumFractionDigits: 0,
                    }
                  )}`
                : "-"
            }
          />
        ) : null}
      </Stack>
    </Stack>
  );
}
