import { AppRouteBuilder } from "src/models/AppRoute";
import { PermissionGuard } from "src/components/PermissionGuard";
import { ACTIVITYLOGSROLES } from "src/utils/useUserRoleList";
import { ActivityLogPage } from "./AccountsManagementActivityLogs.page";

type AccountManagementActivityLogsParams = {
  accountId: string;
};

export const accountsManagementActivityLogsRoute =
  new AppRouteBuilder<AccountManagementActivityLogsParams>({
    path: "/accounts-management/logs/:accountId",
    element: (
      <PermissionGuard
        component={ActivityLogPage}
        allowedRoles={ACTIVITYLOGSROLES}
        disableMobile
        redirectTo="/"
      />
    ),
  });
