import { RefObject } from "react";
import { useTheme, ListItem, ListItemProps } from "@mui/material";

type ListBaseItemPs = ListItemProps & {
  innerRef?: RefObject<HTMLLIElement>;
};

export function ListBaseItem({ innerRef, ...props }: ListBaseItemPs) {
  const { palette } = useTheme();

  return (
    <ListItem
      disablePadding
      ref={innerRef}
      {...props}
      sx={{
        border: props.divider ? undefined : `1px solid ${palette.divider}`,
        borderRadius: props.divider ? undefined : 2,
        p: 2,
        ...props.sx,
      }}
    />
  );
}
