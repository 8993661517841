import { SvgIcon, SvgIconProps } from "@mui/material";

export const ArchiveAlertBadge = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <rect width="20" height="20" rx="6.66667" fill="white" />
        <path
          d="M12.0833 7.91667H7.91667V12.0833H12.0833V7.91667ZM10.6944 10.6944H9.30556V9.30556H10.6944V10.6944ZM16.25 9.30556V7.91667H14.8611V6.52778C14.8611 5.76389 14.2361 5.13889 13.4722 5.13889H12.0833V3.75H10.6944V5.13889H9.30556V3.75H7.91667V5.13889H6.52778C5.76389 5.13889 5.13889 5.76389 5.13889 6.52778V7.91667H3.75V9.30556H5.13889V10.6944H3.75V12.0833H5.13889V13.4722C5.13889 14.2361 5.76389 14.8611 6.52778 14.8611H7.91667V16.25H9.30556V14.8611H10.6944V16.25H12.0833V14.8611H13.4722C14.2361 14.8611 14.8611 14.2361 14.8611 13.4722V12.0833H16.25V10.6944H14.8611V9.30556H16.25ZM13.4722 13.4722H6.52778V6.52778H13.4722V13.4722Z"
          fill="#F57C00"
        />
      </svg>
    </SvgIcon>
  );
};
