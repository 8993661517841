import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { History, PersonPin } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import DropDownButton from "src/components/DropDownButton/DropDownButton";
import { DropDownButtonProps } from "src/components/DropDownButton/DropDownButton.model";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";
import {
  getStatusIcon,
  getStatusLabel,
} from "src/pages/UserManagement/UserManagement.utils";
import { useUserDetailsUpdate } from "src/api/useUserDetailsUpdate";
import { UserDataResponse } from "src/api/useUserData";
import {
  getAuthUserRole,
  getIsImpersonateDisabled,
  getUserHighestRole,
} from "src/api/useUserManagementResult.getters";
import { useSessionContext } from "src/api/useSessionContext";
import { useImpersonateUser } from "src/api/useImpersonateUser";
import { useIsDemoMode } from "src/api/useIsDemoMode";
import { getRoleFromUser } from "src/utils/useUserRoleList";
import { useOpenState } from "src/utils/useOpenState";
import { userManagementActivityLogsRoute } from "../UserManagementActivityLogs/UserManagementActivityLogs.route";
import { watchListRoute } from "../WatchList/WatchList.route";
import { DemoModeConfirmationModal } from "../UserManagement/components/DemoModeConfirmationModal/DemoModeConfirmationModal";
import { EndDemoModeModal } from "../UserManagement/components/EndDemoModeModal/EndDemoModeModal";

type UserManagementActionsButtonProps = Omit<
  DropDownButtonProps,
  "renderMenu"
> & {
  userData: UserDataResponse;
};

export function UserManagementActionsButton({
  userData,
  ...props
}: UserManagementActionsButtonProps) {
  const userStatus = userData.isActive ? "active" : "disabled";
  const StatusIcon = getStatusIcon(userStatus);
  const startDemoDialog = useOpenState();
  const endDemoDialog = useOpenState();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const role = getRoleFromUser(user);
  const authUserHighestRole = getAuthUserRole(role);
  const userHighestRole = getUserHighestRole(userData);

  const isImpersonateDisabled = getIsImpersonateDisabled(
    authUserHighestRole,
    userHighestRole
  );

  const isDemoMode = useIsDemoMode();

  const { isImpersonatingUser, userId } = useSessionContext();

  const enableActivityLogs =
    role.includes("Admin") || role.includes("Account Manager");

  const userGeneralDataUpdate = useUserDetailsUpdate({
    options: {
      origin: "userDataKey",
    },
  });

  const onUserDeactivate = () => {
    if (!userData.id) return;
    userGeneralDataUpdate.mutate({
      body: {
        isActive: !userData.isActive,
      },
      params: {
        path: {
          id: userData.id,
        },
      },
    });
  };

  const impersonateUser = useImpersonateUser({
    options: {
      onSuccess: () => {
        startDemoDialog.hide();
        navigate(watchListRoute.path);
      },
    },
  });

  const impersonate = (isDemo: boolean) => {
    if (!userData.id) return;
    impersonateUser.mutate({
      params: {
        path: {
          id:
            isImpersonatingUser === true && userData.id === userId
              ? "00000000-0000-0000-0000-000000000000"
              : userData.id,
        },
        query: isDemo ? { mode: "demo" } : undefined,
      },
    });
  };

  return (
    <>
      <DropDownButton
        variant="outlined"
        sx={{
          height: 42,
        }}
        {...props}
        renderMenu={(menuProps, closeMenu) => (
          <Menu {...menuProps}>
            <MenuItem
              onClick={() => {
                closeMenu();
                onUserDeactivate();
              }}
            >
              <ListItemIcon>
                <StatusIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>{getStatusLabel(userStatus)}</ListItemText>
            </MenuItem>

            <TooltipBase
              width={181}
              textAlign="left"
              title={"Available to Admin and Account Manager only."}
              placement="right"
              disableHoverListener={enableActivityLogs}
            >
              <Box>
                <MenuItem
                  disabled={!enableActivityLogs}
                  onClick={() => {
                    closeMenu();
                    navigate(
                      userManagementActivityLogsRoute.makeUrl({
                        userId: userData.id ? userData.id : "",
                      })
                    );
                  }}
                >
                  <ListItemIcon>
                    <History fontSize="small" color="primary" />
                  </ListItemIcon>
                  <ListItemText>Activity log</ListItemText>
                </MenuItem>
              </Box>
            </TooltipBase>

            <MenuItem
              disabled={isImpersonateDisabled}
              onClick={() => {
                if (!userData.id) return;
                closeMenu();
                impersonate(false);
              }}
            >
              <ListItemIcon>
                <PersonPin fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>
                {isImpersonatingUser === true &&
                userData.id === userId &&
                !isDemoMode
                  ? "Stop Impersonating"
                  : "Impersonate user"}
              </ListItemText>
            </MenuItem>

            <MenuItem
              disabled={isImpersonateDisabled}
              onClick={() => {
                closeMenu();
                isDemoMode === true && userData.id === userId
                  ? endDemoDialog.show()
                  : startDemoDialog.show();
              }}
            >
              <ListItemIcon>
                <PersonPin fontSize="small" color="info" />
              </ListItemIcon>
              <ListItemText>
                {isDemoMode === true && userData.id === userId
                  ? "Stop Demo Mode"
                  : "Start Demo Mode"}
              </ListItemText>
            </MenuItem>
          </Menu>
        )}
      >
        Actions
      </DropDownButton>

      <DemoModeConfirmationModal
        open={startDemoDialog.isOpen}
        onClose={startDemoDialog.hide}
        onSubmit={() => impersonate(true)}
        content={
          <Typography variant="body1">
            Are you sure you want to start a demo?
          </Typography>
        }
        submitLabel="Yes"
        title="Start Demo"
        isLoading={impersonateUser.isLoading}
      />
      <EndDemoModeModal
        isOpen={endDemoDialog.isOpen}
        onClose={endDemoDialog.hide}
      />
    </>
  );
}
