import { format } from "date-fns";
import { ActivityLogItem } from "./useAccountActivityLogs";

export function getActivityLogDate(value: ActivityLogItem) {
  const date = value.timestamp
    ? format(new Date(value.timestamp), "PP pp")
    : " - ";

  return date;
}

export function getActivityLogUser(value: ActivityLogItem) {
  return value.userName || " - ";
}

export function getActivityLogActivity(value: ActivityLogItem) {
  return value.type || " - ";
}

export function getActivityLogDetails(value: ActivityLogItem) {
  return value.message || " - ";
}
