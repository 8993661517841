type EntityMap<T> = {
  [id: string]: T;
};

/**
 * Create a map keyed by id from list of objects
 */
export function makeEntityMap<T>({
  list,
  getId,
}: {
  list: T[];
  getId: (item: T, index: number) => string;
}): EntityMap<T> {
  const resultMap: EntityMap<T> = {};
  for (const item of list) {
    const index = Object.keys(resultMap).length;
    const id = getId(item, index);
    resultMap[id] = item;
  }

  return resultMap;
}
