import { Stack, Typography } from "@mui/material";

export function TableEmptyLayout({
  icon,
  header,
  content,
}: {
  icon?: JSX.Element;
  header?: JSX.Element | string;
  content?: JSX.Element | string;
}) {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center" gap={2}>
      {icon}
      {typeof header === "string" ? (
        <Typography variant="h6" color="text.disabled">
          {header}
        </Typography>
      ) : (
        header
      )}
      {typeof content === "string" ? (
        <Typography variant="h6" color="text.disabled">
          {content}
        </Typography>
      ) : (
        content
      )}
    </Stack>
  );
}
