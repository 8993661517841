import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Controller, useFormContext } from "react-hook-form";
import isEqual from "lodash/isEqual";
import { SourcesPicker } from "src/components/SourcesPicker/SourcesPicker";
import { EventSourceCategory, useEventSources } from "src/api/useEventSources";
import { makeContentFilterQuery } from "src/utils/makeContentFilterQuery";
import { ContentAccessFilter } from "src/models/ContentAccessFilter";
import { useAccountContentFilterUpdate } from "src/api/useAccountManagementContentFilter";
import { AccountResponse } from "src/api/useAccount";
import {
  CONTENT_FILTER_PRESETS,
  INCLUDE_EXCLUDE_PRESETS,
} from "src/utils/contentFilterPresets";
import { sortIncludeExcludeValues } from "src/utils/sortIncludeExcludeContentFilterValues";
import { IncludeExcludeListGroupLogic } from "src/utils/makeWatchQueryFormValues";
import { EditAccountContentFilterFormValues } from "../AccountsManagementEditContentTab/AccountsManagementContentTab.model";
import { sourceFilterMapper } from "../../utils/sourceFilterMapper";

export function AccountsManagementEditContentFilterForm({
  data,
  selectedPreset,
  setSelectedPreset,
}: {
  data?: AccountResponse;
  selectedPreset?: IncludeExcludeListGroupLogic | null;
  setSelectedPreset?: Dispatch<
    SetStateAction<IncludeExcludeListGroupLogic | null>
  >;
}) {
  const { breakpoints } = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(breakpoints.down("lg"));

  const { control, reset, watch, formState, setValue } =
    useFormContext<EditAccountContentFilterFormValues>();

  const [contentFilters, setContentFilters] =
    useState<ContentAccessFilter | null>(null);

  const { accountId = "" } = useParams();

  const { data: sourcesData } = useEventSources({
    path: {
      categories: Object.values(EventSourceCategory),
      searchText: "*",
    },
    query: {
      ignoreAccessFilter: true,
    },
  });

  const contentFilterFormData = watch();
  const accountContentFilterDataUpdate = useAccountContentFilterUpdate({});

  const excludeValues = watch("sourcesExclude");
  const includeValues = watch("sourcesInclude");
  const formPreset = watch("preset");

  const resetContentFilter = () => {
    if (data?.contentAccessFilter) {
      const matchingPreset = INCLUDE_EXCLUDE_PRESETS.find((item) =>
        isEqual(item.contentAccessFilter, data?.contentAccessFilter)
      );

      const sources = sourceFilterMapper(data.contentAccessFilter, sourcesData);
      const isCustom =
        sources?.excludedFields?.list?.length ||
        sources?.includedFields?.list?.length;

      reset({
        sourcesExclude: sources?.excludedFields || {
          list: [],
          logic: "or",
        },
        sourcesInclude: sources?.includedFields || {
          list: [],
          logic: "or",
        },
        preset: matchingPreset
          ? matchingPreset?.title
          : isCustom
          ? "custom"
          : "",
      });
      if (matchingPreset)
        setSelectedPreset?.({
          include: sources?.includedFields || {
            list: [],
            logic: "or",
          },
          exclude: sources?.excludedFields || {
            list: [],
            logic: "or",
          },
        });
    }
  };

  const submitContentFilterTabData = () => {
    if (!contentFilters) return;

    accountContentFilterDataUpdate.mutate({
      body: contentFilters,
      params: {
        path: {
          id: accountId,
        },
      },
    });
  };

  useEffect(() => {
    const filters = makeContentFilterQuery(
      contentFilterFormData.sourcesInclude,
      contentFilterFormData.sourcesExclude
    );
    setContentFilters(filters);
    const matchingPreset = INCLUDE_EXCLUDE_PRESETS.find((item) =>
      isEqual(item.contentAccessFilter, filters)
    );
    if (!matchingPreset) {
      setValue("preset", "custom");
    } else {
      setValue("preset", matchingPreset.title);
      setSelectedPreset?.({
        include: contentFilterFormData.sourcesInclude || {
          list: [],
          logic: "or",
        },
        exclude: contentFilterFormData.sourcesExclude || {
          list: [],
          logic: "or",
        },
      });
    }
  }, [
    contentFilterFormData.sourcesExclude,
    contentFilterFormData.sourcesInclude,
    setValue,
    setSelectedPreset,
  ]);

  useEffect(() => {
    if (selectedPreset) {
      const isIncludeValuesEqual = isEqual(
        includeValues,
        selectedPreset?.include
      );
      const isExcludeValuesEqual = isEqual(
        excludeValues,
        selectedPreset?.exclude
      );
      if (!isIncludeValuesEqual || !isExcludeValuesEqual) {
        setValue("preset", "custom");
        setSelectedPreset?.(null);
      }
    }

    if (formPreset) {
      if (!includeValues?.list?.length && !excludeValues?.list?.length) {
        setValue("preset", "Apex");
      }
    }
  }, [
    excludeValues,
    includeValues,
    selectedPreset,
    formPreset,
    setValue,
    setSelectedPreset,
  ]);

  const handlePresetChange = (event: SelectChangeEvent) => {
    const presetTitle = event.target.value;
    const selectedContentFilterPreset = INCLUDE_EXCLUDE_PRESETS.find(
      (preset) => preset.title === presetTitle
    );

    if (selectedContentFilterPreset) {
      const accountSources =
        selectedContentFilterPreset?.contentAccessFilter &&
        sourceFilterMapper(
          selectedContentFilterPreset?.contentAccessFilter,
          sourcesData
        );
      if (accountSources?.includedFields) {
        setValue("sourcesInclude", accountSources?.includedFields, {
          shouldDirty: true,
        });
      }

      if (accountSources?.excludedFields) {
        setValue("sourcesExclude", accountSources?.excludedFields);
      }

      setValue("preset", presetTitle, { shouldDirty: true });
      setSelectedPreset?.({
        include: accountSources?.includedFields || {
          list: [],
          logic: "or",
        },
        exclude: accountSources?.excludedFields || {
          list: [],
          logic: "or",
        },
      });
    }
  };
  const allSources =
    !excludeValues?.list?.length && !includeValues?.list?.length;

  return (
    <>
      <div ref={containerRef} style={{ width: "100%" }} />
      <Stack py={3} height={420}>
        <Typography variant="subtitle1">Content Filter</Typography>
        <Grid container spacing={isMobile ? 1.5 : 1} mb={2} mt={1}>
          <Grid item xs={12} mb={3}>
            <Controller
              name="preset"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth>
                  <InputLabel>
                    {allSources ? "All sources" : "Presets"}
                  </InputLabel>
                  <Select
                    {...field}
                    id="preset"
                    label={allSources ? "All sources" : "Presets"}
                    value={field.value}
                    onChange={handlePresetChange}
                    onBlur={field.onBlur}
                    fullWidth
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        width: 100,
                      },
                    }}
                  >
                    <MenuItem value={"custom"}>Custom </MenuItem>
                    {CONTENT_FILTER_PRESETS.map((preset) => (
                      <MenuItem key={preset.title} value={preset.title}>
                        {preset.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="sourcesInclude"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <SourcesPicker
                    id="source-include"
                    label="Include"
                    value={field.value || { list: [] }}
                    excludedOptions={
                      contentFilterFormData?.sourcesExclude?.list
                    }
                    onChange={(e) => {
                      //Sort selected options to match BE order
                      const sortedValues = sortIncludeExcludeValues(e);
                      field.onChange(e.list.length ? sortedValues : e);
                    }}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message || " "}
                    ignoreAccessFilter
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="sourcesExclude"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <SourcesPicker
                    id="source-exclude"
                    label="Exclude"
                    value={field.value || { list: [] }}
                    excludedOptions={
                      contentFilterFormData?.sourcesInclude?.list
                    }
                    onChange={(e) => {
                      //Sort selected options to match BE order
                      const sortedValues = sortIncludeExcludeValues(e);
                      field.onChange(e.list.length ? sortedValues : e);
                    }}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message || " "}
                    ignoreAccessFilter
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="flex-end" gap={2} mb={3} mt={2}>
          <Button
            variant="outlined"
            sx={{ height: "42px", width: "124px", fontSize: "15px" }}
            onClick={resetContentFilter}
            disabled={!formState.isDirty}
          >
            Reset
          </Button>
          <Button
            disabled={!formState.isDirty}
            variant="contained"
            color="primary"
            sx={{ height: "42px", width: "124px", fontSize: "15px" }}
            onClick={submitContentFilterTabData}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
