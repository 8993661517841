import { format, formatDistanceToNowStrict } from "date-fns";
/**
 * FIXME: this purpose of this entire file is to provide a value getters for badly typed
 * user management result. We may get rid of this once types are stabilized.
 */
import { UserItem } from "./useUsersManagement";
import { UserDataResponse } from "./useUserData";

const roleOrder = ["Admin", "Account Manager", "User Manager", "User"];

enum UserRole {
  Admin = "Admin",
  AccountManager = "Account Manager",
  UserManager = "User Manager",
  User = "User",
  NoRole = "No role",
}

export function getUserManagementId(value: UserItem) {
  return value.id;
}

export function getUserManagementFirstName(value: UserItem) {
  return value.firstName || " - ";
}

export function getUserManagementLastName(value: UserItem) {
  return value.lastName || " - ";
}

export function getUserManagementFullName(value: UserItem) {
  if (!value.firstName && !value.lastName) {
    return " - ";
  }
  return `${value.firstName || ""} ${value.lastName || ""}`;
}

export function getUserManagementAccount(value: UserItem) {
  return value.accountName || " - ";
}

export function getUserManagementStatus(value: UserItem) {
  return value.status || " - ";
}

export function getUserManagementEmail(value: UserItem) {
  return value.email || " - ";
}

export function getUserManagementCreationDateDistanceToNow(value: UserItem) {
  const startDateDistance = value.created
    ? formatDistanceToNowStrict(new Date(value.created), { addSuffix: true })
    : " - ";
  return startDateDistance;
}

export function getUserManagementExpirationDateDistanceToNow(value: UserItem) {
  const startDateDistance = value.created
    ? formatDistanceToNowStrict(new Date(value.expiration), { addSuffix: true })
    : " - ";
  return startDateDistance;
}

export function getUserManagementCreationDate(value: UserItem) {
  const startDateTime = value.created
    ? format(new Date(value.created), "PP")
    : " - ";
  return startDateTime;
}

export function getUserManagementExpirationDate(value: UserItem) {
  const startDateTime = value.expiration
    ? format(new Date(value.expiration), "PP")
    : " - ";
  return startDateTime;
}

export function getUserManagementRoles(value: UserItem) {
  return value.roles || ["No role"];
}

export function getUserManagementAcountId(value: UserItem) {
  return value.accountId || "";
}

export function getUserHighestRole(value: UserItem | UserDataResponse) {
  for (const role of roleOrder) {
    if (value?.roles?.includes(role)) {
      return role;
    }
  }
  return "No role";
}

export function getAuthUserRole(value: string): UserRole {
  if (value.includes(UserRole.Admin)) return UserRole.Admin;
  if (value.includes(UserRole.AccountManager)) return UserRole.AccountManager;
  if (value.includes(UserRole.UserManager)) return UserRole.UserManager;
  if (value.includes(UserRole.User)) return UserRole.User;
  return UserRole.NoRole;
}

export function getIsImpersonateDisabled(authRole: string, userRole: string) {
  if (authRole === UserRole.Admin) return false;
  if (authRole === UserRole.AccountManager && userRole !== UserRole.Admin)
    return false;
  if (authRole === UserRole.UserManager) {
    if (
      userRole === authRole ||
      userRole === UserRole.User ||
      userRole === UserRole.NoRole
    )
      return false;
  }
  return true;
}
