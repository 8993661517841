import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { addSeconds, isEqual } from "date-fns";
import { useDebounce } from "use-debounce";
import {
  Button,
  DrawerProps,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { components } from "@tveyes/twosionwebapischema";
import { BooleanParam, useQueryParam } from "use-query-params";
import { useShiftPlayerData } from "src/api/useShiftPlayerData";
import { useStationTime } from "src/api/useStationTime";
import { EventDetails } from "src/models/EventDetails";
import { ShiftPlayerDataProps } from "src/pages/WatchListTermResultClipEditor/WatchListTermResultClipEditor.page";
import { ShiftPlayerRangePager } from "./ShiftPlayerRangePager/ShiftPlayerRangePager";
import { ShiftPlayerEventsList } from "./ShiftPlayerEventsList/ShiftPlayerEventsList";
import { ShiftPlayerTimeLine } from "./ShiftPlayerTimeLine/ShiftPlayerTimeLine";
import { RightSideDrawer } from "../RightSideDrawer/RightSideDrawer";
import { UseTrimRangeValue } from "../ClipEditorPage/hook/useTrimRangeState";

type ShiftPlayerDrawerProps = Omit<
  DrawerProps,
  "anchor" | "hideBackdrop" | "onClose" | "title"
> & {
  onClose: () => void;
  event: EventDetails;
  onShiftPlayerPlay?: (data: ShiftPlayerDataProps) => void;
  initialStartDateTime?: string;
  bounds?: [Date, Date] | null;
  minSpanSec: number;
  trimRange?: UseTrimRangeValue;
  setTrimRange?: Dispatch<SetStateAction<UseTrimRangeValue>>;
  mode: "view" | "edit";
  query?: components["schemas"]["QueryDefinition"];
  shiftPlayerOffset: number;
  offset: number;
};

type Highlights = Exclude<EventDetails["highlights"], undefined>;

function makeUniqueHighlight(val: Highlights) {
  return val.reduce((res: Highlights, next) => {
    const isAlreadyInclude = res.some((v) => v.timestamp === next.timestamp);
    if (!isAlreadyInclude) {
      res.push(next);
    }
    return res;
  }, []);
}

export function ShiftPlayerDrawer({
  event,
  query,
  onShiftPlayerPlay,
  initialStartDateTime,
  bounds,
  minSpanSec,
  trimRange,
  setTrimRange,
  mode,
  shiftPlayerOffset,
  offset,
  ...props
}: ShiftPlayerDrawerProps) {
  const [isExpandedTextMode, setExpandedTextMode] = useQueryParam(
    "expanded",
    BooleanParam
  );

  const [highlights, setHighlights] = useState<Highlights>([]);
  const [maxDateTime, setMaxDateTime] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(new Date());
  useEffect(() => {
    if (initialStartDateTime) {
      const cursorTime = addSeconds(
        new Date(initialStartDateTime),
        shiftPlayerOffset
      );
      setCurrentDate(cursorTime);
    }
  }, [initialStartDateTime, shiftPlayerOffset]);

  const [debouncedCurrentDate] = useDebounce(currentDate, 500);

  const stationTime = useStationTime({
    params: {
      path: {
        station: event.sourceId ?? "",
      },
    },
  });

  useEffect(() => {
    setMaxDateTime((prevValue) => {
      if (!stationTime.data || !stationTime.data.currentLocalTime)
        return prevValue;
      if (isEqual(prevValue, new Date(stationTime.data.currentLocalTime)))
        return prevValue;

      return addSeconds(new Date(stationTime.data.currentLocalTime), -3 * 60);
    });
  }, [stationTime.data]);

  const { data } = useShiftPlayerData({
    options: {
      enabled: Object.keys(event).length !== 0 && props.open,
    },
    params: {
      cursorRangeBounds: [0, +10],
      fetchParams: {
        dateTime: debouncedCurrentDate,
        market: null,
        station: {
          group: "StationGUID",
          name: event.source ?? "",
          value: event.sourceId ?? "",
        },
        stationCurrentTime: maxDateTime,
        sentenceAlign: false,
      },
      body: query,
    },
  });

  useEffect(() => {
    if (data) {
      const resultsHighlights = data.reduce((res: Highlights, next) => {
        if (next.highlights && next.highlights.length !== 0) {
          return [...res, ...next.highlights];
        }
        return res;
      }, []);

      const highlights = makeUniqueHighlight(
        event.highlights?.length
          ? [...resultsHighlights, ...event.highlights]
          : resultsHighlights
      );
      setHighlights(highlights);
    }
  }, [data, event.highlights]);

  const content = (
    <>
      <ShiftPlayerTimeLine
        highlights={highlights}
        value={currentDate}
        handleChange={setCurrentDate}
        maxDateTime={maxDateTime}
      />
      <ShiftPlayerRangePager
        value={currentDate}
        bounds={[-1, +1]}
        maxDate={maxDateTime}
        onChange={setCurrentDate}
        dateTimeFormat="p"
      />
      <ShiftPlayerEventsList
        showFullTranscript={!!isExpandedTextMode}
        onShiftPlayerPlay={onShiftPlayerPlay}
        event={event}
        rows={data ?? []}
        bounds={bounds}
        minSpanSec={minSpanSec}
        trimRange={trimRange}
        setTrimRange={setTrimRange}
        mode={mode}
        offset={offset}
      />
    </>
  );

  return (
    <RightSideDrawer
      {...props}
      intercomId="shift-player-drawer"
      title="Zoom Timeline"
      children={content}
      hideBackdrop
      PaperProps={{
        sx: { width: 536 },
      }}
      ModalProps={{
        slotProps: {
          root: {
            style: {
              zIndex: 4,
              width: 10,
            },
          },
        },
      }}
      footer={
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          height={64}
        >
          <FormControlLabel
            checked={!!isExpandedTextMode}
            control={
              <Switch
                onChange={(_e, checked) =>
                  setExpandedTextMode(checked, "replaceIn")
                }
              />
            }
            label={<Typography variant="caption">Expanded Text</Typography>}
            sx={{ mx: 0 }}
          />
          <Button
            variant="contained"
            sx={{ width: 164 }}
            onClick={props.onClose}
          >
            Close
          </Button>
        </Stack>
      }
    />
  );
}
