import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { paths } from "@tveyes/twosionwebapischema";
import { useOpenState } from "src/utils/useOpenState";
import {
  EventSourceCategory,
  EventSourceOption,
  useEventSources,
} from "src/api/useEventSources";
import { AutocompleteModal } from "../AutocompleteModal/AutocompleteModal";
import {
  AppAutocomplete,
  AppAutocompleteProps,
} from "../AppAutocomplete/AppAutocomplete";

const key: keyof paths = "/api/source/autocomplete/{categories}/{searchText}";
type Endpoint = paths[typeof key]["get"];
type QueryParams = Endpoint["parameters"]["query"];

type EventSourcePickerMobileProps = Omit<
  AppAutocompleteProps<EventSourceOption, false>,
  "options"
> & {
  categories: EventSourceCategory[];
  queryParams?: QueryParams;
  value?: EventSourceOption | null;
  onChange: () => void;
};

export function EventSourcePickerMobile({
  error,
  label,
  helperText,
  onChange,
  value,
  categories,
  onInputChange,
  queryParams,
  ...props
}: EventSourcePickerMobileProps) {
  const menuState = useOpenState();
  const [inputValue, setInputValue] = useState("");

  const [searchText] = useDebounce(inputValue, 500);
  const [preSelectedValue, setPreSelectedValue] = useState<
    EventSourceOption | null | undefined
  >(value);

  const sourceCategoryOptions = useEventSources({
    query: {
      ...queryParams,
    },
    path: {
      categories: categories,
      searchText: inputValue ? inputValue : "*",
    },
  });
  const [sourcesOptions, setSourceOptions] = useState(
    sourceCategoryOptions.data?.list
  );

  useEffect(() => {
    setSourceOptions(sourceCategoryOptions.data?.list);
  }, [sourceCategoryOptions]);

  const handlePickerClick = () => {
    if (menuState.isOpen) {
      menuState.hide();
    }
    menuState.show();
  };

  return (
    <>
      <AppAutocomplete<EventSourceOption, false>
        options={[]}
        getOptionLabel={(option) => option.name}
        getOptionKey={(o) => o.value}
        fullWidth
        readOnly
        loading={sourceCategoryOptions.isLoading}
        label={label}
        value={value}
        error={error}
        helperText={helperText}
        onClick={handlePickerClick}
        {...props}
      />
      <AutocompleteModal
        label={label}
        loading={sourceCategoryOptions.isLoading}
        menuState={menuState}
        onChange={onChange}
        value={value}
        queryParams={queryParams}
        inputValue={inputValue}
        setInputValue={setInputValue}
        options={sourcesOptions || []}
        searchText={searchText}
        onInputChange={onInputChange}
        preSelectedValue={preSelectedValue}
        setPreSelectedValue={setPreSelectedValue}
        {...props}
      />
    </>
  );
}
