import {
  Box,
  Button,
  Stack,
  Typography,
  useTheme,
  alertClasses,
  useMediaQuery,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { useCurrentUser } from "src/api/useCurrentUser";
import { useImpersonateUser } from "src/api/useImpersonateUser";
import { DemoModeConfirmationModal } from "src/pages/UserManagement/components/DemoModeConfirmationModal/DemoModeConfirmationModal";
import { useOpenState } from "src/utils/useOpenState";

const DEMO_REDIRECT_DELAY = 5000;

export function DemoModeHandle() {
  const { palette, breakpoints, zIndex } = useTheme();
  const endDemoDialog = useOpenState();

  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const { data: currentUser } = useCurrentUser();
  const impersonateUser = useImpersonateUser({
    options: {
      onSuccess: () => {
        endDemoDialog.hide();
        enqueueSnackbar(
          "Demo has ended. Your session has been restored. You will be redirected.",
          {
            autoHideDuration: DEMO_REDIRECT_DELAY,
          }
        );
        setTimeout(
          () =>
            (window.location.href =
              "http://insight.tveyes.com/help/demo-whats-next"),
          DEMO_REDIRECT_DELAY
        );
      },
      onSettled: () => {
        endDemoDialog.hide();
      },
    },
  });

  const stopImpersonating = () => {
    impersonateUser.mutate({
      params: {
        path: {
          id: "00000000-0000-0000-0000-000000000000",
        },
      },
    });
  };

  const demoExitModeModal = (
    <DemoModeConfirmationModal
      open={endDemoDialog.isOpen}
      onClose={endDemoDialog.hide}
      onSubmit={stopImpersonating}
      content={
        <Typography variant="body1">
          Are you sure you want to close the demo mode?
        </Typography>
      }
      submitLabel="Yes"
      title="End Demo"
      cancelLabel="Cancel"
      isLoading={impersonateUser.isLoading}
    />
  );

  return (
    <Stack>
      {demoExitModeModal}
      {currentUser ? (
        <Box
          display="flex"
          justifyContent={"center"}
          p={1}
          sx={{
            position: "fixed",
            left: 0,
            right: 0,
            width: isMobile ? "250px" : "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Alert
            sx={{
              width: "max-content",
              border: "1px solid",
              borderColor: palette.primary.main,
              paddingY: 1,

              textWrap: "wrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              [`.${alertClasses.icon}`]: {
                order: 2,
                paddingY: 0,
                paddingLeft: 1,
                marginRight: 0,
              },
              [`.${alertClasses.message}`]: {
                paddingY: "4px",
              },
            }}
            icon={
              <Button
                sx={{
                  p: 0,
                  justifyContent: "end",
                  minWidth: "16px",
                  zIndex: zIndex.tooltip,
                  order: 2,
                }}
                onClick={endDemoDialog.show}
              >
                <CloseIcon
                  sx={{
                    width: "24px",
                    height: "24px",
                    color: palette.text.primary,
                  }}
                />
              </Button>
            }
            severity="info"
          >
            <Stack direction={"row"}>
              <InfoOutlinedIcon
                sx={{
                  width: "22px",
                  height: "22px",
                  color: palette.info.main,
                }}
              />
              <Typography
                variant="subtitle2"
                fontFamily={"Roboto Mono, monospace"}
                fontWeight={400}
                lineHeight={"21px"}
                fontSize={16}
                px={1}
              >
                Demo Session:
              </Typography>
              <Typography variant="subtitle2" lineHeight={"21px"}>
                {currentUser?.email}
              </Typography>
            </Stack>
          </Alert>
        </Box>
      ) : null}
    </Stack>
  );
}
