import { Fragment, useState } from "react";
import {
  SortingMenuProps,
  SortBy,
  SortOption,
  sortByValues,
} from "./SortingMenu.model";
import {
  Divider,
  useTheme,
  ListItemIcon,
  ListItemText,
  alpha,
  Menu,
  MenuItem,
  dividerClasses,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonProps,
  useMediaQuery,
} from "@mui/material";
import { useManagementView } from "src/api/useManagementView";
import { SortAZ } from "../icons/SortAZ";
import { SortZA } from "../icons/SortZA";
import { SortNewToOld } from "../icons/SortNewToOld";
import { SortOldToNew } from "../icons/SortOldToNew";
import { SortTrendingUp } from "../icons/SortTrendingUp";
import { SortTrendingDown } from "../icons/SortTrendingDown";
import { SortDistanceUp } from "../icons/SortDistanceUp";
import { SortDistanceDown } from "../icons/SortDistanceDown";
import { SortLocationUp } from "../icons/SortLocationUp";
import { SortLocationDown } from "../icons/SortLocationDown";

export function SortingMenu({
  onChange,
  value,
  options = Object.values(SortBy),
  customSortLabel,
  sx,
  minWidth,
  ...props
}: SortingMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const { data } = useManagementView();

  const open = Boolean(anchorEl);
  const handleClick: ToggleButtonProps["onClick"] = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSorting = (sortOption: SortOption) => {
    handleClose();
    onChange(sortOption);
  };

  const iconColor = palette.primary.main;
  return (
    <Fragment>
      <ToggleButtonGroup
        sx={{
          minWidth: minWidth,
        }}
        value={value}
        aria-controls={open ? "sort-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        size={isMobile ? "small" : "medium"}
        {...props}
      >
        <ToggleButton
          value={true}
          onClick={handleClick}
          aria-controls={open ? "sort-menu" : undefined}
          sx={{
            color: "text.primary",
            bgcolor: open
              ? alpha(palette.primary.light, 0.12)
              : palette.background.paper,
          }}
        >
          {(value === SortBy.titleAsc || value === SortBy.marketTitleAsc) && (
            <SortAZ />
          )}
          {(value === SortBy.titleDesc || value === SortBy.marketTitleDesc) && (
            <SortZA />
          )}
          {value === SortBy.defaultAsc && <SortOldToNew />}
          {value === SortBy.defaultDesc && <SortNewToOld />}
          {value === SortBy.viewershipDesc && <SortTrendingUp />}
          {value === SortBy.viewershipAsc && <SortTrendingDown />}
          {value === SortBy.disctanceAsc && <SortDistanceUp />}
          {value === SortBy.distanceDesc && <SortDistanceDown />}
          {value === SortBy.marketSizeDesc && <SortLocationUp />}
          {value === SortBy.marketSizeAsc && <SortLocationDown />}
        </ToggleButton>
      </ToggleButtonGroup>
      <Menu
        sx={sx}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "sort-button",
        }}
      >
        <MenuItem disabled sx={{ height: 48, padding: "4px 16px" }}>
          <ListItemText sx={{ fontSize: "14px" }}>Change Order</ListItemText>
        </MenuItem>
        {options.includes(SortBy.marketTitleAsc) && [
          <MenuItem
            onClick={() => handleSorting(sortByValues[SortBy.marketTitleAsc])}
            sx={{ height: 48 }}
            key={SortBy.marketTitleAsc}
          >
            <ListItemIcon>
              <SortAZ sx={{ color: iconColor }} />
            </ListItemIcon>
            <ListItemText>Market title</ListItemText>
          </MenuItem>,
          <MenuItem
            onClick={() => handleSorting(sortByValues[SortBy.marketTitleDesc])}
            sx={{ height: 48 }}
            key={SortBy.marketTitleDesc}
          >
            <ListItemIcon>
              <SortZA sx={{ color: iconColor }} />
            </ListItemIcon>
            <ListItemText>Market title-reversed</ListItemText>
          </MenuItem>,
        ]}
        {options.includes(SortBy.titleAsc) && [
          <MenuItem
            onClick={() => handleSorting(sortByValues[SortBy.titleAsc])}
            sx={{ height: 48 }}
            key={SortBy.titleAsc}
          >
            <ListItemIcon>
              <SortAZ sx={{ color: iconColor }} />
            </ListItemIcon>
            <ListItemText>
              Sort by {customSortLabel ? customSortLabel : "title"}
            </ListItemText>
          </MenuItem>,
          <MenuItem
            onClick={() => handleSorting(sortByValues[SortBy.titleDesc])}
            sx={{ height: 48 }}
            key={SortBy.titleDesc}
          >
            <ListItemIcon>
              <SortZA sx={{ color: iconColor }} />
            </ListItemIcon>
            <ListItemText>
              Sort by {customSortLabel ? customSortLabel : "title"}-reversed
            </ListItemText>
          </MenuItem>,
        ]}
        {options.includes(SortBy.defaultAsc) && [
          <Divider
            key={"divider"}
            className={`${SortBy.defaultAsc}`}
            sx={{
              [`&.${dividerClasses.root}`]: {
                margin: 0,
              },
            }}
          />,
          <MenuItem
            onClick={() => handleSorting(sortByValues[SortBy.defaultDesc])}
            sx={{ height: 48 }}
            key={SortBy.defaultDesc}
          >
            <ListItemIcon>
              <SortNewToOld sx={{ color: iconColor }} />
            </ListItemIcon>
            <ListItemText>Newest-oldest</ListItemText>
          </MenuItem>,
          <MenuItem
            onClick={() => handleSorting(sortByValues[SortBy.defaultAsc])}
            sx={{ height: 48 }}
            key={SortBy.defaultAsc}
          >
            <ListItemIcon>
              <SortOldToNew sx={{ color: iconColor }} />
            </ListItemIcon>
            <ListItemText>Oldest-newest</ListItemText>
          </MenuItem>,
        ]}

        {options.includes(SortBy.viewershipDesc) && [
          <Divider
            key={"divider"}
            sx={{
              [`&.${dividerClasses.root}`]: {
                margin: 0,
              },
            }}
          />,
          <MenuItem
            onClick={() => handleSorting(sortByValues[SortBy.viewershipDesc])}
            sx={{ height: 48 }}
            key={SortBy.viewershipDesc}
          >
            <ListItemIcon>
              <SortTrendingUp sx={{ color: iconColor }} />
            </ListItemIcon>
            <ListItemText>Most viewed</ListItemText>
          </MenuItem>,
          <MenuItem
            onClick={() => handleSorting(sortByValues[SortBy.viewershipAsc])}
            sx={{ height: 48 }}
            key={SortBy.viewershipAsc}
          >
            <ListItemIcon>
              <SortTrendingDown sx={{ color: iconColor }} />
            </ListItemIcon>
            <ListItemText>Least viewed</ListItemText>
          </MenuItem>,
        ]}
        {options.includes(SortBy.marketSizeDesc) && [
          <Divider
            key={"divider"}
            className={`${SortBy.marketSizeDesc}`}
            sx={{
              [`&.${dividerClasses.root}`]: {
                margin: 0,
              },
            }}
          />,
          <MenuItem
            onClick={() => handleSorting(sortByValues[SortBy.marketSizeAsc])}
            sx={{ height: 48 }}
            key={SortBy.marketSizeAsc}
          >
            <ListItemIcon>
              <SortLocationDown sx={{ color: iconColor }} />
            </ListItemIcon>
            <ListItemText>Market size-larger</ListItemText>
          </MenuItem>,
          <MenuItem
            onClick={() => handleSorting(sortByValues[SortBy.marketSizeDesc])}
            sx={{ height: 48 }}
            key={SortBy.marketSizeDesc}
          >
            <ListItemIcon>
              <SortLocationUp sx={{ color: iconColor }} />
            </ListItemIcon>
            <ListItemText>Market size-smaller</ListItemText>
          </MenuItem>,
        ]}
        {options.includes(SortBy.distanceDesc) &&
          data?.trackLocation && [
            <Divider
              key={"divider"}
              sx={{
                [`&.${dividerClasses.root}`]: {
                  margin: 0,
                },
              }}
            />,
            <MenuItem
              onClick={() => handleSorting(sortByValues[SortBy.disctanceAsc])}
              sx={{ height: 48 }}
              key={SortBy.disctanceAsc}
            >
              <ListItemIcon>
                <SortDistanceUp sx={{ color: iconColor }} />
              </ListItemIcon>
              <ListItemText>Distance-closer</ListItemText>
            </MenuItem>,
            <MenuItem
              onClick={() => handleSorting(sortByValues[SortBy.distanceDesc])}
              sx={{ height: 48 }}
              key={SortBy.distanceDesc}
            >
              <ListItemIcon>
                <SortDistanceDown sx={{ color: iconColor }} />
              </ListItemIcon>
              <ListItemText>Distance-farther</ListItemText>
            </MenuItem>,
          ]}
      </Menu>
    </Fragment>
  );
}
