import { useRef } from "react";
import {
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { queryAdvertisementValues } from "src/models/Advertisement";
import { AdvertisementFilterNotice } from "src/components/AdvertisementPicker/AdvertisementFilterNotice";
import { GlobalSearchFormValues } from "./GlobalSearchForm.model";
import { AdvertisementPicker } from "../AdvertisementPicker/AdvertisementPicker";
import { ProgramTagPicker } from "../ProgramTagPicker/ProgramTagPicker";
import { SourcesPicker } from "../SourcesPicker/SourcesPicker";
import { TextInputBase } from "../TextInputBase";
import { WatchTermInput } from "../WatchTermInput/WatchTermInput";
import { AppDateTimePicker } from "../AppDateTimePicker/AppDateTimePicker";

export function GlobalSearchForm({ loading }: { loading?: boolean }) {
  const { breakpoints } = useTheme();

  const { control, watch } = useFormContext<GlobalSearchFormValues>();
  const sourcesIncludeList = watch("sourcesInclude.list");
  const sourcesExcludeList = watch("sourcesExclude.list");

  const isMobile = useMediaQuery(breakpoints.down("lg"));
  const containerRef = useRef<HTMLDivElement>(null);

  const watchTermRow = (
    <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
      <Grid item xs={12} md={12} lg={2}>
        <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
          Keyword
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} lg={5}>
        <Controller
          name="termsInclude"
          control={control}
          render={({ field, fieldState, formState }) => {
            const { list, logic } = formState.errors[field.name] || {};
            const message = list?.message || logic?.message || " ";
            return (
              <WatchTermInput
                id="term-include"
                label="Include"
                value={field.value}
                options={[]} // no suggestions for now
                onChange={field.onChange}
                onBlur={field.onBlur}
                autoFocus
                disabled={loading}
                error={!!fieldState.error}
                helperText={message}
              />
            );
          }}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={5}>
        <Controller
          name="termsExclude"
          control={control}
          render={({ field, fieldState, formState }) => {
            const { list, logic } = formState.errors[field.name] || {};
            const message = list?.message || logic?.message || " ";

            return (
              <WatchTermInput
                id="term-exclude"
                label="Exclude"
                value={field.value}
                options={[]} // no suggestions for now
                onChange={field.onChange}
                onBlur={field.onBlur}
                disabled={loading}
                error={!!fieldState.error}
                helperText={message}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );

  const displayNameRow = (
    <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
      <Grid item xs={12} md={12} lg={2}>
        <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
          Display name
        </Typography>
      </Grid>

      <Grid item xs={12} md={12} lg={10}>
        <Controller
          name="displayName"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextInputBase
                id="display-name"
                label="Display name"
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                disabled={loading}
                error={!!fieldState.error}
                helperText={fieldState.error?.message || " "}
                fullWidth
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );

  const dateStartEnd = (
    <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
      <Grid item xs={12} md={12} lg={2}>
        <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
          Dates
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} lg={5}>
        <Controller
          name="startDateTime"
          control={control}
          render={({ field, fieldState }) => {
            const message = fieldState.error?.message || " ";

            return (
              <AppDateTimePicker
                label="Start Date"
                value={field.value}
                onChange={field.onChange}
                views={["year", "month", "day", "hours", "minutes"]}
                disabled={loading}
                slotProps={{
                  field: {
                    clearable: true,
                  },
                  textField: {
                    helperText: message,
                    error: !!fieldState.error,
                    fullWidth: true,
                    onBlur: field.onBlur,
                  },
                }}
              />
            );
          }}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={5}>
        <Controller
          name="endDateTime"
          control={control}
          render={({ field, fieldState }) => {
            const message = fieldState?.error?.message || " ";

            return (
              <AppDateTimePicker
                label="End Date"
                value={field.value}
                onChange={field.onChange}
                views={["year", "month", "day", "hours", "minutes"]}
                disabled={loading}
                slotProps={{
                  field: {
                    clearable: true,
                  },
                  textField: {
                    helperText: message,
                    error: !!fieldState.error,
                    fullWidth: true,
                    onBlur: field.onBlur,
                  },
                }}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <Stack>
      {/* create and updates have slight differences in row order */}
      {watchTermRow}
      {displayNameRow}
      {dateStartEnd}

      <Grid container visibility="hidden">
        {/* this is dimension element to align autocomplete dropdown */}
        <Grid item xs={12} md={12} lg={2} />
        <Grid item xs={12} md={12} lg={10} ref={containerRef} />
      </Grid>

      {/* Sources */}
      <Grid
        container
        spacing={isMobile ? 1.5 : 3}
        sx={{
          mb: { xs: 1.5, md: 1.5, lg: 0 },
        }}
      >
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
            Source
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={10}>
          <Controller
            name="sourcesInclude"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <SourcesPicker
                  id="source-include"
                  label={
                    field.value.list.length ? "Include selected" : "All Sources"
                  }
                  dropDownBoundsEl={containerRef}
                  value={field.value}
                  excludedOptions={sourcesExcludeList}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={loading}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                />
              );
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
        <Grid
          item
          lg={2}
          sx={{
            display: { xs: "none", md: "none", lg: "block" },
          }}
        />

        <Grid item xs={12} md={12} lg={10}>
          <Controller
            name="sourcesExclude"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <SourcesPicker
                  id="source-exclude"
                  label="Exclude"
                  dropDownBoundsEl={containerRef}
                  value={field.value}
                  excludedOptions={sourcesIncludeList}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={loading}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      {/* Sources */}

      {/* Advertisement */}
      <Grid container spacing={isMobile ? 1.5 : 3} mb={5}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography
            variant="subtitle1"
            display="flex"
            alignItems="center"
            height={36.5}
          >
            Advertisement
            <span data-intercom-advertisement-id="advertisement-id" />
          </Typography>
        </Grid>

        <Controller
          name="advertisement"
          control={control}
          render={({ field }) => {
            return (
              <>
                <Grid item xs={12} md={6} lg={5}>
                  <AdvertisementPicker
                    id="advertisement"
                    fullWidth
                    disabled={loading}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  />
                </Grid>
                {field.value === queryAdvertisementValues.none && (
                  <>
                    <Grid item lg={5} /* layout spacer */ />
                    <Grid item lg={2} /* layout spacer */ />
                    <Grid item xs={12} lg={10}>
                      <AdvertisementFilterNotice />
                    </Grid>
                  </>
                )}
              </>
            );
          }}
        />
      </Grid>
      {/* Advertisement */}

      {/* Program title */}
      <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
            Program Title
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} lg={5}>
          <Controller
            name="programInclude"
            control={control}
            render={({ field, fieldState, formState }) => {
              const { list, logic } = formState.errors[field.name] || {};
              const message = list?.message || logic?.message || " ";

              return (
                <ProgramTagPicker
                  id="program-include"
                  label="Include"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  getOptionLabel={(o) => o.name}
                  disabled={loading}
                  error={!!fieldState.error}
                  helperText={message}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={5}>
          <Controller
            name="programExclude"
            control={control}
            render={({ field, fieldState, formState }) => {
              const { list, logic } = formState.errors[field.name] || {};
              const message = list?.message || logic?.message || " ";

              return (
                <ProgramTagPicker
                  id="program -exclude"
                  label="Exclude"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  getOptionLabel={(o) => o.name}
                  disabled={loading}
                  error={!!fieldState.error}
                  helperText={message}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
