import axios from "axios";
import { paths } from "@tveyes/twosionwebapischema";
import { enqueueSnackbar } from "notistack";
import { useQuery } from "react-query";
import { makeApiUrl } from "src/utils/makeApiUrl";

export const userInviteDetailsKey = "userInviteDetailsKey";

const apiPath: keyof paths = "/api/Invite/{id}";
const method: keyof paths[typeof apiPath] = "get";
type Endpoint = paths[typeof apiPath][typeof method];

type Parameters = Endpoint["parameters"];
type Response = Endpoint["responses"][200]["content"]["application/json"];

export function useUserInviteDetails({ params }: { params: Parameters }) {
  return useQuery<Response, unknown>({
    keepPreviousData: true,
    queryKey: [userInviteDetailsKey],
    queryFn: async () => {
      const url = makeApiUrl<Parameters>(apiPath, params);
      const { data } = await axios<Response>(url, {
        method,
      });
      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
