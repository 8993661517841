import { useCallback, useEffect, useRef, useState } from "react";
import { BlockerFunction, useBlocker, useNavigate } from "react-router-dom";
import { FormProvider } from "react-hook-form";
import { useAuth0 } from "@auth0/auth0-react";
import isEmpty from "lodash/isEmpty";
import { Close } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useWatchQueryCreate } from "src/api/useWatchQueryCreate";
import { useWatchTermSharedForm } from "src/api/useWatchTermSharedForm";
import { WatchQueryKeywordFormValues } from "src/components/WatchQueryKeywordForm/WatchQueryKeywordForm.model";
import { WatchQueryCustomFormValues } from "src/components/WatchQueryCustomForm/WatchQueryCustomForm.model";
import { PageLayoutMobile } from "src/components/PageLayoutMobile";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { PageHeaderDesktop } from "src/components/PageHeaderDesktop/PageHeaderDesktop";
import { AlertType } from "src/models/AlertType";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { ScrollBox } from "src/components/ScrollBox/ScrollBox";
import { AppTabLayout } from "src/components/AppTabLayout/AppTabLayout";
import { AppTab } from "src/components/AppTabLayout/AppTab";
import { SwitchToSimpleModeModal } from "src/components/SwitchToSimpleModeModal/SwitchToSimpleModeModal";
import { WatchListCreateKeywordForm } from "src/components/WatchListCreateKeywordForm/WatchListCreateKeywordForm";
import { WatchListCreateCustomForm } from "src/components/WatchListCreateCustomForm/WatchListCreateCustomForm";
import { watchListRoute } from "src/pages/WatchList/WatchList.route";
import { WatchListCreateCustomFormWrapper } from "src/components/WatchListCreateCustomForm/WatchListCreateCustomFormWrapper";
import { WatchListCreateKeywordFormWrapper } from "src/components/WatchListCreateKeywordForm/WatchListCreateKeywordFormWrapper";
import { useWatchListCreateForms } from "./WatchListCreateForm.hook";
import { NotificationSection } from "./components/NotificationSection";
import { MoreOptionsSection } from "./components/MoreOptionsSection";

enum Tabs {
  simpleMode = "simpleMode",
  advancedMode = "advancedMode",
}

type WatchQueryFormValues =
  | WatchQueryKeywordFormValues
  | WatchQueryCustomFormValues;

export const WatchListCreatePage = () => {
  const { breakpoints, palette, shadows } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down("lg"));

  const { user } = useAuth0();

  const dropDownBoundsEl = useRef<null | HTMLDivElement>(null);

  const [isInstantAlertActive, setIsInstantAlertActive] = useState(false);
  const [scheduledAlerts, setScheduledAlerts] = useState<AlertType[] | null>(
    null
  );

  const [tabMode, setTabMode] = useViewModeQueryParam<Tabs>({
    paramKey: "tab",
    defaultValue: Tabs.simpleMode,
  });

  const scheduledAlertCreateHandler = (
    newValue: AlertType | AlertType[],
    action?: "delete" | "edit"
  ) => {
    if (Array.isArray(newValue)) {
      return setScheduledAlerts(newValue);
    }

    if (!action) {
      return setScheduledAlerts((v) => {
        const prevValue = v ?? [];
        return [...prevValue, newValue];
      });
    }

    setScheduledAlerts((v) => {
      if (action === "delete") {
        return v?.filter((val) => val.id !== newValue.id) ?? [];
      }

      const updatedAlerts =
        v?.map((item) => (item.id === newValue.id ? newValue : item)) ?? [];
      return updatedAlerts;
    });
  };

  const toggleInstantAlert = () => {
    setIsInstantAlertActive((prevState) => !prevState);
  };

  const {
    keyWordFormHook,
    customQueryFormHook,
    formState,
    handleSubmit,
    resetCustomQueryValues,
  } = useWatchListCreateForms();

  const { clearSharedValues } = useWatchTermSharedForm();

  useEffect(() => {
    return clearSharedValues;
  }, [clearSharedValues]);

  const shouldBlockLeaving = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      const { watch } = customQueryFormHook;
      return (
        !!watch().customQuery &&
        nextLocation.search.includes(Tabs.simpleMode) &&
        currentLocation.search !== nextLocation.search
      );
    },
    [customQueryFormHook]
  );
  const blocker = useBlocker(shouldBlockLeaving);

  const switchToSimpleMode = useCallback(() => {
    resetCustomQueryValues();
    blocker.proceed?.();
  }, [blocker, resetCustomQueryValues]);

  const watchQueryCreate = useWatchQueryCreate({});
  const { isSuccess } = watchQueryCreate;

  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      navigate(watchListRoute.path);
    }
  }, [navigate, isSuccess]);

  const goBack = () => {
    navigate(-1);
  };

  const submitWatchTerm = handleSubmit(
    (data: WatchQueryFormValues) => {
      const alerts = [];
      if (isInstantAlertActive) {
        alerts.push({
          id: "00000000-0000-0000-0000-000000000000",
          type: "realtime",
          archive: false,
          destination: user?.email,
          schedule: [],
          enabled: true,
          muted: false,
        });
      }

      if (scheduledAlerts) {
        const newScheduledAlerts = scheduledAlerts.map((alert) => ({
          ...alert,
          id: "00000000-0000-0000-0000-000000000000",
        }));
        alerts.push(...newScheduledAlerts);
      }

      const query = makeQueryDefinition(data);
      watchQueryCreate.mutate({
        title: data.displayName,
        queryBuilderDefinition: query,
        alerts,
      });
    },
    (errors) => {
      console.log("@@ DEBUG:submitWatchTerm:error", errors);
    }
  );

  const newTermButton = (
    <Button
      variant={!isSmallScreen ? "contained" : "text"}
      color="primary"
      sx={
        isSmallScreen
          ? { fontSize: "16px" }
          : { height: "42px", width: "196px", fontSize: "15px" }
      }
      disabled={
        !formState.isValid ||
        watchQueryCreate.isLoading ||
        !isEmpty(formState.errors)
      }
      onClick={submitWatchTerm}
    >
      {!isSmallScreen ? "Create watch term" : "Save"}
    </Button>
  );

  const notificationSection = (
    <NotificationSection
      isInstantAlertActive={isInstantAlertActive}
      toggleInstantAlert={toggleInstantAlert}
      scheduledAlerts={scheduledAlerts}
      scheduledAlertCreateHandler={scheduledAlertCreateHandler}
    />
  );

  const pageHeaderMobile = (
    <Stack
      component={Paper}
      alignItems="center"
      justifyContent="space-between"
      flexDirection="row"
      height={56}
      minHeight={56}
      pl={2}
      pr={1}
      sx={{
        boxShadow: shadows[2],
        borderRadius: 0,
      }}
    >
      <Stack flexDirection="row" alignItems="center" gap={4}>
        <IconButton edge="end" onClick={goBack}>
          <Close sx={{ color: palette.text.primary }} />
        </IconButton>
        <Typography variant="subtitle1">Create Watch Term</Typography>
      </Stack>
      {newTermButton}
    </Stack>
  );

  if (isSmallScreen) {
    return (
      <PageLayoutMobile
        hideTopBar
        content={
          <FormProvider {...keyWordFormHook}>
            {pageHeaderMobile}
            <ScrollBox
              customScrollBarStyle="hidden"
              pt={2}
              sx={{ backgroundColor: palette.background.default }}
            >
              <WatchListCreateKeywordForm
                loading={watchQueryCreate.isLoading}
                dropDownBoundsEl={dropDownBoundsEl}
              />
              <Paper
                sx={{
                  borderRadius: 0,
                  pt: 2,
                  mb: 2,
                }}
              >
                {notificationSection}
              </Paper>
              <MoreOptionsSection
                key="simpleMode"
                loading={watchQueryCreate.isLoading}
              />
            </ScrollBox>
          </FormProvider>
        }
      />
    );
  }

  const pageHeaderDesktop = (
    <PageHeaderDesktop
      title="Create Watch Term"
      hideBackButton
      breadcrumbLabels={{
        0: "New Watchterm",
      }}
      toolbar={
        <Stack direction="row" spacing={2} height="100%" alignItems="flex-end">
          <Button
            variant="outlined"
            sx={{ height: "42px", width: "196px", fontSize: "15px" }}
            onClick={goBack}
            children="Cancel"
          />
          {newTermButton}
        </Stack>
      }
    />
  );

  const desktopContent = (
    <Stack gap={3} flexDirection="column" id="watch-query-create-form-root">
      <Stack
        /* dimension element for dropdowns */
        ml={4}
        mr={3}
        border="1px solid red"
        visibility="hidden"
        ref={dropDownBoundsEl}
      />

      <Stack flexDirection="row" width="100%" gap={3}>
        <Stack component={Paper} borderRadius={0} flexGrow={1}>
          <AppTabLayout
            id="tabs-mode"
            flex={1}
            overflow="hidden"
            variant="standard"
            currentTab={tabMode}
            onCurrentTabChange={(tab) => setTabMode(tab as Tabs)}
          >
            <AppTab
              label="Simple mode"
              value={Tabs.simpleMode}
              children={
                <FormProvider {...keyWordFormHook}>
                  <WatchListCreateKeywordFormWrapper>
                    <WatchListCreateKeywordForm
                      loading={watchQueryCreate.isLoading}
                      dropDownBoundsEl={dropDownBoundsEl}
                    />
                  </WatchListCreateKeywordFormWrapper>
                </FormProvider>
              }
            />
            <AppTab
              label={
                <Stack direction="row">
                  <Stack>Advanced mode</Stack>
                  <Stack
                    width="8px"
                    data-intercom-advanced-tab-id="intercom-advanced-tab"
                  />
                </Stack>
              }
              value={Tabs.advancedMode}
              children={
                <FormProvider {...customQueryFormHook}>
                  <WatchListCreateCustomFormWrapper>
                    <WatchListCreateCustomForm
                      loading={watchQueryCreate.isLoading}
                      dropDownBoundsEl={dropDownBoundsEl}
                    />
                  </WatchListCreateCustomFormWrapper>
                </FormProvider>
              }
            />
          </AppTabLayout>
        </Stack>
        <Paper
          sx={{
            width: "343px",
            minWidth: "343px",
            minHeight: "346px",
            p: 2,
            pt: 3,
            borderRadius: 2,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          {notificationSection}
        </Paper>
      </Stack>

      {tabMode === Tabs.simpleMode ? (
        <FormProvider {...keyWordFormHook}>
          <WatchListCreateKeywordFormWrapper>
            <MoreOptionsSection
              key="simpleMode"
              loading={watchQueryCreate.isLoading}
            />
          </WatchListCreateKeywordFormWrapper>
        </FormProvider>
      ) : (
        <FormProvider {...customQueryFormHook}>
          <WatchListCreateCustomFormWrapper>
            <MoreOptionsSection
              key="customMode"
              loading={watchQueryCreate.isLoading}
            />
          </WatchListCreateCustomFormWrapper>
        </FormProvider>
      )}
    </Stack>
  );

  return (
    <>
      <PageLayoutDesktop
        content={
          <Stack height="100%">
            {pageHeaderDesktop}
            {desktopContent}
          </Stack>
        }
      />
      {blocker && (
        <SwitchToSimpleModeModal
          open={blocker.state === "blocked"}
          onClose={() => blocker.reset?.()}
          title={"Switch to Simple Mode?"}
          onSwitch={switchToSimpleMode}
        />
      )}
    </>
  );
};
