import { Stack, StackProps, Typography, useTheme, alpha } from "@mui/material";
import { EventDetails } from "src/models/EventDetails";
import { PodcastPartnerLogo } from "src/components/PodcastPartnerLogo/PodcastPartnerLogo";
import { EventType } from "src/models/EventType";
import { EventDetailsDataBlock } from "../EventDetailsDataBlock/EventDetailsDataBlock";

export function PCastAudience({
  event,
  ...props
}: Omit<StackProps, "children"> & { event: EventDetails }) {
  const { palette } = useTheme();
  if (
    !event.audiencePodcast?.totalAudience &&
    !event.audiencePodcast?.powerScore &&
    !event.audienceYoutube?.totalAudience
  ) {
    return (
      <Stack rowGap={2} columnGap={2} px={2} {...props} direction={"column"}>
        <Stack
          height="43px"
          width="100%"
          justifyContent="center"
          alignItems="center"
          sx={{
            color: palette.text.secondary,
            backgroundColor: alpha(palette.primary.main, 0.04),
            borderRadius: "8px",
          }}
        >
          Audience data not available
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack rowGap={2} columnGap={2} px={2} {...props}>
      {event.eventType === EventType.PCast ? (
        <>
          <Stack flex={1} rowGap={2}>
            <EventDetailsDataBlock
              value={
                <Stack direction="row">
                  <Typography
                    variant="subtitle2"
                    color="text.primary"
                    children="by"
                  />
                  &nbsp; <PodcastPartnerLogo height={24} />
                </Stack>
              }
            />
          </Stack>
          {event.audiencePodcast?.totalAudience ? (
            <Stack flex={1} rowGap={2}>
              <EventDetailsDataBlock
                title="Audience Estimate"
                value={event?.audiencePodcast?.totalAudience?.toLocaleString()}
              />
            </Stack>
          ) : null}
          {event.audiencePodcast?.powerScore ? (
            <Stack flex={1} rowGap={2}>
              <EventDetailsDataBlock
                title="Power Score"
                value={event.audiencePodcast?.powerScore}
              />
            </Stack>
          ) : null}
        </>
      ) : event.audienceYoutube?.totalAudience ? (
        <Stack flex={1} rowGap={2}>
          <EventDetailsDataBlock
            title="Audience Estimate"
            value={event?.audienceYoutube?.totalAudience?.toLocaleString()}
          />
        </Stack>
      ) : null}
    </Stack>
  );
}
