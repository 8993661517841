import { ErrorOutline } from "@mui/icons-material";
import { Stack, Typography, alpha, useTheme } from "@mui/material";
import { PlayerError } from "@tveyes/mediaviewport-ui";
import { useIsMobile } from "src/utils/useIsMobile";

const getErrorMessage = (error: PlayerError): string[] => {
  switch (error.type) {
    case "assetnotfound":
      return ["The required asset could not be found."];
    case "mediamissing":
      return [
        "Sorry...",
        "This video won’t play because the stream is offline.",
      ];

    case "playbackerror":
      return ["The player encountered a playback error"];

    case "playbacklimitexceeded":
      return [
        "You have played this clip too many times.",
        "The video will be available after 10 minutes.",
      ];

    case "restricted":
      return ["Restricted."];

    default:
      return [`Unknown error occurred.", "Code: ${error.type}`];
  }
};

export function ClipViewerError({ error }: { error: PlayerError }) {
  const { palette } = useTheme();
  const isMobile = useIsMobile();
  const message = getErrorMessage(error);

  return (
    <Stack
      flex={1}
      justifyContent="center"
      alignItems="center"
      bgcolor={palette.background.brand}
      p={2}
    >
      <Stack
        direction="row"
        columnGap={2}
        justifyContent="center"
        alignItems="center"
      >
        <ErrorOutline
          sx={{ color: alpha(palette.primary.contrastText, 0.5) }}
          fontSize="large"
        />
        <Typography color={palette.primary.contrastText} variant="subtitle1">
          {message.map((line, i, all) => {
            const isLast = i === all.length - 1;
            return (
              <span key={`${i}:${line}`}>
                {line}
                {isMobile ? " " : !isLast && <br />}
              </span>
            );
          })}
        </Typography>
      </Stack>
    </Stack>
  );
}
