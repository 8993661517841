import { Dispatch, SetStateAction, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { paths } from "@tveyes/twosionwebapischema";
import {
  AppAutocomplete,
  AppAutocompleteProps,
} from "../AppAutocomplete/AppAutocomplete";

const key: keyof paths = "/api/source/autocomplete/{categories}/{searchText}";
type Endpoint = paths[typeof key]["get"];
type QueryParams = Endpoint["parameters"]["query"];

type AutocompleteModalProps<T> = Omit<
  AppAutocompleteProps<T, false>,
  "renderOption" | "renderTags" | "getOptionLabel" | "getOptionKey" | "options"
> & {
  queryParams?: QueryParams;
  onChange: () => void;
  menuState: {
    isOpen: boolean;
    show: () => void;
    hide: () => void;
  };
  setInputValue: Dispatch<SetStateAction<string>>;
  options: T[];
  searchText: string;
  preSelectedValue?: T | null;
  setPreSelectedValue: React.Dispatch<
    React.SetStateAction<T | null | undefined>
  >;
};

export function AutocompleteModal<T extends { name: string; value: string }>({
  error,
  label,
  helperText,
  onInputChange,
  onChange,
  menuState,
  value,
  queryParams,
  inputValue,
  setInputValue,
  options,
  searchText,
  preSelectedValue,
  setPreSelectedValue,
  ...props
}: AutocompleteModalProps<T>) {
  const { palette } = useTheme();
  const resetChanges = () => onChange(null);

  const onChangeWrapper = () => {
    if (preSelectedValue) onChange(preSelectedValue);
  };

  useEffect(() => {
    onInputChange && onInputChange(searchText);
  }, [searchText, onInputChange]);

  useEffect(() => {
    setPreSelectedValue(value);
  }, [value, setPreSelectedValue]);

  return (
    <Dialog
      open={menuState.isOpen}
      onClose={menuState.hide}
      sx={{
        [`& .${dialogClasses.paper}`]: {
          m: "32px 24px",
          width: "100%",
          minHeight: "calc(100% - 64px)",
        },
      }}
    >
      <DialogTitle sx={{ px: 2, py: 2, width: "100%" }}>
        <Stack gap={1} width="100%">
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            pb={2}
          >
            <Typography variant="subtitle1">{`${label}:`}</Typography>
          </Stack>
          <AppAutocomplete<T, false>
            {...props}
            options={options}
            getOptionLabel={(option) => option.name}
            fullWidth
            getOptionKey={(o) => o.value}
            PaperComponent={() => null}
            disablePortal
            openOnFocus={false}
            onInputChange={setInputValue}
            value={preSelectedValue}
          />
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ overflow: "auto", px: 2 }}>
        <Stack>
          {options.map((option) => (
            <ListItem sx={{ paddingX: 1 }} key={option.value}>
              <ListItemText
                children={option.name}
                onClick={() => setPreSelectedValue(option)}
              />
            </ListItem>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{ px: 2, pt: 1, width: "100%", justifyContent: "end" }}
      >
        <Stack width="100%">
          <Stack
            flexDirection="row"
            gap={2}
            justifyContent="space-between"
            mt={1.5}
          >
            <Button
              variant="outlined"
              sx={{
                flex: 1,
                color: palette.text.primary,
                borderColor: palette.text.primary,
              }}
              onClick={() => {
                resetChanges();
                menuState.hide();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ flex: 1 }}
              onClick={() => {
                onChangeWrapper();
                menuState.hide();
              }}
            >
              Apply
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
