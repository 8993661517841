import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { History } from "@mui/icons-material";
import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
} from "@mui/material";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";
import { accountsManagementActivityLogsRoute } from "src/pages/AccountsManagementActivityLogs/AccountsManagementActivityLogs.route";
import { getRoleFromUser } from "src/utils/useUserRoleList";
import { accountsManagementEditAccountRoute } from "../../AccountsManagementEditAccount.route";

type AccountsManagementItemMenuProps = {
  id?: string;
  onClose: () => void;
  showConvertAccountDialog: () => void;
} & Omit<MenuProps, "children">;

export const AccountsManagementItemMenu = ({
  id = "",
  showConvertAccountDialog,
  ...props
}: AccountsManagementItemMenuProps) => {
  const navigate = useNavigate();

  const { user } = useAuth0();
  const role = getRoleFromUser(user);
  const enableActivityLogs =
    role.includes("Admin") || role.includes("Account Manager");

  const accountUrl = accountsManagementEditAccountRoute.makeUrl({
    accountId: id,
  });
  const handleOpenConvertAccountDialog = () => {
    showConvertAccountDialog();
    props.onClose();
  };

  const handleEdit = () => {
    navigate(accountUrl);
  };

  const handleActivityLog = () => {
    props.onClose();

    navigate(accountsManagementActivityLogsRoute.makeUrl({ accountId: id }));
  };

  return (
    <Menu {...props}>
      <MenuItem onClick={handleEdit}>
        <ListItemIcon>
          <ModeEditIcon fontSize="small" color="primary" />
        </ListItemIcon>
        <ListItemText>Edit</ListItemText>
      </MenuItem>

      <MenuItem onClick={handleOpenConvertAccountDialog}>
        <ListItemIcon>
          <ChangeCircleIcon fontSize="small" color="primary" />
        </ListItemIcon>
        <ListItemText>Convert</ListItemText>
      </MenuItem>

      <TooltipBase
        width={181}
        textAlign="left"
        title={"Available to Admin and Account Manager only."}
        placement="right"
        disableHoverListener={enableActivityLogs}
      >
        <Box>
          <MenuItem onClick={handleActivityLog} disabled={!enableActivityLogs}>
            <ListItemIcon>
              <History fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>Activity Log</ListItemText>
          </MenuItem>
        </Box>
      </TooltipBase>
    </Menu>
  );
};
