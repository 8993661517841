import { SvgIcon, SvgIconProps } from "@mui/material";

export const ArchivedReportIcon = ({
  bgColor,
  ...props
}: SvgIconProps & { bgColor?: string }) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="8" fill={bgColor || "white"} />
        <path
          d="M14.5 9.5H9.5V14.5H14.5V9.5ZM12.8333 12.8333H11.1667V11.1667H12.8333V12.8333ZM19.5 11.1667V9.5H17.8333V7.83333C17.8333 6.91667 17.0833 6.16667 16.1667 6.16667H14.5V4.5H12.8333V6.16667H11.1667V4.5H9.5V6.16667H7.83333C6.91667 6.16667 6.16667 6.91667 6.16667 7.83333V9.5H4.5V11.1667H6.16667V12.8333H4.5V14.5H6.16667V16.1667C6.16667 17.0833 6.91667 17.8333 7.83333 17.8333H9.5V19.5H11.1667V17.8333H12.8333V19.5H14.5V17.8333H16.1667C17.0833 17.8333 17.8333 17.0833 17.8333 16.1667V14.5H19.5V12.8333H17.8333V11.1667H19.5ZM16.1667 16.1667H7.83333V7.83333H16.1667V16.1667Z"
          fill="#F57C00"
        />
      </svg>
    </SvgIcon>
  );
};
