import Joi from "joi";
import { USER_VALIDATION } from "src/pages/AccountsManagement/components/AccountsManagementAddUserForm/AccountsManagementAddUserForm.schema";
import { UserInformationSettingsFormValues } from "./UserInformationSettingsForm.model";

export const UserInformationSettingsFormSchema =
  Joi.object<UserInformationSettingsFormValues>({
    firstName: Joi.string()
      .required()
      .label("First name")
      .regex(USER_VALIDATION)
      .messages({
        "string.pattern.base": "Invalid first name pattern",
        "string.empty": "First name is required",
      }),
    lastName: Joi.string()
      .required()
      .label("Last name")
      .regex(USER_VALIDATION)
      .messages({
        "string.pattern.base": "Invalid last name pattern",
        "string.empty": "Last name is required",
      }),
    email: Joi.string()
      .required()
      .label("Email")
      .email({ tlds: { allow: false } }),
    phoneNumber: Joi.string().optional().allow("").label("Phone"),
    timezone: Joi.string().optional().label("Timezone").allow(""),
    trackLocation: Joi.boolean()
      .required()
      .label("Keep track of my current location"),
    account: Joi.string().label("Account"),
    roles: Joi.array().items(Joi.string().required()).required(),
  });
