import { LoaderFunction, RouteObject } from "react-router-dom";
import { makeAppRouteUrl } from "src/utils/makeAppRouteUrl";

/**
 * Defines app route builder used to type check app route parameters
 */
export class AppRouteBuilder<
  AppRoutePathParams extends void | Record<string, string | number> = void,
  AppRouteQueryParams extends void | Record<
    string,
    string | number | (string | number)[] | boolean | null
  > = void
> {
  readonly id: string;
  readonly path: string;
  readonly element: JSX.Element;
  readonly children?: RouteObject[];
  readonly hideAppBar: boolean;

  readonly loader?: LoaderFunction;

  makeUrl(path: AppRoutePathParams, query: AppRouteQueryParams) {
    return makeAppRouteUrl(this.path, { path, query });
  }

  constructor({
    id,
    path,
    element,
    children,
    hideAppBar,
    loader,
  }: {
    id?: string;
    path: string;
    element: JSX.Element;
    children?: RouteObject[];
    hideAppBar?: boolean;
    loader?: LoaderFunction;
  }) {
    this.id = id ?? path;
    this.path = path;
    this.element = element;
    this.children = children;
    this.hideAppBar = !!hideAppBar;
    this.loader = loader;
  }
}
