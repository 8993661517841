import { AppRouteBuilder } from "src/models/AppRoute";
import { PermissionGuard } from "src/components/PermissionGuard";
import { ACTIVITYLOGSROLES } from "src/utils/useUserRoleList";
import { UserManagementActivityLogPage } from "../UserManagementActivityLogs/UserManagementActivityLogs.page";

type UserManagementActivityLogsParams = {
  accountId: string;
  userId: string;
};

export const accountsManagementUserActivityLogsRoute =
  new AppRouteBuilder<UserManagementActivityLogsParams>({
    path: "/accounts-management/logs/:accountId/:userId",
    element: (
      <PermissionGuard
        component={UserManagementActivityLogPage}
        allowedRoles={ACTIVITYLOGSROLES}
        disableMobile
        redirectTo="/"
      />
    ),
  });
