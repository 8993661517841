import {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BooleanParam, useQueryParam } from "use-query-params";
import { GridRowSelectionModel } from "@mui/x-data-grid-premium";
import {
  Alert,
  alertClasses,
  Box,
  FormControlLabel,
  Paper,
  Snackbar,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DeleteOutline, LocalOfferOutlined } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { endOfDay, formatISO, startOfDay } from "date-fns";
import {
  DateSelector,
  DateSelectorMode,
} from "src/components/DateSelector/DateSelector";
import { DateRangePickerMobile } from "src/components/DateRangePickerMobile/DateRangePickerMobile";
import { PageLayoutMobile } from "src/components/PageLayoutMobile";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { SearchInput } from "src/components/SearchInput";
import { SortingMenu } from "src/components/SortingMenu/SortingMenu";
import { ViewModeSwitch } from "src/components/ViewModeSwitch/ViewModeSwitch";
import { MediaCenterFilterButton } from "src/components/MediaCenterFilterButton/MediaCenterFilterButton";
import { useEventSelectionMediaCenter } from "src/api/useEventSelectionMediaCenter";
import { MediaCenterItem, useMediaCenter } from "src/api/useMediaCenter";
import { useReportScratchAppendMedia } from "src/api/useReportScratchAppendMedia";
import { useEventsPreselected } from "src/api/useEventsPreselected";
import { useTagListData } from "src/api/useTagListData";
import { useMediaCenterTagList } from "src/api/useMediaCenterTagList";
import {
  useShareMediaFormSnackbar,
  useShareMediaLinkSnackbar,
} from "src/api/useShareMediaSnackbar";
import { useDownloadFilesSnackbar } from "src/api/useDownloadFilesSnackbar";
import { downloadMediaCenterMedia } from "src/api/useMediaCenterMedia";
import { useSessionContext } from "src/api/useSessionContext";
import { useReportCreateFromMedia } from "src/api/useReportCreateFromMedia";

import { useExportCsv } from "src/api/useExportCsv";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";
import {
  SortBy,
  SortOption,
  SortByParameters,
  OrderByParameters,
} from "src/components/SortingMenu/SortingMenu.model";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { PageHeaderDesktop } from "src/components/PageHeaderDesktop/PageHeaderDesktop";
import { AppTopNavBarMobile } from "src/components/AppTopNavBarMobile/AppTopNavBarMobile";
import { AllCheckboxSelector } from "src/components/AllCheckboxSelector/AllCheckboxSelector";
import { GlobalSearchDialog } from "src/components/GlobalSearchDialog/GlobalSearchDialog";
import { GlobalSearchDesktopButton } from "src/components/GlobalSearchDesktopButton/GlobalSearchDesktopButton";
import { SnackbarBase } from "src/components/SnackbarBase/SnackbarBase";
import { ShareMediaDialog } from "src/components/ShareMediaDialog/ShareMediaDialog";
import { ActionButtonsMobile } from "src/components/ActionButtonsMobile/ActionButtonsMobile";
import { ReportCreateDialog } from "src/components/ReportCreateDialog/ReportCreateDialog";
import { PreselectEventsButton } from "src/components/PreselectEventsButton/PreselectEventsButton";
import { ShareButtonDesktop } from "src/components/ShareButtonDesktop/ShareButtonDesktop";
import { ReportCreateFormValues } from "src/components/ReportCreateForm/ReportCreateForm.model";
import { DownloadFilesSnackbar } from "src/components/DownloadFilesSnackbar/DownloadFilesSnackbar";
import { useListSelectInfo } from "src/utils/useListSelectInfo";
import { useQueryPagination } from "src/utils/useQueryPagination";
import { useListFilterDeep } from "src/utils/useListFilterDeep";
import { countMediaCenterFilters } from "src/utils/countMediaCenterFilters";
import { useOpenState } from "src/utils/useOpenState";
import { useQueryDateRangeWithEmptyDefault } from "src/utils/useQueryDateRangeWithEmptyDefault";
import { useGlobalSearchOpen } from "src/api/useGlobalSearchOpen";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import { AssignNewTagModal } from "./components/AssignNewTagModal/AssignNewTagModal";
import { MediaCenterFiltersModal } from "./components/MediaCenterFiltersModal/MediaCenterFiltersModal";
import { MediaCenterResultExportButton } from "./components/MediaCenterResultExportButton";
import { MediaCenterTable } from "./components/MediaCenterTable/MediaCenterTable";
import { MediaCenterList } from "./components/MediaCenterList/MediaCenterList";
import { MediaCenterTiles } from "./components/MediaCenterTiles/MediaCenterTiles";
import { MediaCenterDeleteDialog } from "./components/MediaCenterDeleteDialog/MediaCenterDeleteDialog";
import { hashStringToColor } from "./MediaCenter.utils";
import { useMediaCenterFilter } from "./hooks/useMediaCenterFilter";

const viewModeOptions: {
  desktop: ViewMode[];
  mobile: ViewMode[];
} = {
  desktop: [ViewMode.table, ViewMode.list, ViewMode.tile],
  mobile: [ViewMode.tile, ViewMode.table],
};

const emptyList: MediaCenterItem[] = [];

export const MediaCenter = () => {
  const [sortBy, setSortBy] = useState<SortByParameters>("default");
  const [sortOption, setSortOption] = useState(SortBy.defaultDesc);
  const [orderBy, setOrderBy] = useState<OrderByParameters>("desc");

  const { t } = useTranslation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const watchQuerySearchDialog = useGlobalSearchOpen();

  const [filterQuery, setFilterQuery] = useMediaCenterFilter();
  const filterDialog = useOpenState();

  const [isExpandedTextMode, setExpandedTextMode] = useQueryParam(
    "expanded",
    BooleanParam
  );

  const [dateRange, setDateRange] = useQueryDateRangeWithEmptyDefault();

  const [pagination, setPagination] = useQueryPagination();
  const initialViewMode: ViewMode = isMobile ? ViewMode.tile : ViewMode.list;
  const tabParam = useQueryParam<ViewMode>("tab");
  const [viewMode = initialViewMode, setViewMode] = tabParam;

  const reportCreateDialog = useOpenState();
  const reportCreateSnackBar = useOpenState();
  const shareDialog = useOpenState();
  const shareFormSnackBar = useShareMediaFormSnackbar();
  const shareLinkSnackBar = useShareMediaLinkSnackbar();
  const downloadFilesSnackBar = useDownloadFilesSnackbar();

  const { list, selectAll, unselectAll } = useEventSelectionMediaCenter();

  const reportScratchAppend = useReportScratchAppendMedia({});
  const { userId, effectiveEntitlements } = useSessionContext();
  const enableMediaDownload = effectiveEntitlements.enableMediaDownloads?.value;

  const limitEventsInReport =
    effectiveEntitlements.limitEventsInReport?.value || 0;
  const disableCreateReport = list.length > limitEventsInReport;

  const downloadMediaItem = useCallback(
    (item: MediaCenterItem) => {
      downloadMediaCenterMedia({
        userId,
        itemId: item.id,
        title: item.title as string,
      });
    },
    [userId]
  );

  const downloadMedia = useCallback(() => {
    list.forEach(downloadMediaItem);
    unselectAll();
  }, [list, downloadMediaItem, unselectAll]);

  const isDownloadDisabled = list.length > 10;

  const { setHighlightedEventsIds, showTooltip } = useEventsPreselected();

  // const downloadPdfMutation = useExportPdf({
  //   options: {
  //     onSuccess: unselectAll,
  //   },
  // });
  // const downloadPdf = useCallback(() => {
  //   downloadFilesSnackBar.show();
  //   downloadPdfMutation.mutate({
  //     selectedEvents: list.map((mediaItem) => mediaItem.eventHighlights),
  //   });
  // }, [downloadPdfMutation, downloadFilesSnackBar, list]);

  const downloadCsvMutation = useExportCsv({
    options: {
      onSuccess: unselectAll,
    },
  });
  const downloadCsv = useCallback(() => {
    downloadFilesSnackBar.show();
    downloadCsvMutation.mutate({
      selectedEvents: list.map((mediaItem) => mediaItem.eventHighlights),
    });
  }, [downloadCsvMutation, downloadFilesSnackBar, list]);

  const addItemToReport = useCallback(
    (item: MediaCenterItem) => {
      reportScratchAppend.mutate({ itemIds: [item.id] });
      setHighlightedEventsIds([item.eventHighlights?.id as string]);
      enqueueSnackbar(
        `${item.title} was successfully pre selected and sent to the "Report pre selected" page, now you can create a new Report from it`,
        {
          variant: "success",
        }
      );
    },
    [reportScratchAppend, setHighlightedEventsIds]
  );

  const preselectEvents = useCallback(() => {
    const itemIds = list.map((item) => item.id);
    const eventsIds = list.map((item) => item.eventHighlights?.id);

    reportScratchAppend.mutate({ itemIds });
    setHighlightedEventsIds(eventsIds as string[]);
    showTooltip();
    unselectAll();
    enqueueSnackbar(
      'Events were successfully pre selected and sent to the "Report pre selected" page, now you can create a new Report from them',
      {
        variant: "success",
      }
    );
  }, [
    reportScratchAppend,
    list,
    showTooltip,
    unselectAll,
    setHighlightedEventsIds,
  ]);

  const selectedEventsIds = useMemo(
    () => list.map((event) => event.id),
    [list]
  );

  const reportCreateOptions = {
    onSuccess: () => {
      reportCreateDialog.hide();
      unselectAll();
      reportCreateSnackBar.show();
    },
  };

  const reportCreateMutation = useReportCreateFromMedia({
    options: reportCreateOptions,
  });

  const createReport = useCallback(
    (data: ReportCreateFormValues) => {
      reportCreateMutation.mutate({
        title: data.title,
        description: data.description,
        itemIds: selectedEventsIds,
      });
    },
    [reportCreateMutation, selectedEventsIds]
  );

  const tagsList = useMediaCenterTagList({});
  const tagsListData = useTagListData();

  useEffect(() => {
    const { data } = tagsList;
    const updatedData = (data || []).map((tag) => ({
      tagName: tag,
      color: hashStringToColor(tag),
    }));

    if (tagsListData.tags.length === updatedData.length) return;

    tagsListData.set(updatedData);
  }, [tagsList, tagsListData]);

  const mediaCenterResults = useMediaCenter({
    request: {
      params: {
        body: makeQueryDefinition(filterQuery),
        query: {
          from: pagination.page * pagination.pageSize,
          size: pagination.pageSize,
          startDateTime: dateRange[0]
            ? formatISO(startOfDay(dateRange[0]))
            : undefined,
          endDateTime: dateRange[1]
            ? formatISO(endOfDay(dateRange[1]))
            : undefined,
          sortby: sortBy,
          orderby: orderBy,
          tags: filterQuery.tags.map((item) => item.tagName).join(","),
        },
      },
    },
    options: {},
  });

  const [filterText, setFilterText] = useState("");
  const filterData = useListFilterDeep(
    mediaCenterResults.data?.results || emptyList,
    filterText,
    [
      "title",
      "itemType",
      "eventHighlights.eventType",
      "mediaType",
      "eventHighlights.source",
      "eventHighlights.market",
      "eventHighlights.country",
      "eventHighlights.highlights",
    ]
  );

  const totalRowCount = mediaCenterResults.data?.total || 0;

  const onRowSelectionModelChange = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => {
      const selectedEvents = filterData.filter((event) =>
        rowSelectionModel.includes(event.id)
      );
      selectAll(selectedEvents);
    },
    [filterData, selectAll]
  );

  const { isAllSelected, isPartiallySelected } = useListSelectInfo({
    list: filterData,
    selectedList: list,
  });

  const handleChangeAllCheckbox = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        selectAll(filterData);
      } else {
        unselectAll();
      }
    },
    [filterData, selectAll, unselectAll]
  );

  const allCheckboxSelector = useMemo(
    () =>
      [ViewMode.tile, ViewMode.list].includes(viewMode) && (
        <AllCheckboxSelector
          checked={isAllSelected || isPartiallySelected}
          isPartiallySelected={isPartiallySelected}
          onChange={handleChangeAllCheckbox}
          sx={{ ml: viewMode === ViewMode.tile ? "12px" : "28px" }}
        />
      ),
    [handleChangeAllCheckbox, isAllSelected, isPartiallySelected, viewMode]
  );

  const onSortChange = (sortBy: SortOption) => {
    setSortOption(sortBy.value);
    setSortBy(sortBy.field);
    setOrderBy(sortBy.order);
  };

  const showExportShareButtons = list.length > 0;
  const disableShareButton = list.length > limitEventsInReport;

  const copyShareLinkCallback = useCallback(() => {
    unselectAll();
    shareLinkSnackBar.show();
  }, [shareLinkSnackBar, unselectAll]);

  const handleShareDialogSubmit = useCallback(() => {
    unselectAll();
    shareFormSnackBar.show();
  }, [shareFormSnackBar, unselectAll]);

  const filterInput = useMemo(
    () => (
      <SearchInput
        placeholder="Search by text..."
        onTextChangeThrottled={setFilterText}
        sx={{ maxWidth: isMobile ? "100%" : 200 }}
      />
    ),
    [isMobile]
  );

  const dateSelector = useMemo(
    () => (
      <DateSelector
        value={dateRange}
        onChange={setDateRange}
        isMobile={isMobile}
        mode={DateSelectorMode.variable}
        sx={{ maxWidth: 416 }}
      />
    ),
    [dateRange, isMobile, setDateRange]
  );

  const viewModeSwitch = useMemo(
    () => (
      <ViewModeSwitch
        value={viewMode}
        onChange={setViewMode}
        options={isMobile ? viewModeOptions.mobile : viewModeOptions.desktop}
        size={isMobile ? "small" : "medium"}
      />
    ),
    [isMobile, setViewMode, viewMode]
  );

  const deleteButton = useMemo(() => {
    const idList = list.map((item) => item.id);
    const disabled = !list.length;

    return (
      <>
        <ToggleButtonGroup size={isMobile ? "small" : "medium"}>
          <ToggleButton
            disabled={disabled}
            onClick={() => setShowDeleteModal(true)}
            value={true}
          >
            <DeleteOutline
              sx={{
                color: disabled ? "text.disabled" : "text.primary",
              }}
            />
          </ToggleButton>
        </ToggleButtonGroup>
        <MediaCenterDeleteDialog
          open={showDeleteModal}
          value={idList}
          onClose={() => {
            setShowDeleteModal(false);
            unselectAll();
          }}
        />
      </>
    );
  }, [list, isMobile, showDeleteModal, unselectAll]);

  const addNewTagButton = useMemo(() => {
    const disabled = !list.length;

    return (
      <ToggleButtonGroup size={isMobile ? "small" : "medium"}>
        <ToggleButton
          disabled={disabled}
          onClick={() => setShowTagModal(true)}
          value={true}
        >
          <LocalOfferOutlined
            sx={{
              color: disabled ? "text.disabled" : "text.primary",
            }}
          />
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }, [list, isMobile]);

  const filterButton = useMemo(
    () => (
      <MediaCenterFilterButton
        value={filterQuery}
        onClick={filterDialog.show}
        isMobile={isMobile}
      />
    ),
    [filterDialog.show, filterQuery, isMobile]
  );

  const sortingMenu = useMemo(
    () => (
      <SortingMenu
        value={sortOption}
        onChange={onSortChange}
        size={isMobile ? "small" : "medium"}
        options={[
          SortBy.titleAsc,
          SortBy.titleDesc,
          SortBy.defaultAsc,
          SortBy.defaultDesc,
        ]}
      />
    ),
    [isMobile, sortOption]
  );

  const expandTextButton = useMemo(
    () =>
      viewMode === ViewMode.tile && (
        <FormControlLabel
          checked={!!isExpandedTextMode}
          control={
            <Switch
              onChange={(_e, checked) =>
                setExpandedTextMode(checked, "replaceIn")
              }
            />
          }
          label={<Typography variant="caption">Expanded Text</Typography>}
          sx={{ mx: 0 }}
        />
      ),
    [isExpandedTextMode, setExpandedTextMode, viewMode]
  );

  const mobileToolbar = useMemo(
    () => (
      <Stack
        direction="row"
        mb={1}
        gap={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row">
          {allCheckboxSelector}
          {filterButton}
        </Stack>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <DateRangePickerMobile
            iconButtonSize="small"
            value={dateRange}
            onChange={setDateRange}
          />
          {sortingMenu}
          {addNewTagButton}
          {deleteButton}
          {viewModeSwitch}
        </Stack>
      </Stack>
    ),
    [
      addNewTagButton,
      dateRange,
      deleteButton,
      allCheckboxSelector,
      filterButton,
      setDateRange,
      sortingMenu,
      viewModeSwitch,
    ]
  );

  const mobileHeader = useMemo(
    () => (
      <Stack>
        <Stack
          px={2}
          py={1}
          children={
            <AppTopNavBarMobile
              title="Media Center"
              hideBackButton
              toolbar={expandTextButton}
            />
          }
        />
        <Stack px={2} pb={2} children={mobileToolbar} />
        <Stack px={2} pb={2} children={filterInput} />
      </Stack>
    ),
    [expandTextButton, filterInput, mobileToolbar]
  );

  const desktopHeader = useMemo(
    () => (
      <Stack
        p={2}
        gap={2}
        direction={{ md: "column", lg: "row" }}
        justifyContent="space-between"
      >
        <Stack direction="row">
          {allCheckboxSelector}
          <Stack
            sx={{
              justifyContent: "flex-end",
              mr: 2,
            }}
          >
            {filterInput}
          </Stack>
          {dateSelector}
        </Stack>
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          {filterButton}
          {sortingMenu}
          {addNewTagButton}
          {deleteButton}
          {viewModeSwitch}
        </Stack>
      </Stack>
    ),
    [
      addNewTagButton,
      allCheckboxSelector,
      dateSelector,
      deleteButton,
      filterButton,
      filterInput,
      sortingMenu,
      viewModeSwitch,
    ]
  );

  const renderHeader = useCallback(() => {
    return mobileHeader;
  }, [mobileHeader]);

  const dataViewElement = useMemo(() => {
    const isLoading =
      mediaCenterResults.isLoading || mediaCenterResults.isFetching;
    //For podcast or youtube events
    const hideMarket = filterData?.every(
      (item) => item.mediaType === "podcast" || item.mediaType === "youtube"
    );

    const filtersCount =
      countMediaCenterFilters(filterQuery) || filterText.length;
    const hasFilters = filtersCount > 0;

    switch (viewMode) {
      case ViewMode.table:
        return (
          <MediaCenterTable
            id="media-center-table"
            rows={filterData}
            rowCount={totalRowCount}
            loading={isLoading}
            paginationMode="server"
            paginationModel={pagination}
            onPaginationModelChange={setPagination}
            isMobile={isMobile}
            checkboxSelection
            onRowSelectionModelChange={onRowSelectionModelChange}
            rowSelectionModel={selectedEventsIds}
            downloadMediaItem={downloadMediaItem}
            addItemToReport={addItemToReport}
            columnVisibilityModel={{
              market: !hideMarket,
            }}
            hasFilters={hasFilters}
          />
        );
      case ViewMode.list:
        return (
          <MediaCenterList
            id="media-center-list"
            rows={filterData}
            rowCount={totalRowCount}
            loading={isLoading}
            paginationModel={pagination}
            onPaginationModelChange={setPagination}
            downloadMediaItem={downloadMediaItem}
            addItemToReport={addItemToReport}
            hasFilters={hasFilters}
          />
        );
      case ViewMode.tile:
        return (
          <MediaCenterTiles
            id="media-center-tiles"
            filterText={filterText}
            lineClamp={isMobile && isExpandedTextMode ? 5 : 2}
            sortBy={sortBy}
            orderBy={orderBy}
            downloadMediaItem={downloadMediaItem}
            addItemToReport={addItemToReport}
            renderHeader={isMobile ? renderHeader : undefined}
            filterQuery={filterQuery}
            hasFilters={hasFilters}
          />
        );

      default:
        return null;
    }
  }, [
    mediaCenterResults,
    filterText,
    filterQuery,
    isExpandedTextMode,
    viewMode,
    filterData,
    isMobile,
    totalRowCount,
    pagination,
    setPagination,
    selectedEventsIds,
    onRowSelectionModelChange,
    sortBy,
    orderBy,
    downloadMediaItem,
    addItemToReport,
    renderHeader,
  ]);

  const reportContent = useMemo(() => {
    return (
      list.length > 0 && (
        <ReportCreateDialog
          dateRange={dateRange}
          open={reportCreateDialog.isOpen}
          onClose={reportCreateDialog.hide}
          createReport={createReport}
          isLoading={reportCreateMutation.isLoading}
        />
      )
    );
  }, [
    list,
    dateRange,
    reportCreateDialog.isOpen,
    reportCreateDialog.hide,
    createReport,
    reportCreateMutation.isLoading,
  ]);

  const assignNewTagModal = useMemo(() => {
    const value = list.map((item) => item.id);

    return (
      <AssignNewTagModal
        value={value}
        open={showTagModal}
        onClose={() => setShowTagModal(false)}
      />
    );
  }, [list, showTagModal]);

  const shareMediaDialog = useMemo(() => {
    return (
      list.length > 0 && (
        <ShareMediaDialog
          title={
            list.length > 1
              ? "Share Media Center Items"
              : "Share Media Center Item"
          }
          itemsIds={list.map((item) => item.id)}
          open={shareDialog.isOpen}
          onClose={shareDialog.hide}
          onSubmit={handleShareDialogSubmit}
          copyShareLinkCallback={copyShareLinkCallback}
        />
      )
    );
  }, [
    copyShareLinkCallback,
    handleShareDialogSubmit,
    list,
    shareDialog.hide,
    shareDialog.isOpen,
  ]);

  const commonContent = (
    <Fragment>
      <GlobalSearchDialog
        open={watchQuerySearchDialog.isOpen}
        onClose={watchQuerySearchDialog.hide}
      />
      {assignNewTagModal}
      {shareMediaDialog}
      <MediaCenterFiltersModal
        mobileFullHeight={isMobile}
        closeOnLeftSide={isMobile}
        title="Event filter"
        open={filterDialog.isOpen}
        initial={filterQuery}
        onClose={filterDialog.hide}
        onSubmit={setFilterQuery}
      />
      {reportContent}
      <Snackbar
        open={shareLinkSnackBar.isOpen}
        autoHideDuration={5000}
        onClose={shareLinkSnackBar.hide}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="success"
          variant="filled"
          onClose={shareLinkSnackBar.hide}
          sx={{
            borderRadius: "4px",
            alignItems: "center",
            [`.${alertClasses.action}`]: {
              pt: 0,
            },
          }}
        >
          The link to the Media Center Item was successfully copied to the
          clipboard
        </Alert>
      </Snackbar>
      <SnackbarBase
        open={shareFormSnackBar.isOpen}
        onClose={shareFormSnackBar.hide}
        title="Your share is being processed."
        subtitle="You and your recipients will get an email."
      />
      <SnackbarBase
        open={reportCreateSnackBar.isOpen}
        onClose={reportCreateSnackBar.hide}
        title="Your Report is being processed."
        subtitle={
          <>
            It may take some time depending on the amount of items in the
            report.
            <br />
            Finished report appears in your Reports page and your Email
          </>
        }
      />
      <DownloadFilesSnackbar
        open={downloadFilesSnackBar.isOpen}
        onClose={downloadFilesSnackBar.hide}
      />
    </Fragment>
  );

  const mobileContent = (
    <Fragment>
      <Stack height="100%" overflow="hidden">
        {viewMode === ViewMode.tile
          ? null /* in tile mode header is handled by the scroll container */
          : mobileHeader}
        {dataViewElement}
      </Stack>
      {showExportShareButtons && (
        <ActionButtonsMobile
          showShareDialog={shareDialog.show}
          showReportDialog={reportCreateDialog.show}
          preselectEvents={preselectEvents}
          selectedEvents={list}
          selectedEventsLength={list.length}
          mediaCenterProps={{ downloadMedia, isDownloadDisabled }}
          disableShare={disableShareButton}
          limitEventsInReport={limitEventsInReport}
          // downloadPdf={downloadPdf}
          downloadCsv={downloadCsv}
        />
      )}
    </Fragment>
  );

  const headerDesktop = (
    <PageHeaderDesktop
      title="Media Center"
      hideBackButton
      hideBreadcrumbs
      toolbar={
        <Stack direction="row" spacing={2}>
          <GlobalSearchDesktopButton />
          <PreselectEventsButton
            badgeCounter={list.length}
            disabled={!showExportShareButtons}
            onClick={preselectEvents}
          />
          <MediaCenterResultExportButton
            sx={{
              minWidth: { xs: 120, md: 160 },
            }}
            createReport={reportCreateDialog.show}
            downloadMedia={downloadMedia}
            downloadCsv={downloadCsv}
            isDownloadDisabled={isDownloadDisabled}
            disabled={!list.length}
            enableMediaDownload={enableMediaDownload}
            disableCreateReport={disableCreateReport}
            limitEventsInReport={limitEventsInReport}
          />
          <TooltipBase
            title={t("limitEventsInReport", {
              limit: `${limitEventsInReport}`,
            })}
            disableHoverListener={!disableShareButton}
          >
            <Box>
              <ShareButtonDesktop
                disabled={!list.length || disableShareButton}
                showShareDialog={shareDialog.show}
                badgeContent={list.length}
              />
            </Box>
          </TooltipBase>
        </Stack>
      }
    />
  );

  const desktopContent = (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        height: "100%",
      }}
    >
      {desktopHeader}
      {dataViewElement}
    </Paper>
  );

  if (isMobile) {
    return (
      <Fragment>
        <PageLayoutMobile content={mobileContent} />
        {commonContent}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <PageLayoutDesktop
        header={headerDesktop}
        content={desktopContent}
        overflow="hidden"
      />
      {commonContent}
    </Fragment>
  );
};
