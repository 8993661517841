import { useRef } from "react";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { AdvertisementPicker } from "src/components/AdvertisementPicker/AdvertisementPicker";
import { ProgramTagPicker } from "src/components/ProgramTagPicker/ProgramTagPicker";
import { WatchQueryKeywordFormValues } from "src/components/WatchQueryKeywordForm/WatchQueryKeywordForm.model";
import { WatchQueryCustomFormValues } from "src/components/WatchQueryCustomForm/WatchQueryCustomForm.model";
import { queryAdvertisementValues } from "src/models/Advertisement";
import { AdvertisementFilterNotice } from "src/components/AdvertisementPicker/AdvertisementFilterNotice";

export const MoreOptionsSection = ({ loading }: { loading?: boolean }) => {
  const { breakpoints, palette } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down("md"));
  const accordionPaddingValue = isSmallScreen ? 0 : 3;

  const { formState, control } = useFormContext<
    WatchQueryKeywordFormValues | WatchQueryCustomFormValues
  >();
  const { isSubmitting } = formState;
  const isBusy = isSubmitting || loading;

  const moreOptionsSection = useRef<null | HTMLDivElement>(null);
  const scrollToMoreOptionsSection = () => {
    setTimeout(() => {
      if (moreOptionsSection.current && isSmallScreen) {
        moreOptionsSection.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    }, 250);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        minHeight: "68px",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: isSmallScreen ? 0 : "8px",
      }}
    >
      <Accordion
        sx={{
          pl: accordionPaddingValue,
          pr: accordionPaddingValue,
          width: "100%",
        }}
        ref={moreOptionsSection}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ minHeight: "68px", background: "transparent !important" }}
          onClick={scrollToMoreOptionsSection}
        >
          <Typography color={palette.primary.main}>More options</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: palette.background.paper,
          }}
        >
          <Grid container spacing={1.5} mb={2}>
            {/* Advertisement */}
            <Grid item xs={12}>
              <Typography
                variant={isSmallScreen ? "body2" : "subtitle1"}
                fontWeight={600}
                display="flex"
                alignItems="center"
                height={36.5}
              >
                Advertisement
                <span data-intercom-advertisement-id="advertisement-id" />
              </Typography>
            </Grid>

            <Grid item xs={12} mb={isSmallScreen ? 0 : 3}>
              <Controller
                name="advertisement"
                control={control}
                render={({ field }) => {
                  return (
                    <Stack gap={3}>
                      <Stack width={!isSmallScreen ? 483 : undefined}>
                        <AdvertisementPicker
                          fullWidth
                          disabled={loading}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                        />
                      </Stack>
                      {field.value === queryAdvertisementValues.none && (
                        <AdvertisementFilterNotice />
                      )}
                    </Stack>
                  );
                }}
              />
            </Grid>
          </Grid>
          {/* Advertisement */}

          {/* Program title */}
          <Grid container spacing={1.5} mb={2} mt={2}>
            <Grid item xs={12} md={12} lg={12}>
              {isSmallScreen ? (
                <Typography
                  variant="body2"
                  fontWeight={600}
                  lineHeight="24px"
                  mb={1.5}
                >
                  Program Title
                </Typography>
              ) : (
                <Typography variant="subtitle1">Program Title</Typography>
              )}
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Controller
                name="programInclude"
                control={control}
                render={({ field, fieldState, formState }) => {
                  const { list, logic } = formState.errors[field.name] || {};
                  const message = list?.message || logic?.message || " ";

                  return (
                    <ProgramTagPicker
                      id="program-include-input"
                      label="Include"
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      getOptionLabel={(o) => o.name}
                      disabled={isBusy}
                      error={!!fieldState.error}
                      helperText={message}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Controller
                name="programExclude"
                control={control}
                render={({ field, fieldState, formState }) => {
                  const { list, logic } = formState.errors[field.name] || {};
                  const message = list?.message || logic?.message || " ";

                  return (
                    <ProgramTagPicker
                      id="program-exclude-input"
                      label="Exclude"
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      getOptionLabel={(o) => o.name}
                      disabled={loading}
                      error={!!fieldState.error}
                      helperText={message}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          {/* Program title */}
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};
