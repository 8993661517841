import { SourcesPickerValue } from "src/components/SourcesPicker/SourcesPicker.model";
import { ContentAccessFilter } from "src/models/ContentAccessFilter";
import { makeSourceCategoryValues } from "./makeSourceCategoryValues";

export function makeContentFilterQuery(
  includeData?: SourcesPickerValue,
  excludeData?: SourcesPickerValue
): ContentAccessFilter {
  const sourceIncludeCategoryIds = includeData?.list
    ? makeSourceCategoryValues(includeData.list)
    : null;
  const sourceExcludeCategoryIds = excludeData?.list
    ? makeSourceCategoryValues(excludeData.list)
    : null;

  return {
    podcastSources: {
      included: sourceIncludeCategoryIds?.podcastSources || [],
      excluded: sourceExcludeCategoryIds?.podcastSources || [],
    },
    contentTypes: {
      included: sourceIncludeCategoryIds?.eventTypeList || [],
      excluded: sourceExcludeCategoryIds?.eventTypeList || [],
    },
    countries: {
      included: sourceIncludeCategoryIds?.countryList || [],
      excluded: sourceExcludeCategoryIds?.countryList || [],
    },
    states: {
      included: sourceIncludeCategoryIds?.stateList || [],
      excluded: sourceExcludeCategoryIds?.stateList || [],
    },
    markets: {
      included: sourceIncludeCategoryIds?.marketList || [],
      excluded: sourceExcludeCategoryIds?.marketList || [],
    },
    stations: {
      included: sourceIncludeCategoryIds?.stationList || [],
      excluded: sourceExcludeCategoryIds?.stationList || [],
    },
  };
}
