import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { enqueueSnackbar } from "notistack";
import { UseMutationOptions, useMutation } from "react-query";

const apiKey: keyof paths = "/api/editor/bulk-order";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;

type RequestBody = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

type RequestMutationFnParams = {
  query: RequestBody;
};

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError" | "onSettled"
>;

export function useClipsSave({ options }: { options?: RequestOptions }) {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async ({ query }) => {
      const token = await getAccessTokenSilently();

      const url = apiKey;
      const body: RequestBody = query;

      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });

      return {
        id: crypto.randomUUID(),
        ...data,
      };
    },
    onSuccess: (data, variables, context) => {
      enqueueSnackbar(`
        Your clips are being prepared. You will receive an email pointing you to the clip location shortly. Thank You`);

      options?.onSuccess && options.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });

      options?.onError && options.onError(error, variables, context);
    },
  });
}
