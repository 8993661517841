import { useCallback, useEffect, useRef, useState } from "react";
import {
  BlockerFunction,
  useBlocker,
  useNavigate,
  useParams,
} from "react-router-dom";
import { FormProvider } from "react-hook-form";
import { useAuth0 } from "@auth0/auth0-react";
import { Close } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import { useWatchQuery } from "src/api/useWatchQuery";
import { useWatchQueryUpdate } from "src/api/useWatchQueryUpdate";
import { useWatchTermSharedForm } from "src/api/useWatchTermSharedForm";
import { useReadOnlySnackbar } from "src/api/usReadOnlySnackbar";
import { WatchQueryKeywordFormValues } from "src/components/WatchQueryKeywordForm/WatchQueryKeywordForm.model";
import { WatchQueryCustomFormValues } from "src/components/WatchQueryCustomForm/WatchQueryCustomForm.model";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { PageLayoutMobile } from "src/components/PageLayoutMobile";
import { AlertType } from "src/models/AlertType";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import {
  makeWatchQueryCustomFormValues,
  makeWatchQueryKeywordFormValues,
  makeWatchQuerySharedFormValues,
} from "src/utils/makeWatchQueryFormValues";
import { ScrollBox } from "src/components/ScrollBox/ScrollBox";
import { AppTabLayout } from "src/components/AppTabLayout/AppTabLayout";
import { AppTab } from "src/components/AppTabLayout/AppTab";
import { SwitchToSimpleModeModal } from "src/components/SwitchToSimpleModeModal/SwitchToSimpleModeModal";
import { watchListRoute } from "src/pages/WatchList/WatchList.route";
import { useWatchListCreateForms } from "src/pages/WatchListCreate/WatchListCreateForm.hook";
import { WatchListCreateKeywordForm } from "src/components/WatchListCreateKeywordForm/WatchListCreateKeywordForm";
import { WatchListCreateCustomForm } from "src/components/WatchListCreateCustomForm/WatchListCreateCustomForm";
import { NotificationSection } from "src/pages/WatchListCreate/components/NotificationSection";
import { MoreOptionsSection } from "src/pages/WatchListCreate/components/MoreOptionsSection";
import { WatchListCreateCustomFormWrapper } from "src/components/WatchListCreateCustomForm/WatchListCreateCustomFormWrapper";
import { WatchListCreateKeywordFormWrapper } from "src/components/WatchListCreateKeywordForm/WatchListCreateKeywordFormWrapper";
import { CustomQueryReadonlySnackbar } from "src/components/CustomQueryReadonlySnackbar/CustomQueryReadonlySnackbar";
import { PageHeaderDesktop } from "src/components/PageHeaderDesktop/PageHeaderDesktop";

enum Tabs {
  simpleMode = "simpleMode",
  advancedMode = "advancedMode",
}

type WatchQueryFormValues =
  | WatchQueryKeywordFormValues
  | WatchQueryCustomFormValues;

export const WatchListUpdatePage = () => {
  const { breakpoints, palette } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down("lg"));
  const { user } = useAuth0();
  const { queryId = "" } = useParams();
  const currentQueryData = useWatchQuery({
    path: {
      queryId,
    },
  });

  const isCustomQueryForm =
    !!currentQueryData?.data?.queryBuilderDefinition.customQuery;

  const dropDownBoundsEl = useRef<null | HTMLDivElement>(null);

  const [isInstantAlertActive, setIsInstantAlertActive] = useState(false);
  const [scheduledAlerts, setScheduledAlerts] = useState<AlertType[] | null>(
    null
  );
  const [hasNotificationUpdate, setHasNotificationUpdate] = useState(false);
  const [hasConvertedCustomQuery, setHasConvertedCustomQuery] = useState(false);

  const readOnlySnackbar = useReadOnlySnackbar();

  const currentQueryTitle = currentQueryData.data?.title || "Loading...";

  const watchQueryUpdate = useWatchQueryUpdate({});
  const { isSuccess, isLoading } = watchQueryUpdate;

  const [tabMode, setTabMode] = useViewModeQueryParam<Tabs>({
    paramKey: "tab",
    defaultValue: Tabs.simpleMode,
  });

  const [tabWasSet, updateTabWasSet] = useState(false);

  const {
    keyWordFormHook,
    customQueryFormHook,
    formState,
    handleSubmit,
    resetCustomQueryValues,
    isValid,
  } = useWatchListCreateForms();

  const { isDirty } = formState;

  const shouldBlockLeaving = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      if (isSmallScreen) {
        return false;
      }

      const { watch } = customQueryFormHook;
      return (
        !!watch().customQuery &&
        nextLocation.search.includes(Tabs.simpleMode) &&
        currentLocation.search !== nextLocation.search
      );
    },
    [isSmallScreen, customQueryFormHook]
  );
  const blocker = useBlocker(shouldBlockLeaving);

  const switchToSimpleMode = useCallback(() => {
    resetCustomQueryValues();
    blocker.proceed?.();
  }, [blocker, resetCustomQueryValues]);

  const { createCustomQueryFromKeyword } = keyWordFormHook;
  const isKeywordForm =
    !currentQueryData?.data?.queryBuilderDefinition.customQuery;

  const onModeChange = useCallback(
    (tab: Tabs) => {
      if (tab === Tabs.advancedMode && isKeywordForm) {
        createCustomQueryFromKeyword();
        setHasConvertedCustomQuery(true);
      }
      setTabMode(tab);
    },
    [createCustomQueryFromKeyword, setTabMode, isKeywordForm]
  );

  const { saveSharedValues, clearSharedValues } = useWatchTermSharedForm();

  useEffect(() => {
    if (!currentQueryData.data) return;
    const dataSharedValues = makeWatchQuerySharedFormValues(
      currentQueryData.data
    );
    saveSharedValues(dataSharedValues);
  }, [currentQueryData.data, saveSharedValues]);

  useEffect(() => {
    return clearSharedValues;
  }, [clearSharedValues]);

  const resetKeywordForm = keyWordFormHook.reset;
  const resetCustomForm = customQueryFormHook.reset;

  useEffect(() => {
    if (!currentQueryData.data || tabWasSet) return;

    let initialFormValues;
    if (isCustomQueryForm) {
      initialFormValues = makeWatchQueryCustomFormValues(currentQueryData.data);
      setTabMode(Tabs.advancedMode);
      resetCustomForm(initialFormValues);
    } else {
      initialFormValues = makeWatchQueryKeywordFormValues(
        currentQueryData.data
      );
      setTabMode(Tabs.simpleMode);
      resetKeywordForm(initialFormValues);
    }

    updateTabWasSet(true);

    const instantAlert =
      currentQueryData.data?.alerts.filter(
        (alert) => alert.type === "realtime"
      ) || [];
    const hasInstantAlert = instantAlert.length > 0;
    setIsInstantAlertActive(hasInstantAlert);
    setScheduledAlerts(currentQueryData.data.alerts);
  }, [
    currentQueryData.data,
    isCustomQueryForm,
    resetKeywordForm,
    resetCustomForm,
    setTabMode,
    tabWasSet,
  ]);

  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      navigate(watchListRoute.path);
    }
  }, [navigate, isSuccess]);

  const goBack = () => {
    navigate(-1);
    readOnlySnackbar.hide();
  };

  useEffect(() => {
    if (scheduledAlerts && currentQueryData.data?.alerts) {
      const alerts = currentQueryData.data.alerts;
      const hasAlertChanges = !isEqual(alerts, scheduledAlerts);
      setHasNotificationUpdate(hasAlertChanges);
    }
  }, [scheduledAlerts, currentQueryData?.data?.alerts]);

  const toggleInstantAlert = () => {
    setIsInstantAlertActive((prevState) => {
      if (prevState) {
        const updatedAlerts =
          currentQueryData.data?.alerts.filter(
            (alert) => alert.type !== "realtime"
          ) || [];
        setScheduledAlerts(updatedAlerts);
      } else {
        const newInstantAlert = {
          id: "00000000-0000-0000-0000-000000000000",
          type: "realtime",
          archive: false,
          destination: user?.email,
          schedule: [],
          enabled: true,
          muted: false,
        };
        if (scheduledAlerts) {
          setScheduledAlerts([...scheduledAlerts, newInstantAlert]);
        } else {
          setScheduledAlerts([newInstantAlert]);
        }
      }
      return !prevState;
    });
  };

  const scheduledAlertCreateHandler = (
    newValue: AlertType | AlertType[],
    action?: "delete" | "edit"
  ) => {
    if (Array.isArray(newValue)) {
      return setScheduledAlerts(newValue);
    }

    if (!action) {
      return setScheduledAlerts((v) => {
        const prevValue = v ?? [];
        return [...prevValue, newValue];
      });
    }

    setScheduledAlerts((v) => {
      if (action === "delete") {
        return v?.filter((val) => val.id !== newValue.id) ?? [];
      }

      const updatedAlerts =
        v?.map((item) => (item.id === newValue.id ? newValue : item)) ?? [];
      return updatedAlerts;
    });
  };

  const submitWatchTerm = handleSubmit(
    (data: WatchQueryFormValues) => {
      if (!currentQueryData.data) return;

      const query = makeQueryDefinition(data);
      const initial = currentQueryData.data;

      watchQueryUpdate.mutate({
        id: initial.id,
        title: data.displayName,
        userId: initial.userId,
        sort: initial.sort,
        alerts: scheduledAlerts || [],
        queryBuilderDefinition: query,
      });
    },
    (errors) => {
      console.log("@@ DEBUG:submitWatchQueryUpdate:error", errors);
    }
  );

  const formWasChanged =
    isDirty ||
    hasNotificationUpdate ||
    (tabMode === Tabs.advancedMode && hasConvertedCustomQuery);

  const editTermButton = (
    <Button
      variant={!isSmallScreen ? "contained" : "text"}
      color="primary"
      sx={
        isSmallScreen
          ? { fontSize: "16px" }
          : { height: "42px", width: "196px", fontSize: "15px" }
      }
      disabled={
        !formWasChanged || !isValid || isLoading || !isEmpty(formState.errors)
      }
      onClick={submitWatchTerm}
    >
      {!isSmallScreen ? "Save changes" : "Save"}
    </Button>
  );

  const notificationSection = (
    <NotificationSection
      isInstantAlertActive={isInstantAlertActive}
      toggleInstantAlert={toggleInstantAlert}
      scheduledAlerts={scheduledAlerts}
      scheduledAlertCreateHandler={scheduledAlertCreateHandler}
      defaultAlertId
    />
  );

  const moreOptionsSection =
    tabMode === Tabs.simpleMode ? (
      <FormProvider {...keyWordFormHook}>
        <WatchListCreateKeywordFormWrapper>
          <MoreOptionsSection key="simpleMode" loading={isLoading} />
        </WatchListCreateKeywordFormWrapper>
      </FormProvider>
    ) : (
      <FormProvider {...customQueryFormHook}>
        <WatchListCreateCustomFormWrapper>
          <MoreOptionsSection key="customMode" loading={isLoading} />
        </WatchListCreateCustomFormWrapper>
      </FormProvider>
    );

  const pageHeaderMobile = (
    <Paper>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
        height={56}
        marginLeft={1.5}
        marginRight={1.5}
      >
        <Stack flexDirection="row" alignItems="center" gap={4}>
          <IconButton edge="end" onClick={goBack}>
            <Close sx={{ color: palette.text.primary }} />
          </IconButton>
          <Typography variant="subtitle1">Edit Watch Term</Typography>
        </Stack>
        {editTermButton}
      </Stack>
    </Paper>
  );

  if (isSmallScreen) {
    return (
      <PageLayoutMobile
        hideTopBar
        content={
          <>
            {pageHeaderMobile}
            <ScrollBox
              customScrollBarStyle="hidden"
              pt={2}
              sx={{ backgroundColor: palette.background.default }}
            >
              {isCustomQueryForm ? (
                <Stack component={Paper} borderRadius={0}>
                  <AppTabLayout
                    id="tabs-mode"
                    flex={1}
                    overflow="hidden"
                    variant="fullWidth"
                    currentTab={tabMode}
                    onCurrentTabChange={(tab) => onModeChange(tab as Tabs)}
                  >
                    <AppTab
                      disabled
                      label="Simple mode"
                      value={Tabs.simpleMode}
                      children={
                        <FormProvider {...keyWordFormHook}>
                          <WatchListCreateKeywordFormWrapper>
                            <WatchListCreateKeywordForm
                              loading={isLoading}
                              dropDownBoundsEl={dropDownBoundsEl}
                            />
                          </WatchListCreateKeywordFormWrapper>
                        </FormProvider>
                      }
                    />
                    <AppTab
                      label="Advanced mode"
                      value={Tabs.advancedMode}
                      children={
                        <FormProvider {...customQueryFormHook}>
                          <WatchListCreateCustomFormWrapper>
                            <WatchListCreateCustomForm
                              loading={isLoading}
                              dropDownBoundsEl={dropDownBoundsEl}
                            />
                          </WatchListCreateCustomFormWrapper>
                        </FormProvider>
                      }
                    />
                  </AppTabLayout>
                </Stack>
              ) : (
                <FormProvider {...keyWordFormHook}>
                  <WatchListCreateKeywordFormWrapper>
                    <WatchListCreateKeywordForm
                      loading={isLoading}
                      dropDownBoundsEl={dropDownBoundsEl}
                    />
                  </WatchListCreateKeywordFormWrapper>
                </FormProvider>
              )}

              <Paper
                sx={{
                  borderRadius: 0,
                  pt: 2,
                  mb: 2,
                }}
              >
                {notificationSection}
              </Paper>
              {moreOptionsSection}
            </ScrollBox>
            <CustomQueryReadonlySnackbar />
          </>
        }
      />
    );
  }

  const pageHeaderDesktop = (
    <PageHeaderDesktop
      title="Edit Watch Term"
      hidePenultimateRoute
      hideBackButton
      breadcrumbLabels={{
        0: currentQueryTitle,
      }}
      toolbar={
        <Stack direction="row" spacing={2} height="100%" alignItems="flex-end">
          <Button
            variant="outlined"
            sx={{ height: "42px", width: "196px", fontSize: "15px" }}
            onClick={goBack}
            children="Cancel"
          />
          {editTermButton}
        </Stack>
      }
    />
  );

  const desktopContent = (
    <Stack gap={3} flexDirection="column" id="watch-query-create-form-root">
      <Stack
        /* dimension element for dropdowns */
        ml={4}
        mr={3}
        border="1px solid red"
        visibility="hidden"
        ref={dropDownBoundsEl}
      />

      <Stack flexDirection="row" width="100%" gap={3}>
        <Stack
          component={Paper}
          borderRadius={0}
          flexGrow={1}
          width="calc(100% - 367px)"
        >
          <AppTabLayout
            id="tabs-mode"
            flex={1}
            overflow="hidden"
            variant="standard"
            currentTab={tabMode}
            onCurrentTabChange={(tab) => onModeChange(tab as Tabs)}
          >
            <AppTab
              label="Simple mode"
              value={Tabs.simpleMode}
              children={
                <FormProvider {...keyWordFormHook}>
                  <WatchListCreateKeywordFormWrapper>
                    <WatchListCreateKeywordForm
                      loading={isLoading}
                      dropDownBoundsEl={dropDownBoundsEl}
                    />
                  </WatchListCreateKeywordFormWrapper>
                </FormProvider>
              }
            />
            <AppTab
              label={
                <Stack direction="row">
                  <Stack>Advanced mode</Stack>
                  <Stack
                    width="8px"
                    data-intercom-advanced-tab-id="intercom-advanced-tab"
                  />
                </Stack>
              }
              value={Tabs.advancedMode}
              children={
                <FormProvider {...customQueryFormHook}>
                  <WatchListCreateCustomFormWrapper>
                    <WatchListCreateCustomForm
                      loading={isLoading}
                      dropDownBoundsEl={dropDownBoundsEl}
                    />
                  </WatchListCreateCustomFormWrapper>
                </FormProvider>
              }
            />
          </AppTabLayout>
        </Stack>
        <Paper
          sx={{
            width: "343px",
            minWidth: "343px",
            minHeight: "346px",
            p: 2,
            pt: 3,
            borderRadius: 2,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          {notificationSection}
        </Paper>
      </Stack>

      {moreOptionsSection}

      {blocker && (
        <SwitchToSimpleModeModal
          open={blocker.state === "blocked"}
          onClose={() => blocker.reset?.()}
          title={"Switch to Simple Mode?"}
          onSwitch={switchToSimpleMode}
        />
      )}
    </Stack>
  );

  return (
    <PageLayoutDesktop
      content={
        <Stack height="100%">
          {pageHeaderDesktop}
          {desktopContent}
        </Stack>
      }
    />
  );
};
