import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  DownloadReportFormat,
  downloadLimitTitle,
} from "src/api/useReportExport";
import { useSessionContext } from "src/api/useSessionContext";
import DropDownButton from "src/components/DropDownButton/DropDownButton";
import { DropDownButtonProps } from "src/components/DropDownButton/DropDownButton.model";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";
import { DownloadPDF } from "src/components/icons/DownloadPDF";
import { DownloadCSV } from "src/components/icons/DownloadCSV";

type ReportsExportButtonProps = Omit<DropDownButtonProps, "renderMenu"> & {
  exportReports: (format: DownloadReportFormat) => void;
  isDownloadDisabled: boolean;
};

export function ReportsExportButton({
  exportReports,
  isDownloadDisabled,
  ...props
}: ReportsExportButtonProps) {
  const { palette } = useTheme();

  const { t } = useTranslation();
  const { effectiveEntitlements } = useSessionContext();
  const enableReportDownloads =
    effectiveEntitlements.enableReportDownloads?.value;

  return (
    <DropDownButton
      {...props}
      renderMenu={(menuProps, closeMenu) => (
        <Menu {...menuProps}>
          <TooltipBase
            width={190}
            textAlign="left"
            title={
              enableReportDownloads
                ? downloadLimitTitle
                : t("reportDownloadsDisabled")
            }
            placement="right"
            disableHoverListener={enableReportDownloads && !isDownloadDisabled}
          >
            <Box>
              <MenuItem
                disabled={isDownloadDisabled || !enableReportDownloads}
                onClick={() => {
                  exportReports(DownloadReportFormat.pdf);
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <DownloadPDF />
                </ListItemIcon>
                <ListItemText>Download PDF</ListItemText>
              </MenuItem>
            </Box>
          </TooltipBase>

          <TooltipBase
            width={190}
            textAlign="left"
            title={
              enableReportDownloads
                ? downloadLimitTitle
                : t("reportDownloadsDisabled")
            }
            placement="right"
            disableHoverListener={enableReportDownloads && !isDownloadDisabled}
          >
            <Box>
              <MenuItem
                disabled={isDownloadDisabled || !enableReportDownloads}
                onClick={() => {
                  exportReports(DownloadReportFormat.csv);
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <DownloadCSV
                    labelColor={
                      palette.mode === "light"
                        ? palette.common.white
                        : palette.primary.contrastText
                    }
                  />
                </ListItemIcon>
                <ListItemText>Download CSV</ListItemText>
              </MenuItem>
            </Box>
          </TooltipBase>
        </Menu>
      )}
    >
      Export
    </DropDownButton>
  );
}
