import { Dispatch, SetStateAction } from "react";
import {
  Autocomplete,
  Button,
  Chip,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
  outlinedInputClasses,
} from "@mui/material";
import { ExpandLess } from "@mui/icons-material";
import {
  TagPickerLogic,
  TagPickerProps,
  TagPickerValue,
} from "src/components/TagPicker/TagPicker.model";
import { OptionCycler } from "src/components/OptionCycler/OptionCycler";
import { TextInputBase } from "src/components/TextInputBase";
import { AutocompleteInlineCountBadge } from "src/components/AutocompleteInline/AutocompleteInlineCountBadge";
import { EventSourceOption } from "src/api/useEventSources";
import { OpenedSectionType } from "./hooks/useTagPickerMobileSectionState";

type ModalTitleSectionProps<T> = TagPickerProps<T> & {
  tags: TagPickerValue<T>;
  setTags: Dispatch<SetStateAction<TagPickerValue<T>>>;
  isExpandAllOpen: boolean;
  setIsExpandAllOpen: Dispatch<SetStateAction<boolean>>;
  setOpenedSection: Dispatch<SetStateAction<OpenedSectionType>>;
  setInputValue: (v: string) => void;
};

export function ModalTitleSection({
  label,
  isExpandAllOpen,
  setIsExpandAllOpen,
  setOpenedSection,
  setTags,
  setInputValue,
  getOptionLabel,
  placeholder,
  helperText,
  error,
  dropDownBoundsEl,
  dropDownWidth,
  tags,
  value,
  onChange,
  inputValue,
  options,
  hideOperator,
  renderStartAdornment,
  clearOnBlur,
  ...props
}: ModalTitleSectionProps<EventSourceOption>) {
  const { transitions } = useTheme();

  const renderTotalCountAdornment = () => {
    if (tags.list.length < 2) {
      return null;
    }

    return (
      <>
        <AutocompleteInlineCountBadge count={tags.list.length} />
        {tags.list.length > 1 && !hideOperator && (
          <OptionCycler<TagPickerLogic>
            onChange={(logic) => onChange({ list: tags.list, logic })}
            value={tags.logic}
            getOptionLabel={(opt) => opt.toUpperCase()}
            options={["and", "or"]}
          />
        )}
      </>
    );
  };

  const icon = (
    <ExpandLess
      color="primary"
      sx={{
        width: 24,
        height: 24,
        marginRight: 0,
        rotate: isExpandAllOpen ? 0 : "180deg",
        transition: transitions.create("rotate", {
          duration: 300,
        }),
      }}
    />
  );

  const getOptionLabelWrapper: typeof getOptionLabel = (option) => {
    return getOptionLabel ? getOptionLabel(option) : `${option}`;
  };

  return (
    <DialogTitle sx={{ px: 2, pb: 0, width: "100%" }}>
      <Stack gap={1} width="100%">
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle1">{`${label}:`}</Typography>
          <Button
            onClick={() => {
              setTags({ list: [], logic: "or" });
            }}
          >
            Reset
          </Button>
        </Stack>

        <Autocomplete
          {...props}
          options={options}
          multiple
          disableCloseOnSelect
          clearOnBlur={clearOnBlur}
          value={tags.list}
          inputValue={inputValue}
          onChange={(_e, nextValue, _details) => {
            onChange({
              list: nextValue,
              logic: tags.logic,
            });
          }}
          onInputChange={(event, value, reason) => {
            if (event && event.type === "blur") {
              setInputValue("");
            } else if (reason === "clear") {
              setTags((prevValue) => {
                return {
                  ...prevValue,
                  list: [],
                };
              });
              setInputValue(value);
            } else if (reason !== "reset") {
              // this for keeping filter value on blur
              setInputValue(value);
            }
          }}
          renderInput={(params) => (
            <TextInputBase
              {...params}
              label={label}
              placeholder={placeholder}
              error={error}
              helperText={helperText}
              fullWidth
              InputProps={{
                ...params.InputProps,
                ref: undefined,
                onClick: undefined,
                endAdornment: undefined,
              }}
              sx={{
                [`& .${outlinedInputClasses.root}`]: {
                  paddingRight: "9px !important",
                  //for small size
                  height: "100%",
                  minHeight: "48px",
                },
              }}
            />
          )}
          getOptionLabel={getOptionLabel}
          renderTags={(value: EventSourceOption[], getTagProps) => {
            const tagElements = value.map((option, index) => {
              const label = getOptionLabelWrapper(option);
              const { key, ...tagProps } = getTagProps({ index });

              return (
                <Chip
                  key={key}
                  label={label}
                  {...tagProps}
                  onDelete={() => {
                    setTags((prevValue) => {
                      const updatedOptions = prevValue.list.filter(
                        (opt) =>
                          opt.group !== option.group ||
                          opt.value !== option.value
                      );
                      return {
                        ...prevValue,
                        list: updatedOptions,
                      };
                    });
                  }}
                  size="medium"
                />
              );
            });

            const totalTagsCount = renderTotalCountAdornment();

            return (
              <Stack
                flexDirection="row"
                overflow="auto"
                flexWrap="wrap"
                maxHeight={114}
              >
                {totalTagsCount}
                {tagElements}
              </Stack>
            );
          }}
        />

        <Button
          variant="text"
          startIcon={icon}
          onClick={() => {
            setOpenedSection((prevState) => {
              return Object.keys(prevState).reduce(
                (res: OpenedSectionType, next) => {
                  res[next] = !isExpandAllOpen;
                  return res;
                },
                {}
              );
            });
            setIsExpandAllOpen((prevState) => !prevState);
          }}
          fullWidth
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            gap: 4,
            padding: "0 16px",
            height: 48,
            "& .MuiButton-startIcon": {
              marginRight: 0,
              marginLeft: 0,
            },
          }}
        >
          <Typography variant="body1">
            {isExpandAllOpen ? "Collapse all" : "Expand all"}
          </Typography>
        </Button>
      </Stack>
    </DialogTitle>
  );
}
