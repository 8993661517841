import { Box, LinearProgress, Stack } from "@mui/material";
import { useRef } from "react";
import InfiniteLoader from "react-window-infinite-loader";
import {
  TileLayoutAutoSizer,
  TileLayoutAutoSizerProps,
} from "./TileLayoutAutoSizer";
import { TileLayoutGrid, TileLayoutGridProps } from "./TileLayoutGrid";

export type TileLayoutLazyProps<T = unknown> = {
  /** Currently loaded items */
  items: T[];

  /** Total number of items in the collection */
  total: number;

  /** called when next batch of items need to be loaded */
  loadMoreItems: (startIndex: number, stopIndex: number) => Promise<void>;

  /** when true, the component-level loading indicator will be shown */
  isLoading?: boolean;

  /** Inner insets for scrolling container */
  insets?: TileLayoutGridProps["insets"];

  /** Column gap from container width */
  getColGap?: TileLayoutGridProps<T>["getColGap"];

  /** Row gap from container width */
  getRowGap?: TileLayoutGridProps<T>["getRowGap"];

  /** Calculate column count from container width */
  getColCount?: TileLayoutAutoSizerProps["getColCount"];

  /** Calculate row height */
  getRowHeight?: TileLayoutGridProps["getRowHeight"];

  /** Calculate col height */
  getColHeight?: TileLayoutGridProps["getColWidth"];

  /** Get item key, usually an id */
  getItemKey: TileLayoutGridProps<T>["getItemKey"];

  /** Render item */
  renderItem: TileLayoutGridProps<T>["renderItem"];

  /** Empty container */
  renderEmpty?: () => JSX.Element;

  onScroll?: TileLayoutGridProps<T>["onScroll"];
};

export function TileLayoutLazy<T = unknown>({
  isLoading,

  items,

  total,

  getColGap,

  getRowGap,

  getColCount,

  getRowHeight,

  getItemKey,

  loadMoreItems,

  renderItem,

  renderEmpty,

  onScroll,

  insets,
}: TileLayoutLazyProps<T>) {
  const rootRef = useRef<HTMLDivElement>(null);
  const getColCountWrapper: typeof getColCount = (containerWidth) => {
    if (getColCount) {
      return getColCount(containerWidth);
    }

    const defaultColumnCount = Math.floor(containerWidth / 335);
    return defaultColumnCount;
  };

  const getColGapWrapper: typeof getColGap = (containerWidth) => {
    return getColGap?.(containerWidth) ?? 1;
  };

  const getRowGapWrapper: typeof getRowGap = (containerWidth) => {
    return getRowGap?.(containerWidth) ?? getColGapWrapper(containerWidth);
  };

  return (
    <Box ref={rootRef} flex={1} position="relative">
      <TileLayoutAutoSizer
        count={items.length}
        total={total}
        getColCount={getColCountWrapper}
      >
        {({ width, height, columnCount, rowCount }) => {
          const isItemLoaded = (i: number) => !!items[i];

          return (
            <InfiniteLoader
              itemCount={total}
              isItemLoaded={isItemLoaded}
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered, ref }) => {
                return (
                  <TileLayoutGrid<T>
                    innerRef={ref}
                    items={items}
                    total={total}
                    width={width}
                    height={height}
                    colCount={columnCount}
                    rowCount={rowCount}
                    insets={insets}
                    onItemsRendered={onItemsRendered}
                    isItemLoaded={isItemLoaded}
                    getColGap={getColGapWrapper}
                    getRowHeight={getRowHeight}
                    getRowGap={getRowGapWrapper}
                    getItemKey={getItemKey}
                    renderItem={renderItem}
                    onScroll={onScroll}
                  />
                );
              }}
            </InfiniteLoader>
          );
        }}
      </TileLayoutAutoSizer>

      {!items.length && !isLoading && (
        <Stack
          justifyContent="center"
          alignItems="center"
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          sx={{ pointerEvents: "none" }}
          children={renderEmpty?.()}
        />
      )}

      {isLoading ? (
        <LinearProgress
          sx={{ position: "absolute", left: 0, top: 0, right: 0 }}
        />
      ) : null}
    </Box>
  );
}
