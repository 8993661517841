import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { BooleanParam, useQueryParam } from "use-query-params";
import {
  DrawerProps,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { GridPaginationModel } from "@mui/x-data-grid-premium";
import { useEventSelectionDuplicatesDrawer } from "src/api/useEventSelectionDuplicatesDrawer";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { useListFilter } from "src/utils/useListFilter";
import { useListSelectInfo } from "src/utils/useListSelectInfo";
import { RightSideDrawer } from "src/components/RightSideDrawer/RightSideDrawer";
import { AllCheckboxSelector } from "src/components/AllCheckboxSelector/AllCheckboxSelector";
import {
  OrderByParameters,
  SortBy,
  SortByParameters,
  SortOption,
} from "src/components/SortingMenu/SortingMenu.model";
import { SearchInput } from "src/components/SearchInput";
import { SortingMenu } from "src/components/SortingMenu/SortingMenu";
import { SelectAllPages } from "src/components/SelectAllPages/SelectAllPages";
import { DuplicatesList } from "./DuplicatesList/DuplicatesList";

type DuplicatesDrawerProps = Omit<
  DrawerProps,
  "anchor" | "hideBackdrop" | "onClose" | "title"
> & {
  onClose: () => void;
  duplicatesData: PowerSearchResultItem[];
  loading: boolean;
  pagination: GridPaginationModel;
  setPagination: (nextPagination: GridPaginationModel) => void;
  addItemToReport: (value: PowerSearchResultItem) => void;
  setSortBy: Dispatch<SetStateAction<SortByParameters>>;
  setOrderBy: Dispatch<SetStateAction<OrderByParameters>>;
  isPowerSearch?: boolean;
};

const emptyList: PowerSearchResultItem[] = [];

export function DuplicatesDrawer({
  duplicatesData,
  addItemToReport,
  loading,
  pagination,
  setPagination,
  setSortBy,
  setOrderBy,
  isPowerSearch,
  ...props
}: DuplicatesDrawerProps) {
  const { queryId = "" } = useParams();
  const [sortOption, setSortOption] = useState(SortBy.defaultDesc);
  const [isExpandedTextMode, setExpandedTextMode] = useQueryParam(
    "expandedDrawer",
    BooleanParam
  );

  const { list, selectEvents, unselectEvents } =
    useEventSelectionDuplicatesDrawer();

  const [filterText, setFilterText] = useState("");
  const filterData = useListFilter(duplicatesData || emptyList, filterText, [
    "title",
    "highlights",
    "source",
    "country",
    "market",
    "category",
    "language",
    "viewership",
  ]);

  const filterDataIds = filterData.map((ev) => ev.id);
  const selectedInCurrentDrawer = list.filter((event) =>
    filterDataIds.includes(event.id)
  );

  const currentPageData = useMemo(
    () =>
      filterData.slice(
        pagination.page * pagination.pageSize,
        (pagination.page + 1) * pagination.pageSize
      ),
    [filterData, pagination]
  );

  const { isAllSelected, isPartiallySelected } = useListSelectInfo({
    list: currentPageData,
    selectedList: selectedInCurrentDrawer,
  });

  const hasMorePages = useMemo(() => {
    return filterData.length / pagination.pageSize > 1;
  }, [filterData.length, pagination.pageSize]);

  const selectAllPagesIsShown = isAllSelected && hasMorePages;

  const handleChangeAllCheckbox = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        selectEvents(currentPageData);
      } else {
        unselectEvents(filterData);
      }
    },
    [currentPageData, filterData, selectEvents, unselectEvents]
  );

  const renderAllCheckboxSelector = useCallback(
    ({ hideLabel }: { hideLabel?: boolean }) => {
      return (
        <AllCheckboxSelector
          checked={isAllSelected || isPartiallySelected}
          isPartiallySelected={isPartiallySelected}
          onChange={handleChangeAllCheckbox}
          sx={{ ml: "28px" }}
          hideLabel={hideLabel}
        />
      );
    },
    [handleChangeAllCheckbox, isAllSelected, isPartiallySelected]
  );

  const onSortChange = useCallback(
    (sortBy: SortOption) => {
      setSortOption(sortBy.value);
      setSortBy(sortBy.field);
      setOrderBy(sortBy.order);
    },
    [setSortBy, setOrderBy]
  );

  const sortingMenu = useMemo(
    () => (
      <SortingMenu
        value={sortOption}
        onChange={onSortChange}
        options={[
          SortBy.titleAsc,
          SortBy.titleDesc,
          SortBy.defaultAsc,
          SortBy.defaultDesc,
          SortBy.viewershipDesc,
          SortBy.viewershipAsc,
          SortBy.disctanceAsc,
          SortBy.distanceDesc,
        ]}
      />
    ),
    [sortOption, onSortChange]
  );

  const setFilterTextWrapper = useCallback(
    (value: string) => {
      if (filterText !== value) {
        setPagination({
          ...pagination,
          page: 0,
        });
      }
      setFilterText(value);
    },
    [setFilterText, pagination, setPagination, filterText]
  );

  const toolbar = useMemo(
    () => (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        px={2}
        pb={2}
        pt={1}
      >
        <Stack direction="row" alignItems="flex-end">
          {renderAllCheckboxSelector({})}
          <SearchInput
            placeholder="Search by text..."
            onTextChangeThrottled={setFilterTextWrapper}
            sx={{ width: 200, ml: 4 }}
          />
        </Stack>
        {sortingMenu}
      </Stack>
    ),
    [renderAllCheckboxSelector, sortingMenu, setFilterTextWrapper]
  );

  const renderCustomFooterEl = useCallback(() => {
    return (
      <FormControlLabel
        checked={!!isExpandedTextMode}
        control={
          <Switch
            size="small"
            onChange={(_e, checked) =>
              setExpandedTextMode(checked, "replaceIn")
            }
          />
        }
        label={<Typography variant="caption">Expanded Text</Typography>}
        sx={{ ml: 2, whiteSpace: "nowrap" }}
      />
    );
  }, [isExpandedTextMode, setExpandedTextMode]);

  const renderSelectAll = useCallback(() => {
    return (
      <SelectAllPages
        rowCount={filterData.length}
        selectedCount={selectedInCurrentDrawer.length}
        areAllPagesSelected={
          filterData.length === selectedInCurrentDrawer.length
        }
        setAllPagesSelected={(value) => {
          if (value) {
            selectEvents(filterData);
          } else {
            unselectEvents(filterData);
          }
        }}
        unselectAll={() => unselectEvents(filterData)}
        allCheckboxSelector={renderAllCheckboxSelector({ hideLabel: true })}
      />
    );
  }, [
    selectedInCurrentDrawer,
    filterData,
    selectEvents,
    unselectEvents,
    renderAllCheckboxSelector,
  ]);

  const content = useMemo(
    () => (
      <>
        {selectAllPagesIsShown ? renderSelectAll() : toolbar}
        <DuplicatesList
          id="duplicates-list"
          queryId={queryId}
          isExpandedTextMode={!!isExpandedTextMode}
          rows={currentPageData}
          rowCount={filterData.length}
          loading={loading}
          paginationModel={pagination}
          onPaginationModelChange={setPagination}
          addItemToReport={addItemToReport}
          renderCustomFooterEl={renderCustomFooterEl}
          isPowerSearch={isPowerSearch}
        />
      </>
    ),
    [
      selectAllPagesIsShown,
      renderSelectAll,
      toolbar,
      queryId,
      isExpandedTextMode,
      currentPageData,
      filterData.length,
      loading,
      pagination,
      setPagination,
      addItemToReport,
      renderCustomFooterEl,
      isPowerSearch,
    ]
  );

  return (
    <RightSideDrawer
      {...props}
      intercomId="duplicates-drawer"
      title="Duplicates"
      children={content}
      PaperProps={{
        sx: { width: 800, maxWidth: "100%" },
      }}
    />
  );
}
