import { ChangeEvent } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { ROLES, getRoleFromUser } from "src/utils/useUserRoleList";
import { useSessionContext } from "src/api/useSessionContext";
import { isAdminSafeAccount } from "src/utils/isAdminSafeAccount";

type UserRolePickerProps = {
  value: Array<string>;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

export function UserRolePicker({ value, onChange }: UserRolePickerProps) {
  const { user } = useAuth0();
  const { accountId } = useSessionContext();

  const role = getRoleFromUser(user);
  const availableRoles = ROLES.reduce((res: Array<typeof next>, next) => {
    if (role.includes(next)) {
      res.push(next);
    }
    return res;
  }, []);

  const isDisabled = !isAdminSafeAccount(accountId);

  return (
    <>
      <Typography variant="subtitle1">Roles</Typography>
      <FormControl component="fieldset">
        <FormGroup sx={{ flexDirection: "row" }}>
          {availableRoles.map((option) => (
            <FormControlLabel
              key={option}
              control={
                <Checkbox
                  checked={value?.includes(option)}
                  onChange={onChange}
                  name={option}
                  value={option}
                  disabled={option === "Admin" ? isDisabled : false}
                />
              }
              label={option}
            />
          ))}
        </FormGroup>
      </FormControl>
    </>
  );
}
