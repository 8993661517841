import { WatchQueryFilterFormSchema } from "../WatchQueryFilterForm/WatchQueryFilterForm.schema";
import { WatchQueryKeywordFormValues } from "./WatchQueryKeywordForm.model";
import { WatchQueryKeywordFormSchema } from "./WatchQueryKeywordForm.schema";

export const isWatchQueryKeywordValues = (
  data: unknown
): data is WatchQueryKeywordFormValues => {
  const validation = WatchQueryKeywordFormSchema.validate(data);
  return !validation.error;
};

export const isWatchQueryFilterValues = (
  data: unknown
): data is Omit<WatchQueryKeywordFormValues, "displayName"> => {
  const validation = WatchQueryFilterFormSchema.validate(data);
  return !validation.error;
};
