import { useCallback, useEffect, useMemo } from "react";
import Editor, { useMonaco } from "@monaco-editor/react";
import {
  darken,
  lighten,
  Stack,
  Typography,
  useTheme,
  rgbToHex,
  alpha,
  Button,
  Tooltip,
  buttonClasses,
} from "@mui/material";
import CheckCircle from "@mui/icons-material/CheckCircle";
import CopyAll from "@mui/icons-material/CopyAll";
import FileDownloadDone from "@mui/icons-material/FileDownloadDone";
import { blue, green, purple } from "@mui/material/colors";
import { useValidateCustomQuerySmart } from "src/api/useValidateCustomQuerySmart";
import { useCustomQueryValidationData } from "src/api/useCustomQueryValidationData";
import { useReadOnlySnackbar } from "src/api/usReadOnlySnackbar";
import { validateLuceneQuery } from "src/utils/validateLuceneQuery";
import { LoadingButton } from "src/components/buttons/LoadingButton";
import { ScrollBox } from "src/components/ScrollBox/ScrollBox";
import { AnimatedHint } from "./AnimatedHint";
import { useCopyButtonState } from "src/utils/useCopyButtonState";

export const MonacoCodeEditor = ({
  value,
  onChange,
  readOnly = false,
}: {
  value?: string;
  onChange: (value: string | undefined) => void;
  readOnly?: boolean;
}) => {
  const { palette } = useTheme();
  const monaco = useMonaco();

  const { wasCopied, copy } = useCopyButtonState();

  const readOnlySnackbar = useReadOnlySnackbar();

  const placeholder = `(coke NOT (
        "coke it's delicious" OR 
        "coke tastes refreshing"
    )) 
OR 
(sprite NOT (
        "holy cow I love sprite" OR 
        "drink moar sprite"))`;

  useEffect(() => {
    if (monaco) {
      // a delay to ensure Monaco is fully ready before applying configurations
      setTimeout(() => {
        monaco.languages.register({
          id: "lucene",
        });

        monaco.languages.setMonarchTokensProvider("lucene", {
          tokenizer: {
            root: [
              // for highlighting with color for AND, OR, NOT, ~
              [/\bAND\b/, "custom-and"],
              [/\bOR\b/, "custom-or"],
              [/\bNOT\b/, "custom-not"],
              [/~/, "custom-~"],

              // for errors highlighting:
              [/\b(AND|OR|NOT|AND NOT)\b/, "keyword"],
              [/\b(\w+):/, "type"],
              [/\(/, "@brackets"],
              [/\)/, "@brackets"],
              [/".*?"/, "string"],
              [/[0-9]+(\.[0-9]+)?/, "number"],
              [/[a-zA-Z_]\w*/, "identifier"],
            ],
          },
        });

        // Define and set a custom theme to highlight with color for AND, OR, NOT, ~
        monaco.editor.defineTheme("customTheme", {
          base: palette.mode === "dark" ? "vs-dark" : "vs",
          inherit: true,
          rules: [
            { token: "custom-and", foreground: green["500"] },
            { token: "custom-or", foreground: blue["500"] },
            { token: "custom-not", foreground: rgbToHex(palette.warning.main) },
            { token: "custom-~", foreground: purple["A200"] },
            { token: "string", foreground: rgbToHex(palette.primary.dark) },
          ],
          colors: {
            "editor.background": readOnly
              ? rgbToHex(alpha(palette.action.hover, 0.04))
              : rgbToHex(palette.background.paper),
          },
        });
        monaco.editor.setTheme("customTheme");

        // Register a code action provider for quick fixes
        monaco.languages.registerCodeActionProvider("lucene", {
          provideCodeActions: function (model, range, context, token) {
            let codeActions: any = [];
            for (let i = 0; i < context.markers.length; i++) {
              const marker = context.markers[i];

              const match = marker.message.match(/'(\w+)'/);
              if (match) {
                const word = match[1].toUpperCase();
                const startPosition = model.getPositionAt(
                  model.getOffsetAt({
                    lineNumber: marker.startLineNumber,
                    column: marker.startColumn,
                  })
                );
                const endPosition = model.getPositionAt(
                  model.getOffsetAt({
                    lineNumber: marker.endLineNumber,
                    column: marker.endColumn,
                  })
                );

                if (
                  marker.message ===
                  "Whitespace before a proximity operator is invalid."
                ) {
                  codeActions.push({
                    title: "Remove whitespace before proximity operator",
                    kind: "quickfix",
                    diagnostics: [marker],
                    edit: {
                      edits: [
                        {
                          resource: model.uri,
                          edit: {
                            range: new monaco.Range(
                              startPosition.lineNumber,
                              startPosition.column, // Start from the position where the whitespace starts
                              endPosition.lineNumber,
                              endPosition.column // End position of the entire proximity operator (including number)
                            ),
                            text:
                              "~" +
                              model.getValueInRange({
                                startLineNumber: endPosition.lineNumber,
                                startColumn: startPosition.column + 2, // Skip over the whitespace and tilde
                                endLineNumber: endPosition.lineNumber,
                                endColumn: endPosition.column,
                              }),
                          },
                        },
                      ],
                    },
                  });
                } else if (marker.message.startsWith("Keyword")) {
                  codeActions.push({
                    title: "Remove adjacent operator",
                    kind: "quickfix",
                    diagnostics: [marker],
                    edit: {
                      edits: [
                        {
                          resource: model.uri,
                          edit: {
                            range: new monaco.Range(
                              startPosition.lineNumber,
                              startPosition.column - 1,
                              endPosition.lineNumber,
                              endPosition.column
                            ),
                            text: "",
                          },
                        },
                      ],
                    },
                  });
                } else if (marker.message.startsWith("Operator")) {
                  codeActions.push({
                    title: `Convert to uppercase: ${word}`,
                    kind: "quickfix",
                    diagnostics: [marker],
                    edit: {
                      edits: [
                        {
                          resource: model.uri,
                          textEdit: {
                            range: new monaco.Range(
                              startPosition.lineNumber,
                              startPosition.column,
                              endPosition.lineNumber,
                              endPosition.column
                            ),
                            text: word,
                          },
                        },
                      ],
                    },
                  });
                }
              }
            }

            return {
              actions: codeActions,
              dispose: function () {},
            };
          },
        });
      }, 500);
    }
  }, [monaco, palette, readOnly]);

  const handleEditorDidMount = (editor: any, monaco: any) => {
    const model = editor.getModel();
    // Apply placeholder if the editor is empty
    if (model && !model.getValue()) {
      editor.updateOptions({ readOnly: true });
      model.setValue(placeholder);
    }
    // Add listener to remove placeholder on focus
    editor.onDidFocusEditorText(() => {
      if (model && model.getValue() === placeholder) {
        editor.updateOptions({ readOnly: false });
        model.setValue("");
      }
    });

    // Initial validation
    validateLuceneQuery(monaco, editor.getModel());

    // Set up the listener:
    editor.onDidChangeModelContent(() => {
      validateLuceneQuery(monaco, editor.getModel());
    });

    editor.onKeyDown((e: { preventDefault: () => void }) => {
      // Prevent default behavior that may trigger a default readOnlyMessage
      if (readOnly) {
        e.preventDefault();
      }
    });

    // Handle touch events (for mobile) in read-only mode
    const editorElement = editor.getDomNode();
    if (editorElement && readOnly) {
      editorElement.addEventListener(
        "touchstart",
        (e: { preventDefault: () => void }) => {
          e.preventDefault(); // Prevents the mobile keyboard from appearing
          readOnlySnackbar.show();
        }
      );
    }
  };

  const { saveValidationData, clearValidationData } =
    useCustomQueryValidationData();

  useEffect(() => {
    return () => {
      clearValidationData();
    };
  }, [clearValidationData]);

  const customQueryValidateMutation = useValidateCustomQuerySmart({
    options: {
      onSuccess: saveValidationData,
    },
  });

  const testCustomQuery = useCallback(() => {
    if (value) {
      customQueryValidateMutation.mutate({
        customQuery: value,
      });
    }
  }, [value, customQueryValidateMutation]);

  const copyToClipboard = () => {
    if (!value) return;
    if (!navigator.clipboard) {
      console.error("browser does not support navigation.clipboard");
    }
    navigator.clipboard.writeText(value);
    copy();
  };

  const copyButton = (
    <Stack
      sx={{
        backgroundColor: palette.background.paper,
        position: "absolute",
        bottom: 8,
        right: 92,
      }}
    >
      <Tooltip title="Copy code">
        <Button
          variant="contained"
          color="info"
          size="small"
          disabled={!value}
          sx={{
            width: "30px",
            minWidth: "30px",
            height: "30px",
            [`.${buttonClasses.startIcon}`]: {
              marginRight: 0,
            },
          }}
          onClick={copyToClipboard}
          startIcon={wasCopied ? <FileDownloadDone /> : <CopyAll />}
        ></Button>
      </Tooltip>
    </Stack>
  );

  const testButton = (
    <Stack
      sx={{
        backgroundColor: palette.background.paper,
        position: "absolute",
        bottom: 8,
        right: 12,
        borderRadius: "8px",
      }}
    >
      <LoadingButton
        variant="contained"
        color="secondary"
        size="small"
        startIcon={<CheckCircle />}
        disabled={!value || customQueryValidateMutation.isLoading}
        loading={customQueryValidateMutation.isLoading}
        onClick={testCustomQuery}
      >
        Test
      </LoadingButton>
    </Stack>
  );

  const testMessageContent = useMemo(() => {
    if (!customQueryValidateMutation.data) return;
    const { isValid, message, suggestions, recommendedQuery } =
      customQueryValidateMutation.data;
    return (
      <Stack
        p={2}
        color={
          isValid
            ? darken(palette.success.main, 0.6)
            : darken(palette.error.main, 0.6)
        }
        sx={{
          borderRadius: "4px",
          backgroundColor: isValid
            ? lighten(palette.success.main, 0.9)
            : lighten(palette.error.main, 0.9),
        }}
      >
        <Typography fontWeight={700} fontSize="14px">
          {isValid ? "Searches for:" : "An error occured!"}
        </Typography>
        {!!message && (
          <Typography mt={1} fontSize="14px">
            {message}
          </Typography>
        )}
        {!!suggestions?.length && (
          <>
            <Typography mt={1} fontWeight={700} fontSize="14px">
              Please, try:
            </Typography>
            {suggestions.map((sugg) => (
              <Typography key={sugg} fontSize="14px">
                - {sugg}
              </Typography>
            ))}
          </>
        )}
        {!!recommendedQuery && (
          <>
            <Typography mt={1} fontWeight={700} fontSize="14px">
              Recommended query:
            </Typography>
            <Typography fontSize="14px">{recommendedQuery}</Typography>
          </>
        )}
      </Stack>
    );
  }, [
    customQueryValidateMutation.data,
    palette.error.main,
    palette.success.main,
  ]);

  return (
    <Stack
      flexDirection="row"
      border={`1px solid ${palette.action.disabled}`}
      borderRadius="4px"
    >
      <Stack
        position="relative"
        width={readOnly ? "100%" : "65%"}
        minHeight={300}
        sx={{
          ...(!value
            ? {
                /** color applied to the placeholder */
                "& .view-line span": {
                  color: `${alpha(palette.text.secondary, 0.65)}`,
                },
              }
            : {}),

          /** Hide vertical line at the right of the editor */
          "canvas.decorationsOverviewRuler": {
            display: "none !important",
          },

          /** Adjust border radius of the editor */
          ".custom-editor": {
            overflow: "hidden",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
          },
          ".monaco-editor, .monaco-editor-background, .monaco-editor .margin, .margin-view-overlays":
            {
              borderTopLeftRadius: "4px",
              borderBottomLeftRadius: "4px",
              overflow: "hidden",
            },
          ".monaco-editor .margin-view-overlays": {
            clipPath: "inset(0px 0px 0px -10px)",
          },

          /** Add background color to the current line */
          ".current-line": {
            backgroundColor: alpha(palette.action.hover, 0.04),
            border: "none",
          },

          ".monaco-editor .line-numbers": {
            color: alpha(palette.text.secondary, 0.65),
          },

          ".monaco-editor .slider": {
            left: "1px !important",
          },
        }}
      >
        <Editor
          height="100%"
          width="100%"
          defaultLanguage="lucene"
          value={value}
          onChange={onChange}
          onMount={handleEditorDidMount}
          options={{
            minimap: {
              enabled: false,
            },
            lineHeight: 25,
            fontSize: 16,
            wordWrap: "on",
            scrollbar: {
              verticalScrollbarSize: 6,
              horizontal: "hidden",
            },
            padding: {
              top: 10,
            },
            lineNumbersMinChars: 3,
            readOnly: readOnly,
            domReadOnly: readOnly,
            contextmenu: !readOnly,
            selectOnLineNumbers: !readOnly,
          }}
          theme="customTheme"
          className="custom-editor"
        />
        {!readOnly && (
          <>
            {copyButton}
            {testButton}
          </>
        )}
      </Stack>
      {!readOnly && (
        <Stack
          py={1}
          pl="10px"
          pr="2px"
          width="35%"
          height={300}
          flexDirection="column"
          justifyContent="space-between"
          sx={{
            borderLeft: `1px solid ${palette.action.disabled}`,
          }}
        >
          <ScrollBox>
            {!!customQueryValidateMutation.data && testMessageContent}
          </ScrollBox>
          <Stack mt={2} mb={1} mr={1}>
            <AnimatedHint isLoading={customQueryValidateMutation.isLoading} />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
