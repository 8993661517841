import { Tooltip } from "@mui/material";
import { format } from "date-fns";
import {
  getActivityLogActivity,
  getActivityLogDetails,
  getActivityLogDate,
  getActivityLogUser,
} from "src/api/useActivityLog.getters";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { AppLink } from "src/components/AppLink/AppLink";
import { userManagementActivityLogsRoute } from "src/pages/UserManagementActivityLogs/UserManagementActivityLogs.route";
import { accountsManagementUserActivityLogsRoute } from "src/pages/AccountsManagementUserActivityLogs/AccountsManagementUserActivityLogs.route";
import { ActivityLogTableTableColDef } from "./ActivityLogTable.model";

const commonCellParams: Pick<
  ActivityLogTableTableColDef,
  "sortable" | "align" | "headerAlign" | "disableColumnMenu"
> = {
  sortable: false,
  disableColumnMenu: true,
};

export function getTimeWithoutTimezone(d: string, timeFormat?: string) {
  const dateFromDB = new Date(d);
  const dateWithoutTimezone = new Date(
    dateFromDB.valueOf() + dateFromDB.getTimezoneOffset() * 60 * 1000
  );
  return format(dateWithoutTimezone, timeFormat ?? "p");
}

export function makeActivityLogTableColumns(
  accountId?: string
): ActivityLogTableTableColDef[] {
  const getUserLink = (userId: string, accountId?: string) => {
    if (!accountId) {
      return userManagementActivityLogsRoute.makeUrl({
        userId,
      });
    }

    return accountsManagementUserActivityLogsRoute.makeUrl({
      accountId,
      userId,
    });
  };

  return [
    {
      ...commonCellParams,
      headerName: "Date/time",
      field: "timestamp",
      width: 200,
      renderCell(params) {
        return (
          <Tooltip title={getActivityLogDate(params.row)}>
            <TextLineClamp lineClamp={2}>
              {getTimeWithoutTimezone(getActivityLogDate(params.row), "PPpp")}
            </TextLineClamp>
          </Tooltip>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "User",
      field: "userName",
      width: 180,
      renderCell(params) {
        return (
          <AppLink
            title={getActivityLogUser(params.row)}
            to={getUserLink(params.row.userId, accountId)}
          />
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Activity",
      field: "type",
      width: 180,
      valueGetter(params) {
        return getActivityLogActivity(params.row);
      },
    },
    {
      ...commonCellParams,
      headerName: "Details",
      field: "message",
      width: 550,
      valueGetter(params) {
        return getActivityLogDetails(params.row);
      },
    },
  ];
}
