import { Fragment, ReactNode, useRef } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { colors, Paper, Stack, StackProps, useTheme } from "@mui/material";
import { useAnalyticsLiteQueryFilter } from "src/pages/ReportAnalysisLite/hooks/useAnalyticsLiteQueryFilter";
import { WatchQueryExplanation } from "src/pages/WatchList/components/WatchQueryTable/components/WatchQueryExplanation";
import { countQueryFilters } from "src/utils/countQueryFilters";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import { useIsMobile } from "src/utils/useIsMobile";
import { useOpenState } from "src/utils/useOpenState";
import { AppPopper } from "../AppPopper/AppPopper";
import { AppPopperArrowSize } from "../AppPopper/components/AppPopperArrow/AppPopperArrow.model";
import { AppChartLegend, AppChartLegendProps } from "./AppChartLegend";
import { TextLineClamp } from "../TextLineClamp/TextLineClamp";

export function AppChartContainer({
  title,
  legend = [],
  children,
  actions,
  ...props
}: StackProps & {
  title?: string;
  legend?: AppChartLegendProps["data"];
  actions?: ReactNode;
}) {
  const isMobile = useIsMobile();
  const { palette } = useTheme();
  const [filterQuery] = useAnalyticsLiteQueryFilter();
  const hasFilters = !!countQueryFilters(filterQuery);
  const queryDefinition = makeQueryDefinition(filterQuery);
  const popperState = useOpenState();
  const containerRef = useRef<SVGSVGElement | null>(null);

  const filterPopper = (
    <AppPopper
      onClose={popperState.hide}
      open={popperState.isOpen}
      anchorEl={containerRef.current}
      placement={isMobile ? undefined : "right"}
      arrow={AppPopperArrowSize.medium}
      children={
        <WatchQueryExplanation
          title="Applied Filters"
          queryDefinition={queryDefinition}
        />
      }
    />
  );

  const filterIcon = hasFilters && (
    <FilterAltIcon
      ref={containerRef}
      onMouseEnter={popperState.show}
      onMouseLeave={popperState.hide}
      sx={{
        color: colors.blueGrey[200],
        verticalAlign: "top",
      }}
    />
  );

  const desktopHeader =
    !isMobile && title ? (
      <Stack direction="row" alignItems="center">
        <TextLineClamp
          flex={1}
          py={1}
          px={2}
          variant="subtitle1"
          textAlign="center"
          children={
            <Fragment>
              {title} {filterIcon}
            </Fragment>
          }
          lineClamp={1}
        />
        {filterPopper}
      </Stack>
    ) : null;

  const mobileHeader =
    isMobile && title ? (
      <Stack direction="row" alignItems="center">
        <TextLineClamp
          flex={1}
          py={1}
          px={2}
          variant="h6"
          children={title}
          lineClamp={1}
        />
        {filterIcon}
        {filterPopper}
      </Stack>
    ) : null;

  return (
    <Stack
      component={Paper}
      elevation={palette.mode === "light" ? 1 : 2}
      borderRadius={2}
      borderColor={palette.divider}
      overflow="hidden"
      {...props}
      sx={{
        width: "100%",
        maxHeight: 600,
        aspectRatio: isMobile ? 1 : 16 / 9,
        ...props.sx,
      }}
    >
      {desktopHeader || mobileHeader}
      {legend?.length ? (
        <Stack px={2} mb={1}>
          <AppChartLegend data={legend} />
        </Stack>
      ) : null}
      <Stack flex={1} overflow="hidden" position="relative">
        {children}
        {!!actions && (
          <Stack
            position="absolute"
            top={8}
            right={24}
            pl={2}
            component={Paper}
            borderRadius={1}
            border={`1px solid ${palette.divider}`}
            elevation={4}
          >
            {actions}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
