import { Stack, useTheme } from "@mui/material";
import { LoadingOverlay } from "src/components/LoadingOverlay/LoadingOverlay";

export const WatchQueryTileLoading = () => {
  const { palette } = useTheme();
  return (
    <Stack
      flex={1}
      gap={1}
      border={`1px solid ${palette.divider}`}
      borderRadius={2}
      children={<LoadingOverlay size={80} />}
    />
  );
};
