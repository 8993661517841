import { AxisValueFormatterContext } from "@mui/x-charts/models/axis";
import { format } from "date-fns";

export const axisDateValueFormatter = (
  value: Date,
  context: AxisValueFormatterContext,
  minDate: Date,
  maxDate: Date
) => {
  if (context.location === "tooltip") {
    return format(new Date(value), "MM/dd/yyyy, HH:mm:ss");
  }
  const rangeHours = (maxDate.getTime() - minDate.getTime()) / (1000 * 60 * 60);
  return rangeHours > 48
    ? format(new Date(value), "MM/dd")
    : format(new Date(value), "hh a");
};
