import { BarChart } from "@mui/x-charts/BarChart";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { components } from "@tveyes/twosionwebapischema";
import { max, min } from "date-fns";
import { useAppChartColorMapper } from "src/components/charts/utils/useAppChartColorMapper";
import { AppChartContainer } from "./AppChartContainer";
import { axisNumberTickAutoFormat } from "./utils/axisNumberTickAutoFormat";
import { axisDateValueFormatter } from "./utils/axisDateValueFormatter";

type AppBarChartData = {
  series?: components["schemas"]["MentionCompositeAnalysisSeriesDTO"][] | null;
  dates?: string[] | null;
};

type AppBarChartProps = {
  title: string;
  data?: AppBarChartData;
  isLoading: boolean;
};

export function AppBarChart({ title, data, isLoading }: AppBarChartProps) {
  const { getColorForId } = useAppChartColorMapper();
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const noResults = data?.series?.every((serie) =>
    serie.values?.every((audience) => audience?.value === 0)
  );

  return (
    <AppChartContainer
      title={title}
      legend={data?.series}
      sx={{ pointerEvents: noResults ? "none" : "initial" }}
    >
      {!isLoading && noResults && (
        <Typography
          variant="subtitle1"
          sx={{
            color: palette.text.secondary,
            position: "absolute",
            top: "35%",
            left: isMobile ? "30%" : "35%",
          }}
        >
          No audience data available
        </Typography>
      )}
      {data?.series && (
        <BarChart
          yAxis={[
            { scaleType: "linear", valueFormatter: axisNumberTickAutoFormat },
          ]}
          loading={isLoading}
          margin={{
            left: 100,
            right: 24,
            top: 16,
          }}
          slotProps={{
            legend: { hidden: true },
          }}
          xAxis={data?.series.map(({ queryId, values }, index) => {
            const xValues =
              values?.map(({ timeStamp }) => {
                return new Date(`${timeStamp}`);
              }) || [];

            const t0 = min(xValues);
            const t1 = max(xValues);

            return {
              id: queryId,
              label: "Date",
              data: xValues,
              scaleType: "band",
              min: t0,
              max: t1,
              valueFormatter: (value, context) =>
                axisDateValueFormatter(value, context, t0, t1),
            };
          })}
          series={data.series.map(({ queryId, values, queryTitle }, index) => {
            return {
              id: queryId,
              // each chart will provide a value for a particular date, corresponding to an index in the array
              data: values?.map(({ value }) => value ?? null) || [],
              // we have only one stack for each date index
              stack: "default",
              label: queryTitle ?? "",
              color: getColorForId(queryId).bg,
            };
          })}
        />
      )}
    </AppChartContainer>
  );
}
