import { AppRouteBuilder } from "src/models/AppRoute";
import { PublicUserInvitePage } from "./PublicUserInvite.page";

export type PublicUserInvitePathParams = {
  inviteId: string;
};

export const publicUserInviteRoute =
  new AppRouteBuilder<PublicUserInvitePathParams>({
    path: "/public/invite/:inviteId",
    element: <PublicUserInvitePage />,
  });
