import axios from "axios";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { watchQueryKey } from "./useWatchQuery";
import { watchQueryListKey } from "./useWatchQueryList";
import { watchQueryListLazyKey } from "./useWatchQueryListLazy";

const apiKey: keyof paths = "/api/user/query/{queryId}/alert/{alertId}";
const method: keyof paths[typeof apiKey] = "delete";

type Endpoint = paths[typeof apiKey][typeof method];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

type RequestMutationFnParams = {
  params: {
    path: {
      queryId: Endpoint["parameters"]["path"]["queryId"];
      alertId: string;
    };
  };
};

type OriginType = "watchlist" | "edit" | "create";

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError" | "onSettled"
> & {
  origin: OriginType;
  type: "instant" | "scheduled";
};

const getQueryKeyName = (origin: OriginType) => {
  switch (origin) {
    case "edit":
      return [watchQueryKey];
    case "create":
      return [watchQueryListKey];
    case "watchlist":
      return [watchQueryListKey, watchQueryListLazyKey];
  }
};

export function useWatchQueryAlertDelete({
  options,
}: {
  options?: RequestOptions;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async ({ params }) => {
      const token = await getAccessTokenSilently();

      const url = makeApiUrl<Endpoint["parameters"]>(apiKey, params);

      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: [],
      });

      return data;
    },
    onSuccess: (data, variables, context) => {
      const successmessage = `The ${
        options?.type === "instant" ? "Instant" : "Scheduled"
      } alert for the "${data.title}" Watch term was successfully ${
        options?.type === "instant" ? "deactivated" : "changed"
      }.`;
      enqueueSnackbar(successmessage);

      options?.onSuccess && options.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });

      options?.onError && options.onError(error, variables, context);
    },
    onSettled(data, error, variables, context) {
      if (!options?.origin) return;
      const queryKeyName = getQueryKeyName(options?.origin);
      queryKeyName.forEach((keyName) => {
        queryClient.refetchQueries({
          queryKey: keyName,
        });
      });

      options?.onSettled && options.onSettled(data, error, variables, context);
    },
  });
}
