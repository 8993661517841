import { createAccountDefaultValues } from "../AccountsManagementCreateAccount/AccountsManagementCreateAccount.const";
import { EditAccountFeaturesFormValues } from "./components/AccountsManagementEditFeaturesTab/AccountManagementEditFeatures.model";

export function makeEntitlements(
  accountFormData: EditAccountFeaturesFormValues
) {
  return {
    enableEditWatchList: {
      value:
        accountFormData.enableEditWatchList ??
        createAccountDefaultValues.enableEditWatchList,
      displayName: "string",
    },
    enableAdFiltering: {
      value:
        accountFormData.enableAdFiltering ??
        createAccountDefaultValues.enableAdFiltering,
      displayName: "string",
    },
    enableMediaDownloads: {
      value:
        accountFormData.enableMediaDownloads ??
        createAccountDefaultValues.enableMediaDownloads,
      displayName: "string",
    },
    enableReportDownloads: {
      value:
        accountFormData.enableReportDownloads ??
        createAccountDefaultValues.enableReportDownloads,
      displayName: "string",
    },
    enableWatchlistRSS: {
      value:
        accountFormData.enableWatchlistRSS ??
        createAccountDefaultValues.enableWatchlistRSS,
      displayName: "string",
    },
    enableArchivedScheduledReports: {
      value:
        accountFormData.enableArchivedScheduledReports ??
        createAccountDefaultValues.enableArchivedScheduledReports,
      displayName: "string",
    },
    enableCustomReportBanner: {
      value:
        accountFormData.enableCustomReportBanner ??
        createAccountDefaultValues.enableCustomReportBanner,
      displayName: "string",
    },
    limitItemsPerEmail: accountFormData.limitItemsPerEmail
      ? {
          value: accountFormData.limitItemsPerEmail,
          displayName: "string",
        }
      : null,
    limitWatchlistSize: accountFormData.limitWatchlistSize
      ? {
          value: accountFormData.limitWatchlistSize,
          displayName: "string",
        }
      : null,
    limitEventsInReport: accountFormData.limitEventsInReport
      ? {
          value: accountFormData.limitEventsInReport,
          displayName: "string",
        }
      : null,
    limitEventsInCSV: accountFormData.limitEventsInCSV
      ? {
          value: accountFormData.limitEventsInCSV,
          displayName: "string",
        }
      : null,
    limitEventsInPDF: accountFormData.limitEventsInPDF
      ? {
          value: accountFormData.limitEventsInPDF,
          displayName: "string",
        }
      : null,
    limitScratchReportItems: accountFormData.limitScratchReportItems
      ? {
          value: accountFormData.limitScratchReportItems,
          displayName: "string",
        }
      : null,
  };
}
