import { Assessment, Mp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import DropDownButton from "src/components/DropDownButton/DropDownButton";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";

// every commented line here is related to MMSR-2997
// import { DownloadPDF } from "src/components/icons/DownloadPDF";
import { DownloadCSV } from "src/components/icons/DownloadCSV";
import { MediaCenterResultExportButtonProps } from "./MediaCenterResultExportButton.model";

export function MediaCenterResultExportButton({
  createReport,
  downloadMedia,
  // downloadPdf,
  downloadCsv,
  isDownloadDisabled,
  enableMediaDownload,
  disableCreateReport,
  limitEventsInReport,
  ...props
}: MediaCenterResultExportButtonProps) {
  const { palette } = useTheme();
  const { t } = useTranslation();

  return (
    <DropDownButton
      {...props}
      renderMenu={(menuProps, closeMenu) => (
        <Menu {...menuProps}>
          <TooltipBase
            width={181}
            textAlign="left"
            title={t("limitEventsInReport", {
              limit: `${limitEventsInReport}`,
            })}
            disableHoverListener={!disableCreateReport}
          >
            <Box>
              <MenuItem
                onClick={() => {
                  closeMenu();
                  createReport();
                }}
                disabled={disableCreateReport}
              >
                <ListItemIcon>
                  <Assessment color="primary" />
                </ListItemIcon>
                <ListItemText>Create Report</ListItemText>
              </MenuItem>
            </Box>
          </TooltipBase>
          <TooltipBase
            width={181}
            textAlign="left"
            title={
              enableMediaDownload
                ? "Download is available for up to 10 clips. Please select less items"
                : "You can't download media as you have an account limitation. Please contact your account manager to extend your limits."
            }
            placement="right"
            disableHoverListener={!isDownloadDisabled && enableMediaDownload}
          >
            <Box>
              <MenuItem
                disabled={!enableMediaDownload || isDownloadDisabled}
                onClick={() => {
                  closeMenu();
                  downloadMedia();
                }}
              >
                <ListItemIcon>
                  <Mp color="primary" />
                </ListItemIcon>
                <ListItemText>Download Media</ListItemText>
              </MenuItem>
            </Box>
          </TooltipBase>

          {/* <MenuItem
            onClick={() => {
              downloadPdf();
              closeMenu();
            }}
          >
            <ListItemIcon>
              <DownloadPDF />
            </ListItemIcon>
            <ListItemText>Download PDF</ListItemText>
          </MenuItem> */}

          <MenuItem
            onClick={() => {
              downloadCsv();
              closeMenu();
            }}
          >
            <ListItemIcon>
              <DownloadCSV
                labelColor={
                  palette.mode === "light"
                    ? palette.common.white
                    : palette.primary.contrastText
                }
              />
            </ListItemIcon>
            <ListItemText>Download CSV</ListItemText>
          </MenuItem>
        </Menu>
      )}
    >
      Export
    </DropDownButton>
  );
}
