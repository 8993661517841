import { SetStateAction, useEffect } from "react";
import { FormControlLabel, Stack, Switch, useTheme } from "@mui/material";
import { useSessionContext } from "src/api/useSessionContext";
import { PremiumIcon } from "src/components/PremiumIcon/PremiumIcon";
import { AlertConfig } from "../ScheduledAlertPopover.model";

const archiveAlertTypes = ["weekly", "monthly"];

type ArchiveAlertsType = {
  config: AlertConfig;
  sheduledAlertArchiveEnabled: boolean;
  setSheduledAlertArchiveEnabled: (value: SetStateAction<boolean>) => void;
};

export function ArchiveAlerts({
  config,
  sheduledAlertArchiveEnabled,
  setSheduledAlertArchiveEnabled,
}: ArchiveAlertsType) {
  const { palette } = useTheme();
  const { effectiveEntitlements } = useSessionContext();
  const enableArchivedScheduledReports =
    effectiveEntitlements.enableArchivedScheduledReports?.value;

  useEffect(() => {
    if (
      !archiveAlertTypes.includes(config.type) &&
      sheduledAlertArchiveEnabled
    ) {
      setSheduledAlertArchiveEnabled(false);
    }
  }, [
    config.type,
    setSheduledAlertArchiveEnabled,
    sheduledAlertArchiveEnabled,
  ]);

  return archiveAlertTypes.includes(config.type) ? (
    <Stack
      border={`1px solid ${palette.action.disabledBackground}`}
      flexDirection="row"
      borderRadius={1}
      px={2}
      py={2}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Stack
        data-intercom-automatic-reporting-switch={
          !enableArchivedScheduledReports
            ? "intercom-automatic-reporting-switch"
            : ""
        }
      >
        <FormControlLabel
          label="Automatic Reporting"
          control={
            <Switch
              checked={sheduledAlertArchiveEnabled}
              onChange={() =>
                setSheduledAlertArchiveEnabled(!sheduledAlertArchiveEnabled)
              }
              disabled={!enableArchivedScheduledReports}
            />
          }
        />
      </Stack>
      <Stack
        data-intercom-automatic-reporting-id={`intercom-automatic-reporting-${
          enableArchivedScheduledReports ? "enabled" : "disabled"
        }`}
      >
        <PremiumIcon />
      </Stack>
    </Stack>
  ) : null;
}
