import { useSnackbar } from "notistack";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  Button,
  CircularProgress,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useUserInviteAccept } from "src/api/useUserInviteAccept";
import { useUserInviteDetails } from "src/api/useUserInviteDetails";
import { PageLoader } from "src/components/PageLoader";
import { HeroSwirlContainer } from "src/components/HeroSwirlContainer/HeroSwirlContainer";
import { Logo } from "src/components/logo/Logo";
import { pageNotFoundRoute } from "../PageNotFound/PageNotFound.route";
import { UserInviteAcceptForm } from "./components/UserInviteAcceptForm/UserInviteAcceptForm";
import { UserInviteAcceptFormValues } from "./components/UserInviteAcceptForm/UserInviteAcceptForm.model";
import { userInviteAcceptFormSchema } from "./components/UserInviteAcceptForm/UserInviteAcceptForm.schema";
import { PublicUserInvitePathParams } from "./PublicUserInvite.route";
import { UserInviteExpired } from "./components/UserInviteContent/UserInviteExpired";
import { UserInviteAccepted } from "./components/UserInviteContent/UserInviteAccepted";

export const PublicUserInvitePage = (): JSX.Element => {
  const { palette, breakpoints } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const navigate = useNavigate();
  const userInviteAccept = useUserInviteAccept();

  const formHook = useForm<UserInviteAcceptFormValues>({
    mode: "onChange",
    resolver: joiResolver(userInviteAcceptFormSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
    },
  });

  const { inviteId } = useParams<PublicUserInvitePathParams>();
  const userInviteDetails = useUserInviteDetails({
    params: {
      path: {
        id: inviteId ?? "",
      },
    },
  });

  const { handleSubmit } = formHook;
  const onSubmit = handleSubmit(
    (values) => {
      userInviteAccept.mutate({
        path: {
          id: inviteId ?? "",
        },
        query: {
          firstname: values.firstName,
          lastname: values.lastName,
        },
      });
    },
    (errors) => {
      Object.values(errors).forEach((error) => {
        enqueueSnackbar({
          variant: "error",
          message: error.message,
        });
      });
    }
  );

  useEffect(() => {
    if (!inviteId) {
      const url = pageNotFoundRoute.makeUrl();
      navigate(url);
    }
  });

  const status = userInviteDetails.data?.status;

  if (status === "expired") return <UserInviteExpired />;
  if (status === "accepted") return <UserInviteAccepted />;

  const color = palette.text.primary;

  const logo = (
    <Stack direction="row" justifyContent="center">
      <Logo />
    </Stack>
  );

  const title = (
    <Typography variant="h5" align="center" color={color}>
      Accept Invitation
    </Typography>
  );

  const subtitle = (
    <Typography align="center" color={color}>
      Enter your details below to set up your account
    </Typography>
  );

  const loader = !userInviteDetails.data && userInviteDetails.isFetching && (
    <PageLoader />
  );

  const isBusy = userInviteAccept.isLoading || userInviteDetails.isFetching;
  const form = <UserInviteAcceptForm isLoading={isBusy} />;
  const submitButton = (
    <Button
      variant="contained"
      onClick={onSubmit}
      disabled={isBusy}
      size="large"
      type="submit"
      sx={{
        color: palette.info.contrastText,
        bgcolor: palette.info.main,
        borderRadius: "20px",
      }}
    >
      {userInviteDetails.isLoading ? <CircularProgress size={26} /> : "Accept"}
    </Button>
  );

  const mobileContent = loader || (
    <Stack gap={3} margin="auto" px={2} pt={2} pb={4} component={Paper}>
      {logo}
      {title}
      {subtitle}
      {form}
      {submitButton}
    </Stack>
  );

  const desktopContent = loader || (
    <Stack
      m="auto"
      p={3}
      maxWidth={400}
      boxSizing="content-box"
      component={Paper}
      gap={2}
    >
      {logo}
      {title}
      {subtitle}
      {form}
      {submitButton}
    </Stack>
  );

  return (
    <FormProvider {...formHook}>
      <HeroSwirlContainer flex={1} justifyContent="center" alignItems="center">
        {isMobile ? mobileContent : desktopContent}
      </HeroSwirlContainer>
    </FormProvider>
  );
};
