import axios from "axios";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { enqueueSnackbar } from "notistack";
import { useQuery } from "react-query";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiPath: keyof paths = "/api/WatchList";
const method: keyof paths[typeof apiPath] = "post";

type Endpoint = Required<paths[typeof apiPath][typeof method]>;
type Parameters = {
  query: Endpoint["parameters"]["query"];
  filters?: NonNullable<Endpoint["requestBody"]>["content"]["application/json"];
};

export type WatchQueryListResponse =
  Endpoint["responses"][200]["content"]["application/json"];

export type WatchListQueries = Required<
  NonNullable<WatchQueryListResponse["results"]>
>;

export const watchQueryListLazyKey = "watchQueryListLazyKey";

export function useWatchQueryListLazy(params: Parameters) {
  const { query, filters } = params;
  const { getAccessTokenSilently } = useAuth0();
  const [prevData, setPrevData] = useState<WatchQueryListResponse>();

  const hook = useQuery<null, unknown>({
    queryKey: [watchQueryListLazyKey, query, filters],
    keepPreviousData: true,

    // disable query automatic execution and caching when size is empty
    enabled: !!query?.size,
    cacheTime: query?.size ? undefined : 0,
    //
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Parameters>(apiPath, {
        query: {
          groupByCluster: false,
          includeSummary: false,
          ...query,
        },
        filters,
      });

      const { data } = await axios<WatchQueryListResponse>(url, {
        data: filters,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const total = data.total ?? 0;
      const from = data.configuration?.from ?? 0;
      const size = data.configuration?.size ?? 0;
      const incoming = data.results || [];
      const combined = [...(prevData?.results || [])];

      for (let i = 0; i < size; i++) {
        const index = from + i;
        const item = incoming[i];
        if (!item) continue;

        combined[index] = item;
      }

      const result: WatchQueryListResponse = {
        total,
        results: combined,
      };

      console.log(`@@ DEBUG:useWatchQueryListLazy`, combined);

      setPrevData(result);

      return null;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });

  return {
    ...hook,
    data: prevData,
  };
}
