import { useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  EventSourceCategory,
  EventSourceOption,
  useEventSources,
} from "src/api/useEventSources";
import { SourcesPickerProps } from "./SourcesPicker.model";
import { TagPicker } from "../TagPicker/TagPicker";
import { TagPickerMobile } from "../TagPickerMobile/TagPickerMobile";

const defaultCategories = {
  all: Object.values(EventSourceCategory),
  noPodcastColumns: Object.values(EventSourceCategory).filter(
    (v) => v !== EventSourceCategory.language
  ),
} as const;

/**
 * An API-enabled Autocomplete component for picking Event Sources.
 */
export function SourcesPicker({
  value,
  excludedOptions,
  onChange,
  isBroadcast,
  ignoreAccessFilter = false,
  ...props
}: SourcesPickerProps) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const [sourcesQuery, setSourceQuery] = useState<string | null>("");
  const [searchText] = useDebounce(sourcesQuery, 500);

  const isPodcastSelected = value.list.find(
    (v) =>
      v.group === EventSourceCategory.eventType ||
      v.value.toLowerCase() === "podcast"
  );

  const categories: EventSourceCategory[] = isPodcastSelected
    ? defaultCategories.all
    : defaultCategories.noPodcastColumns;

  const { data, isLoading, isFetching } = useEventSources({
    path: {
      categories,
      searchText: searchText ? searchText : "*",
    },
    isBroadcast,
    query: {
      ignoreAccessFilter,
    },
  });

  const { list = [], meta = {} } = data || {};

  const options = useMemo(() => {
    return list.filter((item) => {
      return !(excludedOptions ?? []).some(
        (e) => e.group === item.group && e.value === item.value
      );
    });
  }, [excludedOptions, list]);

  if (isMobile) {
    return (
      <TagPickerMobile
        value={value}
        excludedOptions={excludedOptions}
        openOnFocus
        options={options}
        presets={{}}
        onChange={onChange}
        onInputChange={setSourceQuery}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option) => option.name}
        loading={isLoading || isFetching}
        noOptionsText="Start typing to find sources..."
        getGroupLabel={(groupKey) => meta[groupKey].label}
        {...props}
      />
    );
  }

  return (
    <TagPicker<EventSourceOption>
      value={value}
      excludedOptions={excludedOptions}
      options={options}
      onChange={onChange}
      onInputChange={setSourceQuery}
      groupBy={(option) => option.group}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      filterOptions={(options) => options /* using server-side filtering */}
      getOptionLabel={(option) => option.name}
      loading={isLoading || isFetching}
      noOptionsText="Start typing to find sources..."
      getGroupLabel={(groupKey) => meta[groupKey].label}
      getGroupTotal={(groupKey) => meta[groupKey].total}
      allowMultipleLines={4}
      scrollToLast
      openOnFocus
      {...props}
    />
  );
}
