import { useDebounce } from "use-debounce";
import { Fragment, useEffect, useState } from "react";
import { Autocomplete, CircularProgress, useTheme } from "@mui/material";
import { EventSourceOption } from "src/api/useEventSources";
import { TextInputBase } from "../TextInputBase";
import { EventSourceOptionPickerProps } from "./EventSourceOptionPicker.model";

/**
 * An Autocomplete for picking EventSourceOption values.
 */
export function EventSourceOptionPicker({
  error,
  label,
  helperText,
  variant,
  onInputChange,
  ...props
}: EventSourceOptionPickerProps) {
  const { palette } = useTheme();

  const [inputValue, setInputValue] = useState("");
  const [searchText] = useDebounce(inputValue, 500);

  useEffect(() => {
    onInputChange && onInputChange(searchText);
  }, [searchText, onInputChange]);

  return (
    <Autocomplete<EventSourceOption, false, false, false>
      {...props}
      slotProps={{
        ...props.slotProps,
        paper: {
          elevation: palette.mode === "dark" ? 2 : undefined,
          ...props.slotProps?.paper,
        },
      }}
      isOptionEqualToValue={(o, v) => v.value === o.value}
      getOptionLabel={(option) => option.name}
      onInputChange={(event, value, reason) => {
        if (event && event.type === "blur") {
          setInputValue(value);
        } else if (reason !== "reset") {
          // this for keeping filter value on blur
          setInputValue(value);
        }
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={`${option.name}-${option.value}`}>
            <span>{option.name}</span>
          </li>
        );
      }}
      renderInput={(params) => {
        return (
          <TextInputBase
            {...params}
            autoComplete="off"
            error={error}
            helperText={helperText}
            label={label}
            variant={variant}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {props.loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
}
