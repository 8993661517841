import Joi from "joi";
import { USER_VALIDATION } from "src/pages/AccountsManagement/components/AccountsManagementAddUserForm/AccountsManagementAddUserForm.schema";
import { UserInformationUpdateFormValues } from "./UserInformationUpdateForm.model";

export const UserInformationSettingsFormSchema =
  Joi.object<UserInformationUpdateFormValues>({
    firstName: Joi.string()
      .label("First name")
      .required()
      .regex(USER_VALIDATION)
      .messages({
        "string.pattern.base": "Invalid first name pattern",
        "string.empty": "First name is required",
      }),
    lastName: Joi.string()
      .required()
      .label("Last name")
      .regex(USER_VALIDATION)
      .messages({
        "string.pattern.base": "Invalid last name pattern",
        "string.empty": "Last name is required",
      }),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    accountId: Joi.string().required().label("Account"),
    isActive: Joi.bool().label("Active").optional(),
    notify: Joi.bool().label("Notify user").optional(),
    phoneNumber: Joi.string().label("Phone").optional().allow(""),
    timezone: Joi.string().label("Timezone").optional().allow(""),
    roles: Joi.array().items(Joi.string()),
    createdDate: Joi.string().label("Creation date").optional().allow(""),
    expirationDate: Joi.string().label("Expiration date").optional().allow(""),
  });
