import { Stack, StackProps, useTheme } from "@mui/material";
import { forwardRef } from "react";

export const TranscriptBlock = forwardRef<HTMLDivElement, StackProps>(
  (props: StackProps, ref) => {
    const { palette } = useTheme();

    return (
      <Stack
        ref={ref}
        {...props}
        sx={{
          background:
            palette.mode === "light"
              ? palette.action.selected
              : palette.action.hover,
          ...props.sx,
        }}
      />
    );
  }
);
