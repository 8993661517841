import { endOfDay, formatISO, startOfDay } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { useWatchQueryListLazy } from "src/api/useWatchQueryListLazy";
import {
  TileLayoutLazy,
  TileLayoutLazyProps,
} from "src/components/TileLayoutLazy/TileLayoutLazy";
import { useIsMobile } from "src/utils/useIsMobile";
import { useListFilter } from "src/utils/useListFilter";
import { ScrollHeaderLayoutCustom } from "src/components/ScrollHeaderLayoutCustom/ScrollHeaderLayoutCustom";
import { ScrollHeaderLayoutCustomProps } from "src/components/ScrollHeaderLayoutCustom/ScrollHeaderLayoutCustom.model";
import { WatchListUserQueryDTO } from "src/models/WatchListUserQueryDTO";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import { WatchQueryTile } from "./WatchQueryTile";
import { WatchQueryTilesLazyProps } from "./WatchQueryTilesLazy.model";
import { WatchQueryTilesLazyEmpty } from "./WatchQueryTilesLazyEmpty";
import { WatchQueryTileLoading } from "./WatchQueryTileLoading";

type FetchParams = {
  startIndex: number;
  stopIndex: number;
};

const defaultPageSize = 8;

export function WatchQueryTilesLazy({
  id,
  filterText,
  filterQuery,
  dateRange,
  renderHeader,
  canCreateQuery,
}: WatchQueryTilesLazyProps) {
  const isMobile = useIsMobile();
  const [fetchParams, setFetchParams] = useState<FetchParams>();

  const filters = useMemo(
    () => makeQueryDefinition(filterQuery),
    [filterQuery]
  );

  const query = useMemo(() => {
    const { startIndex = 0, stopIndex } = fetchParams || {};
    const size =
      typeof startIndex === "number" && typeof stopIndex === "number"
        ? 1 + stopIndex - startIndex // 1 is added because stopIndex is inclusive
        : defaultPageSize;

    return {
      startDate: formatISO(startOfDay(dateRange[0])),
      endDate: formatISO(endOfDay(dateRange[1])),
      includeSummary: true,
      groupByCluster: false,
      from: startIndex,
      size,
    };
  }, [dateRange, fetchParams]);

  const watchQueryListLazy = useWatchQueryListLazy({
    query,
    filters,
  });

  const queries = useMemo(
    () => watchQueryListLazy.data?.results ?? [],
    [watchQueryListLazy.data?.results]
  );

  const total = watchQueryListLazy.data?.total ?? defaultPageSize;
  const filterData = useListFilter(queries || [], filterText, ["title"]);

  const renderItem: TileLayoutLazyProps<WatchListUserQueryDTO>["renderItem"] =
    useCallback(
      ({ data }) => {
        if (!data) {
          return <WatchQueryTileLoading />;
        }

        const alerts = data.alerts || [];

        return (
          <WatchQueryTile
            key={data.id}
            query={data}
            alerts={alerts}
            canCreateQuery={canCreateQuery}
          />
        );
      },
      [canCreateQuery]
    );

  const fetchBatch: TileLayoutLazyProps["loadMoreItems"] = useCallback(
    async (startIndex, stopIndex) => {
      console.log(`@@ DEBUG:fetchBatch`, startIndex, stopIndex);
      setFetchParams({
        startIndex,
        stopIndex,
      });
    },
    []
  );

  const getRowHeight: TileLayoutLazyProps["getRowHeight"] = (
    containerWidth
  ) => {
    return (containerWidth / 16) * 9 + 80 + 72;
  };

  const renderScrollContainerWrapper: ScrollHeaderLayoutCustomProps["renderScrollContainer"] =
    useCallback(
      ({ onScroll }) => {
        return (
          <TileLayoutLazy
            items={filterData}
            total={total}
            loadMoreItems={fetchBatch}
            renderItem={renderItem}
            onScroll={onScroll}
            getItemKey={(item) => item?.id}
            getRowHeight={getRowHeight}
            insets={{
              left: 1.5,
              top: 1,
              right: 1,
              bottom: 1,
            }}
          />
        );
      },
      [fetchBatch, filterData, renderItem, total]
    );

  if (isMobile) {
    return (
      <ScrollHeaderLayoutCustom
        id="watch-list-tiles"
        isLoading={watchQueryListLazy.isLoading}
        renderHeader={renderHeader}
        renderScrollContainer={renderScrollContainerWrapper}
      />
    );
  }

  return (
    <TileLayoutLazy
      items={filterData}
      total={total}
      loadMoreItems={fetchBatch}
      renderItem={renderItem}
      getItemKey={(item) => item?.id}
      getRowHeight={getRowHeight}
      renderEmpty={() => {
        return (
          <WatchQueryTilesLazyEmpty
            title={filterText ? "No data due to a text filter" : "No Data"}
          />
        );
      }}
      isLoading={watchQueryListLazy.isLoading}
      insets={{
        left: 2,
        top: 2,
        right: 2,
        bottom: 2,
      }}
    />
  );
}
