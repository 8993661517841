import {
  Typography,
  useTheme,
  Stack,
  Box,
  BoxProps,
  Tooltip,
} from "@mui/material";
import { DateRange } from "@mui/icons-material";
import { AppLink } from "src/components/AppLink/AppLink";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { TextLineClampProps } from "src/components/TextLineClamp/TextLineClamp.model";
import { getSnapshotIcon } from "src/utils/getSnapshotIcon";
import { EventType } from "src/models/EventType";
import { StationSnapshot } from "src/api/useSnapshot";
import { ClipViewer } from "src/components/ClipViewer/ClipViewer";
import {
  getSnapshotDateTime,
  getSnapshotEndDate,
} from "src/api/useSnapshot.getters";
import { SnapshotsClipViewerRoute } from "src/pages/SnapshotsClipViewer/SnapshotsClipViewer.route";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";

export function SnapshotTile({
  value,
  width = "100%",
  height = "100%",
  viewMode,
}: {
  value: StationSnapshot;
  width?: BoxProps["width"];
  height?: BoxProps["width"];
  lineClamp?: TextLineClampProps["lineClamp"];
  viewMode: ViewMode;
}) {
  const snapshotUrl = SnapshotsClipViewerRoute.makeUrl({
    stationId: value?.station?.stationGUID || "",
    date: value.thumbnailDateTime,
  });

  const { palette } = useTheme();
  const Icon = getSnapshotIcon(value?.station?.mediaType);
  return (
    <Stack
      border={`1px solid ${palette.divider}`}
      borderRadius={2}
      width={width}
      height={height}
      overflow="hidden"
    >
      <Box position="relative">
        <ClipViewer
          id={value?.station?.stationGUID || ""}
          event={{
            eventType: EventType.BCast,
            sourceId: value?.station?.stationGUID || "",
            startDateTime: value.thumbnailDateTime || "",
            endDateTime: getSnapshotEndDate(value?.thumbnailDateTime),
            mediaType: value?.station?.mediaType,
          }}
          viewMode="compact"
          aspectRatio={viewMode === ViewMode.smallTiles ? 274 / 154 : 366 / 205}
          sx={{
            [`& > .MuiBox-root:first-of-type div`]: {
              borderTopRightRadius: 7, // not 8 because of outer 1px border
              borderTopLeftRadius: 7, // not 8 because of outer 1px border
              overflow: "hidden",
            },
          }}
        />
      </Box>
      <Stack rowGap={0.5} px={2} py={1}>
        <Stack direction="row" alignItems="center">
          <Tooltip title={value?.station?.mediaType}>
            <Icon />
          </Tooltip>
          <AppLink title={value?.station?.name || ""} to={snapshotUrl} pt={0.5}>
            <TextLineClamp
              variant="subtitle1"
              color="primary"
              lineClamp={1}
              ml={1}
            >
              {value?.station?.name || ""}
            </TextLineClamp>
          </AppLink>
        </Stack>
        <Stack direction="row" alignItems="center">
          <DateRange sx={{ color: palette.action.active }} />
          <Typography variant="body2" ml={1}>
            {getSnapshotDateTime(value).toLocaleString()}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
