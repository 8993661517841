import { Update } from "@mui/icons-material";
import { alpha, IconButton, useTheme } from "@mui/material";
import { addMinutes, addSeconds, isEqual, max, min } from "date-fns";
import { DateTimeSearchResultItem } from "src/api/useDateTimeSearch";
import { EventDetails } from "src/models/EventDetails";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";

type ShiftPlayerDataProps = {
  startDateTime?: string;
  endDateTime?: string;
  shiftOffset?: number;
};

export function ShiftPlayerPlayButton({
  value,
  event,
  onShiftPlayerPlay,
  offset,
}: {
  value: DateTimeSearchResultItem;
  event: EventDetails;
  onShiftPlayerPlay?: (data: ShiftPlayerDataProps) => void;
  offset: number;
}) {
  const { palette } = useTheme();

  return (
    <IconButton
      size="large"
      onClick={() => {
        if (!value.blockEndTime || !value.blockStartTime) return;
        if (!event.startDateTime || !event.endDateTime) return;

        const startDate = addMinutes(new Date(value.blockStartTime), -1);
        const endDate = addMinutes(new Date(value.blockStartTime), 9);

        const outputData: ShiftPlayerDataProps = {};
        outputData.startDateTime = formatDateTimeWithoutTimeZone(startDate);
        outputData.endDateTime = formatDateTimeWithoutTimeZone(endDate);

        const currentCursorPosition = addSeconds(
          new Date(event.startDateTime),
          offset / 1000
        );

        const minDate = min([endDate, new Date(event.startDateTime)]);
        const maxDate = max([startDate, new Date(event.endDateTime)]);
        const isCurrentCPEarlierThanEventStartDate = isEqual(
          min([currentCursorPosition, startDate]),
          currentCursorPosition
        );
        if (
          isEqual(minDate, endDate) ||
          isEqual(maxDate, startDate) ||
          isCurrentCPEarlierThanEventStartDate
        ) {
          outputData.shiftOffset = 60;
        }

        onShiftPlayerPlay && onShiftPlayerPlay(outputData);
      }}
      sx={{
        position: "absolute",
        top: 55,
        left: "calc(50% - 30px)",
        color:
          palette.mode === "light"
            ? palette.primary.contrastText
            : palette.text.primary,
        backgroundColor:
          palette.mode === "light"
            ? alpha(palette.action.active, 0.3)
            : alpha(palette.common.black, 0.3),
        "&:hover": {
          backgroundColor:
            palette.mode === "light"
              ? palette.action.active
              : alpha(palette.common.black, 0.5),
        },
      }}
    >
      <Update fontSize="large" />
    </IconButton>
  );
}
