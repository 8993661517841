import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { SearchOutlined } from "@mui/icons-material";
import { TextInputBase } from "src/components/TextInputBase";
import { LoadingButton } from "src/components/buttons/LoadingButton";
import { DateTimeZonedPicker } from "src/components/DateTimeZonedPicker/DateTimeZonedPicker";
import { EventSourceMarketPicker } from "src/components/EventSourceMarketPicker/EventSourceMarketPicker";
import { EventSourceStationPicker } from "src/components/EventSourceStationPicker/EventSourceStationPicker";
import { DateTimeSearchFormValues } from "src/pages/DateTimeSearchRoot/hooks/useDateTimeSearchForm";
import { EventSourcePickerMobile } from "src/components/EventSourcePickerMobile/EventSourcePickerMobile";
import { EventSourceCategory } from "src/api/useEventSources";

export function DateTimeSearchForm({
  maxDateTime,
  timeZone,
  isLoading,
  onSubmit,
}: {
  maxDateTime?: Date;
  timeZone?: string;
  isLoading?: boolean;
  onSubmit: (form: DateTimeSearchFormValues) => void;
}) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const formContext = useFormContext<DateTimeSearchFormValues>();
  const { control, formState, handleSubmit, watch } = formContext;
  const isBusy = formState.isSubmitting || isLoading;

  const { market } = watch();

  const onSubmitWrapper = handleSubmit(onSubmit);

  const marketInput = (
    <Controller
      name="market"
      control={control}
      render={({ field, fieldState }) => {
        return isMobile ? (
          <EventSourcePickerMobile
            id="timezone-picker"
            categories={[EventSourceCategory.market]}
            label={"Market"}
            value={field.value}
            onChange={field.onChange}
            error={!!fieldState.error}
            onBlur={field.onBlur}
            disabled={isBusy}
            helperText={fieldState.error?.message || " "}
          />
        ) : (
          <EventSourceMarketPicker
            id="timezone-picker"
            label="Market"
            value={field.value}
            onChange={(_e, nextMarket) => field.onChange(nextMarket)}
            onBlur={field.onBlur}
            disabled={isBusy}
            fullWidth
            error={!!fieldState.error}
            helperText={fieldState.error?.message || " "}
          />
        );
      }}
    />
  );

  const stationInput = (
    <Controller
      name="station"
      control={control}
      render={({ field, fieldState }) => {
        return isMobile ? (
          <EventSourcePickerMobile
            id="station-picker"
            categories={[EventSourceCategory.station]}
            label={"Station"}
            value={field.value}
            onChange={field.onChange}
            error={!!fieldState.error}
            onBlur={field.onBlur}
            disabled={isBusy}
            helperText={fieldState.error?.message || " "}
            queryParams={
              market?.value
                ? {
                    marketFilter: Number(market.value),
                  }
                : undefined
            }
          />
        ) : (
          <EventSourceStationPicker
            id="station-picker"
            label="Station"
            value={field.value}
            queryParams={
              market?.value
                ? {
                    marketFilter: Number(market.value),
                  }
                : undefined
            }
            onChange={(_e, v) => field.onChange(v)}
            onBlur={field.onBlur}
            disabled={isBusy}
            fullWidth
            error={!!fieldState.error}
            helperText={fieldState.error?.message || " "}
          />
        );
      }}
    />
  );

  const dateTimeInput = (
    <Controller
      name="dateTime"
      control={control}
      render={({ field, fieldState }) => {
        const label = timeZone
          ? `Date Time (${timeZone.replace("_", " ")})`
          : "Date Time";

        return (
          <DateTimeZonedPicker
            label={label}
            value={field.value}
            onChange={field.onChange}
            disabled={isBusy}
            timezone={timeZone}
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            maxDateTime={maxDateTime}
            loading={isLoading}
            slotProps={{
              textField: {
                id: "datetime-zoned-picker",
                autoComplete: "off",
                fullWidth: true,
                error: !!fieldState.error,
                helperText: fieldState.error?.message || " ",
                onBlur: field.onBlur,
              },
            }}
            slots={{
              textField: TextInputBase,
            }}
          />
        );
      }}
    />
  );

  return (
    <Grid
      container
      rowSpacing={isMobile ? 1.5 : 3}
      columnSpacing={{ xs: 2, xl: 3 }}
      sx={{ justifyContent: "center" }}
    >
      <Grid item xs={12} md={9} lg={true}>
        {marketInput}
      </Grid>

      <Grid item xs={12} md={9} lg={true}>
        {stationInput}
      </Grid>

      <Grid item xs={12} md={9} lg={true}>
        {dateTimeInput}
      </Grid>

      <Grid item xs={12} md={9} lg="auto">
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          sx={{
            width: "100%",
            height: 56,
            minWidth: isMobile ? 160 : 176,
          }}
          disabled={isBusy || !formState.isValid || !timeZone}
          loading={isBusy}
          startIcon={<SearchOutlined />}
          onClick={onSubmitWrapper}
        >
          Search
        </LoadingButton>
      </Grid>
    </Grid>
  );
}
