import { Fragment, useCallback, useMemo, useState } from "react";
import {
  Article,
  BurstMode,
  ContentCut,
  Info,
  LiveTv,
  People,
  Settings,
} from "@mui/icons-material";
import { components } from "@tveyes/twosionwebapischema";
import {
  Alert,
  Box,
  Collapse,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  StackProps,
  ToggleButton,
  Tooltip,
  alertClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AppTab } from "src/components/AppTabLayout/AppTab";
import { AppTabLayout } from "src/components/AppTabLayout/AppTabLayout";
import { AppTopNavBarMobile } from "src/components/AppTopNavBarMobile/AppTopNavBarMobile";
import { ClipViewer } from "src/components/ClipViewer/ClipViewer";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { ViewModeSwitch } from "src/components//ViewModeSwitch/ViewModeSwitch";
import { TranscriptView } from "src/components/TranscriptView/TranscriptView";
import { EventType } from "src/models/EventType";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { useOpenState } from "src/utils/useOpenState";
import { useClipViewLayout } from "src/utils/useClipViewLayout";
import { MediaCenterItem } from "src/api/useMediaCenter";
import { downloadMediaCenterMedia } from "src/api/useMediaCenterMedia";
import { useDownloadFilesSnackbar } from "src/api/useDownloadFilesSnackbar";
import { BCastProgram } from "src/components/ClipViewTabs/BCastProgram/BCastProgram";
import { BCastViewership } from "src/components/ClipViewTabs/BCastViewership/BCastViewership";
import { PCastEpisode } from "src/components/ClipViewTabs/PCastEpisode/PCastEpisode";
import { PCastSource } from "src/components/ClipViewTabs/PCastSource/PCastSource";
import { PCastAudience } from "src/components/ClipViewTabs/PCastAudience/PCastAudience";
import { PageHeaderDesktop } from "src/components/PageHeaderDesktop/PageHeaderDesktop";
import { ClipViewFooterMobile } from "src/components/ClipViewFooterMobile/ClipViewFooterMobile";
import { ReportCreateDialog } from "src/components/ReportCreateDialog/ReportCreateDialog";
import { ReportCreateFormValues } from "src/components/ReportCreateForm/ReportCreateForm.model";
import { DownloadFilesSnackbar } from "src/components/DownloadFilesSnackbar/DownloadFilesSnackbar";
import { GlobalSearchDialog } from "src/components/GlobalSearchDialog/GlobalSearchDialog";
import { GlobalSearchDesktopButton } from "src/components/GlobalSearchDesktopButton/GlobalSearchDesktopButton";
import { EventDetails } from "src/models/EventDetails";
import { useClipsSave } from "src/api/useClipsSave";
import { useReportCreate } from "src/api/useReportCreate";
import {
  useShareEventFormSnackbar,
  useShareEventLinkSnackbar,
} from "src/api/useShareEventSnackbar";
import { useGlobalSearchOpen } from "src/api/useGlobalSearchOpen";
import { useSessionContext } from "src/api/useSessionContext";
import { ShiftPlayerDataProps } from "src/pages/WatchListTermResultClipEditor/WatchListTermResultClipEditor.page";
import { ClipViewerExportButton } from "../ClipViewer/ClipViewerExportButton/ClipViewerExportButton";
import { TranscriptLineBundleViewLabelLayout } from "../TranscriptLineBundleView/TranscriptLineBundleView.const";
import { ClipViewSettingsDialog } from "../ClipViewSettingsDialog/ClipViewSettingsDialog";
import { PageLayoutDesktop } from "../PageLayoutDesktop";
import { PageLayoutMobile } from "../PageLayoutMobile";
import { SnackbarBase } from "../SnackbarBase/SnackbarBase";
import { ShareButtonDesktop } from "../ShareButtonDesktop/ShareButtonDesktop";
import { ShareEventsDialog } from "../ShareEventsDialog/ShareEventsDialog";
import { ClipViewDisplayFAB } from "../ClipViewDisplayFAB/ClipViewDisplayFAB";
import { ShareMediaDialog } from "../ShareMediaDialog/ShareMediaDialog";
import { ShiftPlayerDrawer } from "../ShiftPlayerDrawer/ShiftPlayerDrawer";
import { ShiftPlayerDrawerMobile } from "../ShiftPlayerDrawerMobile/ShiftPlayerDrawerMobile";
import { makeDates } from "../ClipEditorPage/ClipEditorPage.utils";

export const ClipViewerPage = ({
  event,
  query,
  mediaItemId,
  loading,
  routeRoot,
  onEdit,
  onShiftPlayerPlay,
  initialStartDateTime,
  shiftOffset,
}: {
  routeRoot:
    | "Watchlist"
    | "PowerSearch"
    | "Date/Time search"
    | "MediaCenter"
    | "Snapshots";
  event: EventDetails;
  query?: components["schemas"]["QueryDefinition"];
  mediaItemId?: string;
  loading?: boolean;
  onEdit?: () => void;
  shiftOffset?: number;
  onShiftPlayerPlay?: (data: ShiftPlayerDataProps) => void;
  initialStartDateTime?: string;
}) => {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const clipLayout = useClipViewLayout();
  const { userId } = useSessionContext();
  const [autoScroll, setAutoScroll] = useState(true);
  const [showHighlight, setShowHighlight] = useState(true);
  const [offset, setOffset] = useState(0);
  const [seekTo, setSeekTo] = useState(0);
  const [pageScroll, setPageScroll] = useState(0);
  const clipViewSettingsDialog = useOpenState();
  const [mobileClipViewCollapsed, setMobileClipViewCollapsed] = useState(false);
  const exportButtonState = useOpenState();
  const shareDialog = useOpenState();
  const reportCreateDialog = useOpenState();
  const reportCreateSnackBar = useOpenState();
  const shareFormSnackBar = useShareEventFormSnackbar();
  const shareLinkSnackBar = useShareEventLinkSnackbar();
  const downloadFilesSnackBar = useDownloadFilesSnackbar();
  const watchQuerySearchDialog = useGlobalSearchOpen();
  const clipSave = useClipsSave({});

  const [viewMode, setViewMode] = useViewModeQueryParam<ViewMode>({
    paramKey: "layout",
    defaultValue: ViewMode.portrait,
  });

  const rightSideDrawerState = useOpenState();
  const [shiftPlayerOffset, setShiftPlayerOffset] = useState(0);
  const [collapsedMobileVersion, setCollapsedMobileVersion] = useState(false);

  const isFeatureShiftPlayerAvailable = Boolean(onShiftPlayerPlay);
  const hasShiftPlayer =
    event.eventType === EventType.BCast || event.eventType === EventType.Radio;
  const isShiftPlayerFeatureAvailable =
    isFeatureShiftPlayerAvailable && hasShiftPlayer;

  const editClip = useMemo(
    () =>
      !onEdit ? null : (
        <ToggleButton
          value={true}
          size="small"
          sx={{ height: 42, width: 42 }}
          onClick={onEdit}
        >
          <ContentCut sx={{ transform: "rotate(180deg)" }} color="primary" />
        </ToggleButton>
      ),
    [onEdit]
  );

  const reportCreateMutation = useReportCreate({
    options: {
      onSuccess: () => {
        reportCreateDialog.hide();
        reportCreateSnackBar.show();
      },
    },
  });

  const createReport = useCallback(
    (data: ReportCreateFormValues) => {
      reportCreateMutation.mutate({
        title: data.title,
        description: data.description,
        selectedEvents: [event],
        archive: true,
      });
    },
    [reportCreateMutation, event]
  );

  const downloadMediaItem = useCallback(
    (item: MediaCenterItem) => {
      if (mediaItemId) {
        downloadMediaCenterMedia({
          userId,
          itemId: mediaItemId,
          title: item.title as string,
        });
      }
    },
    [userId, mediaItemId]
  );

  const handleDownload = useCallback(() => {
    if (routeRoot === "MediaCenter") {
      downloadMediaItem(event as MediaCenterItem);
      return;
    }
    clipSave.mutate({
      query: [
        {
          eventHighlights: event,
          archive: false,
        },
      ],
    });
  }, [clipSave, event, downloadMediaItem, routeRoot]);

  const toolbar = useMemo(
    () => (
      <Stack
        direction="row"
        columnGap={{ sm: 1, lg: 2 }}
        height="100%"
        alignItems="flex-end"
      >
        <GlobalSearchDesktopButton />
        <ViewModeSwitch
          value={viewMode}
          onChange={setViewMode}
          options={[ViewMode.portrait, ViewMode.book, ViewMode.landscape]}
          size="small"
        />
        <Tooltip title="Edit clip">
          <Box>{editClip}</Box>
        </Tooltip>
        <ClipViewerExportButton
          state={exportButtonState}
          value={event}
          sx={{
            minWidth: { xs: 100, md: 160 },
            height: 42,
          }}
          hideSaveForLongerOption={routeRoot === "MediaCenter"}
          createReport={reportCreateDialog.show}
          handleDownload={handleDownload}
        />

        <ShareButtonDesktop showShareDialog={shareDialog.show} />
      </Stack>
    ),
    [
      editClip,
      event,
      setViewMode,
      viewMode,
      routeRoot,
      exportButtonState,
      reportCreateDialog.show,
      shareDialog.show,
      handleDownload,
    ]
  );

  const dateTimeLabel = event.startDateTime
    ? new Date(event.startDateTime).toLocaleTimeString()
    : " - ";

  const titleLabel = loading
    ? "Loading..."
    : [dateTimeLabel, event.source].filter(Boolean).join(" - ");

  const handleCurrentTimeChange = useCallback(
    (_nextId: string, nextTime: number) => {
      setOffset(nextTime);
    },
    [setOffset]
  );

  const toggleMobileClipViewHeight = useCallback(() => {
    setMobileClipViewCollapsed(!mobileClipViewCollapsed);
  }, [setMobileClipViewCollapsed, mobileClipViewCollapsed]);

  const onTimeExtensionClick = useCallback(
    (currentTime: number, v?: string) => {
      if (!v && isMobile) {
        setCollapsedMobileVersion((prevState) => !prevState);
        toggleMobileClipViewHeight();
      }

      if (!v) {
        !rightSideDrawerState.isOpen && setShiftPlayerOffset(currentTime);

        return rightSideDrawerState.isOpen
          ? rightSideDrawerState.hide()
          : rightSideDrawerState.show();
      }

      if (!event.endDateTime || !event.startDateTime || !onShiftPlayerPlay)
        return;

      const data = makeDates(
        new Date(event.startDateTime),
        new Date(event.endDateTime),
        v
      );
      onShiftPlayerPlay(data);
    },
    [
      event,
      onShiftPlayerPlay,
      rightSideDrawerState,
      isMobile,
      toggleMobileClipViewHeight,
    ]
  );

  const showMedia = [ViewMode.portrait, ViewMode.landscape].includes(viewMode);

  const clipViewerDesktop = useMemo(
    () => (
      <ClipViewer
        id="clip-viewer-desktop"
        key="clip-viewer"
        loading={loading}
        event={event}
        onCurrentTimeChange={handleCurrentTimeChange}
        shiftOffset={shiftOffset}
        seekOffsetMs={seekTo}
        hotStart
        showMedia={showMedia}
        showTimeExtensionControls={isShiftPlayerFeatureAvailable}
        onTimeExtensionClick={onTimeExtensionClick}
        timeExtensionSeconds={240}
      />
    ),
    [
      event,
      loading,
      handleCurrentTimeChange,
      onTimeExtensionClick,
      showMedia,
      seekTo,
      isShiftPlayerFeatureAvailable,
      shiftOffset,
    ]
  );

  const clipViewerMobile = useMemo(
    () => (
      <ClipViewer
        id="clip-viewer-mobile"
        key="clip-viewer"
        loading={loading}
        event={event}
        onCurrentTimeChange={handleCurrentTimeChange}
        shiftOffset={shiftOffset}
        seekOffsetMs={seekTo}
        showMedia
        viewMode={mobileClipViewCollapsed ? "playback" : "full"}
        sx={{
          ".player-container": {
            maxHeight: mobileClipViewCollapsed ? "112px" : undefined,
          },
        }}
        hotStart
        showTimeExtensionControls={isShiftPlayerFeatureAvailable}
        onTimeExtensionClick={onTimeExtensionClick}
        timeExtensionSeconds={240}
      />
    ),
    [
      event,
      loading,
      handleCurrentTimeChange,
      onTimeExtensionClick,
      seekTo,
      mobileClipViewCollapsed,
      isShiftPlayerFeatureAvailable,
      shiftOffset,
    ]
  );

  const headerDesktop = useMemo(
    () => (
      <PageHeaderDesktop
        title={titleLabel}
        toolbar={toolbar}
        breadcrumbLabels={{
          "2": routeRoot,
          "1": "Results",
          "0": loading ? "Loading..." : event.title || " - ",
        }}
      />
    ),
    [titleLabel, toolbar, routeRoot, loading, event.title]
  );

  const tabContentLayout: StackProps["direction"] =
    isMobile || viewMode === ViewMode.landscape || viewMode === ViewMode.book
      ? "column" // for usage in mobile and compact mode
      : "row";

  const programTabContent = useMemo(
    () => (
      <BCastProgram
        event={event}
        flex={1}
        py={2}
        direction={tabContentLayout}
      />
    ),
    [event, tabContentLayout]
  );

  const viewershipTabContent = useMemo(
    () => (
      <BCastViewership
        event={event}
        direction={tabContentLayout}
        flex={1}
        py={2}
      />
    ),
    [event, tabContentLayout]
  );

  const episodeTabContent = useMemo(
    () => (
      <PCastEpisode
        event={event}
        flex={1}
        py={2}
        direction={tabContentLayout}
      />
    ),
    [event, tabContentLayout]
  );

  const sourceTabContent = useMemo(
    () => (
      <PCastSource event={event} flex={1} py={2} direction={tabContentLayout} />
    ),
    [event, tabContentLayout]
  );

  const audienceTabContent = useMemo(
    () => (
      <PCastAudience
        event={event}
        flex={1}
        py={2}
        direction={tabContentLayout}
      />
    ),
    [event, tabContentLayout]
  );

  const transcriptViewDesktop = useMemo(
    () => (
      <TranscriptView
        offset={offset}
        event={event}
        loading={loading}
        overflow="scroll"
        flex={1}
        maxWidth={600}
        mx="auto"
        pb={2}
        autoScroll={autoScroll}
        pageScrollOffset={viewMode === ViewMode.portrait ? pageScroll : 0}
        onAutoScrollChange={setAutoScroll}
        onClickLineBlock={setSeekTo}
        showTimeLabel={
          viewMode === ViewMode.portrait
            ? TranscriptLineBundleViewLabelLayout.top
            : TranscriptLineBundleViewLabelLayout.left
        }
      />
    ),
    [event, loading, offset, autoScroll, viewMode, pageScroll]
  );

  const transcriptViewMobile = useMemo(
    () => (
      <TranscriptView
        offset={offset}
        event={event}
        loading={loading}
        hideAutoScrollSwitch
        overflow="scroll"
        flex={1}
        maxWidth={600}
        mx="auto"
        height="100%"
        autoScroll={autoScroll}
        hideHighlight={!showHighlight}
        onAutoScrollChange={setAutoScroll}
        onClickLineBlock={setSeekTo}
        showTimeLabel={TranscriptLineBundleViewLabelLayout.top}
      />
    ),
    [event, loading, offset, autoScroll, showHighlight]
  );

  const tabSectionDesktop = useMemo(
    () => (
      <Fragment>
        {event.eventType === EventType.BCast && (
          <AppTabLayout flex={1}>
            <AppTab
              label="Program"
              value="program"
              children={programTabContent}
            />
            <AppTab
              label="Viewership"
              value="viewership"
              children={viewershipTabContent}
            />
          </AppTabLayout>
        )}

        {(event.eventType === EventType.PCast ||
          event.eventType === EventType.YouTube) && (
          <AppTabLayout flex={1}>
            <AppTab
              label="Episode"
              value="episode"
              children={episodeTabContent}
            />
            <AppTab label="Source" value="Source" children={sourceTabContent} />
            <AppTab
              label="Audience"
              value="audience"
              children={audienceTabContent}
            />
          </AppTabLayout>
        )}
      </Fragment>
    ),
    [
      audienceTabContent,
      episodeTabContent,
      programTabContent,
      sourceTabContent,
      viewershipTabContent,
      event.eventType,
    ]
  );

  const tabSectionMobile = useMemo(
    () => (
      <Fragment>
        {/* Broadcast Tabs */}
        {event.eventType === EventType.BCast && (
          <AppTabLayout flex={1} layout="mobile">
            <AppTab
              label="Program"
              value="program"
              icon={<LiveTv />}
              children={programTabContent}
              overflow="auto"
            />

            <AppTab
              label="Transcription"
              value="transcription"
              icon={<Article />}
              children={transcriptViewMobile}
              overflow="auto"
            />

            <AppTab
              label="Viewership"
              value="viewership"
              icon={<People />}
              children={viewershipTabContent}
              overflow="auto"
            />
          </AppTabLayout>
        )}
        {/* Broadcast Tabs */}

        {/* Podcast Tabs */}
        {(event.eventType === EventType.PCast ||
          event.eventType === EventType.YouTube) && (
          <AppTabLayout flex={1} layout="mobile">
            <AppTab
              label="Transcription"
              value="transcription"
              icon={<Article />}
              children={transcriptViewMobile}
              overflow="auto"
            />
            <AppTab
              label="Episode"
              value="episode"
              icon={<BurstMode />}
              children={episodeTabContent}
              overflow="auto"
            />

            <AppTab
              label="Source"
              value="source"
              icon={<Info />}
              children={sourceTabContent}
              overflow="auto"
            />

            <AppTab
              label="Audience"
              value="audience"
              icon={<People />}
              children={audienceTabContent}
              overflow="auto"
            />
          </AppTabLayout>
        )}
        {/* Podcast Tabs */}
      </Fragment>
    ),
    [
      event.eventType,
      programTabContent,
      viewershipTabContent,
      transcriptViewMobile,
      episodeTabContent,
      sourceTabContent,
      audienceTabContent,
    ]
  );

  const mobileContent = useMemo(
    () => (
      <Stack height="100%" width="100%" overflow="hidden">
        <Collapse in={!collapsedMobileVersion}>
          <Stack
            mr={1}
            minHeight={56}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <AppTopNavBarMobile title={titleLabel} ml={0.5} />

            <IconButton onClick={clipViewSettingsDialog.show}>
              <Settings sx={{ color: palette.primary.main }} />
            </IconButton>
          </Stack>
        </Collapse>

        {clipViewerMobile}
        {tabSectionMobile}

        <ClipViewFooterMobile
          event={event}
          onEdit={onEdit}
          createReport={reportCreateDialog.show}
          showShareDialog={shareDialog.show}
          handleDownload={handleDownload}
        />

        <ClipViewSettingsDialog
          open={clipViewSettingsDialog.isOpen}
          onClose={clipViewSettingsDialog.hide}
          isAutoScroll={autoScroll}
          onAutoScrollChange={setAutoScroll}
          isHighlight={showHighlight}
          onHighlightChange={setShowHighlight}
        />

        <ClipViewDisplayFAB
          collapsed={mobileClipViewCollapsed}
          onClick={toggleMobileClipViewHeight}
          sx={{
            position: "fixed",
            right: "20px",
            bottom: "88px",
          }}
        />
      </Stack>
    ),
    [
      titleLabel,
      clipViewSettingsDialog.show,
      clipViewSettingsDialog.isOpen,
      clipViewSettingsDialog.hide,
      palette.primary.main,
      clipViewerMobile,
      mobileClipViewCollapsed,
      tabSectionMobile,
      event,
      onEdit,
      toggleMobileClipViewHeight,
      reportCreateDialog.show,
      shareDialog.show,
      autoScroll,
      showHighlight,
      handleDownload,
      collapsedMobileVersion,
    ]
  );

  const desktopContentPortrait = useMemo(
    () => (
      <Stack gap={3} flex={1}>
        <Stack direction="row" gap={3}>
          {null}

          <Stack
            direction="column"
            flex={clipLayout.main.flex}
            flexBasis={clipLayout.main.flexBasis}
            borderRadius={2}
            overflow="hidden"
            component={Paper}
            children={clipViewerDesktop}
          />

          <Box
            position="relative"
            flex={clipLayout.right.flex}
            flexBasis={clipLayout.right.flexBasis}
            children={
              <Paper
                children={transcriptViewDesktop}
                sx={{
                  display: "flex",
                  position: "absolute",
                  left: 0,
                  top: 0,
                  right: 0,
                  bottom: 0,
                  overflow: "hidden",
                }}
              />
            }
          />
        </Stack>

        <Stack component={Paper} children={tabSectionDesktop} />
      </Stack>
    ),
    [clipViewerDesktop, clipLayout, tabSectionDesktop, transcriptViewDesktop]
  );

  const desktopContentBook = useMemo(
    () => (
      <Stack direction="row" gap={3} flex={1} height="100%" overflow="hidden">
        <Stack
          direction="column"
          flex={clipLayout.main.flex}
          flexBasis={clipLayout.main.flexBasis}
          position="relative"
        >
          <Paper
            children={transcriptViewDesktop}
            sx={{
              display: "flex",
              flex: 1,
              overflow: "hidden",
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          />
          <Stack component={Paper} children={clipViewerDesktop} />
        </Stack>
        <Stack
          flex={clipLayout.right.flex}
          flexBasis={clipLayout.right.flexBasis}
          component={Paper}
          overflow="hidden"
          children={tabSectionDesktop}
        />
      </Stack>
    ),
    [clipViewerDesktop, tabSectionDesktop, transcriptViewDesktop, clipLayout]
  );

  const desktopContentLandscape = useMemo(
    () => (
      <Stack gap={3} flex={1}>
        <Stack direction="row" gap={3}>
          {
            /* 
            react resets state when component changes position in the component there
            this null preserves position and player state
            */
            null
          }
          <Stack
            flex={clipLayout.main.flex}
            flexBasis={clipLayout.main.flexBasis}
            borderRadius={2}
            overflow="hidden"
            component={Paper}
            children={clipViewerDesktop}
          />

          <Box
            position="relative"
            flex={clipLayout.main.flex}
            flexBasis={clipLayout.right.flexBasis}
            children={
              <Paper
                children={tabSectionDesktop}
                sx={{
                  display: "flex",
                  position: "absolute",
                  left: 0,
                  top: 0,
                  right: 0,
                  bottom: 0,
                  overflow: "visible",
                }}
              />
            }
          />
        </Stack>

        <Stack flex={1} component={Paper} overflow="hidden" maxHeight={300}>
          {transcriptViewDesktop}
        </Stack>
      </Stack>
    ),
    [clipViewerDesktop, clipLayout, tabSectionDesktop, transcriptViewDesktop]
  );

  const desktopContent = useMemo(() => {
    if (viewMode === ViewMode.portrait) {
      return desktopContentPortrait;
    }

    if (viewMode === ViewMode.book) {
      return desktopContentBook;
    }

    if (viewMode === ViewMode.landscape) {
      return desktopContentLandscape;
    }
  }, [
    desktopContentBook,
    desktopContentLandscape,
    desktopContentPortrait,
    viewMode,
  ]);

  const commonContent = (
    <Fragment>
      <GlobalSearchDialog
        open={watchQuerySearchDialog.isOpen}
        onClose={watchQuerySearchDialog.hide}
      />
      <ReportCreateDialog
        open={reportCreateDialog.isOpen}
        onClose={reportCreateDialog.hide}
        createReport={createReport}
        isLoading={reportCreateMutation.isLoading}
      />

      <SnackbarBase
        open={reportCreateSnackBar.isOpen}
        onClose={reportCreateSnackBar.hide}
        title="Your Report is being processed."
        subtitle={
          <>
            It may take some time depending on the amount of items in the
            report.
            <br />
            Finished report appears in your Reports page and your Email
          </>
        }
      />

      <DownloadFilesSnackbar
        open={downloadFilesSnackBar.isOpen}
        onClose={downloadFilesSnackBar.hide}
      />

      {routeRoot === "MediaCenter" && mediaItemId ? (
        <ShareMediaDialog
          title="Share Media Center Item"
          itemsIds={[mediaItemId]}
          open={shareDialog.isOpen}
          onClose={shareDialog.hide}
          onSubmit={shareFormSnackBar.show}
          copyShareLinkCallback={shareLinkSnackBar.show}
        />
      ) : (
        <ShareEventsDialog
          open={shareDialog.isOpen}
          events={[event]}
          onClose={shareDialog.hide}
          onSubmit={shareFormSnackBar.show}
          copyShareLinkCallback={shareLinkSnackBar.show}
        />
      )}

      <Snackbar
        open={shareLinkSnackBar.isOpen}
        autoHideDuration={5000}
        onClose={shareLinkSnackBar.hide}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="success"
          variant="filled"
          onClose={shareLinkSnackBar.hide}
          sx={{
            borderRadius: "4px",
            alignItems: "center",
            [`.${alertClasses.action}`]: {
              pt: 0,
            },
          }}
        >
          The link to the Event was successfully copied to the clipboard
        </Alert>
      </Snackbar>

      <SnackbarBase
        open={shareFormSnackBar.isOpen}
        onClose={shareFormSnackBar.hide}
        title="Your share is being processed."
        subtitle="You and your recipients will get an email."
      />
    </Fragment>
  );

  if (isMobile) {
    return (
      <Fragment>
        <PageLayoutMobile
          content={mobileContent}
          hideTopBar={collapsedMobileVersion}
        />
        {commonContent}
        {hasShiftPlayer && (
          <ShiftPlayerDrawerMobile
            initialStartDateTime={initialStartDateTime}
            open={rightSideDrawerState.isOpen}
            onClose={() => {
              rightSideDrawerState.hide();
              setCollapsedMobileVersion((prevState) => !prevState);
              toggleMobileClipViewHeight();
            }}
            onOpen={rightSideDrawerState.show}
            onShiftPlayerPlay={onShiftPlayerPlay}
            minSpanSec={2}
            event={event}
            query={query}
            shiftPlayerOffset={shiftPlayerOffset}
            offset={offset}
            mode="view"
          />
        )}
      </Fragment>
    );
  }
  return (
    <Fragment>
      <PageLayoutDesktop
        header={headerDesktop}
        content={desktopContent}
        overflow={viewMode === ViewMode.book ? "hidden" : "scroll"}
        onScroll={(e) => {
          const { scrollTop = 0 } = e.nativeEvent.currentTarget as HTMLElement;
          setPageScroll(scrollTop);
        }}
      />
      {commonContent}
      {hasShiftPlayer && (
        <ShiftPlayerDrawer
          initialStartDateTime={initialStartDateTime}
          open={rightSideDrawerState.isOpen}
          onClose={rightSideDrawerState.hide}
          onShiftPlayerPlay={onShiftPlayerPlay}
          minSpanSec={2}
          event={event}
          query={query}
          mode="view"
          shiftPlayerOffset={shiftPlayerOffset}
          offset={offset}
        />
      )}
    </Fragment>
  );
};
