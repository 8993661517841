import Joi from "joi";
import { USER_VALIDATION } from "src/pages/AccountsManagement/components/AccountsManagementAddUserForm/AccountsManagementAddUserForm.schema";
import { DuplicateUserFormValues } from "./DuplicateUserForm.model";

export const DuplicateDialogFormSchema = Joi.object<DuplicateUserFormValues>({
  email: Joi.string()
    .required()
    .messages({
      "string.empty": "Email is required",
    })
    .email({ tlds: { allow: false } }),
  firstName: Joi.string().regex(USER_VALIDATION).required().messages({
    "string.pattern.base": "Invalid first name pattern",
    "string.empty": "First name is required",
  }),
  lastName: Joi.string().regex(USER_VALIDATION).required().messages({
    "string.pattern.base": "Invalid last name pattern",
    "string.empty": "Last name is required",
  }),
  id: Joi.string().required(),
});
