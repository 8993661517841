import { components } from "@tveyes/twosionwebapischema";
import { AccountResponse } from "src/api/useAccount";
import { EventSourceOption, EventSourcesData } from "src/api/useEventSources";
import { ContentAccessFilter } from "src/models/ContentAccessFilter";
import { AccountsManagementAddUserFormValues } from "src/pages/AccountsManagement/components/AccountsManagementAddUserForm/AccountsManagementAddUserForm.model";
import { createAccountDefaultValues } from "src/pages/AccountsManagementCreateAccount/AccountsManagementCreateAccount.const";
import { isContentAccessRule } from "src/utils/isContentAccessRule";
import { makeTypedPropList } from "src/utils/makeTypedPropList";
import { typeDict } from "src/utils/makeWatchQueryFormValues";
import { EditAccountFeaturesFormValues } from "../components/AccountsManagementEditFeaturesTab/AccountManagementEditFeatures.model";

type AccountContentFilters =
  | {
      includedFields: { list: EventSourceOption[]; logic: string };
      excludedFields: { list: EventSourceOption[]; logic: string };
    }
  | undefined;

type ListGroup = {
  group: string;
  value: string | number;
};

function getListGroup(
  type: "included" | "excluded",
  data: ContentAccessFilter
) {
  const keys = makeTypedPropList(data);
  return keys.reduce((res: ListGroup[], next) => {
    const pair = data[next];

    const groupType = typeDict[next];

    if (!isContentAccessRule(pair) || !groupType) {
      return res;
    }

    const items = data[next][type].map((item) => ({
      value: item,
      group: groupType,
    }));

    res.push(...items);

    return res;
  }, []);
}

export function sourceFilterMapper(
  contentFilters: ContentAccessFilter | null,
  sourceData?: EventSourcesData | undefined
) {
  if (contentFilters && sourceData) {
    const includeList = getListGroup("included", contentFilters);
    const excludeList = getListGroup("excluded", contentFilters);

    const includedFields = sourceData.list.filter((item2) => {
      return includeList.some((item1) => {
        //Needed to match MarketID number value from Accounts with MarkedID string from EventSourceOption
        if (item1.group === "MarketID") {
          return (
            item1.value === Number(item2.value) && item1.group === item2.group
          );
        }
        return item1.value === item2.value && item1.group === item2.group;
      });
    });
    const excludedFields = sourceData.list.filter((item2) => {
      return excludeList.some((item1) => {
        if (item1.group === "MarketID") {
          return (
            item1.value === Number(item2.value) && item1.group === item2.group
          );
        }
        return item1.value === item2.value && item1.group === item2.group;
      });
    });
    return {
      includedFields: {
        list: includedFields,
        logic: "or",
      },
      excludedFields: {
        list: excludedFields,
        logic: "or",
      },
    };
  }
}

export function makeCreateUserDefaultValues(
  accountData: AccountResponse,
  sources: AccountContentFilters
): AccountsManagementAddUserFormValues {
  return {
    //General Tab
    isActive: true,
    notify: false,
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    accountName: accountData.name || "",
    timezone: accountData.timeZone || "",
    expirationDate: accountData.expirationDate,
    creationDate: new Date().toISOString(),
    roles: ["User"],
    //Features
    enableMediaDownloads:
      accountData.entitlements?.enableMediaDownloads?.value || false,
    enableEditWatchList:
      accountData.entitlements?.enableEditWatchList?.value || false,
    enableAdFiltering:
      accountData.entitlements?.enableAdFiltering?.value || false,
    enableCustomReportBanner:
      accountData.entitlements?.enableCustomReportBanner?.value || false,
    enableReportDownloads:
      accountData.entitlements?.enableReportDownloads?.value || false,
    enableWatchlistRSS:
      accountData.entitlements?.enableWatchlistRSS?.value || false,
    enableArchivedScheduledReports:
      accountData.entitlements?.enableArchivedScheduledReports?.value || false,
    limitWatchlistSize: accountData.entitlements?.limitWatchlistSize?.value,
    limitItemsPerEmail: accountData.entitlements?.limitItemsPerEmail?.value,
    limitEventsInReport: accountData.entitlements?.limitEventsInReport?.value,
    limitEventsInCSV: accountData?.entitlements?.limitEventsInCSV?.value,
    limitEventsInPDF: accountData?.entitlements?.limitEventsInPDF?.value,
    limitScratchReportItems:
      accountData.entitlements?.limitScratchReportItems?.value,
    //Content Filter
    sourcesInclude: sources?.includedFields || {
      list: [],
      logic: "or",
    },
    sourcesExclude: sources?.excludedFields || {
      list: [],
      logic: "or",
    },
  };
}

export function makeEntitlementsFromAddUserFormValues(
  accountFormData: AccountsManagementAddUserFormValues
): components["schemas"]["Entitlements"] {
  return {
    enableEditWatchList: {
      value:
        accountFormData.enableEditWatchList ??
        createAccountDefaultValues.enableEditWatchList,
      displayName: "string",
    },
    enableAdFiltering: {
      value:
        accountFormData.enableAdFiltering ??
        createAccountDefaultValues.enableAdFiltering,
      displayName: "string",
    },
    enableMediaDownloads: {
      value:
        accountFormData.enableMediaDownloads ??
        createAccountDefaultValues.enableMediaDownloads,
      displayName: "string",
    },
    enableReportDownloads: {
      value:
        accountFormData.enableReportDownloads ??
        createAccountDefaultValues.enableReportDownloads,
      displayName: "string",
    },
    enableWatchlistRSS: {
      value:
        accountFormData.enableWatchlistRSS ??
        createAccountDefaultValues.enableWatchlistRSS,
      displayName: "string",
    },
    enableArchivedScheduledReports: {
      value:
        accountFormData.enableArchivedScheduledReports ??
        createAccountDefaultValues.enableArchivedScheduledReports,
      displayName: "string",
    },
    enableCustomReportBanner: {
      value:
        accountFormData.enableCustomReportBanner ??
        createAccountDefaultValues.enableCustomReportBanner,
      displayName: "string",
    },
    limitItemsPerEmail: accountFormData.limitItemsPerEmail
      ? {
          value: accountFormData.limitItemsPerEmail,
          displayName: "string",
        }
      : null,
    limitWatchlistSize: accountFormData.limitWatchlistSize
      ? {
          value: accountFormData.limitWatchlistSize,
          displayName: "string",
        }
      : null,
    limitEventsInReport: accountFormData.limitEventsInReport
      ? {
          value: accountFormData.limitEventsInReport,
          displayName: "string",
        }
      : null,
    limitEventsInCSV: accountFormData.limitEventsInCSV
      ? {
          value: accountFormData.limitEventsInCSV,
          displayName: "string",
        }
      : null,
    limitEventsInPDF: accountFormData.limitEventsInPDF
      ? {
          value: accountFormData.limitEventsInPDF,
          displayName: "string",
        }
      : null,
    limitScratchReportItems: accountFormData.limitScratchReportItems
      ? {
          value: accountFormData.limitScratchReportItems,
          displayName: "string",
        }
      : null,
  };
}

export function makeEntitlementsFromAccountFeaturesFormValues(
  accountFormData: EditAccountFeaturesFormValues
): components["schemas"]["Entitlements"] {
  return {
    enableEditWatchList: {
      value:
        accountFormData.enableEditWatchList ??
        createAccountDefaultValues.enableEditWatchList,
      displayName: "string",
    },
    enableAdFiltering: {
      value:
        accountFormData.enableAdFiltering ??
        createAccountDefaultValues.enableAdFiltering,
      displayName: "string",
    },
    enableMediaDownloads: {
      value:
        accountFormData.enableMediaDownloads ??
        createAccountDefaultValues.enableMediaDownloads,
      displayName: "string",
    },
    enableReportDownloads: {
      value:
        accountFormData.enableReportDownloads ??
        createAccountDefaultValues.enableReportDownloads,
      displayName: "string",
    },
    enableWatchlistRSS: {
      value:
        accountFormData.enableWatchlistRSS ??
        createAccountDefaultValues.enableWatchlistRSS,
      displayName: "string",
    },
    enableArchivedScheduledReports: {
      value:
        accountFormData.enableArchivedScheduledReports ??
        createAccountDefaultValues.enableArchivedScheduledReports,
      displayName: "string",
    },
    enableCustomReportBanner: {
      value:
        accountFormData.enableCustomReportBanner ??
        createAccountDefaultValues.enableCustomReportBanner,
      displayName: "string",
    },
    limitItemsPerEmail: accountFormData.limitItemsPerEmail
      ? {
          value: accountFormData.limitItemsPerEmail,
          displayName: "string",
        }
      : null,
    limitWatchlistSize: accountFormData.limitWatchlistSize
      ? {
          value: accountFormData.limitWatchlistSize,
          displayName: "string",
        }
      : null,

    limitEventsInReport: accountFormData.limitEventsInReport
      ? {
          value: accountFormData.limitEventsInReport,
          displayName: "string",
        }
      : null,
    limitEventsInCSV: accountFormData.limitEventsInCSV
      ? {
          value: accountFormData.limitEventsInCSV,
          displayName: "string",
        }
      : null,
    limitEventsInPDF: accountFormData.limitEventsInPDF
      ? {
          value: accountFormData.limitEventsInPDF,
          displayName: "string",
        }
      : null,
    limitScratchReportItems: accountFormData.limitScratchReportItems
      ? {
          value: accountFormData.limitScratchReportItems,
          displayName: "string",
        }
      : null,
  };
}
