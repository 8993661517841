import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Add, Check, Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Switch,
  Typography,
  dialogClasses,
  useTheme,
} from "@mui/material";
import { ScrollBox } from "src/components/ScrollBox/ScrollBox";
import { ScheduledAlertCardView } from "src/components/ScheduledAlertPopover/ScheduledAlertCardView/ScheduledAlertCardView";
import { ScheduledAlertDeleteDialog } from "src/components/ScheduledAlertPopover/ScheduledAlertDeleteDialog/ScheduledAlertDeleteDialog";
import { DialogPromptProps } from "src/components/DialogPrompt/DialogPrompt.model";
import { OriginType } from "src/api/useWatchQueryAlertCreate";
import { useWatchQueryUpdate } from "src/api/useWatchQueryUpdate";
import { useWatchQuery } from "src/api/useWatchQuery";
import { AlertType } from "src/models/AlertType";
import { UserQueryDTO } from "src/models/UserQueryDTO";
import {
  alertTypePresets,
  getScheduledAlertType,
} from "src/components/ScheduledAlertPopover/ScheduledAlertPopover.utils";
import { ScheduledAlertMobileCreate } from "../ScheduledAlertMobileCreate/ScheduledAlertMobileCreate";

type ScheduledAlertMobileEditProps = Omit<
  DialogPromptProps,
  "content" | "footer" | "onSubmit" | "onConfirm"
> & {
  queryId?: string;
  origin: OriginType;
  alerts: UserQueryDTO["alerts"];
  scheduledAlertCreateHandler?: (
    newValue: AlertType | AlertType[],
    action?: "delete" | "edit"
  ) => void;
  sheduledAlertArchiveEnabled: boolean;
  setSheduledAlertArchiveEnabled: Dispatch<SetStateAction<boolean>>;
};

export const ScheduledAlertMobileView = ({
  onClose,
  title,
  alerts,
  origin,
  queryId,
  scheduledAlertCreateHandler,
  sheduledAlertArchiveEnabled,
  setSheduledAlertArchiveEnabled,
  ...props
}: ScheduledAlertMobileEditProps) => {
  const { palette } = useTheme();

  const [scheduledAlertEnabled, setScheduledAlertEnabled] = useState(true);
  const [editRule, setEditRule] = useState<AlertType | null>(null);
  const [showAlertDeleteDialog, setShowAlertDeleteDialog] = useState(false);
  const [alertDeleteData, setAlertDeleteData] = useState<AlertType | null>(
    null
  );

  const [openCreateModal, setOpenCreateModel] = useState(false);

  useEffect(() => {
    const isAlertsEnabled = alerts.every((alert) => alert.enabled);
    setScheduledAlertEnabled(isAlertsEnabled);
  }, [alerts]);

  const editRuleHandler = useCallback(
    (rule?: AlertType | null) => {
      if (!rule && rule !== null) return;

      setEditRule(rule);
      setSheduledAlertArchiveEnabled(rule?.archive || false);
      setOpenCreateModel(true);
    },
    [setSheduledAlertArchiveEnabled]
  );

  const currentQueryData = useWatchQuery({
    enabled: !!queryId && !!props.open,
    path: {
      queryId: queryId ?? "",
    },
  });

  const watchQueryUpdate = useWatchQueryUpdate({
    options: {
      origin,
    },
  });
  const handleScheduledAlertChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (scheduledAlertCreateHandler) {
      const updatedAlerts = (alerts || []).map((v) => {
        return { ...v, enabled: event.target.checked };
      });
      return scheduledAlertCreateHandler(updatedAlerts);
    }

    const data = currentQueryData.data;
    if (!data) return;

    const updatedAlerts = alerts.map((v) => {
      if (v.type === "realtime") return v;
      return { ...v, enabled: event.target.checked };
    });

    watchQueryUpdate.mutate({
      ...data,
      alerts: updatedAlerts,
    });
  };

  const scheduledAlertRules = useMemo(() => {
    const updatedRules = alerts.filter((v) => {
      if (v.type === "realtime") return false;

      return v.id !== editRule?.id;
    });
    return (
      updatedRules.length !== 0 && (
        <ScrollBox p={2} sx={{ backgroundColor: palette.background.default }}>
          {updatedRules.map((alert) => (
            <ScheduledAlertCardView
              sx={{
                "&:not(:first-of-type)": {
                  mt: 2,
                },
              }}
              archive={alert.archive}
              key={alert.id}
              type={getScheduledAlertType(alert)}
              value={alert.schedule || []}
              enabled={alert.enabled}
              onDelete={() => {
                if (!scheduledAlertCreateHandler) {
                  setAlertDeleteData(alert);
                  return setShowAlertDeleteDialog(true);
                }

                scheduledAlertCreateHandler(alert, "delete");
              }}
              onEdit={() => editRuleHandler(alert)}
            />
          ))}
          <ScheduledAlertDeleteDialog
            open={showAlertDeleteDialog}
            onClose={() => setShowAlertDeleteDialog(false)}
            queryId={queryId}
            alert={alertDeleteData}
            origin={origin}
          />
        </ScrollBox>
      )
    );
  }, [
    alerts,
    alertDeleteData,
    editRule,
    editRuleHandler,
    queryId,
    origin,
    showAlertDeleteDialog,
    palette.background.default,
    scheduledAlertCreateHandler,
  ]);

  return (
    <Dialog
      fullScreen
      sx={{
        [`.${dialogClasses.paper}`]: {
          justifyContent: "space-between",
        },
      }}
      {...props}
    >
      <Stack height="calc(100% - 74px)">
        <DialogTitle sx={{ py: 0.5, px: 2 }}>
          <Stack width="100%">
            <Stack flexDirection="row" alignItems="center" gap={2}>
              <IconButton
                onClick={onClose}
                sx={{ color: palette.text.primary }}
              >
                <Close />
              </IconButton>
              <Typography
                variant="h6"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {title}
              </Typography>
            </Stack>
            <Stack flexDirection="row" alignItems="center" py={1} gap={2}>
              <Switch
                disabled={!alerts.length}
                checked={scheduledAlertEnabled}
                onChange={handleScheduledAlertChange}
              />
              <Typography variant="body1">Get the Scheduled alerts</Typography>
            </Stack>
          </Stack>
        </DialogTitle>

        <ScrollBox direction="column">
          <DialogContent sx={{ p: 0 }}>{scheduledAlertRules}</DialogContent>
        </ScrollBox>
      </Stack>

      <DialogActions sx={{ px: 2 }}>
        <Stack
          direction="row"
          justifyContent={"justify-between"}
          width={"100%"}
          spacing={2}
        >
          <Button
            sx={{ width: "100%", minWidth: 170, height: 42 }}
            variant="contained"
            color="info"
            startIcon={<Add />}
            onClick={() => setOpenCreateModel(true)}
          >
            Scheduled rule
          </Button>

          <Button
            sx={{ width: "100%", height: 42 }}
            variant="contained"
            color="primary"
            startIcon={<Check />}
            onClick={onClose}
          >
            Done
          </Button>
        </Stack>
      </DialogActions>

      <ScheduledAlertMobileCreate
        sheduledAlertArchiveEnabled={sheduledAlertArchiveEnabled}
        setSheduledAlertArchiveEnabled={setSheduledAlertArchiveEnabled}
        editedRule={editRule}
        onSuccessEditRule={editRuleHandler}
        queryId={queryId}
        presets={alertTypePresets}
        alerts={alerts}
        open={openCreateModal}
        onClose={() => setOpenCreateModel(false)}
        origin={origin}
        scheduledAlertCreateHandler={scheduledAlertCreateHandler}
      />
    </Dialog>
  );
};
