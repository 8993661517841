import { NewAccountFormValues } from "src/pages/AccountsManagementCreateAccount/AccountsManagementCreateAccount.model";
import { EditAccountContentFilterFormValues } from "../components/AccountsManagementEditContentTab/AccountsManagementContentTab.model";
import { EditAccountFeaturesFormValues } from "../components/AccountsManagementEditFeaturesTab/AccountManagementEditFeatures.model";
import { EditAccountDetailsFormValues } from "../components/AccountsManagementEditGeneralTab/AccountsManagementEditDetails.model";

type FormValues =
  | keyof EditAccountContentFilterFormValues
  | keyof EditAccountDetailsFormValues
  | keyof EditAccountFeaturesFormValues
  | keyof NewAccountFormValues;

export function getSectionName(fieldName: FormValues) {
  switch (fieldName) {
    case "name":
    case "organizationType":
      return "Information";
    case "country":
    case "city":
    case "stateOrProvince":
    case "postalCode":
    case "timezone":
      return "Addres";
    case "sourcesInclude":
    case "sourcesExclude":
    case "preset":
      return "Content Filter";

    case "enableMediaDownloads":
    case "enableEditWatchList":
    case "enableAdFiltering":
    case "enableCustomReportBanner":
    case "enableReportDownloads":
    case "enableWatchlistRSS":
    case "enableArchivedScheduledReports":
    case "limitWatchlistSize":
    case "limitItemsPerEmail":
    case "limitEventsInReport":
    case "limitEventsInCSV":
    case "limitEventsInPDF":
    case "limitScratchReportItems":
      return "Features";

    default:
      return "Other";
  }
}

export function getFieldLabel(fieldName: FormValues) {
  switch (fieldName) {
    case "name":
      return "Organization name";
    case "organizationType":
      return "Organization type";
    case "country":
      return "Country";
    case "city":
      return "City";
    case "timezone":
      return "Time Zone";
    case "stateOrProvince":
      return "State";
    case "postalCode":
      return "Zip Code";
    case "sourcesInclude":
      return "Sources Include";
    case "sourcesExclude":
      return "Sources Exclude";
    case "enableMediaDownloads":
      return "Enable save clips";
    case "enableEditWatchList":
      return "Edit Watchlist";
    case "enableAdFiltering":
      return "Enable ad filtering";
    case "enableCustomReportBanner":
      return "Enable report customization";
    case "enableReportDownloads":
      return "Enable report downloads";
    case "enableWatchlistRSS":
      return "Enable RSS Feed for Watchlist";
    case "enableArchivedScheduledReports":
      return "Archive Alert";
    case "limitWatchlistSize":
      return "Limit Watchlist Size";
    case "limitItemsPerEmail":
      return "Limit items per email";
    case "limitEventsInReport":
      return "Limit Events in Reports/Share";
    case "limitEventsInCSV":
      return "Limit Events in CSV";
    case "limitEventsInPDF":
      return "Limit Events in PDF";
    case "limitScratchReportItems":
      return "Limit scratch report items";

    default:
      return fieldName[0].toLocaleUpperCase() + fieldName.slice(1);
  }
}
