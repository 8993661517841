import { useState } from "react";
import { Check, Warning } from "@mui/icons-material";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import {
  AppAutocomplete,
  AppAutocompleteProps,
} from "../AppAutocomplete/AppAutocomplete";
import { RecipientsCreatePrompt } from "./RecipientsCreatePrompt";
import { makeEntityMap } from "src/utils/makeEntityMap";

type Internal = AppAutocompleteProps<string, true>;

const extractEmailsFromText = (text: string): string[] => {
  const emailsRegEx = /[\w.+]+@[\w.-]+\.[\w]{2,}/gm;
  const match = text.match(emailsRegEx) || [];
  const custom = Array.from(match);

  return custom;
};

const dedupe = (a: string[], b: string[]): string[] => {
  const unique = makeEntityMap({ list: [...a, ...b], getId: (email) => email });
  const result = Object.keys(unique);

  return result;
};

export function RecipientsAutocomplete({
  value = [],
  onChange,
  onBlur,
  validate,
  ...props
}: {
  id?: Internal["id"];
  label: Internal["label"];
  value: Internal["value"];
  onChange: Internal["onChange"];
  onBlur: Internal["onBlur"];
  error: Internal["error"];
  helperText: Internal["helperText"];
  fullWidth?: Internal["fullWidth"];
  disabled?: Internal["disabled"];
  allowMultipleLines?: Internal["allowMultipleLines"];
  /** Return an error message to validate each recipient or undefined if valid */
  validate?: (item: string) => string | undefined;
}) {
  const [inputValue, setInputValue] = useState("");
  const custom = extractEmailsFromText(inputValue);

  const onInputSubmitWrapper: Internal["onInputSubmit"] = () => {
    const next = dedupe(value, custom);
    onChange?.(next);
  };

  const onBlurWrapper: Internal["onBlur"] = (e) => {
    const next = dedupe(value, custom);
    onChange?.(next);
    onBlur?.(e);
  };

  const onPasteWrapper: Internal["onPaste"] = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const paste = e.clipboardData.getData("text");
    const custom = extractEmailsFromText(paste);
    const next = dedupe(value, custom);

    onChange?.(next);
  };

  return (
    <AppAutocomplete<string, true>
      multiple
      scrollToLast
      value={value}
      options={custom}
      onChange={onChange}
      onPaste={onPasteWrapper}
      onBlur={onBlurWrapper}
      onInputChange={setInputValue}
      getChipColor={(item) => (validate?.(item) ? "error" : "success")}
      getChipTooltip={validate}
      renderOption={(liProps, option) => {
        const error = validate?.(option);
        const color = error ? "error" : "success";
        const Icon = error ? Warning : Check;
        return (
          <ListItem {...liProps}>
            <ListItemIcon>
              <Icon color={color} />
            </ListItemIcon>
            <ListItemText primary={option} secondary={error} />
          </ListItem>
        );
      }}
      renderDropDownHeader={({ inputValue }) => {
        return (
          <RecipientsCreatePrompt
            userInput={inputValue}
            parsedCount={custom.length}
          />
        );
      }}
      filterOptions={(options) => options}
      getOptionFromInput={(inputValue) => inputValue}
      onInputSubmit={onInputSubmitWrapper}
      {...props}
    />
  );
}
