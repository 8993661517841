import { Assessment, BookmarkAdd } from "@mui/icons-material";
import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useClipsSave } from "src/api/useClipsSave";
import DropDownButton from "src/components/DropDownButton/DropDownButton";
import { DownloadCSV } from "src/components/icons/DownloadCSV";
import { useSessionContext } from "src/api/useSessionContext";

// every commented line here is related to MMSR-2997
// import { DownloadPDF } from "src/components/icons/DownloadPDF";
import { EventsResultsExportButtonProps } from "./EventsResultsExportButton.model";

export function EventsResultsExportButton({
  showCreateReport,
  // downloadPdf,
  downloadCsv,
  value,
  selectedEventsLength,
  disabled,
  ...props
}: EventsResultsExportButtonProps) {
  const { palette } = useTheme();

  const saveClips = useClipsSave({});
  const { effectiveEntitlements } = useSessionContext();
  const { t } = useTranslation();

  const enableMediaDownload = effectiveEntitlements.enableMediaDownloads?.value;
  const limitEventsInReport =
    effectiveEntitlements.limitEventsInReport?.value || 0;

  const disableCreateReport = selectedEventsLength > limitEventsInReport;

  const handleClipSave = () => {
    const body = value.map((item) => ({ eventHighlights: item }));
    saveClips.mutate({ query: body });
  };

  return (
    <DropDownButton
      disabled={disabled}
      sx={{
        minWidth: { xs: 100, md: 160 },
        height: 42,
      }}
      {...props}
      renderMenu={(menuProps, closeMenu) => (
        <Menu {...menuProps}>
          <Tooltip
            title={
              disableCreateReport
                ? t("limitEventsInReport", {
                    limit: `${limitEventsInReport}`,
                  })
                : ""
            }
          >
            <Box>
              <MenuItem
                onClick={() => {
                  closeMenu();
                  showCreateReport();
                }}
                disabled={disableCreateReport}
              >
                <ListItemIcon>
                  <Assessment color="primary" />
                </ListItemIcon>
                <ListItemText>Create Report</ListItemText>
              </MenuItem>
            </Box>
          </Tooltip>

          {/* <MenuItem
            onClick={() => {
              closeMenu();
              downloadPdf();
            }}
          >
            <ListItemIcon>
              <DownloadPDF />
            </ListItemIcon>
            <ListItemText>Download PDF</ListItemText>
          </MenuItem> */}

          <MenuItem
            onClick={() => {
              closeMenu();
              downloadCsv();
            }}
          >
            <ListItemIcon>
              <DownloadCSV
                labelColor={
                  palette.mode === "light"
                    ? palette.common.white
                    : palette.primary.contrastText
                }
              />
            </ListItemIcon>
            <ListItemText>Download CSV</ListItemText>
          </MenuItem>

          <Tooltip
            title={
              enableMediaDownload
                ? selectedEventsLength > 10
                  ? "Save To Media Center is available for up to 10 clips. Please select less items"
                  : "Save for 90 days to the Media Center from where you can select clips when create report or share items"
                : "You can't save media as you have an account limitation. Please contact your account manager to extend your limits."
            }
          >
            <Box>
              <MenuItem
                onClick={() => {
                  closeMenu();
                  handleClipSave();
                }}
                disabled={!enableMediaDownload || selectedEventsLength > 10}
              >
                <ListItemIcon>
                  <BookmarkAdd color="primary" />
                </ListItemIcon>
                <ListItemText>Save To Media Center</ListItemText>
              </MenuItem>
            </Box>
          </Tooltip>
        </Menu>
      )}
    >
      Export
    </DropDownButton>
  );
}
