import {
  Box,
  ButtonProps,
  IconButton,
  Tooltip,
  alpha,
  useTheme,
} from "@mui/material";
import { QueryBuilderOutlined } from "@mui/icons-material";

export function ScheduleAlertButton({
  onClick,
  active,
}: {
  onClick?: ButtonProps["onClick"];
  active?: boolean;
}) {
  const { palette } = useTheme();
  const Icon = QueryBuilderOutlined;
  return (
    <Tooltip
      title={active ? "Turn off Scheduled  Alert" : "Turn on Scheduled Alert"}
    >
      <Box data-intercom-scheduled-alert-btn-id="scheduled-alert-button-id">
        <IconButton
          sx={{
            borderRadius: 2,
            color: palette.text.secondary,
            backgroundColor: active
              ? alpha(palette.primary.light, 0.08)
              : undefined,
          }}
          onClick={onClick}
        >
          <Icon color={active ? "primary" : undefined} />
        </IconButton>
      </Box>
    </Tooltip>
  );
}
