import Joi from "joi";
import publicEmailProviders from "free-email-domains";
import { UserInviteFormValues } from "./UserInviteForm.model";

export const inviteFormEmailSchema = Joi.string()
  .email({ tlds: { allow: false } })
  .required()
  .custom((value, helpers) => {
    const domain = value.split("@").at(-1);
    if (publicEmailProviders.includes(domain)) {
      return helpers.error("email.invalid");
    }

    return value;
  })
  .messages({
    "email.invalid": "Public email providers are not allowed",
  });

export const userInviteSchema = Joi.object<UserInviteFormValues>({
  emails: Joi.array().items(inviteFormEmailSchema).required().messages({
    "array.includesRequiredUnknowns": "At least one recipient is required",
  }),
  comment: Joi.string().allow(""),
});
