import axios from "axios";
import { paths } from "@tveyes/twosionwebapischema";
import { enqueueSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { userInviteDetailsKey } from "./useUserInviteDetails";
import { managementViewKey } from "./useManagementView";

const userInviteAcceptKey = "userInviteAcceptKey";

const apiPath: keyof paths = "/api/Invite/{id}/accept";
const method: keyof paths[typeof apiPath] = "post";

type Endpoint = paths[typeof apiPath][typeof method];
type Path = NonNullable<Endpoint["parameters"]["path"]>;
type Query = NonNullable<Endpoint["parameters"]["query"]>;

type Request = {
  path: Required<Path>;
  query: Required<Query>;
};

type Response = Endpoint["responses"][200]["content"]["application/json"];
type UseUserInviteCreateArgs = {
  onSuccess: (response: Response, request: Request) => void;
};

export function useUserInviteAccept(params?: UseUserInviteCreateArgs) {
  const { onSuccess } = params || {};
  const queryClient = useQueryClient();

  return useMutation<Response, unknown, Request>({
    mutationKey: [userInviteAcceptKey],
    mutationFn: async (parameters) => {
      const url = makeApiUrl(apiPath, parameters);

      const { data } = await axios<Response>(url, {
        method,
      });

      return data;
    },
    onSuccess: (data, variables) => {
      queryClient.refetchQueries({ queryKey: userInviteDetailsKey });
      queryClient.refetchQueries({ queryKey: managementViewKey });
      onSuccess?.(data, variables);
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
