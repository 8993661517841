import { Chip } from "@mui/material";
import { DuplicatesChipTooltip } from "./DuplicatesChipTooltip";

export function DuplicatesChipListView({
  onClick,
  count,
  selectedCount,
}: {
  onClick: () => void;
  count: number;
  selectedCount: number;
}) {
  return (
    <DuplicatesChipTooltip>
      <Chip
        color={selectedCount ? "primary" : "default"}
        label={
          selectedCount
            ? `+ ${selectedCount}/${count} Duplicates`
            : `+ ${count} Duplicates`
        }
        onClick={onClick}
        sx={{ px: "3px", zIndex: 3 }}
      />
    </DuplicatesChipTooltip>
  );
}
