import { Dispatch, SetStateAction } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  AlertConfig,
  AlertConfigHourly,
} from "src/components/ScheduledAlertPopover/ScheduledAlertPopover.model";
import {
  AlertTypesKey,
  getDefaultAlertStateData,
  isAlertConfigType,
} from "src/components/ScheduledAlertPopover/ScheduledAlertPopover.utils";

type AlertConfigFormHourlyProps = {
  value: AlertConfigHourly;
  onChange: Dispatch<SetStateAction<AlertConfig>>;
  updateSelectOpen?: (v: boolean) => void;
};

export const AlertConfigFormHourly = ({
  value,
  onChange,
  updateSelectOpen,
}: AlertConfigFormHourlyProps) => {
  const { type } = value;

  const onTypeChange = (event: SelectChangeEvent) => {
    if (isAlertConfigType(event?.target.value)) {
      onChange(getDefaultAlertStateData(event.target.value));
      updateSelectOpen && updateSelectOpen(false);
    } else {
      throw new Error(
        `Invalid value: ${event.target.value}. Should be one of ${Object.values(
          AlertTypesKey
        )}`
      );
    }
  };

  return (
    <>
      <FormControl
        fullWidth
        data-intercom-scheduled-alert-type-select-id="scheduled-alert-type-select-id"
      >
        <InputLabel>Type of alert</InputLabel>
        <Select
          onOpen={() => {
            updateSelectOpen && updateSelectOpen(true);
          }}
          MenuProps={{
            TransitionProps: {
              onExited: () => {
                updateSelectOpen && updateSelectOpen(false);
              },
            },
          }}
          value={type}
          label="Type of alert"
          onChange={onTypeChange}
        >
          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.hourly}>
            Hourly
          </MenuItem>

          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.weekdayMorning}>
            Every Weekday Morning
          </MenuItem>
          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.threeTimes}>
            Three Times Daily
          </MenuItem>
          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.mondayMorning}>
            Monday Morning
          </MenuItem>
          <MenuItem sx={{ height: 48 }} divider value={AlertTypesKey.firstDay}>
            First Day of the Month
          </MenuItem>

          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.daily}>
            Custom Every Day
          </MenuItem>
          <MenuItem
            data-intercom-scheduled-alert-weekly-item-id="scheduled-alert-weekly-item-id"
            sx={{ height: 48 }}
            value={AlertTypesKey.weekly}
          >
            Custom Day of Week
          </MenuItem>
          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.monthly}>
            Custom Monthly
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
};
