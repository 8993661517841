import {
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { AdvertisementFilterNotice } from "src/components/AdvertisementPicker/AdvertisementFilterNotice";
import { queryAdvertisementValues } from "src/models/Advertisement";
import { AdvertisementPicker } from "src/components/AdvertisementPicker/AdvertisementPicker";
import { ProgramTagPicker } from "src/components/ProgramTagPicker/ProgramTagPicker";
import { SourcesPicker } from "src/components/SourcesPicker/SourcesPicker";
import { WatchQueryFilterFormValues } from "./WatchQueryFilterForm.model";
import { WatchTermInput } from "../WatchTermInput/WatchTermInput";

export function WatchQueryFilterForm() {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("lg"));

  const { control, watch } = useFormContext<WatchQueryFilterFormValues>();
  const sourcesIncludeList = watch("sourcesInclude.list");
  const sourcesExcludeList = watch("sourcesExclude.list");

  return (
    <Stack pt={3}>
      <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
            Keyword
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} lg={5}>
          <Controller
            name="termsInclude"
            control={control}
            render={({ field, fieldState, formState }) => {
              const { list, logic } = formState.errors[field.name] || {};
              const message = list?.message || logic?.message || " ";

              return (
                <WatchTermInput
                  id="term-include-input"
                  label="Include"
                  value={field.value}
                  options={[]} // no suggestions for now
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  autoFocus
                  error={!!fieldState.error}
                  helperText={message}
                  displayTooltip
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={5}>
          <Controller
            name="termsExclude"
            control={control}
            render={({ field, fieldState, formState }) => {
              const { list, logic } = formState.errors[field.name] || {};
              const message = list?.message || logic?.message || " ";

              return (
                <WatchTermInput
                  id="term-exclude-input"
                  label="Exclude"
                  value={field.value}
                  options={[]} // no suggestions for now
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={message}
                  displayTooltip
                />
              );
            }}
          />
        </Grid>
      </Grid>

      {/* Sources */}
      <Grid
        container
        spacing={isMobile ? 1.5 : 3}
        sx={{
          mb: { xs: 1.5, md: 1.5, lg: 0 },
        }}
      >
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
            Source
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={10}>
          <Controller
            name="sourcesInclude"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <SourcesPicker
                  id="source-include-input"
                  label={
                    field.value.list.length ? "Include selected" : "All Sources"
                  }
                  value={field.value}
                  excludedOptions={sourcesExcludeList}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                />
              );
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
        <Grid
          item
          lg={2}
          sx={{
            display: { xs: "none", md: "none", lg: "block" },
          }}
        />
        <Grid item xs={12} md={12} lg={10}>
          <Controller
            name="sourcesExclude"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <SourcesPicker
                  id="source-exclude"
                  label="Exclude"
                  value={field.value}
                  excludedOptions={sourcesIncludeList}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      {/* Sources */}

      {/* Advertisement */}
      <Grid container spacing={isMobile ? 1.5 : 3} mb={5}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography
            variant="subtitle1"
            display="flex"
            alignItems="center"
            height={36.5}
          >
            Advertisement
            <span data-intercom-advertisement-id="advertisement-id" />
          </Typography>
        </Grid>

        <Controller
          name="advertisement"
          control={control}
          render={({ field }) => {
            return (
              <>
                <Grid item xs={12} md={6} lg={5}>
                  <AdvertisementPicker
                    fullWidth
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  />
                </Grid>
                {field.value === queryAdvertisementValues.none && (
                  <>
                    <Grid item lg={5} /* layout spacer */ />
                    <Grid item lg={2} /* layout spacer */ />
                    <Grid item xs={12} lg={10}>
                      <AdvertisementFilterNotice />
                    </Grid>
                  </>
                )}
              </>
            );
          }}
        />
      </Grid>
      {/* Advertisement */}

      {/* Program title */}
      <Grid container spacing={isMobile ? 1.5 : 3}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
            Program Title
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} lg={5}>
          <Controller
            name="programInclude"
            control={control}
            render={({ field, fieldState, formState }) => {
              const { list, logic } = formState.errors[field.name] || {};
              const message = list?.message || logic?.message || " ";

              return (
                <ProgramTagPicker
                  id="program-include-input"
                  label="Include"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  getOptionLabel={(o) => o.name}
                  error={!!fieldState.error}
                  helperText={message}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={5}>
          <Controller
            name="programExclude"
            control={control}
            render={({ field, fieldState, formState }) => {
              const { list, logic } = formState.errors[field.name] || {};
              const message = list?.message || logic?.message || " ";

              return (
                <ProgramTagPicker
                  id="program-exclude-input"
                  label="Exclude"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  getOptionLabel={(o) => o.name}
                  error={!!fieldState.error}
                  helperText={message}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
