import { EventSourcesData } from "src/api/useEventSources";
import { UserDataResponse } from "src/api/useUserData";
import { ContentAccessFilter } from "src/models/ContentAccessFilter";
import { NewAccountFormValues } from "../AccountsManagementCreateAccount/AccountsManagementCreateAccount.model";
import { createAccountDefaultValues } from "../AccountsManagementCreateAccount/AccountsManagementCreateAccount.const";
import { sourceFilterMapper } from "../AccountsManagementEditAccount/utils/sourceFilterMapper";

export enum UserManagementTabMode {
  general = "general",
  contentFilter = "contentFilter",
  features = "features",
  customization = "customization",
}

type MatchingPreset = {
  title: string;
  contentAccessFilter: ContentAccessFilter;
};

export const pageTabKey = "pageTab";

export function getUserData(data: UserDataResponse) {
  return {
    email: data.email ?? "",
    firstName: data.firstName ?? "",
    lastName: data.lastName ?? "",
    accountId: data.accountId ?? "",
    isActive: data.isActive ?? false,
    phoneNumber: data.phoneNumber ?? "",
    timezone: data.timeZone ?? "",
    roles: data.roles ?? [],
    createdDate: data.createdDate ?? "",
  };
}

export function makeAccountEntitlementsFormValues(
  accountFormData: NewAccountFormValues,
  userData: UserDataResponse
): NewAccountFormValues {
  return {
    ...accountFormData,
    enableMediaDownloads:
      userData?.entitlements?.enableMediaDownloads?.value ??
      createAccountDefaultValues.enableMediaDownloads,
    enableEditWatchList:
      userData?.entitlements?.enableEditWatchList?.value ??
      createAccountDefaultValues.enableEditWatchList,
    enableAdFiltering:
      userData?.entitlements?.enableAdFiltering?.value ??
      createAccountDefaultValues.enableAdFiltering,
    enableCustomReportBanner:
      userData?.entitlements?.enableCustomReportBanner?.value ??
      createAccountDefaultValues.enableCustomReportBanner,
    enableReportDownloads:
      userData?.entitlements?.enableReportDownloads?.value ??
      createAccountDefaultValues.enableReportDownloads,
    enableWatchlistRSS:
      userData?.entitlements?.enableWatchlistRSS?.value ??
      createAccountDefaultValues.enableWatchlistRSS,
    enableArchivedScheduledReports:
      userData?.entitlements?.enableArchivedScheduledReports?.value ??
      createAccountDefaultValues.enableArchivedScheduledReports,
    limitWatchlistSize:
      userData?.entitlements?.limitWatchlistSize?.value ??
      createAccountDefaultValues.limitWatchlistSize,
    limitItemsPerEmail:
      userData?.entitlements?.limitItemsPerEmail?.value ??
      createAccountDefaultValues.limitItemsPerEmail,
    limitEventsInReport:
      userData?.entitlements?.limitEventsInReport?.value ??
      createAccountDefaultValues.limitEventsInReport,
    limitEventsInCSV:
      userData?.entitlements?.limitEventsInCSV?.value ??
      createAccountDefaultValues.limitEventsInCSV,
    limitEventsInPDF:
      userData?.entitlements?.limitEventsInPDF?.value ??
      createAccountDefaultValues.limitEventsInPDF,
    limitScratchReportItems:
      userData?.entitlements?.limitScratchReportItems?.value ??
      createAccountDefaultValues.limitScratchReportItems,
  };
}

export function makeAccountContentFilterFormValues(
  accountFormData: NewAccountFormValues,
  userData: UserDataResponse,
  sourcesData?: EventSourcesData,
  matchingPreset?: MatchingPreset
): NewAccountFormValues {
  if (!userData.contentAccessFilter) return accountFormData;

  const sources = sourceFilterMapper(userData.contentAccessFilter, sourcesData);

  const sourcesExclude = sources?.excludedFields || {
    list: [],
    logic: "or",
  };
  const sourcesInclude = sources?.includedFields || {
    list: [],
    logic: "or",
  };

  const isCustom =
    sources?.includedFields?.list?.length ||
    sources?.excludedFields?.list?.length;

  return {
    ...accountFormData,
    sourcesExclude,
    sourcesInclude,
    preset: matchingPreset ? matchingPreset?.title : isCustom ? "custom" : "",
  };
}

export function makeAccountFormValues(
  userData: UserDataResponse,
  sourcesData?: EventSourcesData,
  matchingPreset?: MatchingPreset
): NewAccountFormValues {
  const outputData: NewAccountFormValues = {
    ...createAccountDefaultValues,
    enableMediaDownloads:
      userData?.entitlements?.enableMediaDownloads?.value ??
      createAccountDefaultValues.enableMediaDownloads,
    enableEditWatchList:
      userData?.entitlements?.enableEditWatchList?.value ??
      createAccountDefaultValues.enableEditWatchList,
    enableAdFiltering:
      userData?.entitlements?.enableAdFiltering?.value ??
      createAccountDefaultValues.enableAdFiltering,
    enableCustomReportBanner:
      userData?.entitlements?.enableCustomReportBanner?.value ??
      createAccountDefaultValues.enableCustomReportBanner,
    enableReportDownloads:
      userData?.entitlements?.enableReportDownloads?.value ??
      createAccountDefaultValues.enableReportDownloads,
    enableWatchlistRSS:
      userData?.entitlements?.enableWatchlistRSS?.value ??
      createAccountDefaultValues.enableWatchlistRSS,
    enableArchivedScheduledReports:
      userData?.entitlements?.enableArchivedScheduledReports?.value ??
      createAccountDefaultValues.enableArchivedScheduledReports,
    limitWatchlistSize:
      userData?.entitlements?.limitWatchlistSize?.value ??
      createAccountDefaultValues.limitWatchlistSize,
    limitItemsPerEmail:
      userData?.entitlements?.limitItemsPerEmail?.value ??
      createAccountDefaultValues.limitItemsPerEmail,
    limitEventsInReport:
      userData?.entitlements?.limitEventsInReport?.value ??
      createAccountDefaultValues.limitEventsInReport,
    limitEventsInCSV:
      userData?.entitlements?.limitEventsInCSV?.value ??
      createAccountDefaultValues.limitEventsInCSV,
    limitEventsInPDF:
      userData?.entitlements?.limitEventsInPDF?.value ??
      createAccountDefaultValues.limitEventsInPDF,
    limitScratchReportItems:
      userData?.entitlements?.limitScratchReportItems?.value ??
      createAccountDefaultValues.limitScratchReportItems,
  };

  if (userData.contentAccessFilter && sourcesData) {
    const sources = sourceFilterMapper(
      userData.contentAccessFilter,
      sourcesData
    );
    const isCustom =
      sources?.includedFields?.list?.length ||
      sources?.excludedFields?.list?.length;

    outputData.sourcesExclude = sources?.excludedFields || {
      list: [],
      logic: "or",
    };
    outputData.sourcesInclude = sources?.includedFields || {
      list: [],
      logic: "or",
    };
    outputData.preset = matchingPreset
      ? matchingPreset?.title
      : isCustom
      ? "custom"
      : "";
  }

  return outputData;
}

export function makeEntitlements(accountFormData: NewAccountFormValues) {
  return {
    enableEditWatchList: {
      value:
        accountFormData.enableEditWatchList ??
        createAccountDefaultValues.enableEditWatchList,
      displayName: "string",
    },
    enableAdFiltering: {
      value:
        accountFormData.enableAdFiltering ??
        createAccountDefaultValues.enableAdFiltering,
      displayName: "string",
    },
    enableMediaDownloads: {
      value:
        accountFormData.enableMediaDownloads ??
        createAccountDefaultValues.enableMediaDownloads,
      displayName: "string",
    },
    enableReportDownloads: {
      value:
        accountFormData.enableReportDownloads ??
        createAccountDefaultValues.enableReportDownloads,
      displayName: "string",
    },
    enableCustomReportBanner: {
      value:
        accountFormData.enableCustomReportBanner ??
        createAccountDefaultValues.enableCustomReportBanner,
      displayName: "string",
    },
    enableWatchlistRSS: {
      value:
        accountFormData.enableWatchlistRSS ??
        createAccountDefaultValues.enableWatchlistRSS,
      displayName: "string",
    },
    enableArchivedScheduledReports: {
      value:
        accountFormData.enableArchivedScheduledReports ??
        createAccountDefaultValues.enableArchivedScheduledReports,
      displayName: "string",
    },
    limitItemsPerEmail: accountFormData.limitItemsPerEmail
      ? {
          value: accountFormData.limitItemsPerEmail,
          displayName: "string",
        }
      : null,
    limitWatchlistSize: accountFormData.limitWatchlistSize
      ? {
          value: accountFormData.limitWatchlistSize,
          displayName: "string",
        }
      : null,
    limitEventsInReport: accountFormData.limitEventsInReport
      ? {
          value: accountFormData.limitEventsInReport,
          displayName: "string",
        }
      : null,
    limitEventsInCSV: accountFormData.limitEventsInCSV
      ? {
          value: accountFormData.limitEventsInCSV,
          displayName: "string",
        }
      : null,
    limitEventsInPDF: accountFormData.limitEventsInPDF
      ? {
          value: accountFormData.limitEventsInPDF,
          displayName: "string",
        }
      : null,
    limitScratchReportItems: accountFormData.limitScratchReportItems
      ? {
          value: accountFormData.limitScratchReportItems,
          displayName: "string",
        }
      : null,
  };
}
