import { SourceFilter } from "src/models/SourceFilter";
import { UserQueryDTO } from "src/models/UserQueryDTO";
import { WatchQueryKeywordFormValues } from "src/components/WatchQueryKeywordForm/WatchQueryKeywordForm.model";
import { WatchQueryCustomFormValues } from "src/components/WatchQueryCustomForm/WatchQueryCustomForm.model";
import { WatchTermSharedValues } from "src/api/useWatchTermSharedForm";
import { isIncludeExcludePair } from "./isIncludeExcludePair";
import { makeTypedPropList } from "./makeTypedPropList";
import { parseWatchQueryAdvert } from "./parseWatchQueryAdvert";

type IncludeExcludeListLogic = {
  include: {
    list: string[];
    logic: string;
  };
  exclude: {
    list: string[];
    logic: string;
  };
};

type ListGroup = {
  name: string;
  group: string;
  value: string;
};

export type IncludeExcludeListGroupLogic = {
  include: {
    list: ListGroup[];
    logic: string;
  };
  exclude: {
    list: ListGroup[];
    logic: string;
  };
};

// FIXME: to force types checks, it is better to use mapped type like
// const typeDict: { [k in keyof SourceFilter]: string | undefined } = {
export const typeDict: { [k: string]: string } = {
  contentType: "EventTypeComposite",
  contentTypes: "EventTypeComposite",
  countries: "CountryCode",
  languages: "LanguageCode",
  markets: "MarketID",
  state: "Country:MarketState",
  states: "Country:MarketState",
  stations: "StationGUID",
  podcastSources: "podcastSources",
};

function parseIncludeExclude(
  data?: null | {
    include?: string[] | null | undefined;
    exclude?: string[] | null | undefined;
    includeOperator?: string | null | undefined;
    excludeOperator?: string | null | undefined;
  }
): IncludeExcludeListLogic {
  return {
    include: {
      list: data?.include || [],
      logic: data?.includeOperator || "or",
    },
    exclude: {
      list: data?.exclude || [],
      logic: data?.excludeOperator || "or",
    },
  };
}

function getListGroup(data: SourceFilter, part: "include" | "exclude") {
  const keys = makeTypedPropList(data);
  return keys.reduce((res: ListGroup[], next) => {
    if (["includeOperator", "excludeOperator"].includes(next)) return res;
    const pair = data[next];
    const groupType = typeDict[next];

    if (!isIncludeExcludePair(pair) || !groupType) {
      return res;
    }

    const items = pair[part];

    if (!items) {
      return res;
    }

    const mappedItems = items.map((item) => ({
      ...item,
      group: groupType,
    }));

    res.push(...mappedItems);
    return res;
  }, []);
}

export function parseIncludeExcludeGroup(
  data: SourceFilter
): IncludeExcludeListGroupLogic {
  const includeList = getListGroup(data, "include");
  const excludeList = getListGroup(data, "exclude");
  return {
    include: {
      list: includeList,
      logic: data?.includeOperator || "or",
    },
    exclude: {
      list: excludeList,
      logic: data?.excludeOperator || "or",
    },
  };
}

export function makeWatchQueryKeywordFormValues(
  query: UserQueryDTO
): WatchQueryKeywordFormValues {
  const { title, queryBuilderDefinition } = query;
  const { sourceFilter = {} } = queryBuilderDefinition;
  const terms = parseIncludeExclude(queryBuilderDefinition.keywordQuery);
  const sources = parseIncludeExcludeGroup(sourceFilter || {});

  return {
    displayName: title,

    termsInclude: terms.include,
    termsExclude: terms.exclude,

    sourcesInclude: sources.include,
    sourcesExclude: sources.exclude,

    advertisement: parseWatchQueryAdvert(sourceFilter?.adverts),

    programInclude: {
      list: sourceFilter?.programs?.include || [],
      logic: sourceFilter?.includeOperator || "or",
    },
    programExclude: {
      list: sourceFilter?.programs?.exclude || [],
      logic: sourceFilter?.excludeOperator || "or",
    },
  };
}

export function makeWatchQueryCustomFormValues(
  query: UserQueryDTO
): WatchQueryCustomFormValues {
  const { title, queryBuilderDefinition } = query;
  const { sourceFilter = {} } = queryBuilderDefinition;
  const sources = parseIncludeExcludeGroup(sourceFilter || {});

  return {
    customQuery: queryBuilderDefinition.customQuery || "",
    displayName: title,

    sourcesInclude: sources.include,
    sourcesExclude: sources.exclude,

    advertisement: parseWatchQueryAdvert(sourceFilter?.adverts),

    programInclude: {
      list: sourceFilter?.programs?.include || [],
      logic: sourceFilter?.includeOperator || "or",
    },
    programExclude: {
      list: sourceFilter?.programs?.exclude || [],
      logic: sourceFilter?.excludeOperator || "or",
    },
  };
}

export function makeWatchQuerySharedFormValues(
  query: UserQueryDTO
): WatchTermSharedValues {
  const { queryBuilderDefinition } = query;
  const { sourceFilter = {} } = queryBuilderDefinition;
  const sources = parseIncludeExcludeGroup(sourceFilter || {});

  return {
    sourcesInclude: sources.include,
    sourcesExclude: sources.exclude,

    advertisement: parseWatchQueryAdvert(sourceFilter?.adverts),

    programInclude: {
      list: sourceFilter?.programs?.include || [],
      logic: sourceFilter?.includeOperator || "or",
    },
    programExclude: {
      list: sourceFilter?.programs?.exclude || [],
      logic: sourceFilter?.excludeOperator || "or",
    },
  };
}
