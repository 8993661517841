import { ContentAccessFilter } from "src/models/ContentAccessFilter";

export const CONTENT_FILTER_PRESETS: {
  title: string;
  queryDefinition: any; // FIXME: how to define type of the preset
}[] = [
  {
    title: "Vantage",
    queryDefinition: {
      keywordQuery: {
        include: [],
        includeOperator: "or",
        exclude: [],
        excludeOperator: "or",
      },
      sourceFilter: {
        contentType: {
          include: [
            {
              group: "EventTypeComposite",
              name: "Podcast",
              value: "Podcast",
            },
            {
              group: "EventTypeComposite",
              name: "Radio",
              value: "Radio",
            },
            {
              group: "EventTypeComposite",
              name: "Television",
              value: "Television",
            },
            {
              group: "EventTypeComposite",
              name: "YouTube",
              value: "YouTube",
            },
          ],
          exclude: [],
        },
        countries: {
          exclude: [
            {
              group: "CountryCode",
              name: "Argentina",
              value: "AR",
            },
            {
              group: "CountryCode",
              name: "Australia",
              value: "AU",
            },
            {
              group: "CountryCode",
              name: "Austria",
              value: "AT",
            },
            {
              group: "CountryCode",
              name: "Bahamas",
              value: "BS",
            },
            {
              group: "CountryCode",
              name: "Barbados",
              value: "BB",
            },
            {
              group: "CountryCode",
              name: "Bermuda",
              value: "BM",
            },
            {
              group: "CountryCode",
              name: "Brazil",
              value: "BR",
            },
            {
              group: "CountryCode",
              name: "Canada",
              value: "CA",
            },
            {
              group: "CountryCode",
              name: "Cayman Islands",
              value: "KY",
            },
            {
              group: "CountryCode",
              name: "China",
              value: "CN",
            },
            {
              group: "CountryCode",
              name: "Colombia",
              value: "CO",
            },
            {
              group: "CountryCode",
              name: "Cuba",
              value: "CU",
            },
            {
              group: "CountryCode",
              name: "Cyprus",
              value: "CY",
            },
            {
              group: "CountryCode",
              name: "Denmark",
              value: "DK",
            },
            {
              group: "CountryCode",
              name: "Ecuador",
              value: "EC",
            },
            {
              group: "CountryCode",
              name: "France",
              value: "FR",
            },
            {
              group: "CountryCode",
              name: "Germany",
              value: "DE",
            },
            {
              group: "CountryCode",
              name: "Greece",
              value: "GR",
            },
            {
              group: "CountryCode",
              name: "India",
              value: "IN",
            },
            {
              group: "CountryCode",
              name: "Ireland",
              value: "IE",
            },
            {
              group: "CountryCode",
              name: "Italy",
              value: "IT",
            },
            {
              group: "CountryCode",
              name: "Jamaica",
              value: "JM",
            },
            {
              group: "CountryCode",
              name: "Japan",
              value: "JP",
            },
            {
              group: "CountryCode",
              name: "Malaysia",
              value: "MY",
            },
            {
              group: "CountryCode",
              name: "Mexico",
              value: "MX",
            },
            {
              group: "CountryCode",
              name: "Netherlands",
              value: "NL",
            },
            {
              group: "CountryCode",
              name: "New Zealand",
              value: "NZ",
            },
            {
              group: "CountryCode",
              name: "Panama",
              value: "PA",
            },
            {
              group: "CountryCode",
              name: "Portugal",
              value: "PT",
            },
            {
              group: "CountryCode",
              name: "Puerto Rico",
              value: "PR",
            },
            {
              group: "CountryCode",
              name: "Qatar",
              value: "QA",
            },
            {
              group: "CountryCode",
              name: "Restricted",
              value: "R ",
            },
            {
              group: "CountryCode",
              name: "Singapore",
              value: "SG",
            },
            {
              group: "CountryCode",
              name: "South Africa",
              value: "ZA",
            },
            {
              group: "CountryCode",
              name: "Spain",
              value: "ES",
            },
            {
              group: "CountryCode",
              name: "Sweden",
              value: "SE",
            },
            {
              group: "CountryCode",
              name: "Switzerland",
              value: "CH",
            },
            {
              group: "CountryCode",
              name: "Trinidad and Tobago",
              value: "TT",
            },
            {
              group: "CountryCode",
              name: "United Kingdom",
              value: "GB",
            },
          ],
          include: [],
        },
        states: {
          include: [],
          exclude: [],
        },
        markets: {
          include: [],
          exclude: [],
        },
        stations: {
          include: [],
          exclude: [],
        },
      },
    },
  },
  {
    title: "Apex",
    queryDefinition: {
      keywordQuery: {
        include: [],
        includeOperator: "or",
        exclude: [],
        excludeOperator: "or",
      },
      sourceFilter: {
        countries: {
          exclude: [],
          include: [],
        },
        states: {
          include: [],
          exclude: [],
        },
        markets: {
          include: [],
          exclude: [],
        },
        stations: {
          include: [],
          exclude: [],
        },
        contentType: {
          include: [],
          exclude: [],
        },
      },
    },
  },
  {
    title: "US Only (All Media Types)",
    queryDefinition: {
      keywordQuery: {
        include: [],
        includeOperator: "or",
        exclude: [],
        excludeOperator: "or",
      },
      sourceFilter: {
        countries: {
          exclude: [],
          include: [
            {
              group: "CountryCode",
              name: "United States",
              value: "US",
            },
          ],
        },
        states: {
          include: [],
          exclude: [],
        },
        markets: {
          include: [],
          exclude: [],
        },
        stations: {
          include: [],
          exclude: [],
        },
        contentType: {
          include: [],
          exclude: [],
        },
      },
    },
  },
  {
    title: "Broadcast US",
    queryDefinition: {
      keywordQuery: {
        include: [],
        includeOperator: "or",
        exclude: [],
        excludeOperator: "or",
      },
      sourceFilter: {
        includeOperator: "or",
        excludeOperator: "or",
        contentType: {
          exclude: [],
          include: [
            {
              group: "EventTypeComposite",
              name: "Radio",
              value: "Radio",
            },
            {
              group: "EventTypeComposite",
              name: "Television",
              value: "Television",
            },
          ],
        },
        countries: {
          exclude: [],

          include: [
            {
              group: "CountryCode",
              name: "United States",
              value: "US",
            },
          ],
        },
        states: {
          exclude: [],
          include: [],
        },
        markets: {
          exclude: [],
          include: [],
        },
        stations: {
          exclude: [],
          include: [],
        },
      },
    },
  },
  {
    title: "Broadcast US/Canada",
    queryDefinition: {
      keywordQuery: {
        include: [],
        includeOperator: "or",
        exclude: [],
        excludeOperator: "or",
      },
      sourceFilter: {
        includeOperator: "or",
        excludeOperator: "or",
        contentType: {
          exclude: [],
          include: [
            {
              group: "EventTypeComposite",
              name: "Radio",
              value: "Radio",
            },
            {
              group: "EventTypeComposite",
              name: "Television",
              value: "Television",
            },
          ],
        },
        countries: {
          exclude: [],
          include: [
            {
              group: "CountryCode",
              name: "Canada",
              value: "CA",
            },
            {
              group: "CountryCode",
              name: "United States",
              value: "US",
            },
          ],
        },
        states: {
          exclude: [],
          include: [],
        },
        markets: {
          exclude: [],
          include: [],
        },
        stations: {
          exclude: [],
          include: [],
        },
      },
    },
  },
  {
    title: "Broadcast Global",
    queryDefinition: {
      keywordQuery: {
        include: [],
        includeOperator: "or",
        exclude: [],
        excludeOperator: "or",
      },
      sourceFilter: {
        includeOperator: "or",
        excludeOperator: "or",
        contentType: {
          exclude: [],
          include: [
            {
              group: "EventTypeComposite",
              name: "Radio",
              value: "Radio",
            },
            {
              group: "EventTypeComposite",
              name: "Television",
              value: "Television",
            },
          ],
        },
        countries: {
          exclude: [],
          include: [],
        },
        states: {
          exclude: [],
          include: [],
        },
        markets: {
          exclude: [],
          include: [],
        },
        stations: {
          exclude: [],
          include: [],
        },
      },
    },
  },
  {
    title: "Podcast US",
    queryDefinition: {
      keywordQuery: {
        include: [],
        includeOperator: "or",
        exclude: [],
        excludeOperator: "or",
      },
      sourceFilter: {
        includeOperator: "or",
        excludeOperator: "or",
        contentType: {
          exclude: [],
          include: [
            {
              group: "EventTypeComposite",
              name: "Podcast",
              value: "Podcast",
            },
          ],
        },
        countries: {
          exclude: [],
          include: [
            {
              group: "CountryCode",
              name: "United States",
              value: "US",
            },
          ],
        },
        states: {
          exclude: [],
          include: [],
        },
        markets: {
          exclude: [],
          include: [],
        },
        stations: {
          exclude: [],
          include: [],
        },
      },
    },
  },
  {
    title: "Podcast Global",
    queryDefinition: {
      keywordQuery: {
        include: [],
        includeOperator: "or",
        exclude: [],
        excludeOperator: "or",
      },
      sourceFilter: {
        includeOperator: "or",
        excludeOperator: "or",
        contentType: {
          exclude: [],
          include: [
            {
              group: "EventTypeComposite",
              name: "Podcast",
              value: "Podcast",
            },
          ],
        },
        countries: {
          exclude: [],
          include: [],
        },
        states: {
          exclude: [],
          include: [],
        },
        markets: {
          exclude: [],
          include: [],
        },
        stations: {
          exclude: [],
          include: [],
        },
      },
    },
  },
  {
    title: "Youtube",
    queryDefinition: {
      keywordQuery: {
        include: [],
        includeOperator: "or",
        exclude: [],
        excludeOperator: "or",
      },
      sourceFilter: {
        includeOperator: "or",
        excludeOperator: "or",
        contentType: {
          exclude: [],
          include: [
            {
              group: "EventTypeComposite",
              name: "YouTube",
              value: "YouTube",
            },
          ],
        },
        countries: {
          exclude: [],
          include: [],
        },
        states: {
          exclude: [],
          include: [],
        },
        markets: {
          exclude: [],
          include: [],
        },
        stations: {
          exclude: [],
          include: [],
        },
      },
    },
  },
];

export const INCLUDE_EXCLUDE_PRESETS: {
  title: string;
  contentAccessFilter: ContentAccessFilter; // FIXME: how to define type of the preset
}[] = [
  {
    title: "Vantage",
    contentAccessFilter: {
      contentTypes: {
        included: ["Podcast", "Radio", "Television", "YouTube"],
        excluded: [],
      },
      countries: {
        included: [],
        excluded: [
          "AR",
          "AU",
          "AT",
          "BS",
          "BB",
          "BM",
          "BR",
          "CA",
          "KY",
          "CN",
          "CO",
          "CU",
          "CY",
          "DK",
          "EC",
          "FR",
          "DE",
          "GR",
          "IN",
          "IE",
          "IT",
          "JM",
          "JP",
          "MY",
          "MX",
          "NL",
          "NZ",
          "PA",
          "PT",
          "PR",
          "QA",
          "R ",
          "SG",
          "ZA",
          "ES",
          "SE",
          "CH",
          "TT",
          "GB",
        ],
      },
      states: {
        included: [],
        excluded: [],
      },
      markets: {
        included: [],
        excluded: [],
      },
      stations: {
        included: [],
        excluded: [],
      },
      podcastSources: {
        included: [],
        excluded: [],
      },
    },
  },
  {
    title: "Apex",
    contentAccessFilter: {
      contentTypes: {
        included: [],
        excluded: [],
      },
      countries: {
        included: [],
        excluded: [],
      },
      states: {
        included: [],
        excluded: [],
      },
      markets: {
        included: [],
        excluded: [],
      },
      stations: {
        included: [],
        excluded: [],
      },
      podcastSources: {
        included: [],
        excluded: [],
      },
    },
  },
  {
    title: "US Only (All Media Types)",
    contentAccessFilter: {
      contentTypes: {
        included: [],
        excluded: [],
      },
      countries: {
        included: ["US"],
        excluded: [],
      },
      states: {
        included: [],
        excluded: [],
      },
      markets: {
        included: [],
        excluded: [],
      },
      stations: {
        included: [],
        excluded: [],
      },
      podcastSources: {
        included: [],
        excluded: [],
      },
    },
  },
  {
    title: "Broadcast US",
    contentAccessFilter: {
      contentTypes: {
        included: ["Radio", "Television"],
        excluded: [],
      },
      countries: {
        included: ["US"],
        excluded: [],
      },
      states: {
        included: [],
        excluded: [],
      },
      markets: {
        included: [],
        excluded: [],
      },
      stations: {
        included: [],
        excluded: [],
      },
      podcastSources: {
        included: [],
        excluded: [],
      },
    },
  },
  {
    title: "Broadcast US/Canada",
    contentAccessFilter: {
      contentTypes: {
        included: ["Radio", "Television"],
        excluded: [],
      },
      countries: {
        included: ["CA", "US"],
        excluded: [],
      },
      states: {
        included: [],
        excluded: [],
      },
      markets: {
        included: [],
        excluded: [],
      },
      stations: {
        included: [],
        excluded: [],
      },
      podcastSources: {
        included: [],
        excluded: [],
      },
    },
  },
  {
    title: "Broadcast Global",
    contentAccessFilter: {
      contentTypes: {
        included: ["Radio", "Television"],
        excluded: [],
      },
      countries: {
        included: [],
        excluded: [],
      },
      states: {
        included: [],
        excluded: [],
      },
      markets: {
        included: [],
        excluded: [],
      },
      stations: {
        included: [],
        excluded: [],
      },
      podcastSources: {
        included: [],
        excluded: [],
      },
    },
  },
  {
    title: "Podcast US",
    contentAccessFilter: {
      contentTypes: {
        included: ["Podcast"],
        excluded: [],
      },
      countries: {
        included: ["US"],
        excluded: [],
      },
      states: {
        included: [],
        excluded: [],
      },
      markets: {
        included: [],
        excluded: [],
      },
      stations: {
        included: [],
        excluded: [],
      },
      podcastSources: {
        included: [],
        excluded: [],
      },
    },
  },
  {
    title: "Podcast Global",
    contentAccessFilter: {
      contentTypes: {
        included: ["Podcast"],
        excluded: [],
      },
      countries: {
        included: [],
        excluded: [],
      },
      states: {
        included: [],
        excluded: [],
      },
      markets: {
        included: [],
        excluded: [],
      },
      stations: {
        included: [],
        excluded: [],
      },
      podcastSources: {
        included: [],
        excluded: [],
      },
    },
  },
  {
    title: "Youtube",
    contentAccessFilter: {
      contentTypes: {
        included: ["YouTube"],
        excluded: [],
      },
      countries: {
        included: [],
        excluded: [],
      },
      states: {
        included: [],
        excluded: [],
      },
      markets: {
        included: [],
        excluded: [],
      },
      stations: {
        included: [],
        excluded: [],
      },
      podcastSources: {
        included: [],
        excluded: [],
      },
    },
  },
];
