import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

export function DuplicatesChipTooltip({
  children,
  ...props
}: Omit<TooltipProps, "title">) {
  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 145,
      fontFamily: "montserrat",
      fontSize: "10px",
      lineHeight: "14px",
    },
  });

  return (
    <StyledTooltip
      title="This clip has duplicates. Click to view or manage."
      {...props}
    >
      {children}
    </StyledTooltip>
  );
}
