import { MutableRefObject } from "react";
import {
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { WatchTermInput } from "src/components/WatchTermInput/WatchTermInput";
import { TextInputBase } from "src/components/TextInputBase";
import { WatchQueryKeywordFormValues } from "src/components/WatchQueryKeywordForm/WatchQueryKeywordForm.model";
import { SourcesPicker } from "src/components/SourcesPicker/SourcesPicker";

export const WatchListCreateKeywordForm = ({
  loading,
  dropDownBoundsEl,
}: {
  loading?: boolean;
  dropDownBoundsEl: MutableRefObject<HTMLDivElement | null>;
}) => {
  const { palette, breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down("lg"));

  const { formState, control, watch } =
    useFormContext<WatchQueryKeywordFormValues>();
  const sourcesIncludeList = watch("sourcesInclude.list");
  const sourcesExcludeList = watch("sourcesExclude.list");
  const { isSubmitting } = formState;
  const isBusy = isSubmitting || loading;

  return (
    <Stack
      sx={{ backgroundColor: palette.background.default }}
      maxWidth={isSmallScreen ? undefined : 785}
    >
      <Stack
        component={Paper}
        mb={2}
        p={isSmallScreen ? 2 : 4}
        pb={2}
        sx={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: isSmallScreen ? 0 : "8px",
          borderBottomRightRadius: isSmallScreen ? 0 : "8px",
        }}
      >
        <Grid container spacing={1.5}>
          {/* Watch term */}
          <Grid item xs={12} md={12} lg={12}>
            {isSmallScreen ? (
              <Typography
                variant="body2"
                fontWeight={600}
                lineHeight="24px"
                mb={1.5}
              >
                Keyword
              </Typography>
            ) : (
              <Typography variant="subtitle1">Keyword</Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="termsInclude"
              control={control}
              render={({ field, fieldState, formState }) => {
                const { list, logic } = formState.errors[field.name] || {};
                const message = list?.message || logic?.message || " ";

                return (
                  <WatchTermInput
                    id="watch-term-include-input"
                    label="Include*"
                    value={field.value}
                    options={[]} // no suggestions for now
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    autoFocus
                    disabled={isBusy}
                    error={!!fieldState.error}
                    helperText={message}
                    displayTooltip
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="termsExclude"
              control={control}
              render={({ field, fieldState, formState }) => {
                const { list, logic } = formState.errors[field.name] || {};
                const message = list?.message || logic?.message || " ";

                return (
                  <WatchTermInput
                    id="watch-term-exclude-input"
                    label="Exclude"
                    value={field.value}
                    options={[]} // no suggestions for now
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    disabled={isBusy}
                    error={!!fieldState.error}
                    helperText={message}
                    displayTooltip
                  />
                );
              }}
            />
          </Grid>
          {/* Watch term */}

          {/* Display name*/}
          <Grid item xs={12} md={12} lg={12}>
            <Controller
              name="displayName"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextInputBase
                    label="Display name"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    disabled={isBusy}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message || " "}
                    fullWidth
                  />
                );
              }}
            />
          </Grid>
          {/* Display name*/}
        </Grid>
      </Stack>

      {/* Sources */}
      <Stack
        component={Paper}
        mb={isSmallScreen ? 2 : undefined}
        p={isSmallScreen ? 2 : 4}
        pb={2}
        sx={{
          overflow: "hidden",
          borderRadius: isSmallScreen ? 0 : 2,
        }}
      >
        <Grid container spacing={1.5}>
          <Grid item xs={12} md={12} lg={12}>
            {isSmallScreen ? (
              <Typography
                variant="body2"
                fontWeight={600}
                lineHeight="24px"
                mb={1.5}
              >
                Sources
              </Typography>
            ) : (
              <Typography variant="subtitle1">Sources</Typography>
            )}
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Controller
              name="sourcesInclude"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <SourcesPicker
                    id="source-include-input"
                    label={"Include"}
                    dropDownBoundsEl={dropDownBoundsEl}
                    value={field.value}
                    excludedOptions={sourcesExcludeList}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    disabled={isBusy}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message || " "}
                  />
                );
              }}
            />
          </Grid>
          {/* Sources */}

          {/* Exclude sources */}
          <Grid item xs={12} md={12} lg={12}>
            <Controller
              name="sourcesExclude"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <SourcesPicker
                    id="source-exclude-input"
                    label="Exclude"
                    dropDownBoundsEl={dropDownBoundsEl}
                    value={field.value}
                    excludedOptions={sourcesIncludeList}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    disabled={isBusy}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message || " "}
                  />
                );
              }}
            />
          </Grid>
          {/* Exclude sources */}
        </Grid>
      </Stack>
    </Stack>
  );
};
