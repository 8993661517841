import { Fragment, useRef } from "react";
import { ArrowDropDownOutlined } from "@mui/icons-material";
import { Button, ButtonGroup } from "@mui/material";
import { useOpenState } from "src/utils/useOpenState";
import { DropDownButtonProps } from "./DropDownButton.model";

export default function DropDownButton({
  renderMenu,
  children,
  state,
  variant = "contained",
  ...props
}: DropDownButtonProps) {
  const anchorRef = useRef<HTMLDivElement>(null);
  const menuState = useOpenState();

  return (
    <Fragment>
      <ButtonGroup variant={variant} ref={anchorRef} {...props}>
        <Button fullWidth onClick={state ? state.show : menuState.show}>
          {children}
        </Button>
        <Button onClick={state ? state.show : menuState.show}>
          <ArrowDropDownOutlined />
        </Button>
      </ButtonGroup>
      {renderMenu(
        {
          open: state ? state.isOpen : menuState.isOpen,
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          transformOrigin: { horizontal: "center", vertical: "top" },
          anchorEl: anchorRef.current,
          onClose: state ? state.hide : menuState.hide,
        },
        state ? state.hide : menuState.hide
      )}
    </Fragment>
  );
}
