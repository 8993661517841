import Joi from "joi";
import { queryAdvertisementValues } from "src/models/Advertisement";
import { MediaCenterFilterFormValues } from "./MediaCenterFilterForm.model";

const sourceListSchema = Joi.array().items().required();
const sourceLogicSchema = Joi.string()
  .allow("and", "or")
  .insensitive()
  .required();

const programListSchema = Joi.array().items().required();
const programLogicSchema = Joi.string()
  .allow("and", "or")
  .insensitive()
  .required();

const tags = Joi.object().keys({
  tagName: Joi.string().required(),
  color: Joi.string().required(),
});

export const MediaCenterFilterFormSchema =
  Joi.object<MediaCenterFilterFormValues>({
    tags: Joi.array().items(tags),
    //
    sourcesInclude: {
      list: sourceListSchema.label("Sources"),
      logic: sourceLogicSchema.label("Logic"),
    },
    sourcesExclude: {
      list: sourceListSchema.label("Sources"),
      logic: sourceLogicSchema.label("Logic"),
    },
    //
    advertisement: Joi.valid(
      ...Object.values(queryAdvertisementValues)
    ).required(),
    //
    programInclude: {
      list: programListSchema.label("Program/Title"),
      logic: programLogicSchema.label("Logic"),
    },
    programExclude: {
      list: programListSchema.label("Program/Title"),
      logic: programLogicSchema.label("Logic"),
    },
  });
