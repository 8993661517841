import { Fragment, useCallback, useMemo, useState } from "react";
import {
  Article,
  BurstMode,
  FileDownloadOutlined,
  Info,
  LiveTv,
  People,
} from "@mui/icons-material";
import {
  Button,
  Paper,
  Stack,
  StackProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AppTab } from "src/components/AppTabLayout/AppTab";
import { AppTabLayout } from "src/components/AppTabLayout/AppTabLayout";
import { ClipViewer } from "src/components/ClipViewer/ClipViewer";
import { TranscriptView } from "src/components/TranscriptView/TranscriptView";
import { EventType } from "src/models/EventType";
import { BCastProgram } from "src/components/ClipViewTabs/BCastProgram/BCastProgram";
import { BCastViewership } from "src/components/ClipViewTabs/BCastViewership/BCastViewership";
import { PCastEpisode } from "src/components/ClipViewTabs/PCastEpisode/PCastEpisode";
import { PCastSource } from "src/components/ClipViewTabs/PCastSource/PCastSource";
import { TranscriptLineBundleViewLabelLayout } from "src/components/TranscriptLineBundleView/TranscriptLineBundleView.const";
import { PCastAudience } from "src/components/ClipViewTabs/PCastAudience/PCastAudience";
import { SingleEventResultItem } from "src/api/useEventDetails";
import { downloadMediaCenterMediaPublic } from "src/api/useMediaCenterMediaPublic";
import { useSessionContext } from "src/api/useSessionContext";
import { ValueOf } from "src/models/ValueOf";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { BannerData } from "src/api/useEventDetailsPublic";
import { getEventDetailsMediaDownloadExtension } from "src/api/usePowerSearchResult.getters";
import { PageLayoutPublicDesktop } from "../PageLayoutPublicDesktop";
import { CustomBannerHeader } from "../CustomBannerHeader/CustomBannerHeader";
import { IntercomPageAnchor } from "../IntercomPageAnchor/IntercomPageAnchor";

const PublicEventSingleTabs = {
  transcript: "transcript",
  program: "program",
  audience: "audience",
  episode: "episode",
  viewership: "viewership",
} as const;

type PublicEventSingleTabValues = ValueOf<typeof PublicEventSingleTabs>;

export function PublicEventSingle({
  eventData,
  mediaItemId,
  itemType,
  isLoading,
  routeRoot,
  bannerData,
}: {
  eventData: SingleEventResultItem;
  mediaItemId?: string;
  itemType?: string;
  isLoading: boolean;
  routeRoot: "MediaCenter" | "Report" | "Share";
  bannerData?: BannerData | null;
}) {
  const { breakpoints } = useTheme();
  const { userId } = useSessionContext();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const [autoScroll, setAutoScroll] = useState(false);
  const [currentTab, setCurrentTab] =
    useViewModeQueryParam<PublicEventSingleTabValues>({
      paramKey: "tab",
      defaultValue: PublicEventSingleTabs.transcript,
    });

  const [offset, setOffset] = useState(0);

  const downloadMediaItem = useCallback(() => {
    if (mediaItemId) {
      downloadMediaCenterMediaPublic({
        userId,
        itemId: mediaItemId,
        title: eventData.title,
      });
    }
  }, [userId, mediaItemId, eventData.title]);

  const event = useMemo(() => eventData || {}, [eventData]);
  const dateTimeLabel = event.startDateTime
    ? new Date(event.startDateTime).toLocaleTimeString()
    : "n/a";
  const titleLabel = isLoading
    ? "Loading..."
    : `${dateTimeLabel} - ${event.source} - ${event.title}`;

  const handleCurrentTimeChange = useCallback(
    (_nextId: string, nextTime: number) => {
      setOffset(nextTime);
    },
    [setOffset]
  );

  const clipViewer = (
    <ClipViewer
      id="clip-viewer"
      hotStart
      loading={isLoading}
      event={eventData}
      onCurrentTimeChange={handleCurrentTimeChange}
    />
  );

  const tabContentLayout: StackProps["direction"] = isMobile
    ? "column" // for usage in mobile and compact mode
    : "row";

  const programTabContent = useMemo(
    () => (
      <BCastProgram
        event={event}
        flex={1}
        py={2}
        direction={tabContentLayout}
      />
    ),
    [event, tabContentLayout]
  );

  const viewershipTabContent = useMemo(
    () => (
      <BCastViewership
        event={event}
        direction={tabContentLayout}
        flex={1}
        py={2}
      />
    ),
    [event, tabContentLayout]
  );

  const episodeTabContent = useMemo(
    () => (
      <PCastEpisode
        event={event}
        flex={1}
        py={2}
        direction={tabContentLayout}
      />
    ),
    [event, tabContentLayout]
  );

  const sourceTabContent = useMemo(
    () => (
      <PCastSource event={event} flex={1} py={2} direction={tabContentLayout} />
    ),
    [event, tabContentLayout]
  );

  const audienceTabContent = useMemo(
    () => (
      <PCastAudience
        event={event}
        flex={1}
        py={2}
        direction={tabContentLayout}
      />
    ),
    [event, tabContentLayout]
  );

  const transcriptView = useMemo(
    () => (
      <TranscriptView
        offset={offset}
        event={event}
        loading={isLoading}
        height="100%"
        hideAutoScrollSwitch={isMobile}
        autoScroll={isMobile || autoScroll}
        showTimeLabel={TranscriptLineBundleViewLabelLayout.top}
        onAutoScrollChange={setAutoScroll}
        overflow="scroll"
      />
    ),
    [event, isLoading, offset, autoScroll, isMobile]
  );

  const tabSectionMobile = useMemo(
    () => (
      <Fragment>
        {/* Broadcast Tabs */}
        {event.eventType === EventType.BCast && (
          <AppTabLayout flex={1} layout="mobile">
            <AppTab
              label="Program"
              value="program"
              icon={<LiveTv />}
              children={programTabContent}
              overflow="auto"
            />

            <AppTab
              label="Transcription"
              value="transcription"
              icon={<Article />}
              children={transcriptView}
              overflow="hidden" // TranscriptView scrolls itself
            />

            <AppTab
              label="Viewership"
              value="viewership"
              icon={<People />}
              children={viewershipTabContent}
              overflow="auto"
            />
          </AppTabLayout>
        )}
        {/* Broadcast Tabs */}

        {/* Podcast Tabs */}
        {(event.eventType === EventType.PCast ||
          event.eventType === EventType.YouTube) && (
          <AppTabLayout flex={1} layout="mobile">
            <AppTab
              label="Transcription"
              value="transcription"
              icon={<Article />}
              children={transcriptView}
              overflow="hidden" // TranscriptView scrolls itself
            />

            <AppTab
              label="Episode"
              value="episode"
              icon={<BurstMode />}
              children={episodeTabContent}
              overflow="auto"
            />

            <AppTab
              label="Source"
              value="source"
              icon={<Info />}
              children={sourceTabContent}
              overflow="auto"
            />

            <AppTab
              label="Audience"
              value="audience"
              icon={<People />}
              children={audienceTabContent}
              overflow="auto"
            />
          </AppTabLayout>
        )}
        {/* Podcast Tabs */}
      </Fragment>
    ),
    [
      audienceTabContent,
      episodeTabContent,
      event.eventType,
      programTabContent,
      sourceTabContent,
      transcriptView,
      viewershipTabContent,
    ]
  );

  const tabSectionDesktop = useMemo(
    () => (
      <Fragment>
        {eventData?.eventType === EventType.BCast && (
          <AppTabLayout
            flex={1}
            overflow="visible"
            currentTab={currentTab}
            onCurrentTabChange={(tab) =>
              setCurrentTab(tab as PublicEventSingleTabValues)
            }
          >
            <AppTab
              label="Program"
              value={PublicEventSingleTabs.program}
              children={programTabContent}
            />

            <AppTab
              label="Transcription"
              value={PublicEventSingleTabs.transcript}
              children={transcriptView}
            />

            <AppTab
              label="Viewership"
              value={PublicEventSingleTabs.viewership}
              children={viewershipTabContent}
            />
          </AppTabLayout>
        )}

        {(eventData?.eventType === EventType.PCast ||
          eventData?.eventType === EventType.YouTube) && (
          <AppTabLayout flex={1} overflow="auto">
            <AppTab
              label="Transcription"
              value={PublicEventSingleTabs.transcript}
              children={transcriptView}
            />

            <AppTab
              label="Episode"
              value={PublicEventSingleTabs.episode}
              children={episodeTabContent}
            />

            <AppTab label="Source" value="source" children={sourceTabContent} />

            <AppTab
              label="Audience"
              value={PublicEventSingleTabs.audience}
              children={audienceTabContent}
            />
          </AppTabLayout>
        )}
      </Fragment>
    ),
    [
      eventData?.eventType,
      currentTab,
      programTabContent,
      transcriptView,
      viewershipTabContent,
      episodeTabContent,
      sourceTabContent,
      audienceTabContent,
      setCurrentTab,
    ]
  );

  const mobileContent = (
    <>
      <CustomBannerHeader isPublicPage eventBannerData={bannerData} />
      <Stack component={Paper} height="100%" overflow="hidden" pb={2}>
        <Stack display="flex" direction="row" alignItems="center">
          <Typography
            variant="subtitle1"
            m={2}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {titleLabel}
          </Typography>
          {routeRoot === "MediaCenter" && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadOutlined fontSize="small" />}
              sx={{
                height: 42,
                minWidth: 90,
                mr: 2,
              }}
              onClick={downloadMediaItem}
            >
              {getEventDetailsMediaDownloadExtension(event)}
            </Button>
          )}
        </Stack>

        {clipViewer}
        {tabSectionMobile}
      </Stack>
    </>
  );

  if (isMobile) {
    return mobileContent;
  }

  return (
    <PageLayoutPublicDesktop
      overflow="scroll"
      header={
        <CustomBannerHeader
          isPublicPage
          eventBannerData={bannerData}
          maxWidth="882px"
        />
      }
      content={
        <Stack>
          <Stack display="flex" direction="row" alignItems="center">
            <Typography variant="h6" m={4} ml={0} mr="auto">
              {titleLabel}
              <IntercomPageAnchor />
            </Typography>
            {routeRoot === "MediaCenter" && (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  height: 42,
                  width: 186,
                  ml: 2,
                }}
                onClick={downloadMediaItem}
              >
                DOWNLOAD {getEventDetailsMediaDownloadExtension(event)}
              </Button>
            )}
          </Stack>
          <Stack component={Paper}>{clipViewer}</Stack>
          <Stack mt={2} pb={2} component={Paper}>
            {tabSectionDesktop}
          </Stack>
        </Stack>
      }
    />
  );
}
