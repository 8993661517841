import { Box, IconButton, Tooltip, alpha, useTheme } from "@mui/material";
import {
  NotificationsActive,
  NotificationsActiveOutlined,
} from "@mui/icons-material";

export function InstantAlertButton({
  onClick,
  active,
}: {
  onClick?: () => void;
  active?: boolean;
}) {
  const { palette } = useTheme();

  const Icon = active ? NotificationsActive : NotificationsActiveOutlined;
  return (
    <Tooltip
      title={active ? "Turn off Instant Alert" : "Turn on Instant Alert"}
    >
      <Box>
        <IconButton
          onClick={onClick}
          sx={{
            borderRadius: 2,
            color: palette.text.secondary,
            backgroundColor: active
              ? alpha(palette.primary.light, 0.08)
              : undefined,
          }}
        >
          <Icon color={active ? "primary" : undefined} />
        </IconButton>
      </Box>
    </Tooltip>
  );
}
