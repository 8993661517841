import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { components } from "@tveyes/twosionwebapischema";
import { Stack, Typography } from "@mui/material";
import {
  MediaViewport,
  BroadcastConfig,
  PodcastConfig,
  YouTubeConfig,
  GenericConfig,
  MediaViewportProps,
  PlayerError,
  CropConfiguration,
} from "@tveyes/mediaviewport-ui";
import { EventType } from "src/models/EventType";
import { ClipViewerProps } from "./ClipViewer.model";
import { ClipViewerError } from "./ClipViewerError";
import { LoadingOverlay } from "../LoadingOverlay/LoadingOverlay";
import { useClipViewerOffsets } from "./utils/useClipViewerOffsets";
import { ClipViewerPlaceholder } from "./ClipViewerPlaceholder";

export type SignedResponse = {
  signature?: components["schemas"]["SignedResponse"]["signature"];
  signed?: components["schemas"]["SignedResponse"]["signed"];
};

export function ClipViewer({
  id,
  event,
  loading,
  seekOffsetMs,
  showMedia = true,
  onCurrentTimeChange,
  onDurationChange,
  onChangePlayState,
  onPlayEnd,
  onPreviewEnd,
  onMentionChange,
  aspectRatio,
  ...props
}: ClipViewerProps) {
  const {
    eventType,
    mediaType,
    sourceId,
    startDateTime,
    endDateTime,
    assetId,
    mediaCenter,
    mediaCenterMedia,
    mediaCenterThumbnail,
    status,
  } = event || {};

  const setPlaying = useState(false)[1];
  const [playerError, setPlayerError] = useState<PlayerError>();
  const [seekOffset, setSeekOffset] = useState<number | undefined>(
    !seekOffsetMs ? undefined : seekOffsetMs / 1000
  );

  const onCurrentTimeWrapper = useCallback(
    (id: string, timeSec: number) => {
      // player reports time in seconds but highlights come in milliseconds
      const timeMs = timeSec * 1000;
      onCurrentTimeChange && onCurrentTimeChange(id, timeMs);
    },
    [onCurrentTimeChange]
  );

  const onChangePlayStateWrapper = useCallback<
    NonNullable<MediaViewportProps["onPaused"]>
  >(
    (instanceId, nextIsPlaying) => {
      if (instanceId === id) {
        setPlaying((prevIsPlaying) => {
          if (nextIsPlaying !== prevIsPlaying) {
            onChangePlayState?.(instanceId, nextIsPlaying);
            return nextIsPlaying;
          }

          return prevIsPlaying;
        });
      }
    },
    [id, setPlaying, onChangePlayState]
  );

  const onMentionChangeWrapper = useCallback<
    NonNullable<MediaViewportProps["onOffsetChange"]>
  >(
    (instanceId, nextMentionIndex) => {
      if (instanceId !== id) return;
      onMentionChange?.(nextMentionIndex);
    },
    [id, onMentionChange]
  );

  const onPlayEndWrapper = useCallback<
    NonNullable<MediaViewportProps["onPlayEnd"]>
  >(() => {
    onPlayEnd?.(id);
  }, [id, onPlayEnd]);

  const onDurationChangeWrapper = useCallback<
    NonNullable<MediaViewportProps["onDurationChange"]>
  >(
    (duration) => {
      // FIXME: workaround, seems to be bad types
      if (typeof duration === "number") {
        onDurationChange?.(id, duration);
      }
    },
    [id, onDurationChange]
  );

  const config = useMemo(() => {
    const bCastConfig: BroadcastConfig | null =
      eventType === EventType.BCast &&
      sourceId &&
      startDateTime &&
      endDateTime &&
      mediaType
        ? {
            eventType,
            station: sourceId,
            mediaType: mediaType,
            startDateTime: startDateTime,
            endDateTime: endDateTime,
          }
        : null;

    const pCastConfig: PodcastConfig | null =
      eventType === EventType.PCast && assetId && startDateTime && endDateTime
        ? {
            eventType,
            assetId,
            startDateTime: startDateTime,
            endDateTime: endDateTime,
          }
        : null;

    const youTubeConfig: YouTubeConfig | null =
      eventType === EventType.YouTube && assetId && startDateTime && endDateTime
        ? {
            eventType,
            assetId,
            startDateTime: startDateTime,
            endDateTime: endDateTime,
          }
        : null;

    const genericConfig: GenericConfig | null =
      mediaCenter &&
      mediaCenterMedia &&
      mediaCenterThumbnail &&
      startDateTime &&
      endDateTime &&
      assetId
        ? {
            eventType: EventType.Generic,
            startDateTime,
            endDateTime,
            mediaURL: mediaCenterMedia,
            posterURL: mediaCenterThumbnail,
          }
        : null;

    // FIXME: interface design flaw!
    // player should be feature-agnostic and receive only config
    const nextConfig = mediaCenter
      ? genericConfig
      : bCastConfig || pCastConfig || youTubeConfig;

    return nextConfig;
  }, [
    assetId,
    endDateTime,
    eventType,
    mediaCenter,
    mediaCenterMedia,
    mediaCenterThumbnail,
    mediaType,
    sourceId,
    startDateTime,
  ]);

  const content: JSX.Element | undefined = useMemo(() => {
    if (loading) {
      return <LoadingOverlay />;
    }

    if (playerError) {
      return <ClipViewerError error={playerError} />;
    }
    if (mediaCenter && status === "Pending") {
      return (
        <ClipViewerPlaceholder type={eventType} mediaType={mediaType || ""} />
      );
    }

    if (!config && mediaCenter) {
      return <Typography align="center">Upload in progress..</Typography>;
    }

    if (!config) {
      console.error("Invalid event for ClipViewer", event);
      return (
        <Typography align="center">
          Invalid ClipViewer event <br />
          {process.env.NODE_ENV !== "production" ? (
            <code>{JSON.stringify(event, null, 2)}</code>
          ) : (
            ""
          )}
        </Typography>
      );
    }
  }, [
    config,
    event,
    loading,
    mediaCenter,
    playerError,
    status,
    eventType,
    mediaType,
  ]);

  const cropConfig: CropConfiguration | undefined = useMemo(() => {
    if (
      event?.cropConfiguration &&
      event.cropConfiguration.startDateTime &&
      event.cropConfiguration.endDateTime
    ) {
      return {
        startDateTime: event.cropConfiguration.startDateTime,
        endDateTime: event.cropConfiguration.endDateTime,
      };
    }
  }, [event?.cropConfiguration]);

  const placeholderIcon = useMemo(
    () => (
      <ClipViewerPlaceholder type={eventType} mediaType={mediaType || ""} />
    ),
    [eventType, mediaType]
  );

  const onPlayerErrorTypeWrapper = useCallback<
    NonNullable<MediaViewportProps["onPlayerError"]>
  >((error) => {
    setPlayerError(error);
  }, []);

  const offsets = useClipViewerOffsets(event?.highlights);

  useEffect(() => {
    setSeekOffset(!seekOffsetMs ? undefined : seekOffsetMs / 1000);
  }, [seekOffsetMs]);

  const seekEndHandler = useCallback(() => {
    setSeekOffset(undefined);
  }, []);

  const getSignature = useCallback(async (query: string): Promise<string> => {
    try {
      const response = await axios<SignedResponse>(
        `/api/signing?q=${encodeURIComponent(query)}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.signed) return response.data.signed;

      throw new Error("signature failure");
    } catch (error) {
      throw error;
    }
  }, []);

  return useMemo(() => {
    if (!config || loading || playerError) {
      return (
        <Stack
          id={`clip-viewer-placeholder:${id}`}
          minWidth={props.minWidth}
          maxWidth={props.maxWidth}
          minHeight={props.minHeight}
          maxHeight={props.maxHeight}
          sx={props.sx}
          children={
            <Stack
              children={content}
              flex={1}
              className="player-container"
              sx={{
                aspectRatio: aspectRatio
                  ? aspectRatio
                  : showMedia
                  ? 1600 / 1154
                  : undefined,
                height: showMedia ? undefined : 128,
              }}
            />
          }
        />
      );
    }

    return (
      <MediaViewport
        id={id}
        partnerId="1321"
        config={config}
        showMedia={showMedia}
        offsets={offsets}
        seekOffset={seekOffset}
        cropConfig={cropConfig}
        signature={getSignature}
        onCurrentTime={onCurrentTimeWrapper}
        onDurationChange={onDurationChangeWrapper}
        onPaused={onChangePlayStateWrapper}
        onPlayEnd={onPlayEndWrapper}
        onPreviewEnd={onPreviewEnd}
        onPlayerError={onPlayerErrorTypeWrapper}
        onOffsetChange={onMentionChangeWrapper}
        onSeekEnd={seekEndHandler}
        prerollOffset={10}
        renderPlaceholder={() => placeholderIcon}
        {...props}
      />
    );
  }, [
    props,
    config,
    loading,
    offsets,
    id,
    content,
    seekOffset,
    cropConfig,
    showMedia,
    playerError,
    getSignature,
    placeholderIcon,
    onCurrentTimeWrapper,
    onDurationChangeWrapper,
    onChangePlayStateWrapper,
    onPlayerErrorTypeWrapper,
    onMentionChangeWrapper,
    onPlayEndWrapper,
    onPreviewEnd,
    seekEndHandler,
    aspectRatio,
  ]);
}
