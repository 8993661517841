import {
  InputAdornment,
  Paper,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { DateRange as DateRangeIcon } from "@mui/icons-material";
import { endOfDay, startOfDay } from "date-fns";
import { Fragment, useMemo } from "react";
import { useWatchQueryList } from "src/api/useWatchQueryList";
import { LoadingOverlay } from "src/components/LoadingOverlay/LoadingOverlay";
import { PageHeaderDesktop } from "src/components/PageHeaderDesktop/PageHeaderDesktop";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { PageLayoutMobile } from "src/components/PageLayoutMobile";
import { ScrollBox } from "src/components/ScrollBox/ScrollBox";
import { ScrollHeaderLayout } from "src/components/ScrollHeaderLayout/ScrollHeaderLayout";
import { ScrollHeaderLayoutProps } from "src/components/ScrollHeaderLayout/ScrollHeaderLayout.model";
import { TileLayout } from "src/components/TileLayout/TileLayout";
import { ViewModeSwitch } from "src/components/ViewModeSwitch/ViewModeSwitch";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { AnalyticsLiteFilterDialog } from "src/components/AnalyticsLiteFilterDialog/AnalyticsLiteFilterDialog";
import { WatchQueryPicker } from "src/components/WatchQueryPicker/WatchQueryPicker";
import { AppBarChart } from "src/components/charts/AppBarChart";
import { AppLineChart } from "src/components/charts/AppLineChart";
import { AppMapChart } from "src/components/charts/AppMapChart";
import { AppPieChart } from "src/components/charts/AppPieChart";
import { useAppChartColorMapper } from "src/components/charts/utils/useAppChartColorMapper";
import { isNormalQuery } from "src/utils/isNormalQuery";
import { useIsMobile } from "src/utils/useIsMobile";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import { useQueryGeoAnalytics } from "src/api/useQueryGeoAnalytics";
import { useOpenState } from "src/utils/useOpenState";
import { useQueryDateTimeRangeAnalyticsLite } from "src/utils/useQueryDateTimeRangeAnalyticsLite";
import { useQueryParamList } from "src/utils/useQueryParamList";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { useAudienceAnalytics } from "src/api/useAudienceAnalytics";
import { useAnalyticsForQueryIds } from "src/api/useAnalyticsForQueryIds";
import { useDataLevelQueryParam } from "src/components/charts/components/DataLevelSwitch";
import { WatchQueryPickerMobile } from "src/components/WatchQueryPickerMobile/WatchQueryPickerMobile";
import { AnalyticsLiteFilterButton } from "./components/AnalyticsLiteFilterButton";
import { useAnalyticsLiteQueryFilter } from "./hooks/useAnalyticsLiteQueryFilter";
import { AnalyticsLiteDateTimeRangePickerActions } from "./components/AnalyticsLiteDateTimeRangePickerActions";

const viewModeOptions: {
  desktop: ViewMode[];
  mobile: ViewMode[];
} = {
  desktop: [ViewMode.list, ViewMode.tile],
  mobile: [ViewMode.list],
};

export function AnalyticsLiteResultPage() {
  const isMobile = useIsMobile();
  const initialViewMode: ViewMode = isMobile ? ViewMode.list : ViewMode.tile;
  const filterDialog = useOpenState();
  const { palette, breakpoints } = useTheme();
  const { releaseColors } = useAppChartColorMapper();
  const [filterQuery, setFilterQuery] = useAnalyticsLiteQueryFilter();
  const [queryIds, setQueryIds] = useQueryParamList({ key: "queryIds" });
  const sortOrder: "asc" | "desc" = "asc";
  const [dataLevel] = useDataLevelQueryParam();

  const [viewMode, setViewMode] = useViewModeQueryParam<ViewMode>({
    paramKey: "tab",
    defaultValue: initialViewMode,
  });

  const isDesktop = useMediaQuery(breakpoints.up("lg"));
  const isNotDesktop = !isDesktop;
  const isOneColumn = isNotDesktop || viewMode === ViewMode.list;

  const [dateTimeRange, setDateTimeRange] = useQueryDateTimeRangeAnalyticsLite({
    updateType: "replaceIn",
  });

  // get watch queries for picking analytics for
  const watchQueryListData = useWatchQueryList({});
  const watchQueryList = useMemo(
    () => watchQueryListData.data?.results?.filter(isNormalQuery) ?? [],
    [watchQueryListData.data?.results]
  );

  const isWatchQueryListLoading = watchQueryListData.isFetching;

  const activeQueries = useMemo(
    () => watchQueryList.filter(({ id }) => queryIds.includes(id)),
    [queryIds, watchQueryList]
  );

  const analyticsForQuery = useAnalyticsForQueryIds({
    queryIds,
    dateRange: dateTimeRange,
    filters: makeQueryDefinition(filterQuery),
    sortOrder,
  });

  const queryGeoAnalyticsData = useQueryGeoAnalytics({
    queryIds,
    dateRange: dateTimeRange,
    filters: makeQueryDefinition(filterQuery),
    sortOrder,
  });

  const useAudienceAnalyticsForQuery = useAudienceAnalytics({
    queryIds,
    dateRange: dateTimeRange,
    filters: makeQueryDefinition(filterQuery),
    sortOrder,
  });

  const watchTermInput = isMobile ? (
    <WatchQueryPickerMobile
      label="Watch terms"
      value={activeQueries}
      options={watchQueryList}
      openOnFocus
      disableCloseOnSelect
      size="small"
      onChange={(nextWatchQueries) => {
        const nextActiveQueryIds = nextWatchQueries.map(({ id }) => `${id}`);
        setQueryIds((prevActiveQueryIds) => {
          const removed =
            prevActiveQueryIds?.filter(
              (id) => !nextActiveQueryIds.includes(`${id}`)
            ) || [];

          releaseColors(removed);

          return nextActiveQueryIds;
        });
      }}
    />
  ) : (
    <WatchQueryPicker
      label="Watch terms"
      value={activeQueries}
      options={watchQueryList}
      openOnFocus
      disableCloseOnSelect
      size="small"
      onChange={(nextWatchQueries) => {
        const nextActiveQueryIds = nextWatchQueries.map(({ id }) => `${id}`);
        setQueryIds((prevActiveQueryIds) => {
          const removed =
            prevActiveQueryIds?.filter(
              (id) => !nextActiveQueryIds.includes(`${id}`)
            ) || [];

          releaseColors(removed);

          return nextActiveQueryIds;
        });
      }}
    />
  );

  const dateRangePicker = (
    <DateRangePicker
      calendars={2}
      value={dateTimeRange}
      onChange={(date) =>
        setDateTimeRange([
          // ensure proper day bounds
          date[0] ? startOfDay(date[0]) : null,
          date[1] ? endOfDay(date[1]) : null,
        ])
      }
      localeText={{ start: "Start date", end: "End date" }}
      format="PP"
      disableFuture
      slots={{
        actionBar: (actionBarProps) => (
          <AnalyticsLiteDateTimeRangePickerActions
            setDateTimeRange={setDateTimeRange}
            {...actionBarProps}
          />
        ),
      }}
      slotProps={{
        desktopPaper: {
          elevation: palette.mode === "dark" ? 2 : undefined,
        },
        textField: {
          variant: "standard",
          InputProps: {
            endAdornment: !isMobile && (
              <InputAdornment position="end">
                <DateRangeIcon />
              </InputAdornment>
            ),
          },
        },
      }}
    />
  );

  const mapChart = (
    <AppMapChart
      title={
        {
          Regional: "Total Mentions by Region",
          National: "Total Mentions by Nation",
        }[dataLevel]
      }
      data={queryGeoAnalyticsData.data}
      queryIds={queryIds}
      dateRange={dateTimeRange}
      isLoading={queryGeoAnalyticsData.isFetching}
    />
  );
  const lineChart = (
    <AppLineChart
      title="Mentions"
      data={analyticsForQuery.data}
      isLoading={analyticsForQuery.isFetching}
    />
  );
  const barChart = (
    <AppBarChart
      title="Audience"
      data={useAudienceAnalyticsForQuery.data}
      isLoading={useAudienceAnalyticsForQuery.isFetching}
    />
  );
  const pieChart = (
    <AppPieChart
      title="Mention Share"
      data={analyticsForQuery.data}
      isLoading={analyticsForQuery.isFetching}
    />
  );

  const desktopDataView = isWatchQueryListLoading ? (
    <Stack flex={1}>
      <LoadingOverlay size={82} />
    </Stack>
  ) : (
    <ScrollBox>
      <Stack
        px={3}
        pt={3}
        pb={2}
        ml={2}
        mr={1}
        sx={{ background: palette.background.default }}
      >
        <TileLayout
          gap={2}
          rows={["map", "bar", "line", "pie"]}
          fillMode={isOneColumn ? 1 : 2}
          renderItem={(type) => {
            switch (type) {
              case "map":
                return mapChart;
              case "line":
                return lineChart;
              case "bar":
                return barChart;
              case "pie":
                return pieChart;
              default:
                return null;
            }
          }}
        />
      </Stack>
    </ScrollBox>
  );

  const mobileDataView = isWatchQueryListLoading ? (
    <Stack flex={1}>
      <LoadingOverlay size={82} />
    </Stack>
  ) : (
    <Stack
      gap={1}
      paddingY={1}
      paddingLeft={1}
      sx={{ background: palette.background.default }}
    >
      {mapChart}
      {lineChart}
      {barChart}
      {pieChart}
    </Stack>
  );

  const commonElements = (
    <AnalyticsLiteFilterDialog
      open={filterDialog.isOpen}
      initial={filterQuery}
      onClose={filterDialog.hide}
      onSubmit={setFilterQuery}
    />
  );

  const renderMobileHeader: ScrollHeaderLayoutProps["renderHeader"] = (
    _params
  ) => {
    return (
      <Stack
        px={2}
        py={2}
        gap={2}
        borderBottom={`1px solid ${palette.divider}`}
      >
        {watchTermInput}
        <Stack direction="row" gap={2}>
          {dateRangePicker}
          <AnalyticsLiteFilterButton
            value={filterQuery}
            onClick={filterDialog.show}
          />
        </Stack>
      </Stack>
    );
  };

  if (isMobile) {
    return (
      <Fragment>
        <PageLayoutMobile
          content={
            <ScrollHeaderLayout
              headerMinHeight={80}
              children={mobileDataView}
              renderHeader={renderMobileHeader}
              scrollbarColor={palette.background.default}
            />
          }
        />
        {commonElements}
      </Fragment>
    );
  }

  return (
    <PageLayoutDesktop
      overflow="hidden"
      maxWidthNormal={1528}
      maxWidthFullscreen={1528}
      header={
        <PageHeaderDesktop
          title="Watchlist Data Analysis"
          hidePenultimateRoute
          hideBreadcrumbs
          hideBackButton
        />
      }
      content={
        <Stack component={Paper} height="100%">
          <Stack
            direction="row"
            px={2}
            py={2}
            gap={2}
            flexWrap={{ xs: "wrap", lg: "nowrap" }}
            justifyContent="space-between"
          >
            <Stack direction="row" width={375} flexGrow={1}>
              {watchTermInput}
            </Stack>

            <Stack
              direction="row"
              gap={2}
              justifyContent="space-between"
              flexGrow={{ xs: 1, lg: 0 }}
            >
              {dateRangePicker}
              <Stack direction="row" gap={2}>
                <AnalyticsLiteFilterButton
                  value={filterQuery}
                  onClick={filterDialog.show}
                />
                {isDesktop && (
                  <ViewModeSwitch
                    value={viewMode}
                    onChange={setViewMode}
                    options={viewModeOptions.desktop}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
          {desktopDataView}
          {commonElements}
        </Stack>
      }
    />
  );
}
