import axios, { AxiosError } from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { enqueueSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { userInviteListKey } from "./useUserInviteList";
import { useSessionContext } from "./useSessionContext";

const userInviteCreateKEy = "userInviteCreateKey";

const apiPath: keyof paths = "/api/Invite";
const method: keyof paths[typeof apiPath] = "post";
type Endpoint = paths[typeof apiPath][typeof method];

type Body = Required<
  NonNullable<Endpoint["requestBody"]>["content"]["application/json"]
>;

type Request = {
  body: Omit<Body, "accountId">;
};

type Response = Endpoint["responses"][200]["content"]["application/json"];

type UseUserInviteCreateArgs = {
  onSuccess: (response: Response, request: Request) => void;
};

const getSuccessMessage = (recipients: string[]): string => {
  if (recipients.length === 1) {
    return `The invitation has been successfully sent`;
  }

  return `The invitations have been successfully sent`;
};

export function useUserInviteCreate(params?: UseUserInviteCreateArgs) {
  const queryClient = useQueryClient();
  const { onSuccess } = params || {};
  const { getAccessTokenSilently } = useAuth0();
  const { accountId } = useSessionContext();

  return useMutation<Response, unknown, Request>({
    mutationKey: [userInviteCreateKEy],
    mutationFn: async (req) => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl(apiPath);

      const body: Body = {
        accountId,
        comment: req.body.comment,
        emailAddresses: req.body.emailAddresses,
      };

      const { data } = await axios<Response>(url, {
        method,
        data: body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    onSuccess: (data, variables) => {
      queryClient.refetchQueries({ queryKey: userInviteListKey });
      onSuccess?.(data, variables);
      const message = getSuccessMessage(variables.body.emailAddresses ?? []);
      enqueueSnackbar(message, { variant: "success" });
    },
    onError: (error) => {
      const message = (error as AxiosError).response?.data;
      if (typeof message === "string") {
        enqueueSnackbar(message, { variant: "error" });
      } else {
        enqueueSnackbar(`${error}`, { variant: "error" });
      }
    },
  });
}
