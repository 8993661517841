import { useTheme } from "@mui/material";
import { gridClasses } from "@mui/x-data-grid-premium";
import { TableBase } from "src/components/TableBase/TableBase";
import {
  NoAccountActivityLogsOverlay,
  NoUserActivityLogsOverlay,
} from "src/components/NoResultsOverlay/NoResultsOverlay";
import { makeActivityLogTableColumns } from "./ActivityLogTable.utils";
import { ActivityLogTableProps } from "./ActivityLogTable.model";

export function ActivityLogTable({
  accountId,
  featureType,
  ...props
}: ActivityLogTableProps) {
  const { typography } = useTheme();

  const columns = makeActivityLogTableColumns(accountId);

  return (
    <TableBase
      columns={columns}
      {...props}
      slots={{
        noRowsOverlay:
          featureType === "accounts"
            ? NoAccountActivityLogsOverlay
            : NoUserActivityLogsOverlay,
        noResultsOverlay:
          featureType === "accounts"
            ? NoAccountActivityLogsOverlay
            : NoUserActivityLogsOverlay,
        ...props.slots,
      }}
      getRowId={(row) => row.timestamp}
      sx={{
        ...props.sx,
        [`.${gridClasses.cell}`]: {
          ...typography.body2,
        },
      }}
    />
  );
}
