import { format } from "date-fns";
import { Fragment, useState } from "react";
import { CheckCircle, Close, Pending, QuestionMark } from "@mui/icons-material";
import { Stack, useTheme } from "@mui/material";
import {
  gridClasses,
  GridColDef,
  GridSortModel,
} from "@mui/x-data-grid-premium";
import { TableBase } from "src/components/TableBase/TableBase";
import { UserInviteDTO } from "src/models/UserInvite";
import { useIsMobile } from "src/utils/useIsMobile";
import { UserInvitesEmpty } from "./components/UserInvitesEmpty/UserInviteNoRowsOverlay";

const columns: GridColDef<UserInviteDTO>[] = [
  { headerName: "Email", field: "email", minWidth: 140, flex: 1 },
  {
    headerName: "Date sent",
    field: "date",
    type: "dateTime",
    width: 200,
    valueFormatter: (params) => {
      return format(params.value, "PP pp");
    },
    valueGetter: (params) => {
      return params.row.created ? new Date(params.row.created) : null;
    },
  },
  {
    headerName: "Status",
    field: "status",
    minWidth: 120,

    renderCell(params) {
      switch (params.row.status?.toLowerCase()) {
        case "pending": {
          return (
            <Fragment>
              <Pending color="warning" />
              &nbsp;{params.row.status}
            </Fragment>
          );
        }
        case "accepted":
          return (
            <Fragment>
              <CheckCircle color="success" />
              &nbsp;{params.row.status}
            </Fragment>
          );

        case "expired":
          return (
            <Fragment>
              <Close color="disabled" />
              &nbsp;{params.row.status}
            </Fragment>
          );
      }

      return (
        <Fragment>
          <QuestionMark color="info" />
          {params.row.status}
        </Fragment>
      );
    },
  },
  { headerName: "Comment", field: "comment", minWidth: 100, flex: 1 },
];

export function UserInviteTable({
  rows,
  isLoading,
  showBorder,
}: {
  rows: UserInviteDTO[];
  isLoading?: boolean;
  showBorder?: boolean;
}) {
  const isMobile = useIsMobile();
  const { palette } = useTheme();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "date", sort: "desc" },
  ]);

  return (
    // FIXME: this is quick workaround for these Ad-Hoc styles
    // perhaps we need to re-think and make TableBase less styles and more flexible by default
    // so it is possible to turn on border without separately and remove isMobile prop
    <Stack
      flex={1}
      border={showBorder ? `1px solid ${palette.divider}` : undefined}
      borderRadius={showBorder ? 2 : undefined}
      overflow="hidden"
    >
      <TableBase
        disableColumnMenu
        disableColumnFilter
        hideFooter
        loading={isLoading}
        columnHeaderHeight={isMobile ? 56 : 40}
        columns={columns}
        rows={rows}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        slots={{
          noRowsOverlay: UserInvitesEmpty,
        }}
        sx={{
          [`.${gridClasses.virtualScroller}`]: {
            // required because in modal virtual scroller does not take full height for some reason
            flex: 1,
          },
        }}
      />
    </Stack>
  );
}
