import {
  FormControlLabel,
  Paper,
  Slider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { TileLayoutLazy } from "src/components/TileLayoutLazy/TileLayoutLazy";

const makeItem = (index: number) => `Item ${index}`;
const genItems = (count: number, startIndex = 0) => {
  return [...new Array(count)].map((_, index) => makeItem(startIndex + index));
};

const lazyItemsInitial = genItems(4);
const lazyItemsLimit = 8;

export function DevListLayoutLazy() {
  const { palette } = useTheme();
  const [lazyItems, setLazyItems] = useState<string[]>(lazyItemsInitial);
  const [isLoading, setLoading] = useState(false);
  const [colCount, setColCount] = useState(0);
  const [rowHeight, setRowHeight] = useState(0);
  const [gap, setGap] = useState(4);
  const [inset, setInset] = useState(4);

  return (
    <Stack height="100%" gap={3} pt={2} bgcolor={palette.background.default}>
      <Stack gap={1}>
        <Typography
          align="center"
          children={`${lazyItems.length} of ${lazyItemsLimit} loaded`}
        />
        <Stack px={2}>
          <FormControlLabel
            label={`Gap ${gap}`}
            labelPlacement="top"
            control={
              <Slider
                min={1}
                max={4}
                step={1}
                marks
                value={gap}
                onChange={(e, value) => setGap(value as number)}
                valueLabelDisplay="auto"
              />
            }
          />
        </Stack>

        <Stack px={2}>
          <FormControlLabel
            label={`Row Height: ${rowHeight || "Auto - Square Tile"}`}
            labelPlacement="top"
            control={
              <Slider
                min={0}
                max={400}
                step={100}
                marks
                value={rowHeight}
                onChange={(e, value) => setRowHeight(value as number)}
                valueLabelDisplay="auto"
              />
            }
          />
        </Stack>

        <Stack px={2}>
          <FormControlLabel
            label={`Columns ${colCount || "Auto"}`}
            labelPlacement="top"
            control={
              <Slider
                min={0}
                max={4}
                step={1}
                marks
                value={colCount}
                onChange={(e, value) => setColCount(value as number)}
                valueLabelDisplay="auto"
              />
            }
          />
        </Stack>

        <Stack px={2}>
          <FormControlLabel
            label={`Insets ${inset}`}
            labelPlacement="top"
            control={
              <Slider
                min={0}
                max={4}
                step={1}
                marks
                value={inset}
                onChange={(e, value) => setInset(value as number)}
              />
            }
          />
        </Stack>
      </Stack>

      <TileLayoutLazy
        items={lazyItems}
        total={lazyItemsLimit}
        isLoading={isLoading}
        getColCount={colCount > 0 ? () => colCount : undefined}
        getRowHeight={rowHeight > 0 ? () => rowHeight : undefined}
        getColGap={() => gap}
        getRowGap={() => gap}
        getItemKey={(item) => item}
        loadMoreItems={async (startIndex, stopIndex) => {
          try {
            setLoading(true);
            console.log(`@@ DEBUG:loadMoreItems: ${startIndex} ${stopIndex}`);
            await new Promise((resolve) => setTimeout(resolve, 2000));
            const loaded = genItems(1 + stopIndex - startIndex, startIndex);
            setLazyItems((prevLazyItems) => {
              const nextLazyItems = [...prevLazyItems];
              for (let index = 0; index < loaded.length; index++) {
                if (nextLazyItems.length === lazyItemsLimit) break;
                nextLazyItems[startIndex + index] = loaded[index];
              }

              return nextLazyItems;
            });
          } finally {
            setLoading(false);
          }
        }}
        renderItem={({
          data,
          columnIndex,
          rowIndex,
          cellWidth,
          cellHeight,
        }) => {
          return (
            <Stack
              flex={1}
              justifyContent="center"
              component={Paper}
              elevation={4}
            >
              <Typography align="center" variant="h6" children={data} />

              <Typography
                align="center"
                variant="h6"
                children={`r=${rowIndex}, c=${columnIndex}`}
              />
              <Typography
                align="center"
                variant="h6"
                children={`w=${cellWidth}, h=${cellHeight}`}
              />
            </Stack>
          );
        }}
        insets={{
          left: inset,
          top: inset,
          right: inset,
          bottom: inset,
        }}
      />
    </Stack>
  );
}
