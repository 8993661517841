import { Grid } from "@mui/material";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { useIsMobile } from "src/utils/useIsMobile";
import { RecipientsAutocomplete } from "src/components/RecipientsAutocomplete/RecipientsAutocomplete";
import { TextInputBase } from "src/components/TextInputBase";
import { inviteFormEmailSchema } from "./UserInviteForm.schema";
import { UserInviteFormValues } from "./UserInviteForm.model";

export function UserInviteForm({ isDisabled }: { isDisabled?: boolean }) {
  const isMobile = useIsMobile();
  const { control } = useFormContext<UserInviteFormValues>();

  return (
    <Grid spacing={2} container>
      <Grid item xs={12} lg={6}>
        <Controller
          name="emails"
          control={control}
          render={({ field, fieldState }) => {
            const fieldError: FieldError = Array.isArray(fieldState.error)
              ? fieldState.error.filter(Boolean)[0]
              : fieldState.error;

            return (
              <RecipientsAutocomplete
                label="Email(s)"
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                fullWidth
                error={!!fieldError}
                helperText={fieldError?.message || " "}
                allowMultipleLines={isMobile ? 4 : undefined}
                disabled={isDisabled}
                validate={(item) => {
                  const { error } = inviteFormEmailSchema.validate(item);
                  return error?.message;
                }}
              />
            );
          }}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Controller
          name="comment"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextInputBase
                label="Comment (optional)"
                multiline={isMobile}
                disabled={isDisabled}
                maxRows={isMobile ? 4 : 1}
                minRows={isMobile ? 4 : 1}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={!!fieldState.error}
                helperText={fieldState.error?.message || " "}
                fullWidth
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
}
