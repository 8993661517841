import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { Logo } from "src/components/logo/Logo";
import ExpiredImg from "./expired.png";
import { UserInviteMarketingContent } from "../UserInviteMarketingContent/UserInviteMarketingContent";
import { UserInviteFooter } from "../UserInviteFooter";

const BG_COLOR = "#FFF";

export const UserInviteExpired = () => {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const [accessibilityMode] = useState(false);

  const commonContent = (
    <Stack flex={1} justifyContent="center" alignItems="center">
      <Stack width="364px" alignItems="center">
        <Box
          component="img"
          alt="Wave"
          src={ExpiredImg}
          sx={{
            width: "140px",
            height: "140px",
          }}
        />
        <Typography
          variant="h5"
          fontSize={"28px"}
          color={palette.common.black}
          pt={3}
        >
          Invitation has Expired
        </Typography>
        <Typography
          variant="body1"
          textAlign="center"
          color={palette.common.black}
          py={2}
        >
          We are sorry to inform, but your invitation link has expired. Please
          ask colleague to send new one.
        </Typography>
      </Stack>
    </Stack>
  );

  const logo = (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      bgcolor={BG_COLOR}
      width="100%"
      pt={3}
      position="relative"
    >
      <Logo />
    </Stack>
  );

  const footer = (
    <UserInviteFooter /* accessibilitySwitch={isMobile && accessibilitySwitch} */
    />
  );

  const marketingContent = <UserInviteMarketingContent />;

  const invitationContent = (
    <Stack
      sx={{
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      {/* !isMobile && accessibilitySwitch */}
      {commonContent}
      {!isMobile && footer}
    </Stack>
  );

  const desktopContent = (
    <Grid container sx={{ minHeight: "100vh" }}>
      <Grid item xs={6} sx={{ width: "100%" }}>
        {marketingContent}
      </Grid>
      <Grid
        item
        xs={6}
        sx={{ width: "100%", background: BG_COLOR, position: "relative" }}
      >
        {invitationContent}
      </Grid>
    </Grid>
  );

  const mobileContent = (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        height: "100%",
        width: "100%",
        background: BG_COLOR,
        backgroundImage:
          "url(https://tveyescorp.wpengine.com/wp-content/uploads/slider3/heroswirl.png)",
        backgroundSize: "max(130vh, 100%)",
        backgroundPosition: "center bottom -115px",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid item xs={12} pt={3}>
        {logo}
      </Grid>
      <Grid item xs={12} pt={10}>
        {invitationContent}
      </Grid>
      <Grid item xs={12} pt={5}>
        {footer}
      </Grid>
    </Grid>
  );

  return (
    <Stack
      sx={{
        flex: 1,
        backgroundColor: palette.background.brand,
        position: "relative",
        overflow: "hidden",
        filter: accessibilityMode ? "grayscale(1)" : "0",
      }}
    >
      {isMobile ? mobileContent : desktopContent}
    </Stack>
  );
};
