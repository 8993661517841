import {
  Badge,
  Button,
  ButtonProps,
  alpha,
  badgeClasses,
  useTheme,
} from "@mui/material";

export const ShareButtonDesktop = ({
  showShareDialog,
  badgeContent,
  ...props
}: ButtonProps & {
  showShareDialog: () => void;
  badgeContent?: number;
}) => {
  const { palette } = useTheme();
  const shareButton = (
    <Button
      variant="contained"
      color="secondary"
      sx={{
        minWidth: { xs: 100, md: 160 },
        height: 42,
      }}
      onClick={showShareDialog}
      {...props}
    >
      Share
    </Button>
  );

  const activeBadgeColor =
    palette.mode === "light" ? "success" : palette.secondary.dark;

  return badgeContent ? (
    <Badge
      badgeContent={badgeContent}
      color="success"
      sx={{
        [`.${badgeClasses.badge}`]: {
          top: 21,
          right: 34,
          backgroundColor: props.disabled
            ? palette.action.disabled
            : activeBadgeColor,
          color: alpha(palette.text.primary, 0.87),
        },
      }}
    >
      {shareButton}
    </Badge>
  ) : (
    shareButton
  );
};
