import { Button, MobileStepper, mobileStepperClasses } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export function UserInviteMarketingStepper({
  activeIndex,
  setActiveIndex,
}: {
  activeIndex?: number;
  setActiveIndex: (next: number) => void;
}) {
  return (
    <MobileStepper
      variant="dots"
      steps={2}
      position="static"
      activeStep={activeIndex}
      sx={{
        marginRight: "18px",
        maxWidth: 120,
        flexGrow: 1,
        background: "none",
        [`.${mobileStepperClasses.dot}:not(.${mobileStepperClasses.dotActive})`]:
          {
            background: "rgb(255 255 255 / 75%)",
          },
      }}
      nextButton={
        <Button
          size="small"
          onClick={() => setActiveIndex(1)}
          disabled={activeIndex === 1}
          sx={{
            borderRadius: "20px",
            background: "rgb(255 255 255 / 75%)",
            minWidth: "26px",
            width: "26px",
            height: "26px",
          }}
        >
          <KeyboardArrowRightIcon />
        </Button>
      }
      backButton={
        <Button
          size="small"
          onClick={() => setActiveIndex(0)}
          disabled={activeIndex === 0}
          sx={{
            borderRadius: "20px",
            background: "rgb(255 255 255 / 75%)",
            minWidth: "26px",
            width: "26px",
            height: "26px",
          }}
        >
          <KeyboardArrowLeftIcon />
        </Button>
      }
    />
  );
}
