import { formatISO } from "date-fns";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Add, Edit, Settings } from "@mui/icons-material";

import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { useWatchQueryList } from "src/api/useWatchQueryList";
import {
  DateSelector,
  DateSelectorMode,
} from "src/components/DateSelector/DateSelector";
import { useListFilter } from "src/utils/useListFilter";
import { useOpenState } from "src/utils/useOpenState";
import { PageLoader } from "src/components/PageLoader";
import { PageLayoutMobile } from "src/components/PageLayoutMobile";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { PageHeaderDesktop } from "src/components/PageHeaderDesktop/PageHeaderDesktop";
import { SearchInput } from "src/components/SearchInput";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";
import { ViewModeSwitch } from "src/components/ViewModeSwitch/ViewModeSwitch";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { ViewModeLayout } from "src/components/ViewModeLayout/ViewModeLayout";
import { isClusterQuery } from "src/utils/isClusterQuery";
import { isNormalQuery } from "src/utils/isNormalQuery";
import { WatchQueryFilterButton } from "src/components/WatchQueryFilterButton/WatchQueryFilterButton";
import { WatchQueryFilterDialog } from "src/components/WatchQueryFilterDialog/WatchQueryFilterDialog";
import { GlobalSearchDialog } from "src/components/GlobalSearchDialog/GlobalSearchDialog";
import { GlobalSearchDesktopButton } from "src/components/GlobalSearchDesktopButton/GlobalSearchDesktopButton";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import { makeNormalQueries } from "./WatchList.utils";
import { useWatchListClusterEditMode } from "./components/WatchListClusterEditToolbar/hooks/useWatchListClusterEditMode";
import { useSessionContext } from "src/api/useSessionContext";
import { useQueryDateRange } from "src/utils/useQueryDateRange";
import { useLimitWatchListSize } from "src/api/useLimitWatchListSize";
import { useGlobalSearchOpen } from "src/api/useGlobalSearchOpen";
import { useWatchQueryFilter } from "src/api/useWatchQueryFilter";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { useQueryPagination } from "src/utils/useQueryPagination";
import { WatchQueryCloneDialog } from "src/components/WatchQueryCloneDialog/WatchQueryCloneDialog";
import { watchListCreateRoute } from "../WatchListCreate/WatchListCreate.route";
import { WatchListClusterEditToolbar } from "./components/WatchListClusterEditToolbar/WatchListClusterEditToolbar";
import { WatchQueryList } from "./components/WatchQueryList/WatchQueryList";
import { WatchQueryTable } from "./components/WatchQueryTable/WatchQueryTable";
import { WatchListEmpty } from "./components/WatchListEmpty/WatchListEmpty";
import { WatchQueryTilesLazy } from "./components/WatchQueryTiles/WatchQueryTilesLazy";

const viewModeOptions: {
  desktop: ViewMode[];
  mobile: ViewMode[];
} = {
  desktop: [ViewMode.table, ViewMode.list, ViewMode.tile],
  mobile: [ViewMode.tile, ViewMode.table],
};

export function WatchListPage() {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const clusterEditMode = useWatchListClusterEditMode();
  const { effectiveEntitlements } = useSessionContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [pagination, setPagination] = useQueryPagination();
  const [filterText, setFilterText] = useState("");
  const filterQuery = useWatchQueryFilter();
  const filterDialog = useOpenState();

  const initialViewMode: ViewMode = isMobile ? ViewMode.tile : ViewMode.table;
  const [viewMode, setViewMode] = useViewModeQueryParam<ViewMode>({
    paramKey: "tab",
    defaultValue: initialViewMode,
  });

  const [dateRange, setDateRange] = useQueryDateRange();
  const watchQuerySearchDialog = useGlobalSearchOpen();

  const watchListSizeLimit = useLimitWatchListSize();
  const watchQueryList = useWatchQueryList({
    params: {
      filters: makeQueryDefinition(filterQuery.data),
      query: {
        from: pagination.page * pagination.pageSize,
        size: pagination.pageSize,
        startDate: formatISO(dateRange[0]),
        endDate: formatISO(dateRange[1]),
        includeSummary: true,
      },
    },
  });

  const queryCount = watchQueryList.data?.metadata?.queries ?? 0;
  const canCreateWatchQuery = queryCount < watchListSizeLimit;
  const enableEditWatchList = effectiveEntitlements.enableEditWatchList?.value;

  const { setClusterNames } = clusterEditMode;
  useEffect(() => {
    const clusterNames = watchQueryList.data?.results
      ?.filter(isClusterQuery)
      .map((cluster) => cluster.title);

    setClusterNames(clusterNames ?? []);
  }, [setClusterNames, watchQueryList.data?.results]);

  const querySummariesFilterData = useListFilter(
    makeNormalQueries(watchQueryList.data?.results || []),
    filterText,
    ["title", "clusterTitle"]
  );

  const clusterEditDesktop = viewMode === ViewMode.table && (
    <TooltipBase
      width={181}
      textAlign="left"
      title={t("editWatchListDisabled")}
      placement="bottom"
      disableHoverListener={enableEditWatchList}
    >
      <Box>
        <Button
          startIcon={<Edit />}
          variant="contained"
          disabled={!enableEditWatchList}
          color="info"
          sx={{ width: 164, height: 42 }}
          onClick={clusterEditMode.activate}
          children="Edit Clusters"
        />
      </Box>
    </TooltipBase>
  );

  const newTermButton = useMemo(
    () => (
      <TooltipBase
        width={181}
        textAlign="left"
        title={
          canCreateWatchQuery
            ? ""
            : "You can't create new term as you have an account limitation. Please contact your account manager to extend your limits."
        }
        placement="left"
      >
        <Box>
          <Button
            startIcon={<Add />}
            disabled={!canCreateWatchQuery}
            variant="contained"
            color="primary"
            onClick={() => navigate(watchListCreateRoute.path)}
            children="New Term"
            sx={{
              width: !isMobile ? 164 : undefined,
              height: isMobile ? 36 : 42,
            }}
          />
        </Box>
      </TooltipBase>
    ),
    [isMobile, navigate, canCreateWatchQuery]
  );

  const filterInput = useMemo(
    () => (
      <SearchInput
        placeholder="Filter term, cluster..."
        onTextChangeThrottled={setFilterText}
        sx={{ minWidth: 168 }}
      />
    ),
    []
  );

  const dateSelector = useMemo(
    () => (
      <DateSelector
        value={dateRange}
        onChange={setDateRange}
        isMobile={isMobile}
        mode={
          viewMode === ViewMode.table
            ? DateSelectorMode.fixed
            : DateSelectorMode.variable
        }
      />
    ),
    [dateRange, isMobile, setDateRange, viewMode]
  );

  const clusterEditMobileButton = useMemo(() => {
    return (
      viewMode === ViewMode.table && (
        <ToggleButtonGroup
          size="small"
          onClick={clusterEditMode.activate}
          children={
            <ToggleButton value={true}>
              <Settings sx={{ color: "text.primary" }} />
            </ToggleButton>
          }
        />
      )
    );
  }, [clusterEditMode.activate, viewMode]);

  const filterButton = useMemo(
    () => (
      <WatchQueryFilterButton
        value={filterQuery.data}
        onClick={filterDialog.show}
        isMobile={isMobile}
      />
    ),
    [filterDialog.show, filterQuery.data, isMobile]
  );

  const viewModeSwitch = useMemo(
    () => (
      <ViewModeSwitch
        value={viewMode}
        onChange={setViewMode}
        options={isMobile ? viewModeOptions.mobile : viewModeOptions.desktop}
        size={isMobile ? "small" : "medium"}
      />
    ),
    [isMobile, setViewMode, viewMode]
  );

  const mobileToolbar = useMemo(
    () => (
      <Stack pt={3}>
        {clusterEditMode.isActive && (
          <Stack px={2} children={<WatchListClusterEditToolbar />} />
        )}

        {!clusterEditMode.isActive && (
          <Stack
            px={2}
            direction="row"
            gap={2}
            alignItems="center"
            justifyContent="space-between"
          >
            {newTermButton}
            <Stack direction="row" gap={1}>
              {filterButton}
              {clusterEditMobileButton}
              {viewModeSwitch}
            </Stack>
          </Stack>
        )}

        <Stack px={2} py={2} children={filterInput} />

        {viewMode !== ViewMode.tile && <Stack px={1} children={dateSelector} />}
      </Stack>
    ),
    [
      dateSelector,
      filterButton,
      filterInput,
      newTermButton,
      clusterEditMobileButton,
      viewMode,
      viewModeSwitch,
      clusterEditMode.isActive,
    ]
  );

  const renderMobileHeader = useCallback(() => {
    return mobileToolbar;
  }, [mobileToolbar]);

  const dataViewElement = useMemo(() => {
    return (
      <ViewModeLayout
        current={viewMode}
        viewMap={{
          table: (
            <WatchQueryTable
              id="watch-query-table"
              rows={querySummariesFilterData}
              rowCount={watchQueryList.data?.total || 0}
              queries={watchQueryList.data?.results || []}
              dateRange={dateRange}
              showDensitySwitch={!isMobile}
              loading={watchQueryList.isFetching}
              paginationMode="server"
              paginationModel={pagination}
              onPaginationModelChange={setPagination}
              canCreateQuery={canCreateWatchQuery}
            />
          ),
          list: (
            <WatchQueryList
              id="watch-query-list"
              rows={querySummariesFilterData}
              rowCount={watchQueryList.data?.total || 0}
              queries={watchQueryList.data?.results || []}
              loading={watchQueryList.isFetching}
              paginationModel={pagination}
              onPaginationModelChange={setPagination}
              hideThumbnail={viewMode !== "list"}
              canCreateQuery={canCreateWatchQuery}
            />
          ),
          tile: (
            <WatchQueryTilesLazy
              id="watch-query-tiles"
              filterText={filterText}
              filterQuery={filterQuery.data}
              dateRange={dateRange}
              renderHeader={isMobile ? renderMobileHeader : undefined}
              hideThumbnail={viewMode !== "tile"}
              canCreateQuery={canCreateWatchQuery}
            />
          ),
        }}
      />
    );
  }, [
    dateRange,
    viewMode,
    isMobile,
    querySummariesFilterData,
    pagination,
    watchQueryList,
    setPagination,
    filterText,
    filterQuery.data,
    renderMobileHeader,
    canCreateWatchQuery,
  ]);

  if (watchQueryList.isLoading) {
    return <PageLoader />;
  }

  const queryList = watchQueryList.data?.results?.filter(isNormalQuery);
  if (!queryList?.length) {
    return <WatchListEmpty />;
  }

  const mobileContent = (
    <Stack rowGap={2} height="100%" overflow="hidden">
      {viewMode === ViewMode.tile ? null : mobileToolbar}
      {dataViewElement}
    </Stack>
  );

  const headerDesktop = (
    <PageHeaderDesktop
      title="Watchlist"
      hideBackButton
      toolbar={
        !clusterEditMode.isActive && (
          <Stack direction="row" spacing={2}>
            <GlobalSearchDesktopButton />
            {clusterEditDesktop}
            {newTermButton}
          </Stack>
        )
      }
      hideBreadcrumbs
    />
  );

  const desktopContent = (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        height: "100%",
      }}
    >
      <Grid container p={2} spacing={2}>
        <Grid
          item
          xs={12}
          lg={3}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          children={filterInput}
        />

        {clusterEditMode.isActive && (
          <Grid
            item
            xs={12}
            lg={9}
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            children={<WatchListClusterEditToolbar />}
          />
        )}

        {!clusterEditMode.isActive && (
          <Grid
            item
            xs={12}
            lg={true}
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            alignItems={{ lg: "center" }}
            children={dateSelector}
          />
        )}

        {!clusterEditMode.isActive && (
          <Grid
            item
            xs={12}
            lg="auto"
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            children={
              <Stack direction="row" spacing={1} justifyContent="flex-end">
                {filterButton}
                {viewModeSwitch}
              </Stack>
            }
          />
        )}
      </Grid>
      {dataViewElement}
    </Paper>
  );

  const footerDesktop = (
    <Stack direction="row" alignItems="center" pl={2} mb={2}>
      <Typography
        color={palette.text.disabled}
        children="* Hover watch term name to see monitored sources and exclusions."
      />
    </Stack>
  );

  const commonContent = (
    <Fragment>
      <WatchQueryFilterDialog
        open={filterDialog.isOpen}
        initial={filterQuery.data}
        onClose={filterDialog.hide}
      />

      <WatchQueryCloneDialog />

      <GlobalSearchDialog
        open={watchQuerySearchDialog.isOpen}
        onClose={watchQuerySearchDialog.hide}
      />
    </Fragment>
  );

  if (isMobile) {
    return (
      <Fragment>
        <PageLayoutMobile content={mobileContent} />
        {commonContent}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <PageLayoutDesktop
        header={headerDesktop}
        content={desktopContent}
        footer={footerDesktop}
        overflow="hidden"
      />
      {commonContent}
    </Fragment>
  );
}
