import {
  Box,
  IconButton,
  paperClasses,
  Snackbar,
  Typography,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { SnackbarBaseProps } from "./SnackbarBase.model";

export const SnackbarBase = ({
  onClose,
  title,
  subtitle,
  sxProps,
  ...props
}: SnackbarBaseProps) => {
  const { palette } = useTheme();
  return (
    <Snackbar
      autoHideDuration={5000}
      onClose={onClose}
      message={
        <Box
          sx={{
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "center",
            color: palette.common.white,
          }}
        >
          <Box>
            {title && (
              <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                {title}
              </Typography>
            )}

            {subtitle && (
              <Typography sx={{ fontSize: 14 }}>{subtitle}</Typography>
            )}
          </Box>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onClose}
            sx={{ ml: 1 }}
          >
            <Close fontSize="small" />
          </IconButton>
        </Box>
      }
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      sx={{
        left: 16,
        right: 16,
        bottom: 24,
        [`.${paperClasses.root}`]: {
          pr: 1,
          background: "rgba(50, 50, 50, 1)",
        },
        ...sxProps,
      }}
      {...props}
    />
  );
};
