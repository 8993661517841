import { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import {
  CarouselBase,
  CarouselBaseProps,
} from "src/components/CarouselBase/CarouselBase";
import { userInviteMarketingContent } from "./UserInviteMarketingContent.const";
import { CarouselItem } from "./UserInviteMarketingContent.model";
import { UserInviteMarketingStepper } from "./UserInviteMarketingStepper";

const BG_COLOR = "#FFF";

export function UserInviteMarketingContent() {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);

  const onChangeWrapper: CarouselBaseProps<CarouselItem>["onChange"] = (
    item
  ) => {
    const selectedIndex = userInviteMarketingContent.findIndex(
      (el) => el.id === item.id
    );

    setActiveIndex(selectedIndex);
  };

  const renderItemWrapper: CarouselBaseProps<CarouselItem>["renderItem"] = (
    item
  ) => (
    <Stack
      color={BG_COLOR}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "340px",
        marginTop: "100px",
      }}
    >
      <Typography variant="h3" pb={2}>
        {item.title}
      </Typography>
      <Typography variant="body1" mb={5}>
        {item.description}
      </Typography>
      <Button
        href={item.link}
        variant="outlined"
        sx={{
          color: BG_COLOR,
          borderRadius: "1.25rem",
          border: `1px solid ${BG_COLOR}`,
        }}
      >
        {item.linkText}
      </Button>
    </Stack>
  );

  return (
    <Stack
      sx={{
        height: "100%",
        overflow: "hidden",
        position: "relative",
        backgroundImage:
          "url(https://tveyescorp.wpengine.com/wp-content/uploads/slider3/heroswirl.png)",
        backgroundSize: "max(130vh, 100%)",
        backgroundPosition: "center top -100px",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Stack
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        height="100%"
      >
        <CarouselBase
          flex={1}
          height="50%"
          width={"100%"}
          data={userInviteMarketingContent}
          getItemId={(item) => `${item.id}`}
          current={activeIndex ?? 0}
          onChange={onChangeWrapper}
          renderItem={renderItemWrapper}
          sx={{
            textAlign: "center",
            ".embla__slide_active": {
              marginRight: "18px",
              justifyContent: "center",
            },
          }}
        />
      </Stack>
      <Stack justifyContent="center" alignItems="center" direction="row" py={4}>
        <UserInviteMarketingStepper
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />
      </Stack>
    </Stack>
  );
}
