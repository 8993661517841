import { useEffect, useRef, useState } from "react";
import { DateRange } from "@mui/icons-material";
import {
  BoxProps,
  Chip,
  Divider,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import { AppLink } from "src/components/AppLink/AppLink";
import { TileCheckBox } from "src/components/TileCheckBox/TileCheckBox";
import { ClipViewer } from "src/components/ClipViewer/ClipViewer";
import { TranscriptBlock } from "src/components/TranscriptBlock/TranscriptBlock";
import { TranscriptText } from "src/components/TranscriptText/TranscriptText";
import { TextLineClampProps } from "src/components/TextLineClamp/TextLineClamp.model";
import { ClipViewerProps } from "src/components/ClipViewer/ClipViewer.model";
import { useEventSelectionMediaCenter } from "src/api/useEventSelectionMediaCenter";
import { MediaCenterItem } from "src/api/useMediaCenter";
import { useSessionContext } from "src/api/useSessionContext";
import { useMediaCenterThumbnail } from "src/api/useMediaCenterThumbnail";
import {
  getMediaCenterResultDateTime,
  getMediaCenterResultEvent,
  getMediaCenterResultEventType,
  getMediaCenterResultId,
  getMediaCenterResultMediaType,
  getMediaCenterResultIndividualTranscript,
} from "src/api/useMediaCenter.getters";
import { getEventIcon, getEventTooltipTitle } from "src/utils/getEventIcon";
import { mediaCenterClipRoute } from "src/pages/MediaCenterClip/MediaCenterClip.route";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { MediaCenterActions } from "../MediaCenterActions/MediaCenterActions";
import { MediaCenterTagsControls } from "../MediaCenterTagsControls/MediaCenterTagsControls";

export function getEventData(
  data: MediaCenterItem,
  thumbnail?: string,
  mediaUrl?: string
) {
  return {
    ...data.eventHighlights,
    mediaCenter: true,
    mediaCenterThumbnail: thumbnail,
    mediaCenterMedia: mediaUrl,
    status: data?.status,
  };
}

export function MediaCenterTile({
  value,
  width = "100%",
  height = "100%",
  lineClamp = 2,
  isChecked,
  isPlaying,
  onChangePlayState,
  downloadMediaItem,
  addItemToReport,
}: {
  value: MediaCenterItem;
  isChecked: boolean;
  isPlaying?: boolean;
  width?: BoxProps["width"];
  height?: BoxProps["width"];
  lineClamp?: TextLineClampProps["lineClamp"];
  onChangePlayState?: ClipViewerProps["onChangePlayState"];
  downloadMediaItem: (value: MediaCenterItem) => void;
  addItemToReport: (value: MediaCenterItem) => void;
}) {
  const { palette } = useTheme();

  const [activeMention, setActiveMention] = useState(0);

  const eventType = getMediaCenterResultEventType(value);
  const mediaType = getMediaCenterResultMediaType(value);
  const Icon = getEventIcon(eventType, mediaType);
  const tooltipTitle = getEventTooltipTitle(eventType, mediaType);

  const eventTitle = getMediaCenterResultEvent(value);
  const detailsUrl = mediaCenterClipRoute.makeUrl({
    eventId: getMediaCenterResultId(value),
  });
  const selectedEventsState = useEventSelectionMediaCenter();

  const { userId } = useSessionContext();
  const thumbnail = useMediaCenterThumbnail({
    params: {
      path: {
        itemid: value.id,
      },
    },
  });

  const [tagsDisplayingMode, setTagsDisplayingMode] = useState<
    "direct" | "reverse"
  >("direct");

  const anchorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!anchorRef || !anchorRef.current) return;
    const rect = anchorRef.current.getBoundingClientRect();
    const { top } = rect;

    const { innerHeight } = window;

    const bottomSpace = innerHeight - top;
    const maxSpace = Math.max(bottomSpace, top);

    const updatedValue = maxSpace === top ? "reverse" : "direct";
    if (tagsDisplayingMode === updatedValue) return;

    setTagsDisplayingMode(updatedValue);
  }, [anchorRef, value, tagsDisplayingMode]);

  const handleChange = (checked: boolean) => {
    if (checked) {
      selectedEventsState.select(value);
    } else {
      selectedEventsState.unselect(value);
    }
  };

  return (
    <Stack
      border={`1px solid ${palette.divider}`}
      borderRadius={2}
      width={width}
      height={height}
      position="relative"
      ref={anchorRef}
    >
      <ClipViewer
        id={value.id}
        event={
          getEventData(
            value,
            thumbnail.data,
            `/api/mediacenter/${userId}/items/${value.id}/media`
          ) || {}
        }
        loading={thumbnail.isLoading}
        play={isPlaying}
        viewMode="compact"
        onChangePlayState={onChangePlayState}
        onMentionChange={setActiveMention}
        sx={{
          [`& > .MuiBox-root:first-of-type div`]: {
            borderTopRightRadius: 7, // not 8 because of outer 1px border
            borderTopLeftRadius: 7, // not 8 because of outer 1px border
            overflow: "hidden",
          },
          aspectRatio: "16/9",
          width: "100%",
        }}
      />

      <TileCheckBox value={isChecked} onChange={handleChange} zIndex={2} />

      <MediaCenterTagsControls
        sx={{
          position: "absolute",
          right: 9,
          top: 9,
        }}
        event={value}
        tagsDisplayingMode={tagsDisplayingMode}
      />

      <Divider />

      <Stack
        direction="row"
        pl={2}
        py={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack rowGap={1}>
          <Stack direction="row" alignItems="center">
            <Tooltip title={tooltipTitle}>
              <Stack>
                <Icon />
              </Stack>
            </Tooltip>
            <Tooltip title={eventTitle}>
              <AppLink to={detailsUrl}>
                <TextLineClamp
                  variant="subtitle1"
                  color="primary"
                  ml={1}
                  lineClamp={1}
                  height={20}
                >
                  {eventTitle}
                </TextLineClamp>
              </AppLink>
            </Tooltip>
          </Stack>

          <Stack direction="row" alignItems="center">
            <DateRange sx={{ color: palette.action.active }} />
            {value.status === "Complete" ? (
              <Typography variant="body2" ml={1}>
                {getMediaCenterResultDateTime(value).toLocaleString()}
              </Typography>
            ) : (
              <Chip
                icon={<UploadIcon sx={{ width: "18px", height: "18px" }} />}
                label="In progress"
                variant="outlined"
                sx={{
                  height: "18px",
                  py: "12px",
                  ml: 2,
                }}
              />
            )}
          </Stack>
        </Stack>
        <MediaCenterActions
          value={value}
          downloadMediaItem={downloadMediaItem}
          addItemToReport={addItemToReport}
        />
      </Stack>
      <TranscriptBlock borderRadius={1} mx={2} mb={2} px={2} py={1}>
        <TranscriptText
          transcript={getMediaCenterResultIndividualTranscript(
            value,
            activeMention
          )}
          lineClamp={lineClamp}
        />
      </TranscriptBlock>
    </Stack>
  );
}
