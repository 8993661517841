import axios from "axios";
import { UseQueryOptions, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { ActivityLogItem } from "./useAccountActivityLogs";

const apiPath: keyof paths = "/api/activity/user/{userId}";
const method: keyof paths[typeof apiPath] = "get";

type Endpoint = paths[typeof apiPath][typeof method];
type Parameters = Endpoint["parameters"];

type ResponseBody = Endpoint["responses"][200]["content"]["application/json"];

// FIXME: this is to make response fields non-optional,
// keep an eye for type updates and get rid of this mock
type UserLogsResponse = {
  total?: ResponseBody["total"];
  metadata?: ResponseBody["metadata"];
  results?: ActivityLogItem[];
  configuration?: ResponseBody["configuration"];
};

export const useUserActivityLogsKey = "useUserActivityLogsKey";

export function useUserActivityLogs({
  request,
  options,
}: {
  request?: Parameters;
  options?: UseQueryOptions<UserLogsResponse, unknown>;
}) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<UserLogsResponse, unknown>({
    ...options,
    queryKey: [useUserActivityLogsKey, request],
    keepPreviousData: true,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Parameters>(apiPath, request);
      const { data } = await axios<UserLogsResponse>(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
