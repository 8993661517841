import TuneIcon from "@mui/icons-material/Tune";
import { Badge, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { countQueryFilters } from "src/utils/countQueryFilters";
import { WatchQueryFilterButtonProps } from "./WatchQueryFilterButton.model";

export function WatchQueryFilterButton({
  value,
  isMobile,
  onClick,
  ...props
}: WatchQueryFilterButtonProps) {
  const title = props.title || "Apply Watchlist filters";
  const badgeContent = countQueryFilters(value);

  return (
    <Tooltip title={title}>
      <Badge color="warning" badgeContent={badgeContent}>
        <ToggleButtonGroup size={isMobile ? "small" : "medium"}>
          <ToggleButton value={true} onClick={onClick}>
            <TuneIcon sx={{ color: "text.primary" }} />
          </ToggleButton>
        </ToggleButtonGroup>
      </Badge>
    </Tooltip>
  );
}
