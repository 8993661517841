import { RefObject, useEffect } from "react";
import { alpha, useEventCallback, useTheme } from "@mui/material";

enum OverflowEffectClass {
  top = "overflow-effect-top",
  bottom = "overflow-effect-bottom",
}

/** Update overflow effect inline-styles */
const updateStyles = (el: HTMLElement, style: Record<string, string>) => {
  const entries = Object.entries(style);
  for (const [propName, propValue] of entries) {
    el.style.setProperty(propName, propValue);
  }
};

/** Returns overflow effect style depending on the container edge */
const makeOverflowEffectStyle = ({
  offset,
  edge,
  color,
  size,
}: {
  // scroll offset from the edge for effect placement
  offset: number;
  // edge of the effect
  edge: "top" | "bottom";
  // overflow effect color
  color: string;
  // overflow effect size
  size: number;
}): Record<string, string> => {
  switch (edge) {
    case "top":
      const top = Math.min(-size + offset, 0);

      return {
        position: "absolute",
        pointerEvents: "none",
        width: "100%",
        height: `${size}px`,

        top: `${top}px`,
        background: `linear-gradient(180deg, ${color}, transparent)`,
      };
    case "bottom":
      const bottom = Math.min(-size + offset, 0);

      return {
        position: "absolute",
        pointerEvents: "none",
        width: "100%",
        height: `${size}px`,

        bottom: `${bottom}px`,
        background: `linear-gradient(0deg, ${color}, transparent)`,
      };
  }
};

/** Create or update overflow effect elements for the root container */
const decorateScrollContainer = ({
  root,
  className,
  style,
}: {
  root: HTMLElement;
  className: OverflowEffectClass;
  style: Record<string, string>;
}) => {
  const existing = root.querySelector(`.${className}`) as HTMLElement | null;

  if (existing) {
    updateStyles(existing, style);
  } else {
    const el = document.createElement("div");
    el.classList.add(className);
    updateStyles(el, style);
    root.appendChild(el);
  }
};

/**
 * Decorate element with overflow effect
 */
export function useOverflowEffectDecoration({
  shadowColor,
  shadowSize = 32,
  shadowOpacity = 0.7,
  darkShadowOpacity = 0.5,

  outerRef,
  scrollRef,
}: {
  shadowColor?: string;
  shadowSize?: number;
  shadowOpacity?: number;
  darkShadowOpacity?: number;

  /** a ref to the element to decorate with effects */
  outerRef: RefObject<HTMLDivElement>;
  /** a ref to the scroll container to listen for scroll events */
  scrollRef: RefObject<HTMLDivElement>;
}) {
  const { palette } = useTheme();

  const defaultShadowColor =
    palette.mode === "light"
      ? alpha(palette.common.white, shadowOpacity)
      : alpha(palette.common.black, darkShadowOpacity);

  const boxShadowColor = shadowColor
    ? alpha(shadowColor, shadowOpacity)
    : defaultShadowColor;

  const onScrollWrapper = useEventCallback((e: Event) => {
    if (!outerRef.current) return;

    const el = e.currentTarget as HTMLElement;
    const { scrollHeight = 0, scrollTop = 0, clientHeight = 0 } = el;

    // top effect
    const overflowEffectTopStyle = makeOverflowEffectStyle({
      offset: scrollTop,
      edge: "top",
      color: boxShadowColor,
      size: shadowSize,
    });

    decorateScrollContainer({
      root: outerRef.current,
      style: overflowEffectTopStyle,
      className: OverflowEffectClass.top,
    });
    // ^^ top effect

    // bottom effect
    const overflowEffectBottomStyle = makeOverflowEffectStyle({
      offset: scrollHeight - (scrollTop + clientHeight),
      edge: "bottom",
      color: boxShadowColor,
      size: shadowSize,
    });

    decorateScrollContainer({
      root: outerRef.current,
      style: overflowEffectBottomStyle,
      className: OverflowEffectClass.bottom,
    });
    // ^^ bottom effect
  });

  useEffect(() => {
    if (!scrollRef.current) return;

    const scroll = scrollRef.current;

    scroll.addEventListener("scroll", onScrollWrapper);

    return () => {
      scroll.removeEventListener("scroll", onScrollWrapper);
    };
  }, [onScrollWrapper, scrollRef]);
}
