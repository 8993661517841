import { useEffect, useState } from "react";

export function useCopyButtonState() {
  const [wasCopied, setWasCopied] = useState(false);

  useEffect(() => {
    if (wasCopied) {
      setTimeout(() => {
        setWasCopied(false);
      }, 2500);
    }
  }, [wasCopied]);

  const copy = () => setWasCopied(true);

  return {
    wasCopied,
    copy,
  };
}
